import { useState } from 'react';
import { BuildOutlined, EditOutlined, InsertChartOutlined, QueryStatsOutlined, HistoryEduOutlined, OutlinedFlag, BusinessCenterOutlined } from "@mui/icons-material";
import { EntityForm, MeasureEntityForm } from "components";
import { Button as MuiButton, Card, CardActions, CardHeader, CardMedia, Divider, IconButton, Stack, Tooltip, Typography, useTheme, CardContent, } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectEntities, selectMeasureEntities } from "redux/entitySlice";
import { permissionSelector } from "redux/userSlice";

function Button({ children, ...props }) {
    return <div><MuiButton {...props}>{children}</MuiButton></div>;
}


export default function EntityCard(props) {
    const { entityId, disableActions, customAction, showDescription } = props;
    const entity = useSelector(selectEntities)[entityId];
    const measureEntity = useSelector(selectMeasureEntities)[entityId];
    const themeMode = useTheme().palette.mode;
    const navigate = useNavigate();
    const [openForm, setOpenForm] = useState(false);
    const [newEntityForm, setNewEntityForm] = useState(false);
    const [openNewMeasureForm, setOpenNewMeasureForm] = useState(false);

    const updateEntityPermission = useSelector((state) => permissionSelector(state, 'update-entity'));
    const viewGoalPermission = useSelector((state) => permissionSelector(state, 'view-goal'));
    const viewMetricPermission = useSelector((state) => permissionSelector(state, 'view-metric'));
    const viewMeasurementPermission = useSelector((state) => permissionSelector(state, 'view-measurement'));
    const viewTopicPermission = useSelector((state) => permissionSelector(state, 'view-topic'));
    const configureMeasureEntityPermission = useSelector((state) => permissionSelector(state, 'update-entity'));
    const viewActivitiesPermission = useSelector((state) => permissionSelector(state, 'view-activity'));

    const getImage = () => {
        if (entity.attributes && entity.attributes.picture) return `${process.env.REACT_APP_API_BASE_URL}/${entity.attributes.picture}`
        else return `${process.env.PUBLIC_URL}/assets/images/default/default.${themeMode}.png`
    }
    
    if (!entity) return null;

    return <Card sx={{ width: '100%', minWidth: '200px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div>
            <CardMedia
                component="img"
                height="200"
                image={getImage()}
                alt={entity.name + " - image"}
            />
            <CardHeader
                title={<Stack direction="row" gap={1} alignItems="flex-start">
                    <Tooltip title={!disableActions ? <FormattedMessage id="ENTITIES" /> : null} placement='right' arrow>
                        <span>
                            <Typography variant='h5' onClick={!disableActions ? () => navigate('/entities/' + entityId) : null} sx={!disableActions ? (theme => ({ ':hover': { color: theme.palette.primary.main }, cursor: 'pointer' })) : undefined}>{entity.name}</Typography>
                        </span>
                    </Tooltip>
                </Stack>}
                subheader={entity.geoProperties.address}
                action={!disableActions && updateEntityPermission && [
                    <Tooltip key="edit-icon" title={<FormattedMessage id="EDIT" />} placement="bottom" arrow>
                        <IconButton key="edit-icon" onClick={() => setOpenForm(true)}><EditOutlined /></IconButton>
                    </Tooltip>
                ]}
            />
        </div>
        {!disableActions && <div>
            {(viewActivitiesPermission || viewMeasurementPermission || configureMeasureEntityPermission) && <><Divider />
                <CardActions sx={{ justifyContent: 'space-between' }}>
                    {viewActivitiesPermission && <Button size='small' key="activities" startIcon={<BusinessCenterOutlined />} onClick={() => navigate('/activities/' + entityId)}>
                        <FormattedMessage id="ACTIVITIES" />
                    </Button>}
                    {viewActivitiesPermission && (viewMeasurementPermission || configureMeasureEntityPermission) && <Divider orientation='vertical' flexItem />}
                    {measureEntity
                        ? (viewMeasurementPermission && <Button size='small' key="measurements" startIcon={<InsertChartOutlined />} onClick={() => navigate('/measureEntities/' + entityId)}>
                            <FormattedMessage id="MEASUREMENTS" />
                        </Button>)
                        : (configureMeasureEntityPermission && <Button size='small' startIcon={<BuildOutlined />} onClick={configureMeasureEntityPermission ? () => setOpenNewMeasureForm(true) : undefined}>
                            <Typography variant='inherit' component="div" noWrap><FormattedMessage id="CONFIGURE.DATA" /></Typography>
                        </Button>)
                    }
                </CardActions>
            </>}
            {(viewTopicPermission || viewGoalPermission || viewMetricPermission) && <><Divider />
                <CardActions sx={{ justifyContent: 'space-between' }}>
                    {viewTopicPermission && <>
                        <Button size='small' startIcon={<HistoryEduOutlined />} onClick={() => navigate('/topics/' + entityId)}>
                            <FormattedMessage id="ESG.TOPICS" />
                        </Button>
                        {(viewGoalPermission || viewMetricPermission) && <Divider orientation='vertical' flexItem />}
                    </>}
                    {viewGoalPermission && <>
                        <Button size='small' startIcon={<OutlinedFlag />} onClick={() => navigate('/goals/' + entityId)}>
                            <FormattedMessage id="ESG.GOALS" />
                        </Button>
                        {viewMetricPermission && <Divider orientation='vertical' flexItem />}
                    </>}
                    {viewMetricPermission && <Button size='small' key="metrics" startIcon={<QueryStatsOutlined />} onClick={() => navigate('/metrics/' + entityId)}>
                        <FormattedMessage id="ESG.METRICS" />
                    </Button>}
                </CardActions>
            </>}
        </div>}
        {showDescription && entity.description && <CardContent>
            <Typography fontStyle='italic'>
                {entity.description}
            </Typography>
        </CardContent>}
        {
            customAction && <>
                {!disableActions && <Divider />}
                <CardActions>
                    {customAction}
                </CardActions>
            </>
        }

        <MeasureEntityForm entity={entity} open={openNewMeasureForm} onClose={() => setOpenNewMeasureForm(false)} />
        <EntityForm openForm={openForm} setOpenForm={setOpenForm} entity={entity} />
        <EntityForm openForm={newEntityForm} setOpenForm={setNewEntityForm} parentId={entity._id} />
    </Card >;
}