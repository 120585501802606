import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { ListItemButton, ListItemIcon, ListItemText, List, Tooltip, Badge, ListSubheader, Collapse } from '@mui/material';
import { AddchartOutlined, ViewQuiltOutlined, LanOutlined, MapOutlined, BadgeOutlined, ScienceOutlined, MessageOutlined, NotificationImportantOutlined, BusinessCenterOutlined, GroupsOutlined, VerifiedUserOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import API from 'api';


const MenuItems = (props) => {
    const { selectedItem, open, setAlert } = props;
    const { token } = useSelector(userSelector);
    const [count, setCount] = useState(null);
    const navigate = useNavigate();
    const viewDashboardPermission = useSelector((state) => permissionSelector(state, 'view-dashboard'));
    const viewNotesPermission = useSelector((state) => permissionSelector(state, 'view-notes'));
    const createMeasurementPermission = useSelector((state) => permissionSelector(state, 'create-measurement'));
    const updateMetricPermission = useSelector((state) => permissionSelector(state, 'update-metric'));
    const viewMeasurementTypesPermission = useSelector((state) => permissionSelector(state, 'view-measurementType'));
    const viewActivityPermission = useSelector((state) => permissionSelector(state, 'view-activity'));
    const viewAlarmsPermission = useSelector((state) => permissionSelector(state, 'view-alarms'));
    const viewAlarmProfilesPermission = useSelector((state) => permissionSelector(state, 'view-alarmProfiles'));
    const viewStakeholdersPermission = useSelector((state) => permissionSelector(state, 'view-stakeholders'));
    const viewUsersPermission = useSelector((state) => permissionSelector(state, 'view-users'));
    const viewRolesPermission = useSelector((state) => permissionSelector(state, 'view-role'));

    const navArray = [
        {

            subheader: <FormattedMessage id="MANAGEMENT" />,
            permission: true,
        },

        {
            item: 'DashboardPage',
            onClick: () => navigate("/dashboard"),
            iconElement: <ViewQuiltOutlined sx={{ width: 26, height: 26 }} />,
            textElement: <FormattedMessage id="DASHBOARD" />,
            permission: viewDashboardPermission,
        },
        {
            item: 'EntitiesPage',
            onClick: () => navigate("/entities"),
            iconElement: <LanOutlined />,
            textElement: <FormattedMessage id="ORGANIZATION" />,
            permission: true,
        },
        {
            item: 'MapPage',
            onClick: () => navigate("/map"),
            iconElement: <MapOutlined />,
            textElement: <FormattedMessage id="MAP" />,
            permission: true,
        },

        {
            subheader: <FormattedMessage id="DATAS" />,
            permission: viewNotesPermission
                || viewAlarmsPermission
                || viewAlarmProfilesPermission
                || createMeasurementPermission
                || updateMetricPermission,
        },

        {
            item: 'MeasurementsEntryPage',
            onClick: () => navigate("/measurementsEntry"),
            iconElement: <AddchartOutlined />,
            textElement: <FormattedMessage id="MEASUREMENTS_ENTRY" />,
            permission: createMeasurementPermission || updateMetricPermission
        },

        {
            item: 'NotesPage',
            onClick: () => navigate("/notes"),
            iconElement: <MessageOutlined />,
            textElement: <FormattedMessage id="NOTES" />,
            badgeContent: 'notes',
            permission: viewNotesPermission,
        },

        {
            item: 'AlarmsPage',
            onClick: () => navigate("/alarms"),
            iconElement: <NotificationImportantOutlined />,
            textElement: <FormattedMessage id="ALARMS" />,
            badgeContent: 'alarms',
            permission: viewAlarmsPermission || viewAlarmProfilesPermission,
        },

        {
            subheader: <FormattedMessage id="INFORMATION" />,
            permission: viewMeasurementTypesPermission || viewActivityPermission || viewStakeholdersPermission,
        },

        {
            item: 'MeasurementTypesPage',
            onClick: () => navigate("/measurementTypes"),
            iconElement: <ScienceOutlined />,
            textElement: <FormattedMessage id="MEASUREMENT_TYPES" />,
            permission: viewMeasurementTypesPermission,
        },

        {
            item: 'ActivitiesPage',
            onClick: () => navigate("/activities"),
            iconElement: <BusinessCenterOutlined />,
            textElement: <FormattedMessage id="ACTIVITIES" />,
            permission: viewActivityPermission,
        },

        {
            item: 'StakeholdersPage',
            onClick: () => navigate("/stakeholders"),
            iconElement: <GroupsOutlined />,
            textElement: <FormattedMessage id="STAKEHOLDERS" />,
            permission: viewStakeholdersPermission
        },
    ];

    const usersArray = [
        {
            item: 'AdministrationPage',
            iconElement: viewUsersPermission ? <BadgeOutlined /> : <VerifiedUserOutlined />,
            textElement: <FormattedMessage id={viewUsersPermission ? "USERS" : "ROLES"} />
        },

    ];

    useEffect(() => {
        if (token) {
            API.configuration.getNotifications(token).then((items) => {
                if (items.data) setCount(items.data);
                else setCount(null);
            }).catch((error) => {
                setCount({});
                console.error(error);
                setAlert({ open: true, messageId: error?.data?.id || "ERROR", severity: "error" });
            })
        }
    }, [token, open, setAlert]);

    return <>
        <List component="nav" style={{ height: 1 }}>
            {navArray.map((item, index) => {
                if (!item.permission) return null;
                else if (item.subheader) return <Collapse key={index} component="li" in={open}>
                    <ListSubheader component="span">{item.subheader}</ListSubheader>
                </Collapse>;
                else return <ListItemButton key={item.item} selected={item.item === selectedItem} onClick={() => item.onClick()}>
                    <ListItemIcon>
                        <Tooltip title={open ? "" : item.textElement} placement="right" arrow>
                            <Badge badgeContent={count ? count[item.badgeContent] || "" : ""} invisible={!item.badgeContent || !count || (count && !count[item.badgeContent])} color="primary" max={9}>
                                {item.iconElement}
                            </Badge>
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={item.textElement} />
                </ListItemButton>;
            })}
            {(viewUsersPermission || viewRolesPermission) && <Collapse component="li" in={open}>
                <ListSubheader component="span"><FormattedMessage id="ADMIN" /></ListSubheader>
            </Collapse>}
            {(viewUsersPermission || viewRolesPermission) && usersArray.map((item) => (
                <ListItemButton key={item.item} selected={item.item === selectedItem} onClick={() => navigate('/administration/' + (viewUsersPermission ? "users" : "roles"))}>
                    <ListItemIcon>
                        <Tooltip title={open ? "" : item.textElement} placement="right" arrow>
                            {item.iconElement}
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={item.textElement} />
                </ListItemButton>
            ))}
            <ListItemButton disabled />
        </List>
    </>
};

export default MenuItems;