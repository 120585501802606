import { Button, Chip, Dialog, DialogActions, DialogContent, Divider, useMediaQuery } from "@mui/material";
import { DialogFormTitle, AlarmProfileFormContent, AlertSnackbar } from "components";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectSeverityTypes, selectMeasurementsTypesConf, selectUnits } from "redux/configurationSlice";
import { selectEntities } from "redux/entitySlice";
import { userSelector } from "redux/userSlice";
import API from 'api';



export default function AlarmProfileForm({ profile, open, onClose, copyFlag, setReset }) {
    const intl = useIntl();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const allRules = useSelector(selectSeverityTypes);
    const allEntities = useSelector(selectEntities);
    const { token } = useSelector(userSelector);
    const [alert, setAlert] = useState({ open: false });
    const [editState, setEditState] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const [name, setName] = useState("");
    const [entity, setEntity] = useState(null);
    const [alarmRules, setAlarmRules] = useState({});
    const [usedTypes, setUsedTypes] = useState([]);

    const measurementTypes = useSelector(selectMeasurementsTypesConf);
    const units = useSelector(selectUnits);

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (profile._id) {
            setEditState(!copyFlag);
            setName(profile.name + (copyFlag ? ` (${intl.formatMessage({ id: 'COPY.COPY' })})` : ""));
            setEntity(allEntities[profile.entityId]);
            setAlarmRules(profile.rules ? { ...profile.rules } : {});
            setUsedTypes(profile.rules ? allRules.map(el => el.value).filter(el => Object.keys(profile.rules).includes(el)) : []);
        }
        else {
            setName("");
            setEntity(null);
            setAlarmRules({});
            setUsedTypes([]);
        }
    }, [profile, copyFlag, allRules, allEntities, open, intl]);

    const alarmProfileValidation = useCallback(() => {
        if (!Object.keys(alarmRules).length) return true;

        for (const rule of Object.values(alarmRules)) {
            if (!rule.filters.length) return true;

            for (const filter of rule.filters) {
                if (!filter.conditions.length || ((filter.type === "AND" || filter.type === "OR") && filter.conditions.length < 2)) {
                    return true;
                } else {
                    for (const condition of filter.conditions) {
                        if (!condition.measurementTypeKey || (units[measurementTypes.find(el => el.key === condition.measurementTypeKey)?.dataType?.measure] && condition.unit === '')) {
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }, [alarmRules, measurementTypes, units]);

    const handleClose = () => {
        setName("");
        setEntity(null);
        setAlarmRules({});
        setUsedTypes([]);
        onClose();
    }

    const handleSubmit = () => {
        setDisableSubmit(true);
        const newAlarmProfileObject = {
            name: name,
            entityId: entity?._id,
            rules: {
                ...alarmRules,
            }
        };

        if (copyFlag || !profile._id) {
            API.alarmProfile.createAlarmProfile(newAlarmProfileObject, token).then(item => {
                if (item && item.data) {
                    handleClose();
                    setReset(reset => reset + 1);
                    setAlert({ open: true, messageId: "SUCCESS.CREATE", severity: "success" });
                    setDisableSubmit(false);
                }
            }).catch(error => {
                setAlert({ open: true, messageId: (error.data && error.data.id) || "ERROR.NOT_CREATED", severity: "error" });
                console.error('Error creating alarm profile:', error);
                setDisableSubmit(false);
            })
        }
        else {
            API.alarmProfile.updateAlarmProfile(profile._id, newAlarmProfileObject, token).then(item => {
                if (item && item.data) {
                    handleClose();
                    setReset(reset => reset + 1);
                    setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
                    setDisableSubmit(false);
                }
            }).catch(error => {
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_UPDATED", severity: "error" });
                console.error('Error updating alarm profile:', error);
                setDisableSubmit(false);
            })
        }
    }

    const updateAlarm = useCallback((data, type, rule, filtIndex, condIndex) => {
        setEditState(false);
        switch (type) {
            case 'name':
                setName(data);
                return;
            case 'entity':
                setEntity(data);
                return;
            case 'rules-edit':
                setAlarmRules({
                    ...alarmRules,
                    [rule]: {
                        ...alarmRules[rule],
                        notificationMethod: data
                    }
                })
                return;
            case 'rule-severity-edit':
                const editedSeverityRules = {
                    ...alarmRules,
                    [data]: alarmRules[rule],
                    [rule]: undefined
                }

                delete editedSeverityRules[rule]
                setAlarmRules(editedSeverityRules)

                let newUsedTypes = usedTypes
                if (newUsedTypes.includes(data)) {
                    newUsedTypes.splice(newUsedTypes.indexOf(data), 1)
                }

                newUsedTypes.splice(newUsedTypes.indexOf(rule), 1, data)
                setUsedTypes(newUsedTypes)
                return;
            case 'rules-add':
                setAlarmRules({
                    ...alarmRules,
                    [data]: {
                        type: "SIMPLE",
                        details: "",
                        filters: [],
                        notificationMethod: [],
                    }
                });
                setUsedTypes([...usedTypes, data]);
                return;
            case 'rules-delete':
                const newRules = {
                    ...alarmRules,
                    [data]: undefined
                };
                delete newRules[data];
                setAlarmRules(newRules);
                setUsedTypes(usedTypes.filter(el => el !== data));
                return;
            case 'message':
                setAlarmRules({
                    ...alarmRules,
                    [rule]: {
                        ...alarmRules[rule],
                        details: data
                    }
                })
                return;
            case 'contacts':
                setAlarmRules({
                    ...alarmRules,
                    [rule]: {
                        ...alarmRules[rule],
                        contacts: data
                    }
                })
                return;
            case 'filters':
                const ruleFilters = [...alarmRules[rule].filters];
                if (data === undefined) ruleFilters.splice(filtIndex, 1);
                else ruleFilters.splice(filtIndex, 1, data);
                setAlarmRules({
                    ...alarmRules,
                    [rule]: {
                        ...alarmRules[rule],
                        filters: ruleFilters
                    }
                });
                break;
            case 'condition':
                const conditions = [...alarmRules[rule].filters[filtIndex].conditions];
                const filters = [...alarmRules[rule].filters];

                if (data === undefined) conditions.splice(condIndex, 1);
                else conditions.splice(condIndex, 1, data);

                filters[filtIndex].conditions = conditions;
                setAlarmRules({
                    ...alarmRules,
                    [rule]: {
                        ...alarmRules[rule],
                        filters
                    }
                });
                break;
            default:
                if (profile._id) {
                    setName(profile.name);
                    setEntity(allEntities[profile.entityId]);
                    setAlarmRules(profile.rules);
                    setUsedTypes(allRules.map(el => el.value).filter(el => Object.keys(profile.rules).includes(el)))
                }
                else {
                    setName("");
                    setEntity(null);
                    setAlarmRules({});
                    setUsedTypes([]);
                }
                return;
        }
    }, [profile, allEntities, alarmRules, allRules, usedTypes]);

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <Dialog open={open} maxWidth="md" fullWidth fullScreen={smallScreen}>
            <DialogFormTitle mainTitle={<span><FormattedMessage id={"ALARMS.PROFILES." + ((profile._id && !copyFlag) ? "EDIT" : "ADD")} /> {copyFlag && <Chip label={<FormattedMessage id="COPY" />} size="small" />}</span>} />
            <Divider />
            <DialogContent>
                <AlarmProfileFormContent
                    name={name}
                    entity={entity}
                    alarmRules={alarmRules}
                    usedTypes={usedTypes}
                    updateAlarm={updateAlarm}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button color="warning" onClick={handleClose}><FormattedMessage id="CANCEL" /></Button>
                <Button disabled={disableSubmit || name === "" || entity === null || editState || alarmProfileValidation()} onClick={handleSubmit}><FormattedMessage id={(profile._id && !copyFlag) ? "EDIT" : "ADD"} /></Button>
            </DialogActions>
        </Dialog>
    </>
}