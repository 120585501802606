import { RuleOutlined, ChevronLeftOutlined, SearchOutlined, SportsScoreOutlined, TroubleshootOutlined, PlagiarismOutlined, PreviewOutlined, HistoryOutlined } from "@mui/icons-material";
import { Alert, Autocomplete, ButtonGroup, Card, CardContent, CardHeader, Chip, Dialog, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { permissionSelector } from 'redux/userSlice';
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router";
import { AssignMetric, CustomDataGrid, ExpandedGridCell, LoadingData, MetricDetailsView, MetricHistoryReportView, MetricReportDetailCard } from 'components';
import { useSelector } from "react-redux";
import { selectEntities } from "redux/entitySlice";
import { CustomButton } from "components/entityLinksButtons/EntityLinksButtons";
import { userSelector } from "redux/userSlice";
import { selectMeasurementsTypesConf } from "redux/configurationSlice";
import { formatDateLocale } from "utils";
import { ValueCell } from "components/metricReportDetailCard/MetricReportDetailCard";
import API from 'api';

const NoDataCell = () => <Typography variant="inherit" color="text.disabled" component="span"><FormattedMessage id="NO_DATA" /></Typography>;

export default function SingleTopicView({ topic, onChangeView }) {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const { token } = useSelector(userSelector);
    const intl = useIntl();
    const queryId = useParams().entityId;
    const allEntities = useSelector(selectEntities);
    const measurementTypes = useSelector(selectMeasurementsTypesConf)
    const [searchFilter, setSearchFilter] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedMetric, setSelectedMetric] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [openDetails, setOpenDetails] = useState(false);
    const [openHistoryList, setOpenHistoryList] = useState(false);
    const [openRowDetails, setOpenRowDetails] = useState(false);
    const complexMetrics = topic?.complexmetrics?.length > 0;

    const viewMetricPermission = useSelector((state) => permissionSelector(state, 'view-metric'));
    const updateTopicPermission = useSelector((state) => permissionSelector(state, 'update-topic'));

    const searchOptions = complexMetrics
        ? (topic.metrics
            ? [...topic.metrics.map(el => el.name), ...topic.complexmetrics.map(el => el.name)]
            : topic.complexmetrics.map(el => el.name)
        )
        : topic.metrics ? topic.metrics.map(el => el.name) : [];

    const topicParent = topic?.connections.parentId;

    const headers = [
        {
            field: 'name',
            headerName: intl.formatMessage({ id: 'NAME' }),
            renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
            minWidth: 100,
            flex: 2,
        },
        {
            field: 'lastUpdated',
            headerName: intl.formatMessage({ id: 'LATEST_REPORT' }),
            renderCell: (row) => <ExpandedGridCell value={row.value || <NoDataCell />} width={row.colDef.computedWidth} />,
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'value',
            headerName: intl.formatMessage({ id: 'VALUE' }),
            valueGetter: (row) => row.value?.description ? row.value.description : row.value,
            renderCell: (row) => row.value !== null && row.value !== undefined ? <ValueCell tableCell variant="inherit" color="inherit" subtitle={row.row.name} prefix={row.row?.prefix} value={row.value} suffix={`${row.row.metricId?.reportData?.calculationMethod !== "count" ? (row.row?.unit || "") : ""} ${row.row?.suffix || ""}`} /> : <NoDataCell />,
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'actions',
            headerName: intl.formatMessage({ id: 'ACTIONS' }),
            type: 'actions',
            getActions: (el) => [
                <Tooltip title={<FormattedMessage id="ESG.METRIC.VIEW" />} placement="bottom" key={'VIEW'} arrow>
                    <IconButton component="span" color="primary" onClick={() => { setSelectedMetric(el.row); setOpenDetails(true) }}><PreviewOutlined /></IconButton>
                </Tooltip>,
                <Tooltip title={<FormattedMessage id="ESG.METRIC.PREVIOUS_REPORTS_VIEW" />} placement="bottom" key={'PREVIOUS_VIEW'} arrow>
                    <IconButton component="span" color="primary" onClick={() => { setSelectedMetric(el.row); setOpenHistoryList(true) }}><HistoryOutlined /></IconButton>
                </Tooltip>
            ],
            minWidth: 200
        }
    ];

    if (complexMetrics) headers.splice(0, 0, {
        field: 'complex',
        type: 'boolean',
        headerName: intl.formatMessage({ id: 'ESG.METRIC.TYPE.COMPLEX' }),
    });

    useEffect(() => {
        setLoading(true);
        if (topic.metricIds?.length) API.metricReports.getLatestMetricReports(token, topic.metricIds).then(res => {
            setData(res.data?.map(el => {
                const latest = el || {};
                const isComplex = latest?.metricId.metricId !== undefined;
                return {
                    id: latest._id,
                    lastUpdated: formatDateLocale(latest.datetime?.lastUpdated || ""),
                    complex: isComplex,
                    unit: isComplex ? latest?.metricId.metricId[0]?.category.scope.unit : latest?.metricId?.category.scope?.unit,
                    ...(latest.metricId?.reportData?.reportUnit?.unit && latest.metricId.reportData.reportUnit),
                    ...latest,
                    reportName: latest.name,
                    name: latest.metricId.name,
                }
            }) || []);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
        else {
            setData([]);
            setLoading(false);
        }
    }, [token, topic]);

    if (!topic) return null;
    else return <>
        <AssignMetric topicId={topic._id} open={openDialog} onCloseDialog={() => setOpenDialog(false)} />
        <Dialog keepMounted={false} open={openRowDetails} onClose={() => { setOpenRowDetails(false); setSelectedMetric(null) }} maxWidth="sm" fullWidth fullScreen={smallScreen}>
            <MetricReportDetailCard metric={selectedMetric} entity={allEntities[queryId]} complex={selectedMetric?.complex} onClose={() => { setOpenRowDetails(false); setSelectedMetric(null) }} />
        </Dialog>
        <>
            <MetricDetailsView metricId={selectedMetric?.metricId?._id} openDialog={openDetails} onClose={() => { setOpenDetails(false); setSelectedMetric(null); }} complex={selectedMetric?.complex} />
            <MetricHistoryReportView
                metricId={selectedMetric?.metricId?._id}
                openDialog={openHistoryList}
                onCloseHandler={() => { setOpenHistoryList(!openHistoryList); setSelectedMetric(null); }}
                entityName={allEntities[queryId]?.name}
                reportName={selectedMetric?.metricId?.name}
                type={selectedMetric?.metricId?.type}
                label={selectedMetric && measurementTypes.find(el => el.key === selectedMetric.metricId?.category?.scope?.measurementTypeKey)}
            />
        </>

        <Card sx={{ width: '100%', p: 0.5, mb: 1 }}>
            <Stack direction={smallScreen ? "column" : "row"} spacing={1} alignItems={smallScreen ? "flex-start" : "center"}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Chip onClick={() => navigate(topicParent ? `?topic=${topicParent}` : ('/topics/' + queryId))} icon={<ChevronLeftOutlined />} label={<FormattedMessage id="BACK" />} />
                    <ButtonGroup size="small" variant="outlined">
                        <CustomButton curvedLeft color="info" variant="outlined" icon={<PlagiarismOutlined />} onClick={() => onChangeView('subtopics')} label={<FormattedMessage id="ESG.SUB-TOPICS" />} />
                        <CustomButton color="info" variant="contained" icon={<TroubleshootOutlined />} label={<FormattedMessage id="ESG.METRICS" />} />
                        <CustomButton curvedRight color="info" variant="outlined" icon={<SportsScoreOutlined />} onClick={() => onChangeView('goals')} label={<FormattedMessage id="ESG.GOALS" />} />
                    </ButtonGroup>
                </Stack>
                <Autocomplete
                    id="search-metric"
                    freeSolo
                    options={searchOptions}
                    value={searchFilter}
                    onChange={(event, value) => setSearchFilter(value)}
                    renderInput={(params) => (
                        <TextField {...params} placeholder={intl.formatMessage({ id: "ESG.METRIC.SEARCH_PLACEHOLDER" })} InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlined color="info" />
                                </InputAdornment>
                            )
                        }} />
                    )}
                    size="small"
                    fullWidth
                />

                {updateTopicPermission && <Chip variant="outlined" sx={{ px: 1 }} icon={<RuleOutlined fontSize="small" color="info" />} onClick={() => setOpenDialog(true)} label={<FormattedMessage id="ESG.METRIC.ASSIGN" />} />}
            </Stack>
        </Card>

        <Card sx={{ width: '100%' }}>
            {viewMetricPermission
                ? <>
                    <CardHeader title={<FormattedMessage id="ESG.METRIC.TABLE" />} />
                    <CardContent>
                        {loading ? <LoadingData /> : <CustomDataGrid columns={headers} rows={data} handleData={(row) => { setOpenRowDetails(true); setSelectedMetric(row.row); }} />}
                    </CardContent>
                </>
                : <Card><Alert severity="warning"><FormattedMessage id='UNAUTHORIZED.NO_PERMISSION' /></Alert></Card>}
        </Card>
    </>
}