import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from 'api';

const initialState = {
    data: [],
    status: 'idle',
    error: null
};

export const fetchConfiguration = createAsyncThunk('configuration/fetchConfiguration', async (token, { rejectWithValue }) => {
    const { getTypesConfiguration, getUserConfiguration } = API.configuration;
    try {
        const response = token ? await getUserConfiguration(token) : await getTypesConfiguration();
        return response.data;
    } catch (error) {
        if (!error?.response) throw error;
        return rejectWithValue(error);
    }
});

export const configurationSlice = createSlice({
    name: 'configuration',
    initialState,
    reducers: {
        updateMeasureTypeConf: (state, action) => {
            const index = state.data.measurementTypes.findIndex(el => el._id === action.payload._id);
            if (index >= 0) state.data.measurementTypes.splice(index, 1, action.payload);
            else state.data.measurementTypes.push(action.payload);
            return state;
        },
        deleteMeasureTypeConf: (state, action) => {
            const index = state.data.measurementTypes.findIndex(el => el._id === action.payload._id);
            if (index >= 0) state.data.measurementTypes.splice(index, 1);
            return state;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchConfiguration.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchConfiguration.rejected, (state, action) => {
                console.error("Failed to load configuration...");
                if (!action.payload) state.error = "CRASHED";
                else if (action.payload.data?.id === "UNAUTHORIZED") state.error = "UNAUTHORIZED";
                else state.error = null;
                state.status = 'failed';
            })
            .addCase(fetchConfiguration.fulfilled, (state, action) => {
                state.status = 'complete';
                state.data = action.payload;
            });
    },
});

export const { updateMeasureTypeConf, deleteMeasureTypeConf } = configurationSlice.actions;

export const selectMeasurementsTypesConf = (state) => state.configuration.data.measurementTypes;
export const selectMeasurementsTypesByKeys = (state, ids) => state.configuration.data.measurementTypes.filter(type => ids.include(type.key));
export const selectCollectorTypesConf = (state) => state.configuration.data.collectorType;
export const selectMetricTypesConf = (state) => state.configuration.data.metricTypes;
export const selectDataFrequencyInterval = (state) => state.configuration.data.dataFrequencyInterval;
export const selectNotificationMethodTypes = (state) => state.configuration.data.notificationMethodTypes;
export const selectDataTypes = (state) => state.configuration.data.dataTypes;
export const selectStatusTypes = (state) => state.configuration.data.statusTypes;
export const selectPriorityTypes = (state) => state.configuration.data.priorityTypes;
export const selectGoalCategoryTypesConf = (state) => state.configuration.data.goalCategories;
export const selectReportIntervalTypesConf = (state) => state.configuration.data.reportInterval;
export const selectUserPermissions = (state) => state.configuration.data.userPermission;
export const selectUnits = (state) => state.configuration.data.units;
export const selectUnitsByFamily = (state, family) => state.configuration.data.units && state.configuration.data.units[family];
export const selectNoteTypes = (state) => state.configuration.data.noteTypes;
export const selectSeverityTypes = (state) => state.configuration.data.severityTypes;
export const selectDashboardConfiguration = (state) => state.configuration.data.dashboardConfig;
export const selectSectorsConfiguration = (state) => state.configuration.data.sectors;
export const selectTopicTemplates = (state) => state.configuration.data.esrsFileNames;

export default configurationSlice.reducer;
