import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { accountControlSelector, fullNameSelector } from "redux/userSlice";
import { Button, Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
import { AccountCircleOutlined, AdminPanelSettingsOutlined, LogoutOutlined } from "@mui/icons-material";
import API from "api";


const UserMenu = () => {
    const accountControl = useSelector(accountControlSelector);
    const fullName = useSelector(fullNameSelector);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const renderUsermenu = useCallback(() => {
        const handleOpenUserMenu = (event) => {
            setAnchorElUser(event.currentTarget);
        };

        const handleCloseUserMenu = () => {
            setAnchorElUser(null);
        };

        const settings = [];
        if (accountControl) settings.push({
            id: "ACCOUNT_ADMINISTRATION",
            onClick: () => window.open(`${process.env.REACT_APP_KEYCLOAK_BASE_URL}realms/${process.env.REACT_APP_KEYCLOAK_REALM}/account`),
            icon: <AdminPanelSettingsOutlined color="primary" fontSize="small" />,
        });
        settings.push({
            id: "LOG_OUT",
            onClick: () =>
                API.keycloak.logout({ redirectUri: process.env.REACT_APP_UI_BASE_URL }),
            icon: <LogoutOutlined color="primary" fontSize="small" />,
        });

        return <>
            {smallScreen ? (
                <IconButton color="inherit" onClick={handleOpenUserMenu}>
                    <AccountCircleOutlined />
                </IconButton>
            ) : (
                <Button
                    color="inherit"
                    onClick={handleOpenUserMenu}
                    startIcon={<AccountCircleOutlined />}
                    sx={{ textTransform: 'none' }}
                >
                    {fullName}
                </Button>
            )}
            <Menu
                id="menu-appbar"
                anchorEl={anchorElUser}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {smallScreen
                    ? [
                        <MenuItem key="user" disabled sx={{ color: "inherit" }}>
                            {fullName}
                        </MenuItem>,
                        <Divider key="divider" />,
                    ]
                    : null}
                {settings.map((setting) => (
                    <MenuItem key={setting.id} onClick={setting.onClick}>
                        <ListItemIcon>{setting.icon}</ListItemIcon>
                        <Typography textAlign="center" fontSize="small">
                            {<FormattedMessage id={setting.id} />}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </>

    }, [accountControl, anchorElUser, fullName, smallScreen])
    return (
        <>
            {renderUsermenu()}
        </>
    );
}

export default UserMenu;