import { Autocomplete, Collapse, FormControl, FormLabel, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectEntities, selectMeasureEntities } from 'redux/entitySlice';

const PieChartConstructor = ({ selectedEntities, checkExistance, setSelectedEntities, allMetrics, allTargets, selectedTarget, setSelectedTarget, existingWidget }) => {
    const entities = useSelector(selectEntities);
    const measureEntities = useSelector(selectMeasureEntities);
    const [targetsLoaded, setTargetsLoaded] = useState(false);

    const filterTargets = (entityArray) => {
        const entityIds = entityArray.map(ent => ent._id);
        const filteredMetricsIds = allMetrics.filter(met => entityIds.includes(met.entityId)).map(met => met._id);
        const filteredTargets = allTargets.filter(tar => filteredMetricsIds.includes(tar.metricId));
        return filteredTargets.filter(tar => !!tar.measurable);
    }

    const handleEntityChange = (selectedArray, reason) => {
        if (reason === "clear") setSelectedEntities([]);
        else if (checkExistance("target") && reason === "removeOption") setSelectedEntities([])
        else if (checkExistance("target")) setSelectedEntities([selectedArray[selectedArray.length - 1]])
        else setSelectedEntities(selectedArray)
        setSelectedTarget(null);
    }
    useEffect(() => {
        if (allTargets.length > 0) {
            setTargetsLoaded(true);
        }
    }, [allTargets]);

    useEffect(() => {
        if (existingWidget) {
            if (existingWidget.config.source.dataType === "target" && targetsLoaded) {
                setSelectedEntities([Object.values(entities).find(el => existingWidget.config.source.target.entity === el.name)]);
                setSelectedTarget(allTargets.find(el => existingWidget.config.source.target.id === el._id));
            }
            else if (existingWidget.config.source.dataType === "measurement") setSelectedEntities(Object.values(entities).filter(el => existingWidget.config.source.measurement.entity.includes(el._id)))
        }

    }, [targetsLoaded, allTargets, entities, existingWidget, setSelectedEntities, setSelectedTarget]);

    return <><Stack direction="column" width={"100%"}>
        {checkExistance("entity") && <FormControl>
            <FormLabel required><FormattedMessage id={checkExistance("target") ? "ENTITY" : "ENTITIES"} /></FormLabel>
            <Autocomplete
                id="select-entities"
                multiple
                options={Object.keys(entities).filter(ent => !!measureEntities[ent]).map(ent => entities[ent])}
                getOptionLabel={(option) => option.name}
                value={selectedEntities}
                onChange={(e, newValue, reason) => handleEntityChange(newValue, reason)}
                renderInput={(params) => <TextField {...params} />}
                size="small"
                fullWidth
            />
        </FormControl>}
    </Stack>
        <Collapse in={selectedEntities.length > 0}>
            <Stack direction="column" width={"100%"}>
                {checkExistance("target") && targetsLoaded && < FormControl >
                    <FormLabel required><FormattedMessage id="DASHBOARD.OPTIONS.target" /></FormLabel>
                    <Autocomplete
                        id="select-target"
                        options={filterTargets(selectedEntities)}
                        getOptionLabel={(option) => `${option.name} (${allMetrics.find(met => met._id === option.metricId)?.name})`}
                        value={selectedTarget}
                        onChange={(e, target) => setSelectedTarget(target)}
                        renderInput={(params) => <TextField {...params} />}
                        size="small"
                        fullWidth
                        isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    />
                </FormControl>}
            </Stack>
        </Collapse>
    </>
}

export default PieChartConstructor;