import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { userSelector, permissionSelector } from "redux/userSlice";
import { deleteMeasureTypeConf } from 'redux/configurationSlice';
import { Tooltip, Card, CardContent, CardHeader, useMediaQuery, IconButton, Grid, Chip, Alert, Typography, Button } from "@mui/material";
import { EditOutlined, DeleteOutline, AddOutlined, CancelOutlined } from "@mui/icons-material";
import { AlertSnackbar, CustomDataGrid, ConfirmationDialog, LoadingData, MeasurementTypeForm, MeasurementTypeDetails, ExpandedGridCell } from "components";
import API from "api";


const MeasurementTypesView = (props) => {
  const intl = useIntl();
  const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [alert, setAlert] = useState({ open: false });
  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({ open: false, selectedMeasurementType: null });
  const { token } = useSelector(userSelector);
  const dispatch = useDispatch();
  const [form, setForm] = useState({ open: false, selectedMeasurementType: null });
  const [selectedType, setSelectedType] = useState(null);
  const [open, setOpen] = useState(false);
  const [reset, setReset] = useState(0);

  const createMeasurementTypePermission = useSelector((state) => permissionSelector(state, 'create-measurementType'));
  const editMeasurementTypePermission = useSelector((state) => permissionSelector(state, 'update-measurementType'));
  const deleteMeasurementTypePermission = useSelector((state) => permissionSelector(state, 'delete-measurementType'));

  const handleDelete = (selectedMeasurementType) => {
    if (selectedMeasurementType) {
      API.measurementTypes.deleteMeasurementType(token, selectedMeasurementType._id).then(response => {
        setAlert({ open: true, messageId: "MEASUREMENT_TYPES.DELETE_SUCCESS", severity: "success" });
        dispatch(deleteMeasureTypeConf(response.data));
        setConfirmDialog({ open: false, selectedMeasurementType: null });
        setReset(reset + 1);
      })
        .catch(error => {
          setAlert({ open: true, messageId: error?.message?.id || "ERROR.NOT_DELETED", severity: "error" });
          console.error(error);
        })
    }
  }

  useEffect(() => {
    setLoading(true);
    API.measurementTypes.getAllMeasurementTypes(token).then(response => {
      if (response.data) {
        setMeasurementTypes(response.data.map(type => { return { ...type, id: type._id } }));
      }
      else setMeasurementTypes([]);
      setLoading(false);
    }).catch(error => {
      setAlert({ open: true, messageId: error?.message?.id || "ERROR.FETCHING_DATA", severity: "error" });
      setLoading(false);
      setMeasurementTypes({ error });
      console.error(error);
    });
  }, [token, intl, reset])

  const renderTable = useCallback(() => {
    const tableHeaders = [
      {
        field: 'key',
        headerName: intl.formatMessage({ id: 'NAME' }),
        ...(!smallScreen && { flex: 1 }),
        valueGetter: (row) => row.row?.name || intl.formatMessage({ id: row.value }),
        renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
        minWidth: 300
      },
      {
        field: 'description',
        headerName: intl.formatMessage({ id: 'DESCRIPTION' }),
        ...(!smallScreen && { flex: 1 }),
        renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
        minWidth: 300
      },
      {
        field: 'serviceType',
        headerName: 'OWM',
        description: intl.formatMessage({ id: 'SERVICE.OWM' }),
        type: 'boolean',
        valueGetter: (row) => row.row.serviceType.OWM ? true : false,
        minWidth: 100
      },
      {
        field: 'dataType',
        headerName: intl.formatMessage({ id: 'VALUE_TYPE' }),
        ...(!smallScreen && { flex: 1 }),
        valueGetter: (row) => intl.formatMessage({ id: "DATA_TYPE." + row.row.dataType.type.toUpperCase() }),
        minWidth: 150
      },
      {
        field: 'measure',
        headerName: intl.formatMessage({ id: 'MEASURING_SIZE' }),
        ...(!smallScreen && { flex: 1 }),
        valueGetter: (row) => row.row.dataType.measure ? intl.formatMessage({ id: row.row.dataType.measure }) : null,
        renderCell: ({ value }) => value ? value : <Typography color="text.disabled"><FormattedMessage id="NONE" /></Typography>,
        minWidth: 180
      },

      {
        field: 'actions',
        headerName: intl.formatMessage({ id: 'ACTIONS' }),
        type: 'actions',
        getActions: (el) => (editMeasurementTypePermission || deleteMeasurementTypePermission) && el.row?.orgId ? [
          <Tooltip title={<FormattedMessage id="MEASUREMENT_TYPES.EDIT" />} placement="bottom" arrow>
            <IconButton component="span" disabled={!editMeasurementTypePermission} color="secondary" key="edit" onClick={() => { setForm({ open: true, selectedMeasurementType: el.row }) }} > <EditOutlined fontSize="small" /> </IconButton>
          </Tooltip>,
          <Tooltip title={<FormattedMessage id="MEASUREMENT_TYPES.DELETE" />} placement="bottom" arrow>
            <IconButton component="span" disabled={!deleteMeasurementTypePermission} color="error" key="delete" onClick={() => setConfirmDialog({ open: true, selectedMeasurementType: el.row })}><DeleteOutline fontSize="small" /></IconButton>
          </Tooltip>
        ] : [smallScreen ? <IconButton key="disabled-actions" disabled><CancelOutlined fontSize="small" /></IconButton> : <Button key="disabled-actions" disabled><FormattedMessage id="DISABLED" /></Button>],
        minWidth: 200
      },
    ]

    if (loading) return <LoadingData />;
    else if (measurementTypes?.error) return <Alert severity='error'><FormattedMessage id="ERROR.NO_DATA" /></Alert>;
    else {
      return <CustomDataGrid rows={measurementTypes} columns={tableHeaders} handleData={({ row }) => { setSelectedType(row); setOpen(true); }} />
    }

  }, [intl, loading, measurementTypes, smallScreen, editMeasurementTypePermission, deleteMeasurementTypePermission])

  const onAlertClose = () => setAlert({ ...alert, open: false });

  return (
    <Grid container direction="column">
      <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
      <MeasurementTypeForm open={form.open} onClose={() => setForm({ open: false, selectedMeasurementType: null })} {...(form.selectedMeasurementType && { existingMeasurementType: form.selectedMeasurementType })} setAlert={setAlert} reset={reset} setReset={setReset} />
      <MeasurementTypeDetails data={selectedType} openDialog={open} onClose={() => { setSelectedType(null); setOpen(false) }} />
      <ConfirmationDialog
        open={confirmDialog.open}
        content={<Typography variant="h6" color="error">{confirmDialog.selectedMeasurementType?.name}</Typography>}
        title={<FormattedMessage id="MEASUREMENT_TYPES.CONFIRM_DELETE" />}
        customButtonTitle={<FormattedMessage id="DELETE" />}
        customButtonColor="error"
        handleCancel={() => setConfirmDialog({ open: false, selectedMeasurementType: null })}
        handleCustomButton={() => handleDelete(confirmDialog.selectedMeasurementType)}
      />
      <Card sx={{ width: '100%' }}>
        <CardHeader title={<FormattedMessage id="MEASUREMENT_TYPES.TABLE" />}
          action={createMeasurementTypePermission && <Chip variant="outlined" onClick={() => setForm({ open: true, selectedMeasurementType: null })} icon={<AddOutlined fontSize="small" color="primary" />} label={<FormattedMessage id="MEASUREMENT_TYPES.ADD" />} />}
        />
        <CardContent>
          {renderTable()}
        </CardContent>
      </Card>
    </Grid>
  )
};

export default MeasurementTypesView;