import React, { useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import { paletteColors } from '../utils/chartsColorPalette';
import { Bar } from 'react-chartjs-2';
import defaultOptions from 'charts/utils/defaultOptions';
import { deepMerge } from 'utils';
import { useSelector } from "react-redux";
import { selectFontSize, selectFontWeight } from 'redux/appSlice';
import { useTheme, useMediaQuery } from '@mui/material';

Chart.register(...registerables);

const StackedBarChart = (props) => {
    const theme = useTheme();
    const fontSize = useSelector(selectFontSize);
    const fontWeight = useSelector(selectFontWeight);
    const xtraSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const chartRef = useRef(null);
    // inject palette colors to each serie
    if (props.data && props.data.datasets) {
        props.data.datasets.forEach((dataset) => {
            const dsColor = paletteColors(dataset.key);
            dataset.backgroundColor = dsColor;
        })
    }

    const options = {
        plugins: {
            title: {
                display: true,
                text: props.title
            },
            tooltip: {
                titleFont: {
                    size: xtraSmallScreen ? 10 : fontSize
                },
                bodyFont: {
                    size: xtraSmallScreen ? 10 : fontSize
                },
                footerFont: {
                    size: xtraSmallScreen ? 10 : fontSize
                }
            },
            legend: {
                display: false,
                labels: {
                    color: theme.palette.text.primary,
                    font: {
                        size: xtraSmallScreen ? 10 : fontSize,
                        weight: fontWeight < 0 ? 'lighter' : fontWeight < 100 ? 'normal' : 'bolder'
                    }
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: theme.palette.text.primary,
                    font: {
                        size: xtraSmallScreen ? 10 : fontSize,
                        weight: fontWeight < 0 ? 'lighter' : fontWeight < 100 ? 'normal' : 'bolder'
                    }
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: theme.palette.text.primary,
                    font: {
                        size: xtraSmallScreen ? 10 : fontSize,
                        weight: fontWeight < 0 ? 'lighter' : fontWeight < 100 ? 'normal' : 'bolder'
                    }
                }
            }
        }
    }

    return (
        <Bar onDoubleClick={() => chartRef?.current?.resetZoom()} className='chart' data={props.data} options={deepMerge(defaultOptions, options)} ref={chartRef} />
    );
}

export default StackedBarChart;