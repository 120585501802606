import { useEffect, useState } from "react";
import { AddOutlined, AddchartOutlined, DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import { Divider, Dialog, DialogContent, DialogActions, Button, Stack, Typography, useMediaQuery, CardHeader, Avatar, Chip, IconButton, Box, Tooltip } from "@mui/material";
import { AlertSnackbar, LoadingData, CompactMetricList, CompactTargetList, DisplayBox, TargetForm, ConfirmationDialog } from "components";
import { FormattedMessage, useIntl } from "react-intl";
import { userSelector, permissionSelector } from "redux/userSlice";
import { useSelector } from "react-redux";
import { selectStatusTypes, selectCollectorTypesConf, selectMeasurementsTypesConf } from "redux/configurationSlice";
import { useNavigate, useParams } from "react-router";
import { getStatusColor, formatDateLocale } from "utils";
import API from "api";



const MetricDetailsView = ({ metricId, complex, metric, openDialog, onClose, small, setOpenViewDialog, setOpenForm, disableEdit }) => {
    const { token } = useSelector(userSelector);

    const entityId = useParams().entityId;
    const statuses = useSelector(selectStatusTypes);
    const sourceTypes = useSelector(selectCollectorTypesConf);
    const measurementTypes = useSelector(selectMeasurementsTypesConf);
    const [data, setData] = useState(null);
    const [targetData, setTargetData] = useState([]);
    const [reset, setReset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [editTargets, setEditTargets] = useState(false);
    const [confirmation, setConfirmation] = useState({ open: false });
    const [alert, setAlert] = useState({ open: false });
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const intl = useIntl();

    const createTargetPermission = useSelector((state) => permissionSelector(state, 'create-target'));
    const deleteTargetPermission = useSelector((state) => permissionSelector(state, 'delete-target'));
    const viewTargetPermission = useSelector((state) => permissionSelector(state, 'view-target'));
    const updateMetricPermission = useSelector((state) => permissionSelector(state, 'update-metric'));
    const addMeasurementPermission = useSelector((state) => permissionSelector(state, 'create-measurement'));

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const onCloseHandler = () => {
        setLoading(false);
        setEditTargets(false);
        onClose();
    }

    const handleNavigate = () => {
        if (data?.category?.type === "Quantitative") navigate('/measurementsEntry', { state: { entityId: data?.entityId || entityId, metric: data } });
        else if (data?.category?.type === "Qualitative") navigate('/measurementsEntry/metrics', { state: { entityId: data?.entityId || entityId, metric: data } });
    }
    const action = <Box>
        {addMeasurementPermission && (data?.category?.type === "Qualitative" || data?.category?.type === "Quantitative") ? <Tooltip title={<FormattedMessage id="MEASUREMENTS_ENTRY" />} placement="bottom" arrow>
            <IconButton onClick={handleNavigate}><AddchartOutlined /></IconButton>
        </Tooltip> : (complex && <Chip label={<FormattedMessage id="ESG.METRIC.COMPLEX" />} size="small" />)}
        {!disableEdit && updateMetricPermission && setOpenViewDialog && <Tooltip title={<FormattedMessage id="ESG.METRIC.EDIT" />} placement="bottom" arrow>
            <IconButton onClick={() => { setOpenViewDialog(false); setOpenForm(true); }}><EditOutlined /></IconButton>
        </Tooltip>}
    </Box>

    useEffect(() => {
        const convertData = (metric) => ({
            ...metric,
            id: metric._id,
            entityId: metric.entityId,
            measurementType: measurementTypes.find(el => el.key === metric.category?.scope?.measurementTypeKey),
            collector: metric.category?.scope?.collector,
            reportUnit: metric.reportData?.reportUnit,
            unit: metric.category?.scope?.unit,
            metricType: metric.category?.type,
            sourceTypeId: sourceTypes.find(el => el.key === metric.category?.scope?.sourceType)?.value,
            calculationMethod: metric.reportData?.calculationMethod,
            createdAt: formatDateLocale(metric.reportData?.createdAt),
            startDate: formatDateLocale(metric.reportData?.startDate),
            reportInterval: metric.reportData?.reportInterval,
            dataFrequencyInterval: metric.reportData?.dataFrequencyInterval
        })

        if (metric) setData(convertData(metric));

        else if (metricId && openDialog) {
            setLoading(true);
            API.metrics[complex ? "getComplexMetric" : "getMetric"](metricId, token).then(item => {
                if (item && item.data) {
                    setData(convertData(item.data));
                    setTargetData(item.data.targets || []);
                    setLoading(false);
                }
                else setData({});
            }).catch(error => {
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
                console.error(error);
                setData(null);
                setLoading(false);
            });
        };
    }, [metricId, metric, complex, openDialog, token, reset, sourceTypes, measurementTypes]);

    const Display = (props) => {
        return <Typography color="text.secondary" component="span" justifyContent="space-between" display="flex">
            {props.title}
            <Typography variant={props.variant} color={props.color || "black"} display="inline" component="span" sx={{ ml: 1 }}>{props.message}</Typography>
        </Typography>
    };

    const onUpdateTargetStatus = (status, targetId) => {
        const targetIndex = targetData.findIndex(t => t._id === targetId);
        setTargetData(targets => {
            if (targetIndex > -1) targets[targetIndex] = {
                ...targets[targetIndex],
                status: status
            }
            return targets;
        })
    }

    return (
        <>
            <ConfirmationDialog
                open={confirmation.open}
                title={<span><FormattedMessage id="CONFIRM_DELETE_FOR" /> <Typography variant="h6" color="error" display="inline" component="span">{confirmation.data?.name}</Typography></span>}
                content={<Stack direction="column" spacing={1}>
                    <Display title={<FormattedMessage id="OWNER" />} message={confirmation.data?.owner} />
                    <Display title={<FormattedMessage id="STATUS" />} color={getStatusColor(confirmation.data?.status)} message={confirmation.data && <FormattedMessage id={"STATUS." + statuses.find(s => s.key === confirmation.data.status)?.value} />} />
                    <Display title={<FormattedMessage id="START_DATE" />} message={formatDateLocale(confirmation.data?.dateRange.startDate)} />
                    <Display title={<FormattedMessage id="END_DATE" />} message={formatDateLocale(confirmation.data?.dateRange.endDate)} />
                </Stack>}
                handleCancel={() => setConfirmation({ open: false })}
                handleCustomButton={() => API.target.deleteTarget(confirmation.data?._id, token).then(() => {
                    setAlert({ open: true, messageId: "SUCCESS.DELETE", severity: "success" });
                    setReset(reset => reset + 1);
                    setEditTargets(false);
                    setConfirmation({ open: false });
                }).catch((error) => setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_DELETED", severity: "error" }))}
                customButtonTitle={<FormattedMessage id="DELETE" />}
                customButtonColor='error'
                disabled={confirmation.disabled}
            />
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
            <TargetForm size="sm" open={open} onClose={(flag) => { setOpen(false); if (flag) setReset(reset => reset + 1) }} defaultMetric={metric ? metric : data} setAlert={setAlert} />
            <Dialog
                open={openDialog}
                maxWidth={small ? 'sm' : 'md'}
                fullWidth
                fullScreen={smallScreen}
                onClose={onCloseHandler}
            >
                {loading ? <DialogContent><LoadingData /></DialogContent> : <>
                    <CardHeader
                        avatar={<Avatar sx={{ bgcolor: 'primary.main' }}>{data?.type}</Avatar>}
                        title={<Typography variant="h6"><FormattedMessage id="ESG.METRIC.DETAILS" /></Typography>}
                        subheader={<Typography color="primary" className="ellipsis-header">{data?.name} </Typography>}
                        action={data !== null && action}
                    />
                    <Divider />
                    {data && <DialogContent>

                        <Divider><Typography variant='h6' color="secondary.main"><FormattedMessage id="DESCRIPTION" /></Typography></Divider>
                        <Stack spacing={1}>
                            <DisplayBox label={<FormattedMessage id="NAME" />} value={data.name} />
                            <DisplayBox label={<FormattedMessage id="ENTITY_NAME" />} value={data.entityName} />
                            <DisplayBox label={<FormattedMessage id="DESCRIPTION" />} value={data.description} />
                            <DisplayBox label={<FormattedMessage id="OWNER" />} value={data.owner} />
                            <DisplayBox label={<FormattedMessage id="ESG.METRIC.CATEGORY" />} value={<FormattedMessage id={"ESG.TYPE." + data.type} />} />
                            <DisplayBox label={<FormattedMessage id="ESG.METRIC.TYPE" />} value={complex ? <FormattedMessage id="COMPLEX" /> : (data.metricType ? <FormattedMessage id={"ESG.METRIC.TYPE." + data.metricType?.toUpperCase()} /> : null)} />
                        </Stack>


                        {(complex || data.metricType === process.env.REACT_APP_ESG_METRIC_DEFAULT_TYPE) && <Divider><Typography variant='h6' color="secondary.main"><FormattedMessage id="MEASUREMENTS" /></Typography></Divider>}
                        {complex
                            ? <Stack spacing={1}>
                                <CompactMetricList metrics={data.metrics || []} />
                                <DisplayBox label={<FormattedMessage id="CALCULATION_METHOD" />} value={data.calculationMethod ? <FormattedMessage id={"CALCULATION_METHOD." + data.calculationMethod} /> : null} />
                            </Stack>
                            : data.metricType === process.env.REACT_APP_ESG_METRIC_DEFAULT_TYPE && <Stack spacing={1}>
                                <DisplayBox label={<FormattedMessage id="SOURCE_TYPE" />} value={data.sourceTypeId ? <FormattedMessage id={data.sourceTypeId} /> : null} />
                                <DisplayBox label={<FormattedMessage id="MEASUREMENT_TYPE" />} value={data.measurementType ? (data.measurementType.name || <FormattedMessage id={data.measurementType.key} />) : null} />
                                <DisplayBox label={<FormattedMessage id="UNIT" />} value={data.unit} />
                                <DisplayBox label={<FormattedMessage id="CALCULATION_METHOD" />} value={data.calculationMethod ? <FormattedMessage id={"CALCULATION_METHOD." + data.calculationMethod} /> : null} />
                                <DisplayBox label={<FormattedMessage id="COLLECTOR" />} value={data.collector} />
                            </Stack>
                        }

                        <Divider><Typography variant='h6' color="secondary.main"><FormattedMessage id="ESG.TARGETS" /></Typography></Divider>
                        <Stack spacing={1}>
                            {!viewTargetPermission
                                ? <Typography sx={{ pt: 1 }} color="text.disabled"><FormattedMessage id="UNAUTHORIZED.NO_PERMISSION" /></Typography>
                                : (targetData.length
                                    ? <CompactTargetList targets={data.targets} xsmall={small} onUpdateTargetStatus={onUpdateTargetStatus} onUpdate={() => setReset(reset => reset + 1)} customAction={editTargets && { icon: <DeleteOutlineOutlined color="error" />, title: <FormattedMessage id="DELETE" /> }} customActionHandler={editTargets && ((t) => setConfirmation({ open: true, data: t }))} />
                                    : <Typography color="text.disabled"><FormattedMessage id="NONE" /></Typography>)
                            }
                            <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ my: 1 }}>
                                {createTargetPermission && <Chip size="small" variant="outlined" onClick={() => setOpen(true)} icon={<AddOutlined color="primary" />} label={<FormattedMessage id="ESG.TARGET.ADD" />} />}
                                {targetData.length > 0 && deleteTargetPermission && <Button size="small" onClick={() => setEditTargets(!editTargets)} startIcon={editTargets ? null : <DeleteOutlineOutlined />}><FormattedMessage id={editTargets ? "CANCEL" : "ESG.TARGET.DELETE"} /></Button>}
                            </Stack>
                        </Stack>

                        <Divider><Typography variant='h6' color="secondary.main"><FormattedMessage id="REPORT" /></Typography></Divider>
                        <Stack spacing={1}>
                            <DisplayBox label={<FormattedMessage id="CREATED_AT" />} value={data.createdAt} />
                            <DisplayBox label={<FormattedMessage id="START_DATE" />} value={data.startDate} />
                            <DisplayBox label={<FormattedMessage id="REPORT_INTERVAL" />} value={intl.formatMessage({ id: "GROUPING." + data.reportInterval.toUpperCase() })} />
                            <DisplayBox label={<FormattedMessage id="DATA_FREQUENCY_INTERVAL" />} value={intl.formatMessage({ id: "GROUPING." + data.dataFrequencyInterval.toUpperCase() })} />
                            <DisplayBox label={<FormattedMessage id="REPORT_UNITS" />} value={data.reportUnit
                                ? (data.reportUnit.prefix || data.reportUnit.suffix) ? <Stack direction="column" spacing={0.5} sx={{ width: '100%' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1 }}><Typography color="text.secondary" variant="caption"><FormattedMessage id="PREFIX" /></Typography> {data.reportUnit.prefix}</Box>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1 }}><Typography color="text.secondary" variant="caption"><FormattedMessage id="UNIT" /></Typography> {data.reportUnit.unit || <Typography color="text.secondary">{data.category?.scope?.unit} (<FormattedMessage id="DEFAULT" />)</Typography>}</Box>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1 }}><Typography color="text.secondary" variant="caption"><FormattedMessage id="SUFFIX" /></Typography> {data.reportUnit.suffix}</Box>
                                </Stack> : data.reportUnit.unit
                                : null
                            } />
                        </Stack>

                    </DialogContent>}
                </>}
                <Divider />

                <DialogActions>
                    <Button color="warning" onClick={onCloseHandler}><FormattedMessage id="CLOSE" /></Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default MetricDetailsView;