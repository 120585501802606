import { useSelector } from "react-redux";
import { permissionSelector } from "redux/userSlice";
import { Button, CardHeader, Chip, Dialog, DialogActions, DialogContent, Divider, IconButton, Stack, useMediaQuery } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { BackspaceOutlined, CloseOutlined, DoNotDisturbOnOutlined, PreviewOutlined, ThumbDownOutlined, ThumbUpOutlined } from "@mui/icons-material";
import { AlarmDetailsView, AlarmButtons, AlarmProfileDetails } from "components";
import { useNavigate } from "react-router";



export default function AlarmDetailsDialog({ open, onClose, alarm, handleAcknowledgeAlarm, handleClearAlarm, disableActions, alarmProfile }) {
    const navigate = useNavigate();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const ackAlarm = useSelector((state) => permissionSelector(state, 'acknowledge-alarms'));
    const clrAlarm = useSelector((state) => permissionSelector(state, 'clear-alarms'));

    const HeaderAction = () => <IconButton onClick={onClose}><CloseOutlined /></IconButton>

    return <Dialog open={open} onClose={onClose} fullScreen={smallScreen} maxWidth="md" fullWidth>
        {Boolean(alarm) && <>
            <CardHeader
                title={<FormattedMessage id={'ALARM.DETAILS'} />}
                subheader={<Stack direction="row" gap={1}>
                    <Chip icon={alarm?.acknowledgedBy ? <ThumbUpOutlined fontSize="small" /> : <ThumbDownOutlined fontSize="small" />} size="small" color={alarm?.acknowledgedBy ? "success" : "warning"} label={<FormattedMessage id={alarm?.acknowledgedBy ? "ACKNOWLEDGED" : "UNACKNOWLEDGED"} />} />
                    {alarm?.clearedBy ? <Chip icon={<BackspaceOutlined fontSize="small" />} size="small" color="secondary" label={<FormattedMessage id="CLEARED" />} /> : null}
                </Stack>}
                action={<HeaderAction />}
            />
            <Divider />
            <DialogContent>
                <AlarmDetailsView
                    alarm={alarm}
                    smallScreen={smallScreen}
                    openDrawer={open}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <AlarmButtons
                    alarm={alarm}
                    smallScreen={smallScreen}
                    openDrawer
                    handleAcknowledgeAlarm={handleAcknowledgeAlarm}
                    handleClearAlarm={handleClearAlarm}
                    disableAcknowledge={!ackAlarm || disableActions || alarm.acknowledgedBy}
                    disableClear={!clrAlarm || disableActions || alarm.clearedBy}

                />
            </DialogActions>
        </>}
        {Boolean(alarmProfile) && <>
            <CardHeader
                title={<FormattedMessage id="ALARMS.PROFILES.DETAILS" />}
                subheader={<Chip size="small" icon={!alarmProfile.enabled ? <DoNotDisturbOnOutlined fontSize='small' /> : null} color={alarmProfile?.enabled ? "primary" : "default"} label={<FormattedMessage id={alarmProfile?.enabled ? "ENABLED" : "DISABLED"} />} />}
                action={<HeaderAction />}
            />
            <Divider />
            <DialogContent>
                <AlarmProfileDetails alarmProfile={typeof (alarmProfile) === "object" ? alarmProfile : null} profileId={typeof (alarmProfile) === "string" ? alarmProfile : null} openDrawer />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button startIcon={<PreviewOutlined fontSize='small' />} onClick={() => { const profileId = alarmProfile._id || alarmProfile; navigate('/alarmProfiles/details/' + profileId) }}><FormattedMessage id="PREVIEW" /></Button>
            </DialogActions>
        </>}
    </Dialog>
}