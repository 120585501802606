import React, { useEffect, useState, useCallback, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import { selectDateRange } from "redux/dateRangeSlice";
import { LoadingData } from "components";
import { Alert, Autocomplete, Box, Chip, FormHelperText, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { MultiAxisLineChart } from "charts";
import { selectEntities } from "redux/entitySlice";
import { selectMeasurementsTypesConf } from "redux/configurationSlice";
import { FilterAltOutlined, RestartAltOutlined } from "@mui/icons-material";
import API from "api";

function MeasurementsTrendGraph({ entityIdArray, fixedDateFrom, fixedDateTo, fixedMeasurementTypes, fixedCollectorTypes, fixedMetricIds, itemWidth, ...props }) {
    const { token } = useSelector(userSelector);
    const intl = useIntl();
    const selectedDateRange = useSelector(selectDateRange);
    const measurementTypes = useSelector(selectMeasurementsTypesConf);
    const [data, setData] = useState({ loading: true });
    const entities = useSelector(selectEntities);
    const [allQuantitativeMetrics, setAllQuantitativeMetrics] = useState([]);
    const [reset, setReset] = useState(0);
    const [filter, setFilter] = useState([]);
    const [initial, setInitial] = useState(false);
    const [toggleFilter, setToggleFilter] = useState(false);
    const [loading, setLoading] = useState(false);
    const chartRef = useRef(null);

    useEffect(() => {
        setToggleFilter(false);
    }, [entityIdArray]);

    useEffect(() => {
        if (data.datasets || data.datasets?.length > 9) {
            setFilter(data.datasets.slice(0, 9));
        } else setFilter([]);
        setInitial(true);
    }, [entityIdArray, data.datasets]);

    const render = useCallback(() => {
        if (loading) return <LoadingData />;
        else if (!loading && data.datasets && data.datasets.length > 0) {
            let chartData = {
                ...data,
                datasets: data.datasets.filter(el => filter.map(el => el.key).includes(el.key)),
            }

            return <>
                {data.datasets?.length > 9 && <Stack sx={{ mt: -4 }} direction="row" gap={0.5} alignItems="center">
                    <Chip size="small" component="span" color={!toggleFilter ? "default" : "primary"} onClick={() => setToggleFilter(v => !v)} icon={<FilterAltOutlined />} label={<FormattedMessage id="MEASUREMENT_TYPES.FILTER" />} />
                    <Tooltip placement="right" title={<FormattedMessage id="RESTART" />}>
                        <IconButton color="secondary" sx={{ visibility: initial ? 'hidden' : 'visible' }} onClick={() => { setFilter(data.datasets.slice(0, 9)); setInitial(true) }} size="small"><RestartAltOutlined fontSize="small" /></IconButton>
                    </Tooltip>
                </Stack>}
                <Stack direction="column" gap={0.5}>
                    {toggleFilter && <Autocomplete
                        id="set-filter"
                        multiple
                        disableCloseOnSelect
                        limitTags={3}
                        fullWidth
                        size="small"
                        sx={{ mt: 0.5 }}
                        value={filter}
                        options={data.datasets || []}
                        onChange={(e, value) => { setFilter(value); setInitial(false); }}
                        renderInput={(props) => <TextField sx={{ mb: 1 }} {...props} placeholder={`${filter.length}/${data.datasets?.length}`} />}
                        renderOption={(props, option) => <li {...props}>{option?.label}</li>}
                        getOptionLabel={option => option.label}
                        isOptionEqualToValue={(opt, val) => opt.label === val.label}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.label}
                                    {...getTagProps({ index })}
                                    size="small"
                                />
                            ))
                        }
                    />}
                    <Box sx={{ minHeight: 300 }}>
                        <MultiAxisLineChart hideLegend={toggleFilter} disableInteraction={props.disableInteraction} name={"trendLineChart_" + entityIdArray[0]} data={chartData} chartRef={chartRef} itemWidth={itemWidth} onReset={() => setReset(reset => reset + 1)} />
                    </Box>
                    {data.datasets?.length > 9 && data.datasets?.length !== filter.length && <FormHelperText>
                        <Typography variant="inherit" component="span" color="warning.dark"><FormattedMessage id={"PARTIAL_DATA_CHART_HELPER_TEXT" + (initial ? ".INITIAL" : "")} /> {!toggleFilter && <span>(<FormattedMessage id="SHOWING" /> {`${filter.length}/${data.datasets.length}`})</span>}</Typography>
                    </FormHelperText>}
                    {!props.disableInteraction && <FormHelperText><FormattedMessage id="DOUBLE_CLICK_CHART_HELPER_TEXT" /></FormHelperText>}
                </Stack>
            </>
        }
        else if (data.error) return <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>;
        else return <Alert severity="warning"><FormattedMessage id='NO_DATA_TIME_PERIOD' /></Alert>

    }, [data, filter, entityIdArray, itemWidth, props.disableInteraction, toggleFilter, initial, chartRef, loading])

    const convertData = useCallback((apiData) => {
        if (fixedMetricIds) {
            const convertedData = [];
            Object.keys(apiData).forEach(key => {
                const foundMetric = allQuantitativeMetrics.find(metric => metric._id === key);
                const entityName = entities[foundMetric?.entityId]?.name;
                const measureType = measurementTypes.find(type => type.key === foundMetric?.category.scope.measurementTypeKey);
                const dataSerie = {
                    label: foundMetric?.name + (entityName ? `(${entityName})` : ""),
                    key: measureType?.key,
                    name: measureType && (measureType.name || intl.formatMessage({ id: measureType.key })),
                    data: apiData[key].map(value => {
                        return {
                            y: value.x,
                            x: value.y,
                            unit: foundMetric?.category.scope.unit,
                        }
                    })
                }
                convertedData.push(dataSerie);
            })
            setData({ datasets: convertedData });
        }
        else {
            apiData.forEach(dataSerie => {
                const measureType = measurementTypes.find(el => el.key === dataSerie.key);
                const entityName = entities[dataSerie.entityId]?.name
                dataSerie.name = measureType.name || intl.formatMessage({ id: measureType.key })
                dataSerie.label = dataSerie.name + (entityName ? `(${entityName})` : "");
                dataSerie.values.forEach(value => {
                    const temp = value.x;
                    value.x = value.y;
                    value.y = temp;
                })
                dataSerie.data = dataSerie.values;
            });
            setData({ datasets: apiData });
        }
    }, [setData, intl, entities, fixedMetricIds, allQuantitativeMetrics, measurementTypes]);

    useEffect(() => {
        if (fixedMetricIds) {
            setLoading(true);
            API.metrics.getAllMetrics(token, process.env.REACT_APP_ESG_METRIC_DEFAULT_TYPE).then(items => {
                if (items.data) {
                    setAllQuantitativeMetrics(items.data)
                    setLoading(false);
                }
            }).catch(error => {
                console.error(error);
                setLoading(false);
            })
        }
    }, [token, fixedMetricIds, reset]);

    useEffect(() => {
        setData({ loading: true });
        if (fixedMetricIds) {
            setLoading(true);
            API.metricReports.getMetricReportTimeSeries(token, fixedMetricIds, new Date(fixedDateFrom), new Date(fixedDateTo)).then(items => {
                if (items.data) {
                    convertData(items.data)
                }
                else setData({});
                setLoading(false);
            }).catch((error) => {
                console.error(error);
                setData({ error: error });
                setLoading(false);
            })

        }
        else {
            setLoading(true);
            API.measurements.getMeasurementValuesHistory(token, fixedDateFrom ? new Date(fixedDateFrom) : selectedDateRange.dateFrom, fixedDateTo ? new Date(fixedDateTo) : selectedDateRange.dateTo, entityIdArray, fixedMeasurementTypes, fixedCollectorTypes).then(items => {
                if (items.data) {
                    convertData(items.data)
                }
                else setData({});
                setLoading(false);
            }).catch((error) => {
                console.error(error);
                setData({ error: error });
                setLoading(false);
            })
        }
    }, [token, convertData, selectedDateRange.dateFrom, selectedDateRange.dateTo, reset, fixedCollectorTypes, fixedMeasurementTypes, fixedMetricIds, entityIdArray, fixedDateFrom, fixedDateTo])

    return <>
        {render()}
    </>
}

export default React.memo(MeasurementsTrendGraph);