import { useEffect, useState } from "react";
import { Box, Button, Dialog, FormLabel, DialogActions, DialogContent, Divider, Stack, Typography, useMediaQuery, useTheme, Tooltip, IconButton, Switch } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import EntityFormDialogContent from "./EntityFormDialogContent";
import { useSelector, useDispatch } from "react-redux";
import { selectMapProps } from "redux/mapSlice";
import { userSelector } from "redux/userSlice";
import { addNewEntity, updateEntity, deleteEntity, selectEntities } from "redux/entitySlice";
import { recursiveChildrenCountSearch } from "utils/helpers";
import { AlertSnackbar, ConfirmationDialog, DialogFormTitle } from "components";
import API from "api";

const checkData = (oldData, newData) => {
    return (
        oldData.name === newData.name &&
        oldData.description === newData.description &&
        oldData.geoProperties.position[0] === newData.longitude &&
        oldData.geoProperties.position[1] === newData.latitude &&
        oldData.geoProperties.address === newData.address
    )
}

const EntityForm = ({ openForm, setOpenForm, entity, parentId }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const allEntities = useSelector(selectEntities)
    const user = useSelector(userSelector);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [address, setAddress] = useState("");
    const mapProps = useSelector(selectMapProps);
    const [position, setPosition] = useState([parseFloat(mapProps.defaultY), parseFloat(mapProps.defaultX)]);
    const [image, setImage] = useState(null)
    const [alert, setAlert] = useState({ open: false });
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [disabledEditButton, setDisabledEditButton] = useState(true);
    const [deleteChildrenFlag, setDeleteChildrenFlag] = useState(false);

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (entity) {
            setName(entity.name);
            setDescription(entity.description);
            setAddress(entity.geoProperties.address);
            setPosition(entity.geoProperties.position);
        }

        return () => { };
    }, [openForm, entity])

    const onDelete = () => {
        API.entities.deleteEntity(user.token, entity._id).then(items => {
            if (items.data) {
                setAlert({ open: true, messageId: "SUCCESS.DELETE", severity: "success" });
                dispatch(deleteEntity(items.data));
            }
        }).catch((err) => {
            console.error(err);
            setAlert({ open: true, messageId: err?.message?.id || "ERROR.NOT_DELETED", severity: "error" });
        })
        setOpenForm(false);
        resetForm();
    }

    const resetForm = () => {
        setName("");
        setAddress("");
        setDescription("");
        setPosition([parseFloat(mapProps.defaultY), parseFloat(mapProps.defaultX)]);
        setImage(null);
        setDeleteChildrenFlag(false);
    }

    const onSubmit = () => {
        if (entity) setDisabledEditButton(true);
        const newEntityObject = {
            ...entity,
            name: name,
            description: description,
            address: address,
            longitude: position[0],
            latitude: position[1],
        }

        if (entity && checkData(entity, newEntityObject) && !image) {
            setAlert({ open: true, messageId: "ERROR.NO_CHANGE_DETECTED", severity: "error" });
            setDisabledEditButton(false);
        }
        else if (entity) {
            API.entities.updateEntity(user.token, entity._id, newEntityObject, image).then(items => {
                if (items.data) {
                    setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
                    dispatch(updateEntity(items.data));
                    setOpenForm(false);
                    resetForm();
                }
            }).catch((err) => {
                console.error(err);
                setAlert({ open: true, messageId: err?.message?.id || "ERROR.NOT_UPDATED", severity: "error" });
                setDisabledEditButton(false);
            })
        }
        else {
            API.entities.createEntity(user.token, newEntityObject, parentId, image).then(items => {
                if (items.data) {
                    setAlert({ open: true, messageId: "SUCCESS.CREATE", severity: "success" });
                    dispatch(addNewEntity(items.data));
                    setOpenForm(false);
                    resetForm();
                }
            }).catch((err) => {
                console.error(err);
                setAlert({ open: true, messageId: err?.message?.id || "ERROR.NOT_CREATED", severity: "error" });
            })
        }
    }

    const deleteDialogContent = <Stack >
        <Box><FormattedMessage id="COUNT_CHILDREN" /> <FormLabel sx={{ color: 'primary.main', typography: 'body1' }}>{entity && entity.connections.childrenArray.length && recursiveChildrenCountSearch(entity._id, allEntities)}</FormLabel></Box>
        {entity && entity.connections.childrenArray.length > 0 && <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormattedMessage id="DELETE_ALL" />
            <FormLabel sx={{ ml: 0.5 }}>
                <Switch size="small" checked={deleteChildrenFlag} onChange={() => setDeleteChildrenFlag(!deleteChildrenFlag)} />
                <Typography sx={{ cursor: 'pointer' }} display="inline" color={deleteChildrenFlag ? "primary" : "text.disabled"}><FormattedMessage id={deleteChildrenFlag ? "YES" : "NO"} /></Typography>
            </FormLabel>
        </Box>}
    </Stack>

    const onCloseDialog = () => {
        if (!entity) resetForm();
        setOpenForm(false);
    }

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <ConfirmationDialog
            open={openDeleteDialog}
            title={!entity ? null : <span><FormattedMessage id="CONFIRM_DELETE_FOR" /> {entity.name}</span>}
            content={deleteDialogContent}
            handleCancel={() => { setOpenDeleteDialog(false); setDeleteChildrenFlag(false); }}
            handleCustomButton={() => onDelete()}
            customButtonTitle={<FormattedMessage id="DELETE" />}
            customButtonColor='error'
            disabled={entity && (entity.connections.childrenArray.length > 0) && !deleteChildrenFlag}
        />
        <Dialog open={openForm} onClose={onCloseDialog} fullScreen={fullScreen} maxWidth='md' fullWidth>
            <DialogFormTitle
                mainTitle={<span><FormattedMessage id={entity ? "EDIT" : "ADD.ENTITY" + (parentId ? ".FOR" : "")} /> {parentId ? allEntities[parentId].name : entity && entity.name}</span>}
                action={entity && <Tooltip key="deleteLocationTooltip" title={<FormattedMessage id="ENTITY.DELETE" />} placement="bottom" arrow>
                    <IconButton key="deleteLocationTooltip" onClick={() => setOpenDeleteDialog(true)}><DeleteOutlined color="error" /></IconButton>
                </Tooltip>} />
            <Divider />
            <DialogContent>
                <EntityFormDialogContent entity={entity} name={name} setName={setName} description={description} setDescription={setDescription} address={address} setAddress={setAddress} position={position} setPosition={setPosition} setImage={setImage} setDisabledEditButton={setDisabledEditButton} />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button color="warning" onClick={onCloseDialog}><FormattedMessage id="CANCEL" /></Button>
                <Button onClick={onSubmit} disabled={!name.length || !address.length || (entity && disabledEditButton)}><FormattedMessage id={entity ? "EDIT" : "ADD"} /></Button>
            </DialogActions>
        </Dialog>
    </>
}

export default EntityForm;