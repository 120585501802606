import { CloseOutlined, InfoOutlined } from "@mui/icons-material";
import { FormLabel, IconButton, MenuItem, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectUnitsByFamily } from "redux/configurationSlice";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB, hr } from "date-fns/locale";

const HelperTooltip = ({ error }) => {
    return <Typography variant="caption">
        <FormattedMessage id="INVALID_OBJECT" />
        <Tooltip title={error} placement="right">
            <InfoOutlined fontSize="small" sx={{ pt: 1 }} />
        </Tooltip>
    </Typography>
}

const typeValueInitialSwitch = (type, defaultValue) => {
    switch (type) {
        case "String":
        case "Integer":
        case "Float":
        case "Boolean":
            return defaultValue || "";
        case "Time":
            return defaultValue || Date.now();
        case "Object":
            return JSON.stringify(defaultValue) || "";
        default:
            break;
    }
}


export default function MeasurementInputField({ type, defaultValue, onChange, onDelete, selectedUnit, variant }) {
    const units = useSelector((state) => selectUnitsByFamily(state, type.dataType.measure));
    const intl = useIntl();
    const [value, setValue] = useState(typeValueInitialSwitch(type.dataType.type, defaultValue));
    const [unit, setUnit] = useState(selectedUnit ? selectedUnit : units ? units[0] : "");
    const [error, setError] = useState(false);

    const valueParser = (type, value) => {
        setError(false);
        if (value === "") return;
        switch (type) {
            case "String":
            case "Time":
            case "Boolean":
                return value;
            case "Integer":
                return parseInt(value);
            case "Float":
                return parseFloat(value);
            case "Object":
                try {
                    setError(false);
                    return JSON.parse(value);
                } catch (error) {
                    setError(error.message);
                    return value;
                }
            default:
                break;
        }
    }

    const onChangeValue = (event) => {
        setValue(event.target.value);
        onChange({ key: type.key, value: valueParser(type.dataType.type, event.target.value), unit: unit });
    }
    const onChangeUnit = (event) => {
        setUnit(event.target.value);
        onChange({ key: type.key, value: value, unit: event.target.value });
    }

    const valueField = () => {
        const label = type.name || <FormattedMessage id={type.key} />;
        switch (type.dataType.type) {
            case "String":
                return <TextField variant={variant} autoFocus={Boolean(selectedUnit)} helperText={!selectedUnit && unit} value={value} onChange={onChangeValue} fullWidth size="small" label={label} />;
            case "Boolean":
                return <TextField variant={variant} autoFocus={Boolean(selectedUnit)} select value={value} onChange={onChangeValue} fullWidth size="small" label={label} >
                    <MenuItem value={"false"}>
                        <FormattedMessage id="FALSE" />
                    </MenuItem>
                    <MenuItem value={"true"}>
                        <FormattedMessage id="TRUE" />
                    </MenuItem>
                </TextField>;
            case "Integer":
                return <TextField variant={variant} autoFocus={Boolean(selectedUnit)} helperText={!selectedUnit && unit} type="number" value={value} onChange={onChangeValue} fullWidth size="small" label={label} placeholder={intl.formatMessage({ id: "MEASUREMENTS.PLACEHOLDER.INT" })} />;
            case "Float":
                return <TextField variant={variant} autoFocus={Boolean(selectedUnit)} helperText={!selectedUnit && unit} type="number" value={value} onChange={onChangeValue} fullWidth size="small" label={label} placeholder={intl.formatMessage({ id: "MEASUREMENTS.PLACEHOLDER.FLOAT" })} />;
            case "Time":
                return <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                    <DateTimePicker
                        autoFocus={Boolean(selectedUnit)}
                        sx={{ width: '100%' }}
                        value={value}
                        onChange={onChangeValue}
                        slotProps={{ textField: { size: 'small', variant: variant } }}
                    />
                </LocalizationProvider>;
            case "Object":
                return <TextField variant={variant} autoFocus={Boolean(selectedUnit)} multiline rows={4} value={value} error={Boolean(error)} helperText={error ? <HelperTooltip error={error} /> : ""} onChange={onChangeValue} fullWidth size="small" label={label} placeholder={intl.formatMessage({ id: "MEASUREMENTS.PLACEHOLDER.OBJECT" })} />;
            default:
                return <Typography sx={{ ml: 1 }} variant="h6" color="text.disabled" component="span">{type.name || intl.formatMessage({ id: type.key })} (<FormattedMessage id="MEASUREMENT_TYPE.NO_DATA_TYPE" />)</Typography>;
        }
    }

    return <Stack direction="row" spacing={1} sx={{ py: 1 }} alignItems="center" justifyContent="space-between">
        {valueField()}
        {!selectedUnit && type.dataType.measure && units && <TextField
            id={"measure-select-" + type.key}
            size="small"
            select
            sx={{ width: '30%' }}
            value={unit}
            onChange={onChangeUnit}
            helperText={unit && " "}
        >
            {units.map(el => <MenuItem key={type.key + "-" + el} value={el}>
                {el}
            </MenuItem>)}
        </TextField>}
        {selectedUnit && <Stack direction="column" sx={{ minWidth: '25%' }}>
            <FormLabel><FormattedMessage id="UNIT" /></FormLabel>
            <Typography variant="h5" >{selectedUnit}</Typography>
        </Stack>}
        {!selectedUnit && <Tooltip title={<FormattedMessage id="REMOVE" />} arrow>
            <IconButton size="small" color="error" onClick={onDelete}>
                <CloseOutlined fontSize="small" />
            </IconButton>
        </Tooltip>}
    </Stack>;
}