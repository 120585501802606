import { useState, useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectCollectorTypesConf } from "redux/configurationSlice";
import { userSelector } from "redux/userSlice";
import { selectDateRange } from "redux/dateRangeSlice";
import { LoadingData } from "components";
import { Alert, Box } from "@mui/material";
import { DoughnutChart } from "charts";
import API from "api";

const CollectorTypeDistributionPie = ({ entityIdArray, fixedDateFrom, fixedDateTo }) => {
    const { token } = useSelector(userSelector);
    const collectorTypes = useSelector(selectCollectorTypesConf);
    const intl = useIntl();
    const selectedDateRange = useSelector(selectDateRange);
    const [data, setData] = useState({ loading: true });

    const render = useCallback(() => {
        if (!data.loading && data.labels && data.labels.length > 0) return <Box sx={{ minHeight: 300 }}><DoughnutChart name={"typeDoughnutChart_" + entityIdArray[0]} data={data} /></Box>;
        else if (data.loading) return <LoadingData noText />;
        else if (data.error) return <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>;
        else return <Alert severity="warning"><FormattedMessage id='NO_DATA_TIME_PERIOD' /></Alert>

    }, [data, entityIdArray])

    const convertData = useCallback((apiData) => {
        const chartData = {
            labels: [],
            datasets: [{ data: [], keys: [] }]
        }
        apiData.forEach(row => {
            const type = `${collectorTypes.find(el => el.key === row._id).value}_TYPE`;
            chartData.labels.push(intl.formatMessage({ id: type }));
            chartData.datasets[0].keys.push(row._id);
            chartData.datasets[0].data.push(row.count);
        });
        setData({ ...chartData });
    }, [setData, collectorTypes, intl]);

    useEffect(() => {
        setData({ loading: true });
        API.measurements.getDistributionTypes(token, fixedDateFrom ? new Date(fixedDateFrom) : selectedDateRange.dateFrom, fixedDateTo ? new Date(fixedDateTo) : selectedDateRange.dateTo, entityIdArray).then(items => {
            if (items.data) {
                convertData(items.data)
            }
            else setData({});
        }).catch((error) => {
            console.error(error);
            setData({ error: error });
        })
    }, [entityIdArray, token, convertData, selectedDateRange.dateFrom, selectedDateRange.dateTo, fixedDateFrom, fixedDateTo])

    return <>
        {render()}
    </>

}

export default CollectorTypeDistributionPie;