import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { reportWebVitals } from 'utils';
import { fetchConfiguration } from 'redux/configurationSlice';
import { fetchEntities } from 'redux/entitySlice';
import { fetchTopics } from 'redux/topicSlice';
import { authUser } from 'redux/userSlice';
import { fetchUsersAndGroups } from 'redux/usersAndGroupsSlice';
import { Provider } from 'react-redux';
import { store } from 'redux/store'

store.dispatch(authUser()).then(({ type, payload }) => {
  let token = null;
  if (type === 'user/auth/fulfilled' && payload.token) {
    token = payload.token;
  }
  store.dispatch(fetchConfiguration(token)).then(({ payload }) => {
    store.dispatch(fetchEntities(token));
    if (payload?.userPermission && payload.userPermission['view-topic']) {
      store.dispatch(fetchTopics(token));
    }
    if (payload?.userPermission && payload.userPermission['view-users']) {
      store.dispatch(fetchUsersAndGroups(token));
    }
  })
});

const root = ReactDOM.createRoot(document.getElementById('root'));
document.title = process.env.REACT_APP_TITLE_ACRONYM;
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

if (process.env.NODE_ENV === "development") reportWebVitals(console.log);