import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { selectCollectorTypesConf } from 'redux/configurationSlice';
import { MeasurementDisplayBlock } from "components";
import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';

const CustomText = (props) => {
    return <Stack direction='column' sx={{ mb: 2 }}>
        <Typography variant='body2' color='primary.main'><FormattedMessage id={props.messageId} /></Typography>
        {props.component ? props.component : <Typography variant='body1'>{props.text}</Typography>}
    </Stack>
};

const SideDrawerComponent = ({ measurement }) => {
    const collectorTypes = useSelector(selectCollectorTypesConf);
    const intl = useIntl();
    return <>
        <CustomText messageId="ENTITY" text={measurement.entityId.name} />
        <CustomText messageId="COLLECTOR" text={measurement.source.name} />
        <CustomText messageId="COLLECTOR.TYPE" text={intl.formatMessage({ id: `${collectorTypes.find(type => type.key === measurement.source.type).value}_TYPE` })} />
        {measurement.unit.length > 0 && <CustomText messageId="UNIT" text={measurement.unit} />}
        <MeasurementDisplayBlock data={measurement} />
    </>
}

const PageComponent = ({ measurement }) => {
    return <Card sx={{ width: '100%' }}>
        <CardHeader title={<FormattedMessage id="MEASUREMENT_DETAILS" />} />
        <CardContent>
            <SideDrawerComponent measurement={measurement} />
        </CardContent>
    </Card>
}

const MeasurementDetails = ({ measurement, sideDrawer }) => {
    return sideDrawer ? <SideDrawerComponent measurement={measurement} /> : <PageComponent measurement={measurement} />
}

export default MeasurementDetails;