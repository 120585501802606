import { DefaultLayout } from 'layouts';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { SplashPage } from "pages";
import { EmptyState } from 'layouts';
import { ActivitiesView } from 'components';


const ActivitiesPage = (props) => {
    return <EmptyState component={<ActivitiesView />} />;
}

const ActivitiesPageComponent = () => {
    const { isSuccess: loggedIn } = useSelector(userSelector);
    const viewActivityPermission = useSelector((state) => permissionSelector(state, 'view-activity'));

    if (loggedIn && viewActivityPermission) return <DefaultLayout page={<ActivitiesPage />} />;
    else return <SplashPage />;
};

export default ActivitiesPageComponent;