import { DefaultLayout, EmptyState } from "layouts";
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import { UsersView, RolesView, UserGroupsView } from "components";
import { SplashPage } from "pages";

const AdministrationPage = ({ users, roles, userGroups }) => {
    if (users) return <EmptyState component={<UsersView />} />;
    if (roles) return <EmptyState component={<RolesView />} />;
    if (userGroups) return <EmptyState component={<UserGroupsView />} />;
    return null;
}

const AdministrationPageComponent = (props) => {
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn) return <DefaultLayout page={<AdministrationPage {...props} />} />;
    else return <SplashPage />

}

export default AdministrationPageComponent;