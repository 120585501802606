import { Button, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormLabel, Stack, TextField, Typography } from "@mui/material";
import { FreeMultipleForm, AlertSnackbar } from "components";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import API from 'api';


export default function StakeholderForm({ stakeholder, onCancel, setReset }) {
    const { token } = useSelector(userSelector);
    const [name, setName] = useState("");
    const [engagement, setEngagement] = useState([]);
    const [concerns, setConcerns] = useState([]);
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [alert, setAlert] = useState({ open: false });

    useEffect(() => {
        if (stakeholder) {
            setName(stakeholder.name);
            setEngagement([...stakeholder.engagement]);
            setConcerns([...stakeholder.concernsAndTopics]);
        }
    }, [stakeholder]);

    const handleSubmit = () => {
        const stakeholderObject = { "name": name, "engagementExamples": engagement, "raisedConcerns": concerns };

        setDisableSubmitButton(true);
        (stakeholder ? API.stakeholder.put(token, stakeholder.id, stakeholderObject) : API.stakeholder.post(token, stakeholderObject)).then(res => {
            setDisableSubmitButton(false);
            onCancel();
            setReset();
        }).catch(error => {
            const errorMsg = "ERROR." + (stakeholder ? "NOT_UPDATED" : "NOT_CREATED");
            setDisableSubmitButton(false);
            setAlert({ open: true, messageId: error?.data?.id || errorMsg, severity: "error" });
            console.error(error);
        });
    }

    const isUnchanged = () => {
        if (stakeholder) return name === stakeholder.name && JSON.stringify(engagement) === JSON.stringify(stakeholder.engagement) && JSON.stringify(concerns) === JSON.stringify(stakeholder.concernsAndTopics);
        else return false;
    }
    return <>
        <AlertSnackbar open={alert.open} onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} messageId={alert.messageId} />
        <DialogTitle>
            <FormattedMessage id="STAKEHOLDER_FORM" />
            <Typography variant='caption' sx={{ display: "block", color: "warning.main" }}><FormattedMessage id="REQUIRED.FIELDS" /></Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
            <Stack direction="column" gap={2}>
                <FormControl required>
                    <FormLabel><FormattedMessage id="NAME" /></FormLabel>
                    <TextField value={name} onChange={(e) => setName(e.target.value)} size="small" />
                </FormControl>
                <FreeMultipleForm participants={engagement} onChange={setEngagement} label={<FormattedMessage id="ENGAGEMENT" />} />
                <FreeMultipleForm participants={concerns} onChange={setConcerns} label={<FormattedMessage id="CONCERNS_AND_TOPICS" />} />
            </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
            <Button onClick={onCancel} color="warning"><FormattedMessage id="CANCEL" /></Button>
            <Button disabled={disableSubmitButton || !name.length || isUnchanged()} onClick={handleSubmit}><FormattedMessage id={stakeholder ? "EDIT" : "SUBMIT"} /></Button>
        </DialogActions>
    </>
}