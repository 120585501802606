import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    initDone: true,
    darkTheme: JSON.parse(localStorage.getItem('darkTheme')) ? JSON.parse(localStorage.getItem('darkTheme')) : false,
    fontSize: JSON.parse(localStorage.getItem('fontSize')) ? JSON.parse(localStorage.getItem('fontSize')) : 14,
    fontWeight: JSON.parse(localStorage.getItem('fontWeight')) ? JSON.parse(localStorage.getItem('fontWeight')) : 0,
    lang: JSON.parse(localStorage.getItem('lang')) ? JSON.parse(localStorage.getItem('lang')) : navigator.language.split(/[-_]/)[0]
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setDarkTheme: (state, action) => {
            state.darkTheme = action.payload;
            localStorage.setItem('darkTheme', JSON.stringify(action.payload));
        },

        setLanguage: (state, action) => {
            state.lang = action.payload;
            localStorage.setItem('lang', JSON.stringify(action.payload));
        },

        setFontSize: (state, action) => {
            state.fontSize = action.payload;
            localStorage.setItem('fontSize', JSON.stringify(action.payload));
        },

        setFontWeight: (state, action) => {
            state.fontWeight = action.payload;
            localStorage.setItem('fontWeight', JSON.stringify(action.payload));
        },
    }
});

export const { setDarkTheme, setLanguage, setFontSize, setFontWeight } = appSlice.actions;

export const selectInitDone = (state) => {
    if (state.configuration.status === 'failed' || state.entities.status === 'failed' || state.user.isError) return 'failed';
    return (state.configuration.status === 'complete' && state.entities.status === 'complete' && state.user.isDone);
}

export const selectAuthDone = (state) => {
    if (state.user.isError) return 'failed';
    return state.user.isDone;
}

export const selectThemeMode = (state) => {
    return state.app.darkTheme ? "dark" : "light";
}

export const selectLanguage = (state) => {
    return state.app.lang;
}

export const selectFontSize = (state) => {
    return state.app.fontSize;
}

export const selectFontWeight = (state) => {
    return state.app.fontWeight;
}

export default appSlice.reducer;
