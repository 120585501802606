import React, { useCallback, useEffect, useState } from 'react';
import { LoadingData, CustomDataGrid } from 'components';
import { Alert, useMediaQuery } from '@mui/material';
import API from 'api';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { selectMeasurementsTypesConf, selectNotificationMethodTypes, selectStatusTypes, selectPriorityTypes, selectNoteTypes, selectSeverityTypes } from 'redux/configurationSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import { dashboardTableHeaders } from 'components/dashboardView/TableHeaders';
import { selectEntities } from 'redux/entitySlice';
import { selectTopics } from 'redux/topicSlice';

const TableDashboardItem = ({ item, dataType, dateFrom, dateTo }) => {
    const [tableData, setTableData] = useState({ loading: true });
    const { token } = useSelector(userSelector);
    const measurementTypes = useSelector(selectMeasurementsTypesConf);
    const intl = useIntl();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const entities = useSelector(selectEntities);
    const notificationMethodTypes = useSelector(selectNotificationMethodTypes);
    const topics = useSelector(selectTopics);
    const statusTypes = useSelector(selectStatusTypes);
    const priorityTypes = useSelector(selectPriorityTypes);
    const noteTypes = useSelector(selectNoteTypes);
    const severityTypes = useSelector(selectSeverityTypes);

    useEffect(() => {
        const filterTopics = () => {
            let filteredTopics = topics.map(top => ({ ...top, id: top._id }));
            if (!!item.entity?.length) {
                filteredTopics = filteredTopics.filter(top => item.entity.includes(top.entityId));
            }
            if (!!item.priority?.length) {
                filteredTopics = filteredTopics.filter(top => item.priority.includes(top.priority));
            }
            if (!!item.type?.length) {
                filteredTopics = filteredTopics.filter(top => item.type.includes(top.type));
            }

            return filteredTopics;
        }

        if (dataType === "measurement") {
            API.measurements.getMeasurementValuesHistory(token, new Date(dateFrom), new Date(dateTo), item.entity, item.measurementType).then(items => {
                if (items.data) {
                    const data = [];
                    items.data.forEach(measurement => {
                        const measurementType = measurementTypes.find(mt => mt.key === measurement?.key);
                        measurement.values.forEach(collection => {
                            data.push({
                                id: collection.id,
                                entityId: measurement.entityId,
                                date: new Date(collection.y),
                                value: collection.x,
                                unit: collection.unit,
                                measurementType: measurementType?.name || intl.formatMessage({ id: measurementType?.key || "NONE" }),
                            })
                        })
                    })
                    setTableData(data);
                }
                else setTableData([]);
            }).catch(error => {
                console.error(error);
                setTableData({ error })
            })
        }
        else if (dataType === "entity") {
            setTableData(Object.keys(entities).map(ent => ({
                ...entities[ent], id: entities[ent]._id, address: entities[ent].geoProperties.address, position: entities[ent].geoProperties.position,
                turnover: entities[ent].KPI?.turnover, capEx: entities[ent].KPI?.capEx, opEx: entities[ent].KPI?.opEx
            })))
        }
        else if (dataType === "metric") {
            API.metrics.getAllMetrics(token, item.category, item.type, item.entity, item.measurementType, new Date(dateFrom), new Date(dateTo),).then(items => {
                if (items.data) {
                    setTableData(items.data.map(item => ({
                        ...item, id: item._id, reportInterval: item.reportData.reportInterval,
                        dataFrequencyInterval: item.reportData.dataFrequencyInterval,
                        calculationMethod: item.reportData?.calculationMethod && intl.formatMessage({ id: "CALCULATION_METHOD." + item.reportData.calculationMethod }),
                        categoryType: item.category.type,
                        unit: item.reportData?.reportUnit?.unit,
                    })));
                }
                else setTableData([]);
            }).catch(error => {
                console.error(error);
                setTableData({ error })
            })
        }
        else if (dataType === "metricReport") {
            API.metricReports.getAllMetricReports(token, item.metric, new Date(dateFrom), new Date(dateTo),).then(items => {
                if (items.data) {
                    setTableData(items.data.map(item => ({
                        ...item, id: item._id, dateFrom: item.datetime.dateFrom, dateTo: item.datetime.dateTo, lastUpdated: item.datetime.lastUpdated
                    })));
                }
                else setTableData([]);
            }).catch(error => {
                console.error(error);
                setTableData({ error })
            })
        }
        else if (dataType === "note") {
            API.notes.getAllNotes(token, undefined, undefined, item.type, item.entity, new Date(dateFrom), new Date(dateTo)).then((items) => {
                if (items.data) setTableData(items.data.map(note => ({ ...note, id: note._id, typeValue: noteTypes.find(type => type.key === note.type)?.value, date: new Date(note.timestamp) })));
                else setTableData([]);
            }).catch((error) => {
                console.error(error);
                setTableData({ error })
            })
        }
        else if (dataType === "goal") {
            API.goal.getAllGoals(token, item.topic, item.entity, item.status, item.type, new Date(dateFrom), new Date(dateTo)).then((items) => {
                if (items.data) setTableData(items.data.map(el => ({ id: el._id, entityId: topics.find(t => t._id === el.topicId)?.entityId, dateFrom: el.dateRange.startDate, dateTo: el.dateRange.endDate, ...el })));
                else setTableData([]);
            }).catch((error) => {
                console.error(error);
                setTableData({ error })
            })
        }
        else if (dataType === "topic") {
            setTableData(filterTopics());
        }
        else if (dataType === "target") {
            API.target.getAllTargets(token, item.goal, item.entity, item.metric, item.status, item.type, new Date(dateFrom), new Date(dateTo)).then(response => {
                if (response.data) setTableData(response.data.map(target => ({
                    ...target, id: target._id,
                    currentValue: target.measurable?.currentValue, endValue: target.measurable?.endValue,
                    dateFrom: target.dateRange.startDate, dateTo: target.dateRange.endDate,
                })))
                else setTableData([]);
            }).catch((error) => {
                console.error(error);
                setTableData({ error })
            })
        }
        else if (dataType === "alarm") {
            API.alarm.getAllAlarms(token, new Date(dateFrom), new Date(dateTo), undefined, undefined, undefined, item.entity).then(response => {
                if (response.data) setTableData(response.data.map(alarm => ({
                    ...alarm, id: alarm._id,
                })))
                else setTableData([]);
            }).catch((error) => {
                console.error(error);
                setTableData({ error })
            })
        }
        else if (dataType === "alarmProfile") {
            API.alarmProfile.getAllAlarmProfiles(token, item.entity).then(response => {
                if (response.data) setTableData(response.data.map(alarmProfile => ({
                    ...alarmProfile, id: alarmProfile._id,
                })))
                else setTableData([]);
            }).catch((error) => {
                console.error(error);
                setTableData({ error })
            })
        }
        else if (dataType === "stakeholder") {
            API.stakeholder.getAll(token).then(response => {
                if (response.data) setTableData(response.data.map(stakeholder => ({
                    ...stakeholder, id: stakeholder._id,
                })))
                else setTableData([]);
            }).catch((error) => {
                console.error(error);
                setTableData({ error })
            })
        }

    }, [token, dataType, dateFrom, dateTo, intl, item.entity, item.category, item.type,
        item.measurementType, item.metric, item.status, item.topic, item.priority, item.goal, priorityTypes, statusTypes, topics, measurementTypes, entities, smallScreen, notificationMethodTypes, noteTypes])

    const renderTable = useCallback(() => {
        if (tableData.loading) return <LoadingData />;
        else if (tableData.error) return <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>;
        else return <CustomDataGrid rows={tableData}
            columns={dashboardTableHeaders(smallScreen, intl, entities, statusTypes, priorityTypes, topics, notificationMethodTypes, severityTypes).filter(header => item.properties?.some(prop => header.modelProp.includes(prop)))}
            enableExport />
    }, [tableData, entities, intl, item.properties, notificationMethodTypes, priorityTypes, smallScreen, statusTypes, topics, severityTypes])

    return <>
        {renderTable()}
    </>
}

export default TableDashboardItem;