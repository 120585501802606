import React from 'react';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { enGB, hr } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useIntl, FormattedMessage } from 'react-intl';

const DateInputFields = ({ dateFrom, setDateFrom, dateTo, setDateTo }) => {
    const intl = useIntl();
    return <>
        <FormControl>
            <FormLabel required>
                <FormattedMessage id="DATE_FROM" />
            </FormLabel>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={intl.locale === 'hr' ? hr : enGB}
            >
                <DateTimePicker
                    value={new Date(dateFrom)}
                    onChange={(date) => setDateFrom(date)}
                    slotProps={{ textField: { size: 'small' } }}
                />
            </LocalizationProvider>
            {new Date(dateFrom).toString() === 'Invalid Date' && (
                <FormHelperText error>
                    <FormattedMessage id="INVALID_DATE" />
                </FormHelperText>
            )}
        </FormControl>
        <FormControl>
            <FormLabel required>
                <FormattedMessage id="DATE_TO" />
            </FormLabel>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={intl.locale === 'hr' ? hr : enGB}
            >
                <DateTimePicker
                    value={new Date(dateTo)}
                    onChange={(date) => setDateTo(date)}
                    slotProps={{ textField: { size: 'small' } }}
                />
            </LocalizationProvider>
            {new Date(dateTo).toString() === 'Invalid Date' && (
                <FormHelperText error>
                    <FormattedMessage id="INVALID_DATE" />
                </FormHelperText>
            )}
        </FormControl>
    </>
}

export default DateInputFields;