import { InfoOutlined } from "@mui/icons-material";
import { Divider, Typography, Stack, TextField, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, Dialog, CardHeader, DialogContent, useMediaQuery, DialogActions, Button, IconButton, InputAdornment, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import { ActivityDetails, AlertSnackbar } from 'components';
import API from 'api';

const SmallRadio = (props) => <Radio {...props} sx={{
    '& .MuiSvgIcon-root': {
        fontSize: 18,
    },
}} />

const InputProps = (val) => ({
    endAdornment: <InputAdornment position="end">{val ? "%" : "€"}</InputAdornment>,
})

export default function ActivityUpdateForm({ open, onClose, activity, entity, AlertCall, onReset }) {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const { token } = useSelector(userSelector);
    const [data, setData] = useState(null);
    const [turnover, setTurnover] = useState(null);
    const [turnoverPC, setTurnoverPC] = useState(null); // percent
    const [capex, setCapex] = useState(null);
    const [capexPC, setCapexPC] = useState(null);
    const [opex, setOpex] = useState(null);
    const [opexPC, setOpexPC] = useState(null);
    const [selected, setSelected] = useState(null);
    const [openInfo, setOpenInfo] = useState(false);
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (activity) {
            setData(activity.objectives);
            setTurnover(activity.KPI.turnover ?? "");
            setTurnoverPC(activity.KPIAlignedPercentage.turnover ?? "");
            setCapex(activity.KPI.capEx ?? "");
            setCapexPC(activity.KPIAlignedPercentage.capEx ?? "");
            setOpex(activity.KPI.opEx ?? "");
            setOpexPC(activity.KPIAlignedPercentage.opEx ?? "");
        }
    }, [activity]);

    const isActivityCompliant = () => {
        if (!data) return false;
        const values = Object.values(data);
        for (const v of values) {
            if (v.assessment === null || v.assessment === "N" || v.assessment === "N-EL") continue;
            else if (Object.values(v.dnsh).some(el => el === null || el === "N")) continue;
            else return true;
        }
        return false;
    }
    const isCompliant = (value) => value.assessment === "Y" && Object.values(value.dnsh).every(el => el === "Y");

    const handleSubmit = () => {
        const changedActivity = {
            objectives: { ...data },
            KPI: { turnover, capEx: capex, opEx: opex },
            KPIAlignedPercentage: isActivityCompliant()
                ? { turnover: turnoverPC, capEx: capexPC, opEx: opexPC }
                : { turnover: null, capEx: null, opEx: null }
        };

        API.activity.putEntityActivity(token, activity._id, changedActivity).then(res => {
            AlertCall({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
            onReset(res.data ? { ...activity, ...res.data, activityId: activity.activityId } : null);
            onClose();
        }).catch(error => {
            console.error(error);
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_UPDATED", severity: "error" });
        })

    }

    const handleKPIchange = (value, cb) => {
        if (value === null || value === "") cb(null);
        else if (!isNaN(value) && value >= 0) cb(value);
        else if (!alert.open) setAlert({ open: true, messageId: "ERROR.INVALID_DATA_TYPE.NUMBER_REQ", severity: "warning" });
    }

    const changePercent = (cb) => ((e) => {
        const value = e.target.value;
        if (value === null || value === "") cb(null);
        else if (Number(value) % 1 === 0 && value >= 0 && value <= 100) cb(value);
        else setAlert({ open: true, messageId: "ERROR.INVALID_DATA_TYPE.NUMBER_REQ_PERCENT", severity: "warning" });
    });

    if (!activity || data === null) return;

    return <Dialog open={open} fullScreen={smallScreen} maxWidth="sm" fullWidth>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <CardHeader title={<FormattedMessage id="ACTIVITY.UPDATE" />}
            subheader={<span>
                {activity.nameKey || <FormattedMessage id={activity.name} />}
                <br />
                {entity.name}
            </span>}
            action={[
                activity ? <IconButton key="info" onClick={() => { setSelected({ ...activity, objectives: activity.activityId.objectives }); setOpenInfo(true); }} color="info"><InfoOutlined /></IconButton> : null,
            ]}
        />
        <Divider />
        <DialogContent>
            <Stack gap={1.5}>
                <Divider>
                    <Typography variant="h6" color="secondary.main"><FormattedMessage id="KPIs.LONG" /></Typography>
                </Divider>

                <TextField value={turnover || ""} onChange={(e) => handleKPIchange(e.target.value, setTurnover)} InputProps={InputProps()} label={<FormattedMessage id="turnover_total" />} fullWidth size="small" />
                <TextField value={capex || ""} onChange={(e) => handleKPIchange(e.target.value, setCapex)} InputProps={InputProps()} label={<FormattedMessage id="capEx_total" />} fullWidth size="small" />
                <TextField value={opex || ""} onChange={(e) => handleKPIchange(e.target.value, setOpex)} InputProps={InputProps()} label={<FormattedMessage id="opEx_total" />} fullWidth size="small" />

                <Divider>
                    <Typography variant="h6" color="secondary.main"><FormattedMessage id="OBJECTIVES" /></Typography>
                </Divider>
                {Object.entries(data).map(([key, value]) => <Stack key={key + value} gap={1} sx={theme => ({ px: 1, border: `0.5px solid ${theme.palette[isCompliant(value) ? "success" : "error"].main}`, borderRadius: 1 })}>
                    <FormControl>
                        <FormLabel sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><Typography variant="h6"><FormattedMessage id={key} /></Typography> <Alert sx={{ border: 0, pl: 0 }} severity={isCompliant(value) ? "success" : "error"} variant="outlined"><FormattedMessage id={isCompliant(value) ? "COMPLIANT" : "NOT_COMPLIANT"} /></Alert></FormLabel>
                        <RadioGroup
                            aria-labelledby="eligibility"
                            value={value.assessment}
                            onChange={(e, v) => setData(oldData => ({ ...oldData, [key]: { assessment: v, dnsh: value.dnsh } }))}
                        >
                            <FormControlLabel value="Y" control={<Radio />} label={<FormattedMessage id="YES" />} />
                            <FormControlLabel value="N" control={<Radio />} label={<FormattedMessage id="NO" />} />
                            <FormControlLabel value="N-EL" control={<Radio />} label={<FormattedMessage id="NEL" />} />
                        </RadioGroup>
                    </FormControl>

                    {Object.entries(value.dnsh).map(([dnshkey, dnshvalue]) => <Stack key={dnshkey} pl={1} gap={1}>
                        <FormControl>
                            <FormLabel><FormattedMessage id={dnshkey} /></FormLabel>
                            <Typography color="error" variant="caption" component="span">
                                <FormattedMessage id="DNSH_CRITERIA" />
                            </Typography>
                            <RadioGroup
                                aria-labelledby="eligibility"
                                value={dnshvalue}
                                onChange={(e, v) => setData(oldData => ({ ...oldData, [key]: { assessment: oldData[key].assessment, dnsh: { ...oldData[key].dnsh, [dnshkey]: v } } }))}
                            >
                                <FormControlLabel value="Y" control={<SmallRadio />} label={<FormattedMessage id="YES" />} />
                                <FormControlLabel value="N" control={<SmallRadio />} label={<FormattedMessage id="NO" />} />
                            </RadioGroup>
                        </FormControl>
                    </Stack>)}
                </Stack>)}
                <Divider>
                    <Typography variant="h6" color="secondary.main"><FormattedMessage id="SUBSTANTIAL_CON" /></Typography>
                </Divider>
                {!isActivityCompliant() ? <Alert size="small" severity="error" variant="outlined"><FormattedMessage id="NOT_COMPLIANT" /></Alert>
                    : [
                        <TextField key="1" disabled={!isActivityCompliant()} value={turnoverPC || ""} onChange={changePercent(setTurnoverPC)} InputProps={InputProps(true)} label={<FormattedMessage id="turnover" />} fullWidth size="small" />,
                        <TextField key="2" disabled={!isActivityCompliant()} value={capexPC || ""} onChange={changePercent(setCapexPC)} InputProps={InputProps(true)} label={<FormattedMessage id="capEx" />} fullWidth size="small" />,
                        <TextField key="3" disabled={!isActivityCompliant()} value={opexPC || ""} onChange={changePercent(setOpexPC)} InputProps={InputProps(true)} label={<FormattedMessage id="opEx" />} fullWidth size="small" />,
                    ].map(el => el)}
            </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
            <Button onClick={onClose} color="warning"><FormattedMessage id="CANCEL" /></Button>
            <Button onClick={handleSubmit}><FormattedMessage id="SUBMIT" /></Button>
        </DialogActions>
        {activity && <ActivityDetails noAction open={openInfo} onClose={() => { setSelected(null); setOpenInfo(false); }} activity={selected} />}
    </Dialog>
}