import { Typography, Link } from '@mui/material';
import { FormattedMessage } from 'react-intl';


const Footer = (props) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props} sx={{ py: 0.5 }} >
            <Link color="inherit" href={process.env.REACT_APP_COPYRIGHT_URL}>
                {process.env.REACT_APP_TITLE_ACRONYM}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
            <Typography variant='caption' component='span' textTransform='lowercase' pl={1}><FormattedMessage id="VERSION" /> {`${process.env.REACT_APP_VERSION}`}</Typography>
        </Typography >
    );
};

export default Footer;