import { Autocomplete, Checkbox, FormControl, FormControlLabel, FormLabel, MenuItem, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LineChartConstructor from './LineChartConstructor';
import { useSelector } from 'react-redux';
import { selectStatusTypes, selectNoteTypes, selectPriorityTypes, selectMetricTypesConf, selectDashboardConfiguration } from 'redux/configurationSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import { selectTopics } from 'redux/topicSlice';
import { selectEntities } from 'redux/entitySlice';
import { getLabelId } from 'utils';

const TableConstructor = ({ checkExistance, selectedEntities, setSelectedEntities, selectedAttributes, setSelectedAttributes, collectorType, setCollectorType, allMetrics, dataType, allGoals, setTableParams, existingWidget }) => {
    const statusTypes = useSelector(selectStatusTypes);
    const noteTypes = useSelector(selectNoteTypes);
    const metricTypes = useSelector(selectMetricTypesConf);
    const allTopics = useSelector(selectTopics);
    const entities = useSelector(selectEntities);
    const priorityTypes = useSelector(selectPriorityTypes);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedGoals, setSelectedGoals] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [selectedPriorities, setSelectedPriorities] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedProperties, setSelectedProperties] = useState([]);
    const dashboardConfiguration = useSelector(selectDashboardConfiguration);
    const dataTypeConfigArray = dashboardConfiguration['table'].source.find(item => dataType in item) && dashboardConfiguration['table'].source.find(item => dataType in item)[dataType].properties;
    const intl = useIntl();
    const [loadedGoals, setLoadedGoals] = useState(false);

    useEffect(() => {
        setSelectedTypes([]);
        setSelectedProperties([]);
        setSelectedCategory("");
        setSelectedPriorities([]);
        setSelectedGoals([]);
        setSelectedStatuses([]);
    }, [dataType]);

    useEffect(() => {
        if (allGoals.length > 0) setLoadedGoals(true);
    }, [allGoals])

    useEffect(() => {
        if (existingWidget) {
            const tableConfig = existingWidget.config.source[existingWidget.config.source.dataType];
            const typeConfig = dataType === "note" ? noteTypes : metricTypes;
            if (tableConfig) {
                setSelectedProperties(tableConfig.properties);
                if (checkExistance("status")) setSelectedStatuses(statusTypes.filter(el => tableConfig.status?.includes(el.key)));
                if (checkExistance("type")) setSelectedTypes(typeConfig.filter(el => tableConfig.type?.includes(el.key)));
                if (checkExistance("priority")) setSelectedPriorities(priorityTypes.filter(el => tableConfig.priority?.includes(el.key)));
                if (checkExistance("goal") && loadedGoals) setSelectedGoals(allGoals.filter(el => tableConfig.goal?.includes(el._id)));
                if (checkExistance("topic")) setSelectedTopics(allTopics.filter(el => tableConfig.topic?.includes(el._id)));
                if (checkExistance("category")) setSelectedCategory(tableConfig.category);
            }
        }

    }, [loadedGoals, allGoals, allTopics, checkExistance, dataType, existingWidget, metricTypes, noteTypes, priorityTypes, statusTypes])

    useEffect(() => {
        setTableParams({
            [dataType]: {
                properties: selectedProperties,
                ...(checkExistance("entity") && { entity: selectedEntities.map(entity => entity._id) }),
                ...(checkExistance("measurementType") && { measurementType: selectedAttributes }),
                ...(checkExistance("metric") && selectedAttributes.length && { metric: selectedAttributes.map(metric => metric._id) }),
                ...(checkExistance("status") && { status: selectedStatuses.map(type => type.key) }),
                ...(checkExistance("type") && { type: selectedTypes.map(type => type.key) }),
                ...(checkExistance("priority") && { priority: selectedPriorities.map(type => type.key) }),
                ...(checkExistance("goal") && { goal: selectedGoals.map(goal => goal._id) }),
                ...(checkExistance("topic") && { topic: selectedTopics.map(topic => topic._id) }),
                ...(checkExistance("category") && { category: selectedCategory }),
            }
        })

    }, [dataType, selectedAttributes, selectedEntities, selectedGoals, selectedPriorities, selectedStatuses, selectedTopics, selectedTypes, selectedProperties, setTableParams, checkExistance, selectedCategory])

    const handlePropertiesChange = (e, element) => {
        if (e.target.checked) {
            setSelectedProperties([...selectedProperties, element]);
        } else {
            setSelectedProperties(selectedProperties.filter((item) => item !== element));
        }
    }

    const handleSelectAll = () => {
        if (selectedProperties.length < dataTypeConfigArray.length) {
            setSelectedProperties(dataTypeConfigArray.map(el => el))
        }
        else {
            setSelectedProperties([]);
        }
    }

    return <><Stack direction="column" width={"100%"} spacing={1}>
        <LineChartConstructor checkExistance={checkExistance} selectedEntities={selectedEntities}
            setSelectedEntities={setSelectedEntities} selectedAttributes={selectedAttributes} setSelectedAttributes={setSelectedAttributes}
            allMetrics={allMetrics} collectorType={collectorType} setCollectorType={setCollectorType} existingWidget={existingWidget} dataType={dataType} fromTable={true} />

        {checkExistance("status") && <FormControl>
            <FormLabel><FormattedMessage id="STATUS" /></FormLabel>
            <Autocomplete
                id="select-status"
                multiple
                options={statusTypes}
                getOptionLabel={(option) => intl.formatMessage({ id: "STATUS." + option.value })}
                value={selectedStatuses}
                onChange={(e, newValue) => setSelectedStatuses(newValue)}
                renderInput={(params) => (
                    <TextField {...params} />
                )}
                size="small"
                fullWidth
            />
        </FormControl>}
        {checkExistance("type") && <FormControl>
            <FormLabel><FormattedMessage id="ESG.TYPE" /></FormLabel>
            <Autocomplete
                id="select-type"
                multiple
                options={dataType === "note" ? noteTypes : metricTypes}
                getOptionLabel={(option) => dataType === "note" ? intl.formatMessage({ id: "NOTE.TYPE." + option.value }) : intl.formatMessage({ id: "ESG.TYPE." + option.key })}
                value={selectedTypes}
                onChange={(e, newValue) => setSelectedTypes(newValue)}
                renderInput={(params) => (
                    <TextField {...params} />
                )}
                size="small"
                fullWidth
            />
        </FormControl>}
        {checkExistance("goal") && <FormControl>
            <FormLabel><FormattedMessage id="ESG.GOAL" /></FormLabel>
            <Autocomplete
                id="select-goal"
                multiple
                options={allGoals}
                getOptionLabel={(goal) => goal.name}
                value={selectedGoals}
                onChange={(e, goal) => setSelectedGoals(goal)}
                renderInput={(params) => (
                    <TextField {...params} />
                )}
                isOptionEqualToValue={(option, value) => option?._id === value?._id}
                size="small"
                fullWidth
            />
        </FormControl>}
        {checkExistance("topic") && <FormControl>
            <FormLabel><FormattedMessage id="ESG.TOPIC" /></FormLabel>
            <Autocomplete
                id="select-topic"
                multiple
                options={allTopics}
                getOptionLabel={(topic) => `${topic.name} (${entities[topic.entityId].name})`}
                value={selectedTopics}
                onChange={(e, topic) => setSelectedTopics(topic)}
                renderInput={(params) => (
                    <TextField {...params} />
                )}
                isOptionEqualToValue={(option, value) => option?._id === value?._id}
                size="small"
                fullWidth
            />
        </FormControl>}

        {checkExistance("priority") && <FormControl>
            <FormLabel><FormattedMessage id="PRIORITY" /></FormLabel>
            <Autocomplete
                id="select-priority"
                multiple
                options={priorityTypes}
                getOptionLabel={(option) => intl.formatMessage({ id: "PRIORITY." + option.value })}
                value={selectedPriorities}
                onChange={(e, newValue) => setSelectedPriorities(newValue)}
                renderInput={(params) => (
                    <TextField {...params} />
                )}
                size="small"
                fullWidth
            />
        </FormControl>}
        {checkExistance("category") && <FormControl>
            <FormLabel><FormattedMessage id="ESG.METRIC.CATEGORY" /></FormLabel>
            <TextField
                id="select-category"
                select
                size="small"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
            >
                {["", process.env.REACT_APP_ESG_METRIC_DEFAULT_TYPE, "Qualitative"].map(type => <MenuItem key={type} value={type}>{type === "" ? <Typography p={1.5}>{type}</Typography> : <FormattedMessage id={"ESG.METRIC.TYPE." + type.toUpperCase()} />}</MenuItem>)}
            </TextField>
        </FormControl>}
        {dataType && <FormControl required>
            <FormLabel ><FormattedMessage id="DASHBOARD.SELECT_PROPERTIES" /></FormLabel>
            <FormControlLabel
                control={<Checkbox
                    checked={selectedProperties.length === dataTypeConfigArray.length}
                    onChange={(e) => handleSelectAll()} />}
                label={<FormattedMessage id="DASHBOARD.SELECT_ALL" />} />
            {dataTypeConfigArray.map((element, index) => (
                <FormControlLabel sx={{ ml: 1 }} key={index}
                    control={<Checkbox
                        checked={selectedProperties.includes(element)}
                        onChange={(e) => handlePropertiesChange(e, element)} />}
                    label={intl.formatMessage({ id: getLabelId(element) })} />
            ))}
        </FormControl>}
    </Stack></>
}

export default TableConstructor;