import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Box, FormLabel, Stack, TextField, useTheme } from "@mui/material";
import { MapComponent, MapElementNew, PictureUploadButton } from "components";

import { Vector } from 'ol/source';
import { fromLonLat } from "ol/proj";

const EntityFormDialogContent = ({ entity, name, setName, description, setDescription, address, setAddress, position, setPosition, setImage, setDisabledEditButton }) => {
    const markerSource = useRef(new Vector());
    const themeMode = useTheme().palette.mode;
    const locationImageUrl = entity && entity.attributes && entity.attributes.picture ? `${process.env.REACT_APP_API_BASE_URL}/${entity.attributes.picture}` : `${process.env.PUBLIC_URL}/assets/images/default/default.${themeMode}.png`

    const handlePositionChange = (lng, lat) => {
        const newPosition = [];
        parseFloat(lng) ? newPosition.push(parseFloat(lng)) : newPosition.push(0);
        parseFloat(lat) ? newPosition.push(parseFloat(lat)) : newPosition.push(0);
        setPosition(newPosition);

        if (markerSource.current.getFeatures().length !== 1) {
            // shouldn't happen
            return;
        }

        const pinFeature = markerSource.current.getFeatures()[0];
        // change coordinates for the existing Feature on the map
        pinFeature.getGeometry().setCoordinates(fromLonLat(newPosition));
    }

    return <Stack spacing={2} >

        <Box>
            <FormLabel required><FormattedMessage id="NAME" /></FormLabel>
            <TextField
                required
                fullWidth
                variant="outlined"
                id="NAME"
                type="text"
                size="small"
                value={name}
                onChange={(e) => { setDisabledEditButton(false); setName(e.target.value) }}
                inputProps={{ maxLength: process.env.REACT_APP_ESG_INPUT_RESTRICTION_SMALL }}
            />
        </Box>

        <Box>
            <FormLabel><FormattedMessage id="DESCRIPTION" /></FormLabel>
            <TextField
                fullWidth
                variant="outlined"
                id="DESCRIPTION"
                type="text"
                size="small"
                value={description}
                onChange={(e) => { setDisabledEditButton(false); setDescription(e.target.value) }}
                inputProps={{ maxLength: process.env.REACT_APP_ESG_INPUT_RESTRICTION_MEDIUM }}
            />
        </Box>
        <Box>
            <FormLabel required><FormattedMessage id="POSITION" /></FormLabel>
            <div style={{ height: '200px' }}>
                <MapComponent className="pin-input" zoom={entity ? 12 : 10} position={position} disableShapes>
                    <MapElementNew
                        key="map-elements"
                        markerSource={markerSource.current}
                        positionInput={position}
                        positionChange={setPosition}
                    />
                </MapComponent>
            </div>
        </Box>

        <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
            <TextField
                required
                key="lng"
                fullWidth
                id="LONGITUDE"
                type="number"
                label={<FormattedMessage id="LONGITUDE" />}
                inputProps={{ inputMode: 'decimal', step: 0.000001 }}
                value={position[0]}
                onChange={(e) => { setDisabledEditButton(false); handlePositionChange(e.target.value, position[1]) }}
                variant="outlined"
                size="small"
            />
            <TextField
                required
                key="lat"
                fullWidth
                id="LATITUDE"
                type="number"
                label={<FormattedMessage id="LATITUDE" />}
                inputProps={{ inputMode: 'decimal', step: 0.000001 }}
                value={position[1]}
                onChange={(e) => { setDisabledEditButton(false); handlePositionChange(position[0], e.target.value) }}
                variant="outlined"
                size="small"
            />
        </Stack>

        <Box>
            <FormLabel required><FormattedMessage id="ADDRESS" /></FormLabel>
            <TextField
                fullWidth
                variant="outlined"
                id="ADDRESS"
                type="text"
                size="small"
                value={address}
                onChange={(e) => { setDisabledEditButton(false); setAddress(e.target.value) }}
            />
        </Box>

        <Box sx={{ py: 1 }}>
            <PictureUploadButton
                defaultImage={{ name: entity ? entity.name : process.env.REACT_APP_DEFAULT_IMAGE, url: locationImageUrl }}
                setFiles={setImage}
                single
                setDisabledEditButton={setDisabledEditButton}
            />
        </Box>
    </Stack>
}

export default EntityFormDialogContent;