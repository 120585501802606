import { TextField, InputAdornment, IconButton, Stack, Chip, Box, Tooltip, useMediaQuery } from "@mui/material";
import { AddOutlined, CloseOutlined, SearchOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { permissionSelector } from "redux/userSlice";

export default function SearchBar(props) {
    const { search, setSearch, noSubmit, entityId, addEntity, helperText, tooltip, sx, variant } = props;
    const [localValue, setLocalValue] = useState(search);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const intl = useIntl();
    const createEntityPermission = useSelector((state) => permissionSelector(state, 'create-entity'));

    const handleSearch = (value) => {
        if (typeof setSearch === 'function') setSearch(value || localValue);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') handleSearch();
        if (e.key === 'Escape') {
            setLocalValue("");
            handleSearch("");
        }
    }

    const handleChange = (e) => {
        setLocalValue(e.target.value);
        if (noSubmit) handleSearch(e.target.value)
    }

    useEffect(() => {
        if (!localValue?.length && typeof setSearch === 'function') setSearch("");
    }, [localValue, setSearch])

    useEffect(() => {
        setLocalValue("");
    }, [entityId])

    return (
        <Stack direction="row" spacing={1} alignItems="top" sx={{ width: '100%' }}>
            <TextField
                sx={{
                    p: 1,
                    ...sx,
                    '.MuiInputBase-root': {
                        paddingLeft: noSubmit ? 1 : 0,
                    },
                    '.MuiOutlinedInput-input': {
                        paddingLeft: 0,
                    }
                }}
                value={localValue}
                onKeyDown={handleKeyPress}
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: "SEARCH_PLACEHOLDER." + ((smallScreen || noSubmit || tooltip) ? "NO_SUBMIT" : "SUBMIT") })}
                InputProps={{
                    startAdornment: !noSubmit && (
                        <InputAdornment position="start">
                            <IconButton onClick={() => handleSearch()} aria-label="search">
                                <SearchOutlined color="primary" />
                            </IconButton>
                        </InputAdornment>
                    ),
                    endAdornment: localValue.length > 0 && (
                        <InputAdornment position="end">
                            <Tooltip title={<FormattedMessage id="CLEAR" />}>
                                <IconButton component="span" onClick={() => handleChange({ target: { value: "" } })} size="small" aria-label="search">
                                    <CloseOutlined fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    )
                }}
                helperText={<Stack component="span">
                    {((smallScreen && !noSubmit) || (!noSubmit && tooltip && !helperText)) && <FormattedMessage id="SEARCH_PLACEHOLDER.SUFFIX" />}
                    {helperText}
                </Stack>}
                size="small"
                variant={variant || "outlined"}
                fullWidth
            />
            {createEntityPermission && addEntity && <Box sx={{ pr: 1, pt: 1.5 }}>
                <Chip variant="outlined" icon={<AddOutlined fontSize="small" color="primary" />} onClick={() => addEntity.setOpenForm(true)} label={<FormattedMessage id={"ADD.ENTITY"} />} />
            </Box>}
        </Stack>
    );
}
