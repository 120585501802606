import instance from "./axiosEnv";
import { setAuthHeader } from "./axiosEnv";
import { localISOString } from "utils";

const getAllTopics = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/esg/topic', config);
}

const getTopic = (topicId, token) => {
    const config = setAuthHeader(token);
    return instance.get('/esg/topic/' + topicId, config);
}

const getTopicsCount = (token, entityIds, priorities, types, dateFrom, dateTo, display) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    if (entityIds) {
        for (let el of entityIds) {
            queryParams.append('entityId', el);
        }
    }
    if (types) {
        for (let el of types) {
            queryParams.append('type', el);
        }
    }
    if (priorities) {
        for (let el of priorities) {
            queryParams.append('priority', el);
        }
    }
    if (dateFrom) queryParams.append('dateFrom', localISOString(dateFrom));
    if (dateTo) queryParams.append('dateTo', localISOString(dateTo));
    if (display) queryParams.append('display', display);

    return instance.get('/esg/topic/count?' + queryParams.toString(), config);
}

const createTopic = (data, token, templateFlag) => {
    const config = setAuthHeader(token);
    return instance.post('/esg/topic' + (templateFlag ? "/template" : ""), data, config);
}

const updateTopic = (topicId, data, token) => {
    const config = setAuthHeader(token);
    return instance.put('/esg/topic/' + topicId, data, config);
}

const updateTopicAssignMetrics = (topicId, data, token) => {
    const config = setAuthHeader(token);
    return instance.put('/esg/topic/' + topicId + '/assign', data, config);
}

const deleteTopic = (topicId, token, deleteChildrenFlag) => {
    const config = setAuthHeader(token);
    return instance.delete('/esg/topic/' + topicId + (deleteChildrenFlag ? '/true' : ''), config);
}

const topicAPI = {
    getAllTopics,
    getTopic,
    getTopicsCount,
    createTopic,
    updateTopic,
    updateTopicAssignMetrics,
    deleteTopic,
};

export default topicAPI;