import instance, { setAuthHeader } from "./axiosEnv";

const createFormDataHelper = (newEntityObject, imageFile) => {
    const formData = new FormData();
    for (const key in newEntityObject) {
        if (typeof newEntityObject[key] !== 'string') {
            formData.append(key, JSON.stringify(newEntityObject[key]));
        }
        else formData.append(key, (newEntityObject[key]));
    }
    if (imageFile) for (const file of imageFile) {
        formData.append('picture', file, file.name);
    }

    return formData;
}

const getAllEntities = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/entity', config);
}

const getEntityKPI = (token, entityId, dateYear) => {
    const config = setAuthHeader(token);
    if (dateYear) return instance.get('/KPI/' + entityId + '/' + dateYear, config);
    else return instance.get('/KPI/' + entityId, config);
}

const getEntityKPIValuesHistory = (token, entityId) => {
    const config = setAuthHeader(token);
    return instance.get('/KPI/' + entityId + '/valuesHistory', config);
}

const createEntity = (token, entityObject, parentId, image) => {
    const config = setAuthHeader(token);
    const parentIdString = parentId ? parentId : "";
    return instance.post('/entity/' + parentIdString, createFormDataHelper(entityObject, image), config);
}

const updateEntity = (token, entityId, entityObject, image) => {
    const config = setAuthHeader(token);
    return instance.put('/entity/' + entityId, createFormDataHelper(entityObject, image), config);
}

const updateEntityKPI = (token, entityId, kpi, dateYear) => {
    const config = setAuthHeader(token);
    return instance.put('/KPI/' + entityId + '/' + dateYear, kpi, config);
}

const createEntityKPI = (token, entityId, kpi, dateYear) => {
    const config = setAuthHeader(token);
    return instance.post('/KPI/' + entityId, { ...kpi, dateYear }, config);
}

const deleteEntity = (token, entityId,) => {
    const config = setAuthHeader(token);
    return instance.delete('/entity/' + entityId + '/' + true, config);
}

const entitiesAPI = {
    getAllEntities,
    getEntityKPI,
    getEntityKPIValuesHistory,
    createEntity,
    createEntityKPI,
    updateEntity,
    updateEntityKPI,
    deleteEntity,
}

export default entitiesAPI;