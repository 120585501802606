import { Autocomplete, Checkbox, FormControl, FormControlLabel, FormLabel, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectEntities } from 'redux/entitySlice';
import { GroupHeader, GroupItems, LoadingData } from 'components';
import { selectDashboardConfiguration } from 'redux/configurationSlice';
import { selectTopics } from 'redux/topicSlice';
import { selectMeasureEntities } from 'redux/entitySlice';
import { getLabelId } from 'utils';
import API from 'api';
import { userSelector } from 'redux/userSlice';

const SimpleCardConstructor = ({ dataType, setSelectedAttributes, allMetrics, allGoals, allNotes, allTargets, allComplexMetrics, allAlarms, allAlarmProfiles, allStakeholders, existingWidget }) => {
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [selectedMeasureEntity, setSelectedMeasureEntity] = useState(null);
    const [selectedMetric, setSelectedMetric] = useState(null);
    const [selectedGoal, setSelectedGoal] = useState(null);
    const [selectedNote, setSelectedNote] = useState(null);
    const [selectedTarget, setSelectedTarget] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [selectedComplexMetric, setSelectedComplexMetric] = useState(null);
    const [selectedAlarm, setSelectedAlarm] = useState(null);
    const [selectedAlarmProfile, setSelectedAlarmProfile] = useState(null);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [allActivities, setAllActivities] = useState([]);
    const [selectedStakeholder, setSelectedStakeholder] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const { token } = useSelector(userSelector);
    const intl = useIntl();
    const dashboardConfiguration = useSelector(selectDashboardConfiguration);
    const dataTypeConfigArray = dashboardConfiguration['simple-card'].source.find(item => dataType in item) && dashboardConfiguration['simple-card'].source.find(item => dataType in item)[dataType];
    const entities = useSelector(selectEntities);
    const measureEntities = useSelector(selectMeasureEntities);
    const topicsList = useSelector(selectTopics);

    useEffect(() => {
        if (selectedEntity && dataType === "entityActivity") {
            API.activity.getEntityActivities(token, selectedEntity?._id).then(items => {
                if (items.data) {
                    setAllActivities(items.data);
                }
            }).catch(error => {
                console.error(error);
            })
        }
    }, [selectedEntity, token, dataType])

    useEffect(() => {
        if (existingWidget && dataType === "entityActivity" && !!allActivities.length) {
            const existingId = existingWidget.config.source[dataType]?.id;
            setSelectedActivity(allActivities.find(el => el._id === existingId) || null);
        }
    }, [existingWidget, dataType, allActivities])

    useEffect(() => {
        const checkLoading = () => {
            if (dataType === "entity" || dataType === "measureEntity" || dataType === "topic" || dataType === "entityActivity") return true;
            if ((dataType === "metric") && allMetrics.length) return true;
            if (dataType === "goal" && allGoals.length) return true;
            if (dataType === "alarmProfile" && allAlarmProfiles.length) return true;
            if (dataType === "note" && allNotes.length) return true;
            if (dataType === "target" && allTargets.length) return true;
            if (dataType === "complexMetric" && allComplexMetrics.length) return true;
            if (dataType === "metricReport" && allMetrics.length) return true;
            if (dataType === "alarm" && allAlarms.length) return true;
            if (dataType === "stakeholder" && allStakeholders.length) return true;
            return false;
        }
        if (!existingWidget) setLoaded(true);
        else if (checkLoading()) {
            setSelectedProperties(existingWidget.config.source[dataType]?.properties || []);
            const existingId = existingWidget.config.source[dataType]?.id;
            switch (dataType) {
                case "entity": setSelectedEntity(entities[existingId] || null); break;
                case "measureEntity": setSelectedMeasureEntity(measureEntities[existingId] || null); break;
                case "topic": setSelectedTopic(topicsList.find(el => el._id === existingId) || null); break;
                case "metricReport":
                case "metric": setSelectedMetric(allMetrics.find(el => el._id === existingId) || null); break;
                case "goal": setSelectedGoal(allGoals.find(el => el._id === existingId) || null); break;
                case "note": setSelectedNote(allNotes.find(el => el._id === existingId) || null); break;
                case "target": setSelectedTarget(allTargets.find(el => el._id === existingId) || null); break;
                case "complexMetric": setSelectedComplexMetric(allComplexMetrics.find(el => el._id === existingId) || null); break;
                case "alarm": setSelectedAlarm(allAlarms.find(el => el._id === existingId) || null); break;
                case "alarmProfile": setSelectedAlarmProfile(allAlarmProfiles.find(el => el._id === existingId) || null); break;
                case "entityActivity": setSelectedEntity(entities[existingWidget.config.source[dataType]?.entityId || null]); break;
                case "stakeholder": setSelectedStakeholder(allStakeholders.find(el => el._id === existingId) || null); break;
                default: break;
            }
            setLoaded(true);
        }
        else setLoaded(false);
    }, [allMetrics, allGoals, allAlarmProfiles, allNotes, allTargets, allComplexMetrics, allAlarms, dataType, existingWidget, entities, measureEntities, topicsList, allStakeholders])

    useEffect(() => {
        let id = {};
        switch (dataType) {
            case "entity":
                id = selectedEntity?._id || {};
                break;
            case "metricReport":
            case "metric":
                id = selectedMetric?._id || {};
                break;
            case "goal":
                id = selectedGoal?._id || {};
                break;
            case "note":
                id = selectedNote?._id || {};
                break;
            case "target":
                id = selectedTarget?._id || {};
                break;
            case "topic":
                id = selectedTopic?._id || {};
                break;
            case "measureEntity":
                id = selectedMeasureEntity?.entityId || {};
                break;
            case "complexMetric":
                id = selectedComplexMetric?._id || {};
                break;
            case "alarm":
                id = selectedAlarm?._id || {};
                break;
            case "alarmProfile":
                id = selectedAlarmProfile?._id || {};
                break;
            case "entityActivity":
                id = selectedActivity?._id || {};
                break;
            case "stakeholder":
                id = selectedStakeholder?._id || {};
                break;
            default:
                break;
        }
        setSelectedAttributes({ properties: selectedProperties, id: id, ...(dataType === "entityActivity" && { entityId: selectedEntity?._id }) });
    }, [selectedProperties, dataType, selectedEntity, selectedMetric, selectedGoal, selectedNote,
        selectedTopic, selectedTarget, selectedMeasureEntity, selectedComplexMetric,
        setSelectedAttributes, selectedAlarm, selectedAlarmProfile, selectedActivity, selectedStakeholder])

    const handlePropertiesChange = (e, element) => {
        if (e.target.checked) {
            setSelectedProperties([...selectedProperties, element]);
        } else {
            setSelectedProperties(selectedProperties.filter((item) => item !== element));
        }
    }

    const handleSelectAll = () => {
        if (selectedProperties.length < dataTypeConfigArray.length) {
            setSelectedProperties(dataTypeConfigArray.map(el => el))
        }
        else {
            setSelectedProperties([]);
        }
    }

    return (loaded
        ? <Stack direction="column" width={"100%"} spacing={2}>
            {(dataType === "entity" || dataType === "entityActivity") && <FormControl>
                <FormLabel required><FormattedMessage id="ENTITIES" /></FormLabel>
                <Autocomplete
                    id="select-entities"
                    options={Object.values(entities)}
                    getOptionLabel={(option) => option.name}
                    value={selectedEntity}
                    onChange={(e, newValue) => { setSelectedActivity(null); setSelectedEntity(newValue) }}
                    renderInput={(params) => <TextField {...params} />}
                    size="small"
                    fullWidth
                />
            </FormControl>}

            {dataType === "measureEntity" && <FormControl>
                <FormLabel required><FormattedMessage id="ENTITY" /></FormLabel>
                <Autocomplete
                    id="select-measure-entities"
                    options={Object.values(measureEntities)}
                    getOptionLabel={(option) => entities[option.entityId].name}
                    value={selectedMeasureEntity}
                    onChange={(e, newValue) => setSelectedMeasureEntity(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    size="small"
                    fullWidth
                />
            </FormControl>}

            {(dataType === "metric" || dataType === "metricReport") && <FormControl>
                <FormLabel required><FormattedMessage id="ESG.METRICS" /></FormLabel>
                <Autocomplete
                    id="select-metric-type"
                    options={allMetrics}
                    getOptionLabel={(metric) => `${metric.name} (${entities[metric.entityId]?.name})`}
                    value={selectedMetric}
                    onChange={(e, newValue) => setSelectedMetric(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    size="small"
                    fullWidth
                    groupBy={(option) => option.entityId}
                    renderGroup={(params) => (
                        <li key={params.group}>
                            <GroupHeader>{entities[params.group].name}</GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                        </li>
                    )}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                />
            </FormControl>}

            {dataType === "goal" && <FormControl>
                <FormLabel required><FormattedMessage id="ESG.GOAL" /></FormLabel>
                <Autocomplete
                    id="select-goal"
                    options={allGoals}
                    getOptionLabel={(goal) => goal.name}
                    value={selectedGoal}
                    onChange={(e, goal) => setSelectedGoal(goal)}
                    renderInput={(params) => (
                        <TextField {...params} />
                    )}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    size="small"
                    fullWidth
                />
            </FormControl>}

            {dataType === "note" && <FormControl>
                <FormLabel required><FormattedMessage id="NOTE" /></FormLabel>
                <Autocomplete
                    id="select-note"
                    options={allNotes}
                    getOptionLabel={(note) => note.title}
                    value={selectedNote}
                    onChange={(e, note) => setSelectedNote(note)}
                    renderInput={(params) => (
                        <TextField {...params} />
                    )}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    size="small"
                    fullWidth
                />
            </FormControl>}

            {dataType === "target" && < FormControl >
                <FormLabel required><FormattedMessage id="ESG.TARGETS" /></FormLabel>
                <Autocomplete
                    id="select-target"
                    options={allTargets}
                    getOptionLabel={(option) => `${option.name} (${allMetrics.find(met => met._id === option.metricId)?.name})`}
                    value={selectedTarget}
                    onChange={(e, target) => setSelectedTarget(target)}
                    renderInput={(params) => <TextField {...params} />}
                    size="small"
                    fullWidth
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                />
            </FormControl>}

            {dataType === "topic" && < FormControl >
                <FormLabel required><FormattedMessage id="ESG.TOPICS" /></FormLabel>
                <Autocomplete
                    id="select-topic"
                    options={[...topicsList].sort((opt1, opt2) => opt1.entityId.localeCompare(opt2.entityId))}
                    getOptionLabel={(option) => `${option.name} (${entities[option.entityId].name})`}
                    value={selectedTopic}
                    onChange={(e, target) => setSelectedTopic(target)}
                    renderInput={(params) => <TextField {...params} />}
                    size="small"
                    fullWidth
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    groupBy={(option) => option.entityId}
                    renderGroup={(params) => (
                        <li key={params.group}>
                            <GroupHeader>{entities[params.group].name}</GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                        </li>
                    )}
                />
            </FormControl>}

            {dataType === "complexMetric" && <FormControl>
                <FormLabel required><FormattedMessage id="ESG.METRICS.COMPLEX" /></FormLabel>
                <Autocomplete
                    id="select-metric-type"
                    options={allComplexMetrics}
                    getOptionLabel={(metric) => `${metric.name} (${entities[metric.entityId]?.name})`}
                    value={selectedComplexMetric}
                    onChange={(e, newValue) => setSelectedComplexMetric(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    size="small"
                    fullWidth
                    groupBy={(option) => option.entityId}
                    renderGroup={(params) => (
                        <li key={params.group}>
                            <GroupHeader>{entities[params.group].name}</GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                        </li>
                    )}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                />
            </FormControl>}

            {dataType === "alarm" && <FormControl>
                <FormLabel required><FormattedMessage id="DASHBOARD.OPTIONS.alarm" /></FormLabel>
                <Autocomplete
                    id="select-alarm"
                    options={allAlarms}
                    getOptionLabel={(option) => `${option.name} (${entities[option.entityId]?.name})`}
                    value={selectedAlarm}
                    onChange={(e, alarm) => setSelectedAlarm(alarm)}
                    renderInput={(params) => (
                        <TextField {...params} />
                    )}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    size="small"
                    fullWidth
                />
            </FormControl>}

            {dataType === "alarmProfile" && <FormControl>
                <FormLabel required><FormattedMessage id="DASHBOARD.OPTIONS.alarmProfile" /></FormLabel>
                <Autocomplete
                    id="select-alarmProfile"
                    options={allAlarmProfiles}
                    getOptionLabel={(option) => `${option.name} (${entities[option.entityId]?.name})`}
                    value={selectedAlarmProfile}
                    onChange={(e, alarmProfile) => setSelectedAlarmProfile(alarmProfile)}
                    renderInput={(params) => (
                        <TextField {...params} />
                    )}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    size="small"
                    fullWidth
                />
            </FormControl>}

            {dataType === "entityActivity" && selectedEntity && <FormControl>
                <FormLabel required><FormattedMessage id="DASHBOARD.OPTIONS.entityActivity" /></FormLabel>
                <Autocomplete
                    id="select-activity"
                    options={allActivities}
                    getOptionLabel={(option) => intl.formatMessage({ id: option.activityId?.name })}
                    value={selectedActivity}
                    onChange={(e, activity) => setSelectedActivity(activity)}
                    renderInput={(params) => (
                        <TextField {...params} />
                    )}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    size="small"
                    fullWidth
                />
            </FormControl>}

            {dataType === "stakeholder" && <FormControl>
                <FormLabel required><FormattedMessage id="STAKEHOLDERS" /></FormLabel>
                <Autocomplete
                    id="select-stakeholder"
                    options={allStakeholders}
                    getOptionLabel={(option) => option.name}
                    value={selectedStakeholder}
                    onChange={(e, stakeholder) => setSelectedStakeholder(stakeholder)}
                    renderInput={(params) => (
                        <TextField {...params} />
                    )}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    size="small"
                    fullWidth
                />
            </FormControl>}

            {dataType && ((dataType === "entity" && selectedEntity) || selectedMeasureEntity || selectedMetric ||
                selectedGoal || selectedNote || selectedTarget || selectedTopic || selectedComplexMetric
                || selectedAlarm || selectedAlarmProfile || selectedStakeholder || (dataType === "entityActivity" && selectedActivity)) && <FormControl required>
                    <FormLabel ><FormattedMessage id="DASHBOARD.SELECT_PROPERTIES" /></FormLabel>
                    <FormControlLabel
                        control={<Checkbox
                            checked={selectedProperties.length === dataTypeConfigArray.length}
                            onChange={(e) => handleSelectAll()} />}
                        label={<FormattedMessage id="DASHBOARD.SELECT_ALL" />} />
                    {dataTypeConfigArray.filter(el => dataType === "entityActivity" ? el.substr(0, 10) === "objectives" ? !!selectedActivity.objectives[el.substr(11)] : true : true).map((element, index) => (
                        <FormControlLabel sx={{ ml: 1 }} key={index}
                            control={<Checkbox
                                checked={selectedProperties.includes(element)}
                                onChange={(e) => handlePropertiesChange(e, element)} />}
                            label={intl.formatMessage({ id: getLabelId(element) })} />
                    ))}
                </FormControl>}

        </Stack>
        : <LoadingData />)
}

export default SimpleCardConstructor;