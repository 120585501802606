import { CloseOutlined } from "@mui/icons-material";
import { CardHeader, Dialog, DialogActions, DialogContent, Divider, IconButton, Stack, useMediaQuery } from "@mui/material";
import { DisplayBox, OWAttribution } from "components";
import { FormattedMessage } from "react-intl";


export default function MeasurementTypeDetails({ data, openDialog, onClose }) {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    return <Dialog open={openDialog} onClose={onClose} maxWidth="sm" fullWidth fullScreen={smallScreen}>
        {data && <>
            <CardHeader
                title={<FormattedMessage id="MEASUREMENT_TYPE_DETAILS" />}
                action={<IconButton onClick={onClose}><CloseOutlined /></IconButton>}
            />
            <Divider />
            <DialogContent>
                <Stack direction="column" gap={2}>
                    <DisplayBox label={<FormattedMessage id="NAME" />} value={data.name || <FormattedMessage id={data.key} />} />
                    <DisplayBox label={<FormattedMessage id="DESCRIPTION" />} value={data.description} />
                    <DisplayBox label={<FormattedMessage id="VALUE_TYPE" />} value={<FormattedMessage id={"DATA_TYPE." + data.dataType.type.toUpperCase()} />} />
                    <DisplayBox label={<FormattedMessage id="MEASURING_SIZE" />} value={data.dataType.measure && <FormattedMessage id={data.dataType.measure} />} />
                </Stack>
            </DialogContent>
            {data.serviceType?.OWM && <DialogActions><OWAttribution linkOnly /></DialogActions>}
        </>}
    </Dialog>

}