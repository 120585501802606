import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, InfoOutlined, QuestionMarkOutlined } from "@mui/icons-material";
import { Button, CardActions, CardHeader, Chip, Dialog, DialogContent, Divider, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import { DisplayBox, ActivityDetails, AlertSnackbar, ConfirmationDialog } from "components";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { selectEntities } from "redux/entitySlice";
import { permissionSelector, userSelector } from "redux/userSlice";
import { useParams } from "react-router";
import API from "api";

export const KpiDisplay = (props) => {
    const getValueText = () => {
        if (!props.secondaryValue || !props.value) return 0;
        else if (props.secondaryValue > 0 && props.value >= 0) return parseFloat(props.value * (props.secondaryValue / 100)).toFixed(2);
        else if (props.value >= 0) return props.value;
        else return "N/A";
    }
    return <CardHeader sx={{ p: 0, my: 2 }} title={props.title} subheader={props.value > 0 && <span><FormattedMessage id="SUBSTANTIAL_CON" />: {props.secondaryValue === null ? 0 : props.secondaryValue}%</span>} action={
        <Stack direction="column" alignItems='flex-end'>
            <Typography component="span" variant="h4" color={props.value !== null && !isNaN(props.value) ? "primary" : "text.disabled"}>{getValueText()} <Typography component="span" variant="h6" color="text.disabled">€</Typography></Typography>
            {Boolean(props.value) && (props.secondaryValue >= 0) && <Typography component="span" variant="body1" color="text.disabled"><FormattedMessage id="TOTAL" />: {props.value} €</Typography>}
        </Stack>
    } />
}
export const AssessmentChip = ({ assessment, outlined, small, sx }) => {
    let color = undefined;
    let icon = undefined
    let label = "";

    switch (assessment) {
        case 'Y':
            color = "success";
            icon = <CheckOutlined />;
            label = "ALIGNED";
            break;
        case 'N':
            color = "error";
            icon = <CloseOutlined />;
            label = "NOT_ALIGNED";
            break;
        case 'NL':
        default:
            color = "default";
            icon = <QuestionMarkOutlined />;
            label = "NEL";
            break;
    }


    return <Chip sx={sx} color={color} icon={icon} variant={outlined ? "outlined" : "filled"} size={small ? "small" : "medium"} label={<FormattedMessage id={label} />} />
}

export default function EntityActivityDetails({ open, onClose, activity, setEdit, setReset, AlertCall }) {
    const { token } = useSelector(userSelector);
    const queryId = useParams().entityId;
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const entities = useSelector(selectEntities);
    const [openDetails, setOpenDetails] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const [openConfirm, setOpenConfirm] = useState(false);


    const updateKPIPermission = useSelector((state) => permissionSelector(state, 'update-activity'));
    const deleteActivityPermission = useSelector((state) => permissionSelector(state, 'delete-activity'));

    const unassignActivity = () => {

        API.activity.deleteEntityActivity(token, activity._id).then(res => {
            onClose();
            AlertCall({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
            setReset();
        }).catch(error => {
            console.error(error);
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_UPDATED", severity: "error" });
        })
        setOpenConfirm(false);
    }

    if (!activity) return null;

    return <>
        <ConfirmationDialog
            maxWidth="sm" fullWidth
            open={openConfirm}
            content={<Stack gap={1}>
                <DisplayBox label={<FormattedMessage id="ACTIVITY" />} value={activity?.nameKey} />
                <DisplayBox label={<FormattedMessage id="ENTITY" />} value={entities[queryId]?.name} />
            </Stack>}
            title={<span><FormattedMessage id="UNASSIGN" />?</span>}
            customButtonTitle={<FormattedMessage id="YES" />}
            customButtonColor="error"
            handleCancel={() => setOpenConfirm(false)}
            handleCustomButton={() => unassignActivity()}
        />
        <Dialog open={open} onClose={onClose} fullScreen={smallScreen} maxWidth="md" fullWidth>
            <CardHeader
                title={<span>
                    {activity.nameKey}
                    <Chip sx={{ ml: 1 }} size="small" color={activity.aligned ? "success" : "error"} label={<FormattedMessage id={activity.aligned ? "ALIGNED" : "NOT_ALIGNED"} icon={activity.aligned ? <CheckOutlined /> : <CloseOutlined />} />} />
                </span>}
                subheader={<span>
                    <FormattedMessage id="ACTIVITY_ENTITY_DETAILS" />
                    {entities[activity.entityId] ? ` (${(entities[activity.entityId].name)})` : null}
                </span>}
                action={[
                    updateKPIPermission ? <IconButton sx={{ ml: 1 }} key="edit" onClick={() => setEdit(activity)} color="secondary"><EditOutlined /></IconButton> : undefined,
                    <IconButton sx={{ ml: 1 }} key="view" onClick={() => setOpenDetails(true)} color="info"><InfoOutlined /></IconButton>,
                    <IconButton sx={{ ml: 1 }} key="close" onClick={onClose}><CloseOutlined /></IconButton>
                ]}
            />
            <Divider />
            <DialogContent sx={{ pt: 0 }}>
                <KpiDisplay title={<FormattedMessage id="turnover" />} value={activity?.KPI?.turnover} secondaryValue={activity?.KPIAlignedPercentage?.turnover} />
                <Divider />
                <KpiDisplay title={<FormattedMessage id="capEx" />} value={activity?.KPI?.capEx} secondaryValue={activity?.KPIAlignedPercentage?.capEx} />
                <Divider />
                <KpiDisplay title={<FormattedMessage id="opEx" />} value={activity?.KPI?.opEx} secondaryValue={activity?.KPIAlignedPercentage?.opEx} />
                <Divider />
                <Stack sx={{ mt: 2 }} gap={1}>
                    {Object.keys(activity.objectives).map((obj, index, { length }) => <Stack gap={1} key={obj}>
                        <Stack direction="column" alignItems="flex-start">
                            <Typography component="span" variant="h6"><FormattedMessage id={obj} /></Typography>
                            <AssessmentChip assessment={activity.objectives[obj]?.assessment} />
                        </Stack>
                        {activity.objectives[obj]?.dnsh && Object.keys(activity.objectives[obj].dnsh).map(dnsh => <Stack pl={1} key={dnsh} gap={1}>
                            <DisplayBox labelColor="text.secondary" labelSize="large" label={<Stack direction="column"><FormattedMessage id={dnsh} /><Typography component="span" variant="caption" color="text.disabled"><FormattedMessage id="DNSH_CRITERIA" /></Typography></Stack>} value={<AssessmentChip sx={{ m: 1 }} outlined assessment={activity.objectives[obj]?.dnsh[dnsh]} />} />
                        </Stack>)}
                        {index < (length - 1) && <Divider flexItem />}
                    </Stack>)}
                </Stack>
            </DialogContent>
            <Divider />
            {deleteActivityPermission && <CardActions>
                <Button color="error" size="small" startIcon={<DeleteOutlined />} onClick={() => setOpenConfirm(true)}><FormattedMessage id="UNASSIGN" /></Button>
            </CardActions>}
        </Dialog>
        <AlertSnackbar open={alert.open} onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} messageId={alert.messageId} />
        {activity && <ActivityDetails noAction open={openDetails} onClose={() => setOpenDetails(false)} activity={{ ...activity, ...(activity && activity.activityId) }} />}
    </>
}