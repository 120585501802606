import { CheckBoxOutlineBlank, CheckBoxOutlined, GroupsOutlined, PersonAddOutlined } from "@mui/icons-material";
import { Autocomplete, Button, Checkbox, Chip, FormControl, FormLabel, IconButton, InputAdornment, TextField, useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux";
import { userSelector, permissionSelector } from "redux/userSlice";
import { selectUsersAndGroups, selectUsersAndGroupsStatus, fetchUsersAndGroups } from "redux/usersAndGroupsSlice";
import { GroupHeader, GroupItems } from "components";
import { store } from 'redux/store';

export default function AssignOwnerField({ owner, onChange, multiple, participants, contacts }) {
    const intl = useIntl();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const { username, token } = useSelector(userSelector);
    const [value, setValue] = useState(owner !== "" ? owner : null);
    const [allUsers, setAllUsers] = useState([]);
    const [allGroups, setAllGroups] = useState([]);
    const usersAndGroups = useSelector(selectUsersAndGroups);
    const status = useSelector(selectUsersAndGroupsStatus);
    const viewUsersPermission = useSelector((state) => permissionSelector(state, 'view-users'));

    useEffect(() => {
        if (!viewUsersPermission) {
            setAllUsers([]);
            setAllGroups([]);
            return;
        }
        else if (status === 'failed') {
            store.dispatch(fetchUsersAndGroups(token));
        }
        else if (status === 'complete') {
            setAllUsers(usersAndGroups.users);
            setAllGroups(usersAndGroups.groups);
        } else {
            setAllUsers([]);
            setAllGroups([]);
        }
    }, [token, usersAndGroups, status, viewUsersPermission]);

    useEffect(() => {
        if (owner === "" || owner === " ") setValue(null);
        else setValue(owner)
    }, [owner])

    const onChangeHandler = (data) => {
        onChange(data);
        setValue(data);
    }

    const assignProps = {
        size: "small",
        color: "primary",
        disabled: value === username,
        onClick: () => onChangeHandler(username),
    }

    const getOptionLabel = (option) => {
        if (option.username) return option.username;
        else if (option.name) return option.name;
        else {
            if (option.type === 'user') return allUsers.find(el => el.id === option.id)?.username;
            else if (option.type === 'group') return allGroups.find(el => el.id === option.id)?.name;
            else return "";
        }
    }

    const options = !multiple ? allUsers.map(user => user.username) : [...allUsers.map(el => ({ ...el, type: 'user' })), ...allGroups.map(el => ({ ...el, type: 'group' }))]

    return !multiple
        ? <FormControl>
            <FormLabel error={status === 'disabled'}><FormattedMessage id="OWNER" /></FormLabel>
            <Autocomplete
                id="select-owner"
                options={options}
                noOptionsText={status === 'disabled' ? intl.formatMessage({ id: 'ERROR.FETCHING_USERS_DATA' }) : undefined}
                getOptionLabel={(option) => option}
                value={status === 'disabled' ? "" : value}
                onChange={(e, newValue) => onChangeHandler(newValue)}
                renderInput={(params) => <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        placeholder: status === 'disabled' ? intl.formatMessage({ id: 'ERROR.FETCHING_DATA' }) : intl.formatMessage({ id: "OWNER_SELECT" }),
                        style: { paddingRight: 10 },
                        error: status === 'disabled',
                        endAdornment: status === 'complete' && (
                            <InputAdornment position="end">
                                {smallScreen ? (
                                    <IconButton {...assignProps} edge="end">
                                        <PersonAddOutlined />
                                    </IconButton>
                                ) : (
                                    <Button {...assignProps}>
                                        <FormattedMessage id="ASSIGN.CURRENT_USER" />
                                    </Button>
                                )}
                            </InputAdornment>
                        ),
                    }}

                />}
                size="small"
                fullWidth
            />
        </FormControl>
        : <FormControl>
            <FormLabel error={status === 'disabled'}><FormattedMessage id={contacts ? "NOTE.CONTACTS" : "PARTICIPANTS"} /></FormLabel>
            <Autocomplete
                id="participants"
                multiple
                options={options}
                noOptionsText={status === 'disabled' ? intl.formatMessage({ id: 'ERROR.FETCHING_USERS_DATA' }) : undefined}
                getOptionLabel={getOptionLabel}
                value={status === 'disabled' ? [] : participants}
                onChange={(e, newArray) => onChange(newArray.map(option => ({ id: option.id, type: option.type })))}
                renderInput={(params) => <TextField {...params} placeholder={status === 'disabled' ? intl.formatMessage({ id: 'ERROR.FETCHING_DATA' }) : intl.formatMessage({ id: "SELECT_USERS_AND_GROUPS" })} error={status === 'disabled'} />}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckBoxOutlined fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.username ? option.username : option.name}
                    </li>
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                groupBy={(option) => option.type}
                renderGroup={(params) => (
                    <li key={params.group}>
                        <GroupHeader><FormattedMessage id={"GROUP." + params.group.toUpperCase()} /></GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                    </li>
                )}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <Chip
                            icon={option.type === "group" ? <GroupsOutlined /> : null}
                            label={getOptionLabel(option)}
                            {...getTagProps({ index })}
                            size="small"
                        />
                    ))
                }
                size="small"
                fullWidth
            />
        </FormControl>
}