import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Checkbox, Divider, FormControl, FormControlLabel, FormLabel, InputAdornment, ListItem, ListItemIcon, ListItemText, Stack, Step, StepLabel, Stepper, TextField, Typography, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { selectDashboardConfiguration } from 'redux/configurationSlice';
import { userSelector } from 'redux/userSlice';
import API from 'api';
import LineChartConstructor from './LineChartConstructor';
import PieChartConstructor from './PieChartConstructor';
import BarChartConstructor from './BarChartConstructor';
import SimpleCardConstructor from './SimpleCardConstructor';
import TableConstructor from './TableConstructor';
import DateInputFields from './DateInputFields';
import { ArticleOutlined, CheckBoxOutlineBlank, CheckBoxOutlined, EqualizerOutlined, PieChartOutlined, TableChartOutlined, TimelineOutlined } from '@mui/icons-material';
import { DisplayBox } from 'components';
import WidgetSizeSelector from './WidgetSizeSelector';

const DashboardFormContent = ({ open, itemType, setItemType, title, setTitle, showTitle, setShowTitle, size, setSize, selectedEntities, setSelectedEntities, dateFrom, setDateFrom, dateTo, setDateTo, collectorType, setCollectorType, dataType, setDataType, selectedAttributes, setSelectedAttributes, selectedTarget, setSelectedTarget, setTableParams, activeStep, showDateFields, steps, existingWidget }) => {
    const intl = useIntl();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const mediumScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const [allMetrics, setAllMetrics] = useState([]);
    const [allTargets, setAllTargets] = useState([]);
    const [allGoals, setAllGoals] = useState([]);
    const [allNotes, setAllNotes] = useState([]);
    const [allComplexMetrics, setAllComplexMetrics] = useState([]);
    const [allAlarms, setAllAlarms] = useState([]);
    const [allAlarmProfiles, setAllAlarmProfiles] = useState([]);
    const [allStakeholders, setAllStakeholders] = useState([]);
    const { token } = useSelector(userSelector);
    const [dataTypeOptions, setDataTypeOptions] = useState([]);
    const dashboardConfiguration = useSelector(selectDashboardConfiguration);
    const itemOptions = Object.keys(dashboardConfiguration);
    const [innerChangeItemType, setInnerChangeItemType] = useState(0);

    const itemOptionIcons = {
        "chart-line": <TimelineOutlined />,
        "chart-pie": <PieChartOutlined />,
        "chart-bar": <EqualizerOutlined />,
        "simple-card": <ArticleOutlined />,
        "table": <TableChartOutlined />,
    }

    const checkExistance = useCallback((attribute) => {
        const source = dashboardConfiguration[itemType]?.source;
        if (source) {
            for (let item of source) {
                if (item[dataType] && (item[dataType].field?.includes(attribute) || (itemType !== 'table' && item[dataType].includes(attribute)))) {
                    return true;
                }
            }
        }
        return false;
    }, [dashboardConfiguration, dataType, itemType]);


    useEffect(() => {
        if (!itemType) setDataTypeOptions([]);
        else setDataTypeOptions(dashboardConfiguration[itemType].source.map(obj => Object.keys(obj)[0]));
        if (innerChangeItemType) setDataType(null);
    }, [itemType, dashboardConfiguration, setDataType, innerChangeItemType]);


    useEffect(() => {
        if (!open) return;

        if (checkExistance("target") || (itemType === "simple-card" && dataType === "target")) {
            API.target.getAllTargets(token).then(items => {
                if (items.data) {
                    setAllTargets(items.data);
                }
            }).catch(error => {
                console.error(error);
            })
        }
        if (checkExistance("metric") || checkExistance("target") || (itemType === "simple-card" && (dataType === "metric" || dataType === "target" || dataType === "metricReport")) || (itemType === "chart-bar" && (dataType === "metric" || dataType === "target"))) {
            API.metrics.getAllMetrics(token).then(items => {
                if (items.data) {
                    setAllMetrics(items.data.sort((opt1, opt2) => opt1.entityId.localeCompare(opt2.entityId)));
                }
            }).catch(error => {
                console.error(error);
            })
        }
        if (checkExistance("goal") || (itemType === "simple-card" && dataType === "goal") || (itemType === "chart-bar" && dataType === "target")) {
            API.goal.getAllGoals(token).then(items => {
                if (items.data) {
                    setAllGoals(items.data);
                }
            }).catch(error => {
                console.error(error);
            })
        }
        if (itemType === "simple-card" && dataType === "note") {
            API.notes.getAllNotes(token).then(items => {
                if (items.data) {
                    setAllNotes(items.data);
                }
            }).catch(error => {
                console.error(error);
            })
        }
        if (itemType === "simple-card" && dataType === "complexMetric") {
            API.metrics.getAllComplexMetrics(token).then(items => {
                if (items.data) {
                    setAllComplexMetrics(items.data);
                }
            }).catch(error => {
                console.error(error);
            })
        }
        if (itemType === "simple-card" && dataType === "alarm") {
            API.alarm.getAllAlarms(token, 0, Date.now()).then(items => {
                if (items.data) {
                    setAllAlarms(items.data);
                }
            }).catch(error => {
                console.error(error);
            })
        }

        if (((itemType === "chart-bar" && dataType === "alarm") || (itemType === "simple-card" && dataType === "alarmProfile"))) {
            API.alarmProfile.getAllAlarmProfiles(token).then(items => {
                if (items.data) {
                    setAllAlarmProfiles(items.data);
                }
            }).catch(error => {
                console.error(error);
            })
        }
        if (itemType === "simple-card" && dataType === "stakeholder") {
            API.stakeholder.getAll(token).then(items => {
                if (items.data) {
                    setAllStakeholders(items.data);
                }
            }).catch(error => {
                console.error(error);
            })
        }
    }, [open, token, checkExistance, itemType, dataType]);

    return !existingWidget ? <Stack direction="column" spacing={1}>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3 }}>
            {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>
        {activeStep === 0 && <>
            <FormControl>
                <FormLabel required><FormattedMessage id="DASHBOARD.WIDGET_TITLE" /></FormLabel>
                <TextField
                    required
                    fullWidth
                    variant="outlined"
                    id="DASHBOARD.WIDGET_TITLE"
                    placeholder={intl.formatMessage({ id: 'ENTER_NAME' })}
                    type="text"
                    size="small"
                    value={title}
                    inputProps={{ maxLength: process.env.REACT_APP_ESG_INPUT_RESTRICTION_SMALL }}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </FormControl>
            <FormControlLabel control={<Checkbox
                icon={<CheckBoxOutlineBlank fontSize="medium" />}
                checkedIcon={<CheckBoxOutlined fontSize="medium" />}
                checked={showTitle}
                onChange={() => setShowTitle(!showTitle)}
            />}
                label={intl.formatMessage({ id: "SHOW_TITLE" })} />
        </>}

        {activeStep === 1 && <Stack direction="column" spacing={2}>
            <Stack direction={smallScreen ? "column" : "row"} spacing={1}>
                <Stack direction="column" width={smallScreen ? "100%" : "50%"}>
                    <FormLabel required><FormattedMessage id="DASHBOARD.SELECT_ITEM_TYPE" /></FormLabel>
                    <Autocomplete
                        id="select-item-type"
                        options={itemOptions}
                        getOptionLabel={(option) => intl.formatMessage({ id: "DASHBOARD.OPTIONS." + option })}
                        renderOption={(props, option) => <ListItem {...props} key={option}>
                            <ListItemIcon>
                                {itemOptionIcons[option]}
                            </ListItemIcon>
                            <ListItemText primary={intl.formatMessage({ id: "DASHBOARD.OPTIONS." + option })} />
                        </ListItem>}
                        value={itemType}
                        onChange={(e, value) => { setInnerChangeItemType(innerChangeItemType + 1); setItemType(value) }}
                        renderInput={(params) => <TextField {...params} InputProps={{ ...params.InputProps, ...(itemType !== null && { startAdornment: <InputAdornment sx={{ ml: 1 }} position='start'>{itemOptionIcons[itemType]}</InputAdornment> }) }} />}
                        size="small"
                        fullWidth
                    />
                </Stack>
                <Stack direction="column" width={smallScreen ? "100%" : "50%"}>
                    <FormLabel required><FormattedMessage id="DATA" /></FormLabel>
                    <Autocomplete
                        disabled={!itemType}
                        id="select-data-type"
                        options={dataTypeOptions}
                        getOptionLabel={(option) => intl.formatMessage({ id: itemType === "chart-bar" ? "DASHBOARD.OPTIONS.COUNT." + option : "DASHBOARD.OPTIONS." + option })}
                        value={dataType}
                        onChange={(e, value) => setDataType(value)}
                        renderInput={(params) => <TextField {...params} placeholder={!itemType ? intl.formatMessage({ id: 'DASHBOARD.OPTIONS.SELECT_WIDGET_TYPE' }) : ''} />}
                        size="small"
                        fullWidth
                    />
                </Stack>
            </Stack>
            {!mediumScreen && <Stack direction="column" spacing={1}>
                <FormLabel><FormattedMessage id="DASHBOARD.WIDGET_SIZE" /></FormLabel>
                <WidgetSizeSelector value={size} onChange={setSize} />
            </Stack>}
        </Stack>}

        {activeStep === 2 && < Stack direction="column" spacing={1}>
            {itemType === "chart-line" && <LineChartConstructor checkExistance={checkExistance} selectedEntities={selectedEntities}
                setSelectedEntities={setSelectedEntities} selectedAttributes={selectedAttributes} setSelectedAttributes={setSelectedAttributes}
                allMetrics={allMetrics} collectorType={collectorType} setCollectorType={setCollectorType} dataType={dataType} />}
            {itemType === "chart-pie" && <PieChartConstructor selectedEntities={selectedEntities} setSelectedEntities={setSelectedEntities}
                selectedAttributes={selectedAttributes} setSelectedAttributes={setSelectedAttributes} allMetrics={allMetrics} allTargets={allTargets}
                checkExistance={checkExistance} selectedTarget={selectedTarget} setSelectedTarget={setSelectedTarget} />}
            {itemType === "chart-bar" && <BarChartConstructor selectedAttributes={selectedAttributes} setSelectedAttributes={setSelectedAttributes} setEntities={setSelectedEntities}
                allMetrics={allMetrics} allTargets={allTargets} checkExistance={checkExistance} dataType={dataType} allGoals={allGoals} allAlarmProfiles={allAlarmProfiles} />}
            {itemType === "simple-card" && dataType && <SimpleCardConstructor dataType={dataType} setSelectedAttributes={setSelectedAttributes} allMetrics={allMetrics}
                allGoals={allGoals} allNotes={allNotes} allTargets={allTargets} allComplexMetrics={allComplexMetrics} allAlarms={allAlarms} allAlarmProfiles={allAlarmProfiles}
                allStakeholders={allStakeholders} />}
            {itemType === "table" && <TableConstructor checkExistance={checkExistance} selectedEntities={selectedEntities}
                setSelectedEntities={setSelectedEntities} selectedAttributes={selectedAttributes} setSelectedAttributes={setSelectedAttributes}
                allMetrics={allMetrics} collectorType={collectorType} setCollectorType={setCollectorType} dataType={dataType} allGoals={allGoals}
                setTableParams={setTableParams} />}
        </Stack>}

        {showDateFields && activeStep === steps.length - 1 && <DateInputFields dateFrom={dateFrom} setDateFrom={setDateFrom} dateTo={dateTo} setDateTo={setDateTo} />}
    </Stack > :
        <Stack direction="column" spacing={1}>
            <Typography align='center' variant='h6' color="primary"> <FormattedMessage id="DASHBOARD.STEPS.1" /></Typography>
            <FormControl>
                <FormLabel required><FormattedMessage id="DASHBOARD.WIDGET_TITLE" /></FormLabel>
                <TextField
                    required
                    fullWidth
                    variant="outlined"
                    id="DASHBOARD.WIDGET_TITLE"
                    placeholder={intl.formatMessage({ id: 'ENTER_NAME' })}
                    type="text"
                    size="small"
                    value={title}
                    inputProps={{ maxLength: process.env.REACT_APP_ESG_INPUT_RESTRICTION_SMALL }}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </FormControl>
            <FormControlLabel
                control={<Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="medium" />}
                    checkedIcon={<CheckBoxOutlined fontSize="medium" />}
                    checked={showTitle}
                    onChange={() => setShowTitle(!showTitle)}
                />}
                label={intl.formatMessage({ id: "SHOW_TITLE" })}
            />
            <Divider>
                <Typography variant='h6' color="primary"> <FormattedMessage id="DASHBOARD.STEPS.2" /></Typography>
            </Divider>
            <Stack direction="column" spacing={2} >
                {itemType && <DisplayBox label={<FormattedMessage id="DASHBOARD.ITEM_TYPE" />} value={intl.formatMessage({ id: "DASHBOARD.OPTIONS." + itemType })} />}
                {dataType && <DisplayBox label={<FormattedMessage id="DATA_TYPE" />} value={intl.formatMessage({ id: itemType === "chart-bar" ? "DASHBOARD.OPTIONS.COUNT." + dataType : "DASHBOARD.OPTIONS." + dataType })} />}
            </Stack>
            <Divider>
                <Typography variant='h6' color="primary"> <FormattedMessage id="DASHBOARD.STEPS.3" /></Typography>
            </Divider>
            {itemType === "chart-line" && <LineChartConstructor checkExistance={checkExistance} selectedEntities={selectedEntities}
                setSelectedEntities={setSelectedEntities} selectedAttributes={selectedAttributes} setSelectedAttributes={setSelectedAttributes}
                allMetrics={allMetrics} collectorType={collectorType} setCollectorType={setCollectorType} existingWidget={existingWidget} dataType={dataType} />}
            {itemType === "chart-pie" && <PieChartConstructor selectedEntities={selectedEntities} setSelectedEntities={setSelectedEntities}
                selectedAttributes={selectedAttributes} setSelectedAttributes={setSelectedAttributes} allMetrics={allMetrics} allTargets={allTargets}
                checkExistance={checkExistance} selectedTarget={selectedTarget} setSelectedTarget={setSelectedTarget} existingWidget={existingWidget} />}
            {itemType === "chart-bar" && <BarChartConstructor selectedAttributes={selectedAttributes} setSelectedAttributes={setSelectedAttributes} setEntities={setSelectedEntities}
                allMetrics={allMetrics} allTargets={allTargets} checkExistance={checkExistance} dataType={dataType} allGoals={allGoals} allAlarmProfiles={allAlarmProfiles} existingWidget={existingWidget} />}
            {itemType === "simple-card" && dataType && <SimpleCardConstructor dataType={dataType} setSelectedAttributes={setSelectedAttributes} allMetrics={allMetrics}
                allGoals={allGoals} allNotes={allNotes} allTargets={allTargets} allComplexMetrics={allComplexMetrics} allAlarms={allAlarms} allAlarmProfiles={allAlarmProfiles} existingWidget={existingWidget}
                allStakeholders={allStakeholders} />}
            {itemType === "table" && <TableConstructor checkExistance={checkExistance} selectedEntities={selectedEntities}
                setSelectedEntities={setSelectedEntities} selectedAttributes={selectedAttributes} setSelectedAttributes={setSelectedAttributes}
                allMetrics={allMetrics} collectorType={collectorType} setCollectorType={setCollectorType} dataType={dataType} allGoals={allGoals}
                setTableParams={setTableParams} existingWidget={existingWidget} />}
            {showDateFields && <Divider>
                <Typography variant='h6' color="primary"> <FormattedMessage id="DASHBOARD.STEPS.4" /></Typography>
            </Divider>}
            {showDateFields && <DateInputFields dateFrom={dateFrom} setDateFrom={setDateFrom} dateTo={dateTo} setDateTo={setDateTo} />}

        </Stack>

};

export default DashboardFormContent;