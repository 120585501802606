import { CheckBoxOutlineBlank, CheckBoxOutlined, DeleteOutline } from "@mui/icons-material";
import { Autocomplete, Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, Divider, IconButton, Stack, TextField, Tooltip, Typography, useMediaQuery, useTheme, createFilterOptions } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectMeasurementsTypesConf } from "redux/configurationSlice";
import { updateMeasureEntity, deleteMeasureEntity } from 'redux/entitySlice';
import { userSelector, permissionSelector } from "redux/userSlice";
import { AlertSnackbar, OWAttribution, ConfirmationDialog, DialogFormTitle } from 'components';
import { crudeCompareStringArrays } from "utils/helpers";
import API from 'api';


const TypeAutocomplete = ({ titleId, options, selected, onChange, attribution }) => {
    const intl = useIntl();

    return <Box sx={{ mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <Typography variant="caption" color="primary"><FormattedMessage id={titleId} /></Typography>
            {attribution}
        </Box>
        <Autocomplete
            multiple
            size="small"
            id={"checkboxes-tags-" + titleId}
            options={options}
            disableCloseOnSelect
            fullWidth
            value={selected}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            limitTags={10}
            isOptionEqualToValue={(option, value) => value ? option.key === value.key : true}
            renderTags={(tagValue, getTagProps) =>
                tagValue.filter(el => el).map((option, index) => (
                    <Chip
                        size="small"
                        key={option.key}
                        label={option.name || intl.formatMessage({ id: option.key })}
                        {...getTagProps({ index })}
                    />
                ))}
            getOptionLabel={option => option.name || option.key}
            filterOptions={createFilterOptions({ stringify: (opt) => opt.name || intl.formatMessage({ id: opt.key }) })}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBoxOutlined fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name || <FormattedMessage id={option.key} />}
                </li>
            )}
            renderInput={(params) => (
                <TextField {...params} placeholder={!selected.length ? intl.formatMessage({ id: 'NONE_SELECTED' }) : ""}
                    helperText={
                        <Button size="small" disabled={selected.length === options.length} onClick={() => onChange(options)}><FormattedMessage id="CHECK_ALL" /></Button>
                    }
                />
            )}
        />
    </Box>
}


export default function MeasureEntityForm({ measureEntity, entity, open, onClose }) {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const measureTypes = useSelector(selectMeasurementsTypesConf);
    const { token } = useSelector(userSelector);

    const [owmService, setOWMService] = useState([]);
    const [manual, setManual] = useState([]);
    const sensor = measureEntity?.measurementTypes?.sensor;
    const [submitted, setSubmitted] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [alert, setAlert] = useState({ open: false });

    const createMeasureEntityPermission = useSelector((state) => permissionSelector(state, 'create-entity'));

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (measureEntity) {
            setOWMService(measureEntity.measurementTypes.service.OWM.map(el => measureTypes.find(type => type.key === el)));
            setManual(measureEntity.measurementTypes.manual.types.map(el => measureTypes.find(type => type.key === el)))
        }

        return () => { };
    }, [open, measureEntity, measureTypes]);

    const resetForm = () => {
        setOWMService([]);
        setManual([]);
        setSubmitted(false);
    }

    const onSubmit = () => {
        setSubmitted(true);
        const newEntity = {
            entityId: entity._id,
            measurementTypes:
            {
                service: { OWM: owmService.map(el => el.key) },
                manual: { types: manual.map(el => el.key) },
                ...(sensor && Object.keys(sensor).length && { sensor: sensor }),
            }
        };

        if (measureEntity && crudeCompareStringArrays(measureEntity.measurementTypes.service.OWM, owmService.map(el => el.key)) && crudeCompareStringArrays(measureEntity.measurementTypes.manual.types, manual.map(el => el.key))) {
            setAlert({ open: true, messageId: "ERROR.NO_CHANGE_DETECTED", severity: "error" });
            setSubmitted(false);
        }
        else if (measureEntity) API.measureEntity.updateMeasureEntity(token, entity._id, newEntity).then(({ data }) => {
            dispatch(updateMeasureEntity(data));
            setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
            onClose();
            resetForm();
        }).catch((error) => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_UPDATED", severity: "error" });
            setSubmitted(false);
        })

        else if (createMeasureEntityPermission) API.measureEntity.createMeasureEntity(token, newEntity).then(({ data }) => {
            dispatch(updateMeasureEntity(data));
            setAlert({ open: true, messageId: "SUCCESS.CREATE", severity: "success" });
            onClose();
            resetForm();
        }).catch((error) => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_CREATED", severity: "error" });
            setSubmitted(false);
        })
    }

    const onDelete = () => {
        API.measureEntity.deleteMeasureEntity(token, entity._id).then(({ data }) => {
            resetForm();
            dispatch(deleteMeasureEntity(data.entityId));
            navigate('/entities/' + entity._id, { state: { open: true, messageId: "SUCCESS.DELETE", severity: "success" } });
        }).catch((error) => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_DELETED", severity: "error" });
        })
    }

    const onCloseDialog = () => {
        if (!measureEntity) resetForm();
        onClose();
    }

    const OWMtypes = measureTypes.filter(el => el.serviceType.OWM);

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <ConfirmationDialog
            open={openDeleteDialog}
            title={!entity ? null : <span><FormattedMessage id="CONFIRM_DELETE" /></span>}
            content={<Typography variant="body1" component="span"><FormattedMessage id="WARNING.DELETE_MEASURE_ENTITY" />:<Typography color="error">{entity.name}</Typography></Typography>}
            handleCancel={() => setOpenDeleteDialog(false)}
            handleCustomButton={onDelete}
            customButtonTitle={<FormattedMessage id="DELETE" />}
            customButtonColor='error'
        />
        <Dialog open={open} onClose={onCloseDialog} fullScreen={fullScreen} maxWidth='sm' fullWidth>
            <DialogFormTitle disableHelpText mainTitle={<span><FormattedMessage id="CONFIGURE" /> {measureEntity && measureEntity.name}</span>} action={measureEntity && <Tooltip key="deleteLocationTooltip" title={<FormattedMessage id="ENTITY.DELETE" />} placement="bottom" arrow>
                <IconButton key="deleteLocationTooltip" onClick={() => setOpenDeleteDialog(true)}><DeleteOutline color="error" /></IconButton>
            </Tooltip>} />
            <Divider />

            <DialogContent>
                <Stack direction="row" justifyContent='space-between'>
                    <Box sx={{ maxWidth: '70%' }}>
                        <Typography variant="caption" color="text.disabled" display="block"><FormattedMessage id="ENTITY" /></Typography>
                        <Typography variant="h6" color="primary" display="block">{entity.name}</Typography>
                    </Box>
                    <Box>
                        <Box>
                            <Typography variant="h5" color="primary" display="inline">{owmService.length} </Typography>
                            <Typography variant="caption" display="inline"><FormattedMessage id="SERVICE_TYPES" /></Typography>
                        </Box>
                        <Box>
                            <Typography variant="h5" color="primary" display="inline">{manual.length} </Typography>
                            <Typography variant="caption" display="inline"><FormattedMessage id="MANUAL_TYPES" /></Typography>
                        </Box>
                    </Box>
                </Stack>

                <br />

                <TypeAutocomplete titleId={"SERVICE_TYPES"} attribution={<OWAttribution />} options={OWMtypes} selected={owmService} onChange={setOWMService} />

                <TypeAutocomplete titleId={"MANUAL_TYPES"} options={measureTypes} selected={manual} onChange={setManual} />
            </DialogContent>
            <Divider />

            <DialogActions>
                <Button color="warning" onClick={onCloseDialog}><FormattedMessage id="CANCEL" /></Button>
                <Button disabled={submitted} onClick={onSubmit}><FormattedMessage id={measureEntity ? "EDIT" : "ADD"} /></Button>
            </DialogActions>
        </Dialog>
    </>
}