import { Button, ButtonGroup } from "@mui/material";
import ComplexMetricForm from "./complexMetricForm/ComplexMetricForm";
import SimpleMetricForm from "./simpleMetricForm/SimpleMetricForm";
import { AlertSnackbar } from 'components';
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";


export default function MetricForm({ disableSwitch, metric, ...props }) {
    const [display, setDisplay] = useState("complex");
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (metric && metric.categoryType === "Complex") setDisplay("complex");
        else setDisplay("simple");
    }, [metric]);

    const ButtonLinks = () => {
        return <ButtonGroup size="small">
            <Button variant={display === "simple" ? "contained" : "outlined"} onClick={() => setDisplay("simple")}>
                <FormattedMessage id="SIMPLE" />
            </Button>
            <Button variant={display === "complex" ? "contained" : "outlined"} onClick={() => setDisplay("complex")} >
                <FormattedMessage id="COMPLEX" />
            </Button>
        </ButtonGroup>
    }

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} action={!props.entityId ? alert.action : null} />
        {display === "complex" || metric?.categoryType === "Complex"
            ? <ComplexMetricForm metric={metric} {...props} setAlert={setAlert} dialogAction={!disableSwitch && <ButtonLinks />} />
            : <SimpleMetricForm metric={metric} {...props} setAlert={setAlert} dialogAction={!disableSwitch && <ButtonLinks />} />
        }
    </>

}