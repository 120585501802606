import { DefaultLayout, EmptyState, PublicLayout } from "layouts";
import { MapComponent } from "components";
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";

const MapPage = () => {
    return <EmptyState component={<MapComponent />} />;
}

const MapPageComponent = () => {
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn) return <DefaultLayout page={<MapPage />} />;
    else return <PublicLayout page={<MapPage />} />;
}

export default MapPageComponent;