import axios from "axios";
import instance, { setAuthHeader } from "./axiosEnv";

const getAllUsersKeycloak = (token) => {
    const config = setAuthHeader(token);
    return axios.get(`${process.env.REACT_APP_KEYCLOAK_BASE_URL}admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/users`, config);
}

const getAllUsers = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/users', config);
}

const userAPI = {
    getAllUsersKeycloak,
    getAllUsers
};

export default userAPI;