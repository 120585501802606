import instance, { setAuthHeader } from "./axiosEnv";
import { localISOString } from "utils";

const getAllAlarms = (token, dateFrom, dateTo, severity, acknowledge, clear, entityId) => {
    const queryParams = new URLSearchParams();
    const config = setAuthHeader(token);

    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));
    if (severity) for (let el in severity) {
        queryParams.append('severity', severity[el]);
    }
    if (acknowledge) queryParams.append('acknowledged', Boolean(acknowledge));
    if (clear) queryParams.append('cleared', Boolean(clear));
    if (entityId) for (let el in entityId) {
        queryParams.append('entityId', entityId[el]);
    }

    return instance.get('/alarms?' + queryParams.toString(), config);
}

const getAlarmsCount = (token, dateFrom, dateTo, entityId, severity, profileId, display) => {
    const queryParams = new URLSearchParams();
    const config = setAuthHeader(token);

    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));
    if (severity) for (let el in severity) {
        queryParams.append('severity', severity[el]);
    }
    if (entityId) for (let el in entityId) {
        queryParams.append('entityId', entityId[el]);
    }
    if (profileId) for (let el in profileId) {
        queryParams.append('profileId', profileId[el]);
    }
    if (display) queryParams.append('display', display);

    return instance.get('/alarms/count?' + queryParams.toString(), config);
}

const getSingleAlarm = (alarmId, token) => {
    const config = setAuthHeader(token);
    return instance.get(`/alarms/${alarmId}`, config);
}

const clearAlarm = (alarmId, token) => {
    const config = setAuthHeader(token);
    return instance.put(`/alarms/${alarmId}/clear`, null, config);
}

const acknowledgeAlarm = (alarmId, token) => {
    const config = setAuthHeader(token);
    return instance.put(`/alarms/${alarmId}/acknowledged`, null, config);
}

const alarmAPI = {
    getAllAlarms,
    getAlarmsCount,
    getSingleAlarm,
    clearAlarm,
    acknowledgeAlarm
};

export default alarmAPI;