import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { selectTopics } from 'redux/topicSlice';
import { Button, Dialog, DialogActions, DialogContent, Divider, useMediaQuery } from '@mui/material';
import GoalFormContent from './GoalFormContent';
import { localISOString } from 'utils';
import { DialogFormTitle } from 'components';
import API from 'api';

const GoalForm = ({ open, onClose, topicId, entity, parent, setAlert, existingGoal }) => {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [goalType, setGoalType] = useState("");
    const [goalStatus, setGoalStatus] = useState(3);
    const [categoryType, setCategoryType] = useState(0);
    const [owner, setOwner] = useState("");
    const [participants, setParticipants] = useState([]);
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const topics = useSelector(selectTopics);
    const [selectedTopic, setSelectedTopic] = useState(topicId ? topics.find(el => el._id === topicId) : null);
    const [editMode, setEditMode] = useState(false);
    const { token } = useSelector(userSelector);

    const updateGoalPermission = useSelector((state) => permissionSelector(state, 'update-goal'));
    const createGoalPermission = useSelector((state) => permissionSelector(state, 'create-goal'));

    const handleClose = (resetFlag) => {
        setName("");
        setDescription("");
        setSelectedTopic(null);
        setGoalType("");
        setGoalStatus(0);
        setCategoryType(0);
        setOwner("");
        setParticipants([]);
        onClose(resetFlag);
        setEditMode(false);
    }

    useEffect(() => {
        if (existingGoal && open) {
            setName(existingGoal.name);
            setDescription(existingGoal.description);
            setSelectedTopic(topics.find(el => el._id === existingGoal.topicId));
            setGoalType(existingGoal.type);
            setGoalStatus(existingGoal.status);
            setCategoryType(existingGoal.category)
            setOwner(existingGoal.owner ?? " ");
            setParticipants(existingGoal.participants ? [...existingGoal.participants] : []);
            if (existingGoal?.dateRange?.startDate) setStartDate(new Date(existingGoal?.dateRange?.startDate))
            if (existingGoal?.dateRange?.endDate) setEndDate(new Date(existingGoal?.dateRange?.endDate))
        }
    
    }, [topics, existingGoal, open])

    const compareData = (oldData, newData) => {
        return (
            oldData.topicId === newData.topicId &&
            (oldData.connections?.parentId !== null ?
                oldData.connections?.parentId === newData.parentId ? true : false
                : true) &&
            oldData.name?.trim() === newData.name?.trim() &&
            oldData.description?.trim() === newData.description?.trim() &&
            oldData.type === newData.type &&
            oldData.status === newData.status &&
            oldData.category === newData.category &&
            oldData.dateRange?.startDate === new Date(newData.startDate).toISOString() &&
            oldData.dateRange?.endDate === new Date(newData.endDate).toISOString() &&
            oldData.owner?.trim() === newData.owner?.trim() &&
            JSON.stringify(oldData.participants?.sort()) === JSON.stringify(newData.participants?.sort())
        )
    }

    const onSubmit = () => {
        setDisabledSubmitButton(true);
        const newGoalObject = {
            topicId: topicId || selectedTopic?._id,
            parentId: parent ? parent._id : existingGoal ? existingGoal.connections?.parentId : null,
            name: name,
            description: description,
            type: goalType,
            status: goalStatus,
            category: categoryType,
            startDate: localISOString(startDate),
            endDate: localISOString(endDate),
            owner: owner,
            participants: participants
        }

        if (existingGoal && compareData(existingGoal, newGoalObject)) {
            setAlert({ open: true, messageId: "ERROR.NO_CHANGE_DETECTED", severity: "error" });
            setDisabledSubmitButton(false);
        } else if (existingGoal && updateGoalPermission) {
            API.goal.updateGoal(existingGoal._id, newGoalObject, token).then(response => {
                setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
                handleClose(true);
                setDisabledSubmitButton(false);
            }).catch(error => {
                console.error(error);
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_UPDATED", severity: "error" });
                setDisabledSubmitButton(false);
            })
            setEditMode(false);
        }
        else if (createGoalPermission) {
            API.goal.createGoal(newGoalObject, token).then(response => {
                setAlert({ open: true, messageId: "SUCCESS.CREATE", severity: "success" });
                handleClose(true);
                setDisabledSubmitButton(false);
            }).catch(error => {
                console.error(error);
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_CREATED", severity: "error" });
                setDisabledSubmitButton(false);
            })
        }
    }
    useEffect(() => {
        if (editMode) {
            if (name.trim() !== "" &&
                goalType !== "" &&
                (!!topicId || selectedTopic !== null)) setDisabledSubmitButton(false);
        } else setDisabledSubmitButton(true);
    }, [name, goalType, goalStatus, selectedTopic, topicId, description, categoryType, owner, participants, startDate, endDate, editMode]);

    return <>
        <Dialog open={open} maxWidth="md" fullWidth fullScreen={smallScreen} >
            <DialogFormTitle
                mainTitle={<FormattedMessage id={existingGoal ? "ESG.GOALS.EDIT" + ((parent || existingGoal?.connections?.parentId) ? "_SUB" : "") : "ESG.GOALS.ADD" + ((parent || existingGoal?.connections?.parentId) ? "_SUB" : "")} />}
                subTitle={parent ? `(${parent.name})` : existingGoal ? existingGoal.name : name}
            />
            <Divider />
            <DialogContent>
                <GoalFormContent name={name} setName={setName} entityId={entity?._id}
                    description={description} setDescription={setDescription}
                    selectedTopic={selectedTopic} setSelectedTopic={setSelectedTopic} topicId={topicId}
                    goalType={goalType} setGoalType={setGoalType}
                    goalStatus={goalStatus} setGoalStatus={setGoalStatus}
                    categoryType={categoryType} setCategoryType={setCategoryType}
                    owner={owner} setOwner={setOwner}
                    startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}
                    participants={participants} setParticipants={setParticipants} existingGoal={existingGoal}
                    setEditMode={setEditMode} />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={() => handleClose()} color="warning"><FormattedMessage id="CANCEL" /></Button>
                <Button disabled={disabledSubmitButton} onClick={onSubmit}><FormattedMessage id={existingGoal ? "EDIT" : "ADD"} /></Button>
            </DialogActions>

        </Dialog>
    </>
}

export default GoalForm;