import { enUS, hrHR } from "@mui/material/locale";
import { hr, enGB } from "date-fns/locale";
import { format, parse } from 'date-fns';

export const selectLocale = (lang, stringFormat) => {
    switch (lang) {
        case "hr":
            return stringFormat ? "hr" : hrHR;
        case "en":
            return stringFormat ? "en" : enUS;
        default:
            break;

    }
}

export const selectDateLocale = (lang) => {
    switch (lang) {
        case "hr":
            return hr;
        case "en":
            return enGB;
        default:
            break;

    }
}

export const crudeCompareStringArrays = (arr1, arr2) => {
    if (!arr1?.length && !arr2?.length) return true;
    if (!arr1?.length || !arr2?.length || arr1.length !== arr2.length) return false;
    if (arr1.length === arr2.length && arr1.length === 0) return true;
    for (var i = 0, l = arr1.length; i < l; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
}

export const recursiveChildrenCountSearch = (entityId, entities) => {
    const obj = entities[entityId];
    if (!obj) {
        return 0;
    }
    if (obj.connections.childrenArray.length === 0) {
        return 1;
    }

    return 1 + entities[entityId].connections.childrenArray.reduce((acc, childId) => acc + recursiveChildrenCountSearch(childId, entities), 0);
}

export const recursiveParentSearch = (entity, entities) => {
    const array = [];
    recursiveParentSearchHelper(entity, entities, array);
    return array.reverse();
}

const recursiveParentSearchHelper = (entity, entities, array) => {
    if (entity && entity.connections && entity.connections.parentId) {
        let parent = entities[entity.connections.parentId];
        if (!parent) return;
        array.push(parent)
        recursiveParentSearchHelper(parent, entities, array)
    }
    else return;
}

export function localISOString(date) {
    const dateISO = (new Date(date - (new Date(date)).getTimezoneOffset() * 60000)).toISOString().replace("Z", "");
    return dateISO;
}

export function timeObjectAggregation(aggregation) {
    let time = {};
    switch (aggregation) {
        case 'y':
            time = {
                unit: 'year',
                displayFormats: { year: 'yyyy' }, // 2022
                tooltipFormat: "yyyy",
            }
            break;
        case 'm':
            time = {
                unit: 'month',
                displayFormats: { month: 'MMM yyyy' }, // Aug 2022
                tooltipFormat: "MMM, yyyy",
            }
            break;
        case 'w':
            time = {
                unit: 'week',
                displayFormats: { week: 'yyyy ww' }, // 2022 34
                tooltipFormat: "yyyy, ww",
            }
            break;
        case 'd':
            time = {
                unit: 'day',
                displayFormats: { day: 'MMM d' }, // Aug 24
                tooltipFormat: "P",
            }
            break;
        case 'h':
            time = {
                unit: 'hour',
                displayFormats: { hour: 'MMM d, HH:mm' }, // Aug 24, 12:30
                tooltipFormat: "Pp",
            }
            break;
        default:
            time = {
                unit: 'day',
                displayFormats: { day: 'MMM d' }, // possible options: yyyy QQQ MMM ww d HH mm                
                tooltipFormat: "Pp",
            }
            break;
    }
    return time;
}

export const groupByKey = (items, key) =>
    items.reduce(
        (hash, { [key]: value, ...rest }) => (
            { ...hash, [value]: (hash[value] || []).concat({ [key]: value, ...rest }) }
        ), {});

export const sortbyName = (a, b) => {
    let name1 = Object.keys(a)[0].toUpperCase();
    let name2 = Object.keys(b)[0].toUpperCase();
    if (name1 < name2) return -1;
    if (name1 > name2) return 1;
    return 0;
}

export function formatDateLocale(date, options) {
    const { getFileTime, getShortFormat, aggregationMode } = options || {};
    const currentLocale = JSON.parse(localStorage.getItem('lang')) === "hr" ? hr : enGB;

    if (aggregationMode) {
        const { tooltipFormat } = timeObjectAggregation(aggregationMode);

        if (aggregationMode === 'w') {
            let dateTime;
            // if date is real date format ('2022-12-12T10:05:53.800Z', 1670839544689) - parsing is not needed, 
            // we parse because DB prepared week aggregation that is not recognised format in date-fns library
            if (date.includes('T') || typeof date === 'number') dateTime = date;
            else {
                dateTime = parse(
                    date.split('-')[1],
                    'ww',
                    new Date().setFullYear(date.split('-')[0]),
                    { weekStartsOn: 1, locale: currentLocale }
                )
            }

            return format(
                new Date(
                    dateTime
                ),
                tooltipFormat,
                { locale: currentLocale });
        }
        else return format(new Date(date), tooltipFormat, { locale: currentLocale });
    }

    // 2022-01 without aggregation this will be formatted as month, but DB is returning this response only for week aggregation
    if (typeof (date) === 'string' && date.lastIndexOf('-') === 4 && !aggregationMode) return date;

    if (isNaN(new Date(date).valueOf())) {
        return date;
    }

    if (getShortFormat) {
        return format(new Date(date), 'P', { locale: currentLocale });
    }

    if (getFileTime) return format(new Date(date), 'dd-MM-yyyy--HH-mm');

    // if no other options are selected return local datetime
    return format(new Date(date), 'Pp', { locale: currentLocale });
}

export function formatDate(date, options) {
    const { getDetailedTime, getGraphTime, getFileTime } = options || {};
    let hours, minutes, graphDay, graphDate;

    if (typeof (date) === 'string' && date.lastIndexOf('-') === 4) return date;

    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (isNaN(d.valueOf())) {
        return date;
    }
    if (getGraphTime) {
        graphDay = d.toString().split(' ')[0];
        graphDate = d.getDate();
        return [graphDay, graphDate].join(' ');
    }


    if (getDetailedTime || getFileTime) {
        hours = d.getHours();
        minutes = d.getMinutes();
    }
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    if (hours < 10)
        hours = '0' + hours;
    if (minutes < 10)
        minutes = '0' + minutes;
    const time = [year, month, day].join('-');

    if (hours !== undefined && minutes !== undefined) {
        if (getFileTime) return [time, [hours, minutes].join('-')].join('--');
        else return [time, [hours, minutes].join(':')].join('  ');
    } else return time;
}

export function statusCodeColor(statusCode) {
    let statusColor;
    switch (statusCode) {
        case 1: // --status-blue
            statusColor = '#0077DB'
            break;
        case 2: // --status-green
            statusColor = '#288964'
            break;
        case 3: // --status-yellow
            statusColor = '#DCAF00'
            break;
        case 4: // --status-orange
            statusColor = '#E66E19'
            break;
        case 5: // --status-red
            statusColor = '#DC2D37'
            break;
        default: // --status-black
            statusColor = '#181818'
            break;
    }

    return statusColor;
}


export function deepMerge(objA, objB) {
    const newObj = mergeObjects({}, objA);
    return mergeObjects(newObj, objB);
}

function mergeObjects(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeObjects(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeObjects(target, ...sources);
}

export function deepEqual(object1, object2, keysArray = []) {
    if (!object1 || !object2) return object1 === object2;

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (keysArray.length && !keysArray.includes(key.toString())) continue;
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false;
        }
    }

    return true;
}

function isObject(object) {
    return object != null && typeof object === 'object';
}

export function exportData(locationName, type, tableData, tableHeaders, base64Image, printPDF) {
    locationName = locationName.replaceAll(" ", "-");
    const data = tableData;
    let CSV = '';

    if (tableHeaders) {
        let row = tableHeaders;
        CSV = row.join(";") + "\r\n";
    }

    for (let i = 0; i < data.length; i++) {
        let row = '';
        for (let index in data[i]) {
            row += '"' + data[i][index] + '";';
        }
        row.slice(0, row.length - 1);
        CSV += row + "\r\n";
    }

    if (CSV === '') {
        console.error('Invalid data for exporting CSV!');
        return;
    }

    let fileName = `${type}_${locationName}_${formatDateLocale(Date.now(), { getFileTime: true })}.csv`;
    let uri = 'data:text/csv;charset=utf-8,\uFEFF' + encodeURI(CSV);
    let link = document.createElement('a');
    link.href = uri;
    link.style = "visibility:hidden";
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function getStatusColor(color) {
    switch (color) {
        case 0: return "error.dark";
        case 1: return "error.light";
        case 3: return "warning.dark";
        case 4: return "secondary.dark";
        case 5: return "primary.dark";
        case 6: return "success.dark";
        case 2:
        default: return "text.disabled";
    }
}

export const getLabelId = (property, intl) => {

    switch (property) {
        case "name": return "NAME";
        case "description": return "DESCRIPTION";
        case "owner": return "OWNER";
        case "status": return "STATUS";
        case "category.type":
        case "category": return "ESG.GOAL.CATEGORY";
        case "category.scope.measurementTypeKey": return "MEASUREMENT_TYPE";
        case "category.scope.collector": return "COLLECTOR";
        case "category.scope.sourceType": return "SOURCE_TYPE";
        case "category.scope.unit": return "UNIT";
        case "dateRange.startDate":
        case "datetime.dateFrom":
        case "reportData.startDate": return "DATE_FROM";
        case "dateRange.endDate":
        case "datetime.dateTo":
        case "reportData.endDate": return "DATE_TO";
        case "reportData.createdAt": return "CREATED_AT";
        case "datetime.lastUpdated": return "LAST_UPDATED";
        case "timestamp": return "DATE";
        case "reportData.dataFrequencyInterval": return "DATA_FREQUENCY_INTERVAL";
        case "reportData.reportInterval": return "REPORT_INTERVAL";
        case "reportData.calculationMethod": return "CALCULATION_METHOD";
        case "type": return "ESG.TYPE";
        case "geoProperties.position": return "POSITION";
        case "geoProperties.address": return "ADDRESS";
        case "measurementTypes.service.OWM": return "SERVICE_TYPES";
        case "measurementTypes.sensor": return "SENSOR_TYPES";
        case "measurementTypes.manual": return "MANUAL_TYPES";
        case "metricId": return "ESG.METRIC";
        case "value": return "VALUE";
        case "title": return "NOTE.TITLE";
        case "content": return "NOTE.CONTENT";
        case "reportData.reportUnit.unit": return "UNIT";
        case "participants": return "PARTICIPANTS";
        case "metricIds": return "ESG.METRICS";
        case "priority": return "PRIORITY";
        case "contacts": return "NOTE.CONTACTS";
        case "notificationMethod": return "NOTIFICATIONS.METHOD";
        case "archived": return "NOTE.ARCHIVED";
        case "active": return "ACTIVE";
        case "measurable": return "VALUE";
        case "message": return "ALARM.MESSAGE";
        case "collector": return "COLLECTOR";
        case "triggeredTime": return "TRIGGERED_TIME";
        case "entityId": return "ENTITY";
        case "severityType": return "SEVERITY";
        case "acknowledgedTime": return "ACKNOWLEDGED_TIME";
        case "acknowledgedBy": return "ACKNOWLEDGED_BY";
        case "clearedTime": return "CLEARED_TIME";
        case "clearedBy": return "CLEARED_BY";
        case "conditionsPassed": return "CONDITIONS_PASSED";
        case "sendStatus": return "STATUS";
        case "enabled": return "ENABLED";
        case "profileId": return "DASHBOARD.OPTIONS.profileId";
        case "goalId": return "DASHBOARD.OPTIONS.goalId";
        case "rules.WARNING":
        case "rules.MAJOR":
        case "rules.MINOR":
        case "rules.INDETERMINATE":
        case "rules.CRITICAL":
            const rule = property.substr(6);
            return "DASHBOARD.OPTIONS.RULE." + rule;
        case "measurementTypeKey": return "MEASUREMENT_TYPE";
        case "unit": return "UNIT";
        case "source": return "DASHBOARD.OPTIONS.collector";
        case "rules": return "ALARMS.PROFILE_RULES";
        case "topicId": return "ESG.TOPIC";
        case "KPI.turnover": return "turnover";
        case "KPI.capEx": return "capEx";
        case "KPI.opEx": return "opEx";
        case "objectives.MITIGATION": return "MITIGATION";
        case "objectives.ADAPTATION": return "ADAPTATION";
        case "objectives.WATER": return "WATER";
        case "objectives.CIRCULAR": return "CIRCULAR";
        case "objectives.POLLUTION": return "POLLUTION";
        case "objectives.BIODIVERSITY": return "BIODIVERSITY";
        case "activityId": return "DASHBOARD.OPTIONS.activityId";
        case "dateYear": return "YEAR";
        case "_id": return "ID";
        case "engagementExamples": return "ENGAGEMENT";
        case "raisedConcerns": return "CONCERNS_AND_TOPICS";
        default: return property;
    }
}

export const findUnitFamily = (unit, units) => {
    if (!unit) return null;
    for (let key in units) {
        if (units[key].includes(unit)) {
            return key;
        }
    }
    return null;
}