import { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogActions, Button, Stack, TextField, Autocomplete, Checkbox, Typography, useMediaQuery, Divider, Chip, createFilterOptions } from "@mui/material";
import { AlertSnackbar, LoadingData, DialogFormTitle } from 'components';
import { FormattedMessage, useIntl } from "react-intl";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import { selectUserPermissions } from "redux/configurationSlice";
import { selectEntities } from "redux/entitySlice";
import { crudeCompareStringArrays } from "utils/helpers";
import API from "api";

const AddEditRoleModal = ({ openDialog, onClose, roleId, reloadData }) => {
    const { token } = useSelector(userSelector);
    const intl = useIntl();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const userPermissions = useSelector(selectUserPermissions);
    const userEntities = useSelector(selectEntities);

    const [name, setName] = useState('');
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [receivedData, setReceivedData] = useState({});
    const [loadingData, setLoadingData] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const [disableEditButton, setDisabledEditButton] = useState(true);

    const onAlertClose = () => setAlert({ ...alert, open: false });

    let allEntities = [];

    useEffect(() => {
        if (roleId !== '' && openDialog) {
            setLoadingData(true);
            API.role.getRole(roleId, token).then(response => {
                if (response?.data) {
                    setName(response?.data?.name);
                    setSelectedPermissions(response?.data?.permissions);
                    setSelectedEntities(response?.data?.entityIds);
                    setReceivedData({
                        name: response?.data?.name,
                        permissions: response?.data?.permissions,
                        entityIds: response?.data?.entityIds.map((entityId) => entityId._id)
                    });
                    setLoadingData(false);
                }
            }).catch(error => {
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
                console.error(error);
                setLoadingData(false);
            })
        }
        else {
            setName('');
            setSelectedPermissions([]);
            setSelectedEntities([]);
        }
        return () => {
            setDisabledEditButton(true);
        }
    }, [roleId, openDialog, token]);

    const handleRoleUpdate = () => {
        setDisabledEditButton(true);
        const data = {
            name: name,
            permissions: selectedPermissions,
            entityIds: selectedEntities.map((entity) => entity._id)
        }
        if (roleId !== '' && crudeCompareStringArrays(data.permissions, receivedData.permissions) && crudeCompareStringArrays(data.entityIds, receivedData.entityIds)) {
            setDisabledEditButton(false);
            setAlert({ open: true, messageId: "ERROR.NO_CHANGE_DETECTED", severity: "error" });

        } else if (roleId !== '') {
            API.role.updateRole(roleId, data, token).then(response => {
                if (response?.data) {
                    setAlert({ open: true, messageId: "ROLE.UPDATE_SUCCESS", severity: "success" });
                    reloadData();
                }
                setDisabledEditButton(false);
            }).catch(error => {
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_UPDATED", severity: "error" });
                console.error(error);
                setDisabledEditButton(false);
            })

        } else {
            API.role.createRole(data, token).then(response => {
                if (response?.data) {
                    setAlert({ open: true, messageId: "ROLE.CREATE_SUCCESS", severity: "success" });
                    reloadData();
                }
                setName('');
                setSelectedEntities([]);
                setSelectedPermissions([]);
                setDisabledEditButton(false);
            }).catch(error => {
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_CREATED", severity: "error" });
                console.error(error);
                setDisabledEditButton(false);
            })
        }
    }

    for (const key in userEntities) {
        allEntities.push({ _id: userEntities[key]._id, name: userEntities[key].name });
    }

    return (
        <>
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />

            <Dialog
                open={openDialog}
                maxWidth='md'
                fullWidth
                fullScreen={smallScreen}
                onClose={onClose}
            >
                <DialogFormTitle disableHelpText={roleId !== ''} mainTitle={roleId !== ''
                    ? <span><FormattedMessage id="ROLE.EDIT" /><Typography display="block" color="primary">{name}</Typography></span>
                    : <FormattedMessage id="ROLE.ADD" />
                } />
                <Divider />
                <DialogContent>
                    {loadingData ? <LoadingData />
                        : <Stack direction="column" spacing={2}>
                            {roleId === '' && <TextField
                                size="small"
                                error={name === process.env.REACT_APP_ADMIN_ROLE}
                                required
                                id="outlined-read-only-input"
                                label={<span><FormattedMessage id="ROLE.NAME" /></span>}
                                value={name}
                                onChange={(e) => { setName(e.target.value); setDisabledEditButton(false) }}
                                fullWidth
                            />}
                            <Autocomplete
                                multiple
                                disabled={name === process.env.REACT_APP_ADMIN_ROLE}
                                id="combo-box-demo-permissions"
                                disableCloseOnSelect
                                limitTags={4}
                                options={Object.keys(userPermissions) ?? []}
                                filterOptions={createFilterOptions({ stringify: (opt) => intl.formatMessage({ id: opt }) })}
                                getOptionLabel={(option) => option}
                                value={selectedPermissions}
                                renderInput={(params) => <TextField {...params} label={intl.formatMessage({ id: "PERMISSIONS" })} placeholder={!selectedPermissions.length ? intl.formatMessage({ id: "NONE_SELECTED" }) : ""} />}
                                onChange={(event, newValue) => {
                                    setSelectedPermissions([...newValue]);
                                    setDisabledEditButton(false)
                                }}
                                size="small"
                                fullWidth
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                                            checkedIcon={<CheckBoxOutlined fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {intl.formatMessage({ id: option })}
                                    </li>
                                )}
                                renderTags={(values, getTagProps) => {
                                    return values.map((option, index) => (
                                        <Chip size="small" {...getTagProps({ index })} label={intl.formatMessage({ id: option })} />
                                    ));
                                }}
                            />
                            <Autocomplete
                                multiple
                                disabled={name === process.env.REACT_APP_ADMIN_ROLE}
                                id="combo-box-demo-entities"
                                disableCloseOnSelect
                                limitTags={4}
                                options={allEntities ?? []}
                                getOptionLabel={(option) => option.name}
                                fullWidth
                                value={selectedEntities}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderInput={(params) => <TextField helperText={!!selectedEntities.length && <span><FormattedMessage id="PERMISSION_HELPER" /></span>} {...params} required label={intl.formatMessage({ id: "ENTITIES" })} placeholder={!selectedEntities.length ? intl.formatMessage({ id: "NONE_SELECTED" }) : ""} />}
                                onChange={(event, newValue) => {
                                    setSelectedEntities([...newValue]);
                                    setDisabledEditButton(false);
                                }}
                                size="small"
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                                            checkedIcon={<CheckBoxOutlined fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </li>
                                )}
                            />
                        </Stack>
                    }
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button color="warning" onClick={onClose}><FormattedMessage id="CANCEL" /></Button>
                    <Button disabled={loadingData || disableEditButton || !name.length || !selectedEntities.length || name === process.env.REACT_APP_ADMIN_ROLE} onClick={handleRoleUpdate} autoFocus><FormattedMessage id={roleId !== '' ? "EDIT" : "SAVE"} /></Button>
                </DialogActions>
            </Dialog>
        </>

    )
}
export default AddEditRoleModal;