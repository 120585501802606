import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { selectEntities } from 'redux/entitySlice';
import NoteFormContent from './NoteFormContent';
import { DialogFormTitle } from 'components';
import { Button, Chip, Dialog, DialogActions, DialogContent, Divider, useMediaQuery } from '@mui/material';
import { localISOString } from 'utils';
import API from 'api';

const NoteForm = ({ open, onClose, setAlert, setReset, onDetailsClose, existingNote }) => {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const entities = useSelector(selectEntities);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [owner, setOwner] = useState("");
    const [selectedNotificationTypes, setNotificationTypes] = useState([]);
    const [noteType, setNoteType] = useState('');
    const [noteDate, setNoteDate] = useState(Date.now());
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);
    const { token } = useSelector(userSelector);
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        if (open && existingNote) {
            setTitle(existingNote.title);
            setContent(existingNote.content);
            setSelectedEntity(entities[existingNote.entityId]);
            setNoteDate(new Date(existingNote.timestamp));
            setNotificationTypes(existingNote.notificationMethod || []);
            setNoteType(existingNote.type !== undefined ? existingNote.type : '');
            setOwner(existingNote.owner || "");
            setContacts(existingNote.contacts);
        }
    }, [open, existingNote, entities])

    const handleClose = () => {
        setSelectedEntity(null);
        setTitle("");
        setContent("");
        setOwner("");
        setNotificationTypes([]);
        setNoteType('');
        setNoteDate(Date.now());
        setContacts([]);
        onClose();
    }

    const handleSubmit = () => {
        setDisabledSubmitButton(true);
        const newNoteObject = {
            timestamp: localISOString(noteDate),
            title: title,
            content: content,
            entityId: selectedEntity?._id,
            notificationMethod: selectedNotificationTypes,
            ...(noteType !== '' && { type: noteType }),
            owner: owner,
            contacts: contacts,
        }

        API.notes.createNote(newNoteObject, token).then(response => {
            if (response.data) {
                handleClose();
                if (onDetailsClose) onDetailsClose();
                setAlert({ open: true, messageId: "SUCCESS.CREATE", severity: "success" });
                setReset(reset => reset + 1);
                setDisabledSubmitButton(false);
            }
        })
            .catch(error => {
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_CREATED", severity: "error" });
                console.error(error);
                setDisabledSubmitButton(false);
            })
    }

    useEffect(() => {
        if (title.trim() !== "" && content.trim() !== "" && selectedEntity !== null && new Date(noteDate).toString() !== "Invalid Date" && selectedNotificationTypes?.length > 0 && noteType !== "") setDisabledSubmitButton(false);
        else setDisabledSubmitButton(true);
    }, [content, noteDate, selectedEntity, title, selectedNotificationTypes, noteType])

    return <Dialog open={open} maxWidth="md" fullWidth fullScreen={smallScreen}>
        <DialogFormTitle mainTitle={<span><FormattedMessage id="NOTE.ADD" /> {Boolean(existingNote) && <Chip size="small" label={<FormattedMessage id="COPY.COPY" />} />}</span>} />
        <Divider />
        <DialogContent>
            <NoteFormContent selectedEntity={selectedEntity} setSelectedEntity={setSelectedEntity}
                title={title} setTitle={setTitle} content={content} setContent={setContent}
                owner={owner} setOwner={setOwner} selectedNotificationTypes={selectedNotificationTypes} setNotificationTypes={setNotificationTypes}
                noteType={noteType} setNoteType={setNoteType} noteDate={noteDate} setNoteDate={setNoteDate}
                contacts={contacts} setContacts={setContacts} />
        </DialogContent>
        <Divider />
        <DialogActions>
            <Button color="warning" onClick={handleClose}><FormattedMessage id="CANCEL" /></Button>
            <Button disabled={disabledSubmitButton} onClick={handleSubmit}><FormattedMessage id="ADD" /></Button>
        </DialogActions>
    </Dialog >
}

export default NoteForm;