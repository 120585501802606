import { LabelImportantOutlined } from "@mui/icons-material";
import { useTheme } from "@mui/material"


export default function AlarmSeverityIcon({ severity, customStyle }) {
    const theme = useTheme();

    return <LabelImportantOutlined
        name={'severity-' + severity}
        className={'severity-' + severity}
        sx={!customStyle ? { width: theme.typography.fontSize * 1.5, height: theme.typography.fontSize * 1.5, pt: 1 } : customStyle}
    />
} 