import { CloseOutlined, DownloadOutlined } from "@mui/icons-material";
import { Button, CardHeader, Checkbox, DialogActions, DialogContent, Divider, FormControl, FormControlLabel, FormLabel, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { LoadingData, AlertSnackbar } from 'components';
import { FormattedMessage, useIntl } from "react-intl";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB, hr } from "date-fns/locale";
import { useSelector } from "react-redux";
import { selectEntities } from "redux/entitySlice";
import { userSelector } from "redux/userSlice";
import API from 'api';



export default function EntityActivityExport({ entityId, KPI, year, onClose }) {
    const intl = useIntl();
    const { token } = useSelector(userSelector);
    const entity = useSelector(selectEntities)[entityId];
    const [value, setValue] = useState({
        turnover: false,
        capEx: false,
        opEx: false
    });
    const [date, setDate] = useState(new Date(year));
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const onSubmit = () => {
        setDisableSubmit(true);
        API.activity.getEntityActivityReport(token, entityId, date.getFullYear(), intl.locale, Object.keys(value).filter(el => value[el]))
            .then((response) => {
                const href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
                const anchorElement = document.createElement('a');
                anchorElement.href = href;
                anchorElement.setAttribute('download', `${entity?.name}_report_${date.getFullYear()}.xlsx`);
                document.body.appendChild(anchorElement);
                anchorElement.click();
                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
                setDisableSubmit(false);
                onClose();
            })
            .catch(error => {
                console.error(error);
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
                setDisableSubmit(false);
            });
    }

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <CardHeader title={<FormattedMessage id="ACTIVITY.EXPORT" />} subheader={entity?.name} action={<IconButton onClick={onClose}><CloseOutlined /></IconButton>} />
        <Divider />
        <DialogContent>
            <Stack gap={1}>
                <FormControl>
                    <FormLabel><Typography variant="h6"><FormattedMessage id="SELECT_TYPE" /></Typography></FormLabel>
                    <FormControlLabel
                        control={<Checkbox
                            disabled={!KPI.turnover}
                            checked={value.turnover}
                            onChange={(e, checked) => setValue(v => ({ ...v, turnover: checked }))}
                        />}
                        label={intl.formatMessage({ id: "turnover" }) + (!KPI.turnover ? ` - ${intl.formatMessage({ id: "NO_DATA" })}` : "")}
                    />
                    <FormControlLabel
                        control={<Checkbox
                            disabled={!KPI.capEx}
                            checked={value.capEx}
                            onChange={(e, checked) => setValue(v => ({ ...v, capEx: checked }))}
                        />}
                        label={intl.formatMessage({ id: "capEx" }) + (!KPI.capEx ? ` - ${intl.formatMessage({ id: "NO_DATA" })}` : "")}
                    />
                    <FormControlLabel
                        control={<Checkbox
                            disabled={!KPI.opEx}
                            checked={value.opEx}
                            onChange={(e, checked) => setValue(v => ({ ...v, opEx: checked }))}
                        />}
                        label={intl.formatMessage({ id: "opEx" }) + (!KPI.opEx ? ` - ${intl.formatMessage({ id: "NO_DATA" })}` : "")}
                    />
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                    <DatePicker slotProps={{ textField: { size: "small", fullWidth: true, InputProps: { startAdornment: <InputAdornment position="start"><FormattedMessage id="DATE.PREFIX.ACTIVITY_EXPORT" /></InputAdornment> } } }} value={date} onChange={(val) => setDate(val)} minDate={new Date("2020")} views={['year']} />
                </LocalizationProvider>
            </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
            <Button disabled={disableSubmit || Object.values(value).every(el => !el)} onClick={onSubmit} startIcon={disableSubmit ? <LoadingData noText /> : <DownloadOutlined />}><FormattedMessage id="DOWNLOAD" /></Button>
        </DialogActions>
    </>
}