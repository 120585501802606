import React from 'react';
import { Box, Typography, useTheme } from "@mui/material";



const EmptyState = (props) => {
    const { message, component } = props;
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                pt: 3,
                flexGrow: 1,
                height: '100%',
                ...(!component && message && {
                    justifyContent: 'center',
                    alignItems: 'center',
                })
            }}
        >
            {component ? component
                : message ? <Typography align="center" paragraph sx={{ color: theme.palette.grey[700] }}>{message}</Typography>
                    : null}
        </Box>
    );
}

export default EmptyState;