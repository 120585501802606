import { LaunchOutlined } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { ConfirmationDialog } from "components";
import { useState } from "react";
import { FormattedMessage } from "react-intl";



export default function GoToButton({ label, onClick, sx, customContent, disableConfirmation }) {
    const [open, setOpen] = useState(false);

    const customSize = theme => ({ fontSize: theme.typography.h6 });

    return <>
        <ConfirmationDialog
            open={open}
            content={customContent || <Typography component="span" sx={customSize} color="error"><FormattedMessage id="PROGRESS_WARNING" /></Typography>}
            title={<Typography component="span" variant="button" sx={customSize}><FormattedMessage id="GO_TO" /> {label}?</Typography>}
            customButtonTitle={<FormattedMessage id="YES" />}
            handleCancel={() => setOpen(false)}
            handleCustomButton={onClick}
        />
        <Button sx={sx || undefined} size="small" startIcon={<LaunchOutlined fontSize="small" />} onClick={disableConfirmation ? onClick : () => setOpen(true)}>
            <FormattedMessage id="GO_TO" /> {label}
        </Button>
    </>;
} 