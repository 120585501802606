import { useCallback, useEffect, useState } from "react";
import { QueryStatsOutlined, PreviewOutlined, HistoryOutlined, InfoOutlined, CloseOutlined } from "@mui/icons-material";
import { Avatar, CardHeader, Typography, IconButton, Tooltip, Alert, Stack, Box, Divider, useMediaQuery, Badge, Dialog, DialogContent } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import { selectMeasurementsTypesConf } from "redux/configurationSlice";
import { AlertSnackbar, MetricDetailsView, LoadingData, MetricHistoryReportView } from 'components';
import { formatDateLocale } from 'utils';
import API from 'api';

export const ValueCell = ({ value, prefix, suffix, subtitle, variant, color, tableCell, customStringWidth }) => {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const [openInfo, setOpenInfo] = useState(false);
    const valueString = value + "";

    const stringWidth = customStringWidth || (smallScreen ? 12 : 20);
    const shortStringFlag = valueString.length < stringWidth;
    const cutAdditionalText = ((prefix ? prefix.length : 0) + (valueString.length) + (suffix ? suffix.length : 0)) < stringWidth;

    return <>
        <Dialog open={openInfo} onClose={() => setOpenInfo(false)}>
            <CardHeader title={<FormattedMessage id="ESG.METRIC.VALUE" />} subheader={subtitle} action={<IconButton onClick={() => setOpenInfo(false)}><CloseOutlined /></IconButton>} />
            <Divider />
            <DialogContent>
                {prefix && <Typography sx={{ pr: 0.5 }} variant="caption" component="span">{prefix}</Typography>}
                <Typography component="span">{valueString}</Typography>
                {suffix && <Typography sx={{ pl: 0.5 }} variant="caption" component="span">{suffix}</Typography>}
            </DialogContent>
        </Dialog>
        <Badge sx={{ alignItems: 'flex-end', width: '100%' }} invisible={shortStringFlag} badgeContent={<IconButton onClick={(e) => { e.stopPropagation(); setOpenInfo(true) }} size="small" color="info"><InfoOutlined fontSize="small" /></IconButton>}>
            {prefix && <Typography sx={{ pr: 0.5 }} variant="inherit" component="span">{!cutAdditionalText && tableCell ? "..." : prefix}</Typography>}
            <Typography color={color ? color : "success.dark"} variant={tableCell ? "inherit" : (smallScreen ? "h5" : "h4")} component="span" sx={!variant ? (theme => ({ fontWeight: theme.typography.fontWeightRegular })) : undefined}>{!shortStringFlag ? valueString.slice(0, stringWidth) + '...' : valueString}</Typography>
            {suffix && <Typography sx={{ pl: 0.5 }} variant="inherit" component="span">{!tableCell ? suffix : (shortStringFlag && suffix)}</Typography>}
        </Badge>
    </>

}

const ReportDetails = ({ value, reportUnit, reportData, calculationMethod }) => {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    return <Stack direction="column" spacing={1}>
        <Divider><Typography color="secondary.main" variant="caption" sx={{ textTransform: 'uppercase' }}><FormattedMessage id="MEASUREMENTS.LAST_VALUE" /></Typography></Divider>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2, mb: 1 }}>
            {value?.value !== undefined && value?.value !== null
                ? <>
                    <ValueCell
                        prefix={reportUnit?.prefix} // && <Typography variant={smallScreen ? "h6" : "h5"} display="inline" sx={theme => ({ fontWeight: theme.typography.fontWeightLight })}>{reportUnit.prefix}</Typography>}
                        value={value.value?.description ? value.value.description : value.value}
                        suffix={reportUnit ? `${reportUnit.unit && calculationMethod !== "count" ? reportUnit?.unit : (value.unit && calculationMethod !== "count" ? value.unit : "")} ${reportUnit.suffix || ""}` : calculationMethod !== "count" ? value.unit : ""} //{(reportUnit?.unit || reportUnit?.suffix || value.unit) && <Typography variant={smallScreen ? "h6" : "h5"} display="inline" sx={theme => ({ fontWeight: theme.typography.fontWeightLight })}>{reportUnit ? `${reportUnit.unit ? reportUnit.unit : (value.unit ? value.unit : "")} ${reportUnit.suffix || ""}` : value.unit}</Typography>}
                    />
                </>
                : <Typography color="text.disabled" variant={smallScreen ? "h4" : "h2"} display="inline" sx={theme => ({ fontWeight: theme.typography.fontWeightRegular })}>N/A</Typography>}
        </Box>
        <Divider><Typography variant="caption" color="secondary.main" sx={{ textTransform: 'uppercase' }}><FormattedMessage id="CURRENT_PERIOD" /></Typography></Divider>
        <>
            <Typography color="primary" variant={smallScreen ? "subtitle1" : "body2"}><FormattedMessage id="DATE_FROM" /></Typography>
            {reportData?.dateFrom ? <Typography variant={smallScreen ? "body2" : "body1"}>{formatDateLocale(reportData.dateFrom)}</Typography> : <Typography variant="button" color="text.disabled">N/A</Typography>}
        </>
        <>
            <Typography color="primary" variant={smallScreen ? "subtitle1" : "body2"}><FormattedMessage id="DATE_TO" /></Typography>
            {reportData?.dateTo ? <Typography variant={smallScreen ? "body2" : "body1"}>{formatDateLocale(reportData.dateTo)}</Typography> : <Typography variant="button" color="text.disabled">N/A</Typography>}
        </>
        <>
            <Typography color="primary" variant={smallScreen ? "subtitle1" : "body2"}><FormattedMessage id="LAST_UPDATED" /></Typography>
            {reportData?.lastUpdated ? <Typography variant={smallScreen ? "body2" : "body1"}>{formatDateLocale(reportData.lastUpdated)}</Typography> : <Typography variant="button" color="text.disabled">N/A</Typography>}
        </>
    </Stack>
}

export default function MetricReportDetailCard({ metric, entity, complex, onClose }) {
    const { token } = useSelector(userSelector);
    const [open, setOpen] = useState(false);
    const [openHistoryList, setOpenHistoryList] = useState(false);
    const [loading, setLoading] = useState(false);
    const measurementTypes = useSelector(selectMeasurementsTypesConf);

    const [latestReport, setLatestReport] = useState(null);
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        setLoading(true);
        if (metric?.metricId?._id) API.metricReports.getLatestMetricReports(token, [metric.metricId._id]).then(items => {
            if (items.data && items.data.length > 0) {
                const item = items.data[0];
                setLatestReport({ ...item, metricId: item.metricId, ...item.reportData });
            }
            else setLatestReport(null);
            setLoading(false);
        }).catch(error => {
            setLatestReport({ error });
            console.error(error);
            setLoading(false);
        })
        else {
            setLatestReport(null);
            setLoading(false);
        }
    }, [metric, token]);

    const renderView = useCallback(() => {
        if (loading) return <LoadingData />;
        else if (!latestReport) return <Alert severity="warning"><FormattedMessage id="NO_DATA" /></Alert>;
        else if (latestReport?.error) return <Alert severity="error"><FormattedMessage id="ERROR.NO_DATA" /></Alert>;
        else return <ReportDetails value={{ value: latestReport.value, unit: latestReport.metricId.category?.scope?.unit }} calculationMethod={latestReport.metricId.reportData?.calculationMethod} reportUnit={latestReport.metricId.reportData?.reportUnit} reportData={latestReport.datetime} />;
    }, [loading, latestReport]);

    if (!metric) return null;

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <MetricDetailsView metricId={metric.metricId?._id} openDialog={open} onClose={() => setOpen(false)} complex={complex} />
        <MetricHistoryReportView
            metricId={metric.metricId?._id}
            openDialog={openHistoryList}
            onCloseHandler={() => setOpenHistoryList(!openHistoryList)}
            entityName={entity?.name}
            reportName={latestReport?.metricId?.name}
            type={latestReport?.metricId?.type}
            label={latestReport && measurementTypes.find(el => el.key === latestReport.metricId?.category?.scope?.measurementTypeKey)}
        />
        <CardHeader
            title={<Typography variant={"h6"}>{metric.name}</Typography>}
            subheader={<span><FormattedMessage id="LATEST_REPORT" /> {entity && `(${entity.name})`}</span>}
            avatar={<Avatar sx={{ bgcolor: 'primary.main' }}><QueryStatsOutlined /></Avatar>}
            action={[<Tooltip title={<FormattedMessage id="ESG.METRIC.VIEW" />} placement="bottom" key={'VIEW'} arrow>
                <span><IconButton color="primary" onClick={() => setOpen(true)}><PreviewOutlined /></IconButton></span>
            </Tooltip>,
            <Tooltip title={<FormattedMessage id="ESG.METRIC.PREVIOUS_REPORTS_VIEW" />} placement="bottom" key={'PREVIOUS_VIEW'} arrow>
                <span><IconButton color="primary" onClick={() => setOpenHistoryList(true)}><HistoryOutlined /></IconButton></span>
            </Tooltip>,
            <IconButton key="close" onClick={onClose}><CloseOutlined /></IconButton>
            ]}
        />
        <DialogContent>
            {renderView()}
        </DialogContent>
    </>
}