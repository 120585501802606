import { AddOutlined, CancelOutlined, CopyAllOutlined, DeleteOutlined, DoNotDisturbOffOutlined, DoNotDisturbOnOutlined, EditOutlined } from '@mui/icons-material';
import { Alert, Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, Chip, IconButton, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { CustomDataGrid, LoadingData, AlarmDetailsDialog, AlarmSeverityIcon, AlertSnackbar, ConfirmationDialog, DisplayBox, AlarmProfileForm, ExpandedGridCell } from 'components';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectEntities } from 'redux/entitySlice';
import { permissionSelector, userSelector } from 'redux/userSlice';
import { useLocation, useNavigate } from 'react-router';
import API from 'api';

export function ProfileDeleteDialogContent({ profile }) {
    const intl = useIntl();
    return <Stack direction="column" gap={1}>
        <DisplayBox label={<FormattedMessage id="NAME" />} value={profile.name} />
        <DisplayBox label={<FormattedMessage id="STATUS" />} value={<FormattedMessage id={profile.enabled ? "ENABLED" : "DISABLED"} />} />
        <DisplayBox label={<FormattedMessage id="ALARMS.PROFILE_RULES" />} value={profile.rules ? Object.keys(profile.rules).map(el => <Chip key={el} component="span" variant="outlined" size="small" sx={{ mr: 0.5, '& .MuiChip-label': { pl: 0 } }} icon={<AlarmSeverityIcon severity={el} customStyle={{ pb: 0.2 }} />} label={intl.formatMessage({ id: "ALARMS.PROFILE_RULES." + el })} />) : null} />
    </Stack>
}

export default function AlarmProfiles(props) {
    const navigate = useNavigate();
    const navigateState = useLocation().state;
    const intl = useIntl();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { token } = useSelector(userSelector);
    const allEntities = useSelector(selectEntities);
    const [data, setData] = useState(null);
    const [selected, setSelected] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [copy, setCopy] = useState(false);
    const [reset, setReset] = useState(0);
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const viewAlarmsPermission = useSelector((state) => permissionSelector(state, 'view-alarms'));
    const viewAlarmProfilesPermission = useSelector((state) => permissionSelector(state, 'view-alarmProfiles'));
    const createAlarmProfile = useSelector((state) => permissionSelector(state, 'create-alarmProfiles'));
    const updateAlarmProfile = useSelector((state) => permissionSelector(state, 'update-alarmProfiles'));
    const deleteAlarmProfile = useSelector((state) => permissionSelector(state, 'delete-alarmProfiles'));

    useEffect(() => {
        if (viewAlarmsPermission && !viewAlarmProfilesPermission) {
            navigate('/alarms');
        }
    }, [viewAlarmsPermission, viewAlarmProfilesPermission, navigate])

    useEffect(() => {
        if (navigateState) setAlert(navigateState);
    }, [navigateState]);

    useEffect(() => {
        API.alarmProfile.getAllAlarmProfiles(token).then((response) => {
            if (response.data) setData(response.data.map(el => ({ id: el._id, ...el })));
            else setData([]);
        }).catch(e => {
            console.error(e);
            setData({ error: e });
        });
    }, [token, reset]);

    const handleCopy = useCallback((profile) => {
        setSelected(profile);
        setCopy(true);
        setOpenForm(true);
    }, []);

    const handleEdit = useCallback((profile) => {
        setSelected(profile);
        setOpenForm(true);
    }, []);

    const handleEnableDisable = useCallback((id, flag) => {
        const updateAlarm = API.alarmProfile[flag ? 'disableAlarmProfile' : 'enableAlarmProfile'];
        updateAlarm(id, token).then(() => {
            setAlert({ open: true, messageId: "SUCCESS." + (flag ? "DISABLE" : "ENABLE"), severity: "success" });
            setReset(reset => reset + 1)
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_UPDATED", severity: "success" });
            console.error(error)
        });
    }, [token]);

    const handleDelete = useCallback((id) => {
        API.alarmProfile.deleteAlarmProfile(id, token).then(() => {
            setAlert({ open: true, messageId: "SUCCESS.DELETE", severity: "success" });
            setReset(reset => reset + 1);
            setOpenDialog(false);
            setSelected({});
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_DELETED", severity: "success" });
            console.error(error);
            setOpenDialog(false);
            setSelected({});
        });
    }, [token]);

    const renderTable = useCallback(() => {
        const headers = [
            {
                field: 'name',
                headerName: intl.formatMessage({ id: 'NAME' }),
                renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
                editable: true,
                ...(!smallScreen && { flex: 1 }),
                minWidth: 300
            },
            {
                field: 'entityId',
                headerName: intl.formatMessage({ id: 'ENTITY' }),
                valueGetter: (row) => allEntities[row.row.entityId]?.name,
                renderCell: ({ value }) => <Typography>{value}</Typography>,
                ...(!smallScreen && { flex: 1 }),
                minWidth: 150
            },
            {
                field: 'rules',
                headerName: intl.formatMessage({ id: 'ALARMS.PROFILE_RULES' }),
                type: 'array',
                valueGetter: ({ row }) => row?.rules ? Object.keys(row.rules) : "undefined",
                renderCell: ({ row, value }) => <Box sx={{ width: 300, overflowX: 'auto' }}>
                    {value !== "undefined"
                        ? <Typography component="span">
                            {Object.keys(row.rules).map(el => <Chip key={el} component="span" variant="outlined" size="small" sx={{ mr: 0.5, '& .MuiChip-label': { pl: 0 } }} avatar={<AlarmSeverityIcon severity={el} customStyle={{ width: 20, height: 20, py: 0.5 }} />} label={intl.formatMessage({ id: "ALARMS.PROFILE_RULES." + el })} />)}
                        </Typography>
                        : <Chip size="small" variant="outlined" label={intl.formatMessage({ id: value })} />
                    }
                </Box>,
                width: 300,
                flex: 0
            },
            {
                field: 'enabled',
                headerName: intl.formatMessage({ id: 'ENABLED' }),
                type: 'boolean',
                minWidth: 100,
                flex: 0
            },
            {
                field: 'actions',
                headerName: intl.formatMessage({ id: 'ACTIONS' }),
                type: 'actions',
                getActions: (el) => (createAlarmProfile || updateAlarmProfile || deleteAlarmProfile) ?
                    [
                        <>
                            {createAlarmProfile && <Tooltip title={<FormattedMessage id="COPY" />} placement="bottom" arrow>
                                <span>
                                    <IconButton color="primary" key="edit" onClick={() => handleCopy(el.row)}><CopyAllOutlined fontSize="small" /> </IconButton>
                                </span>
                            </Tooltip>}
                            {updateAlarmProfile && <Tooltip title={<FormattedMessage id="EDIT" />} placement="bottom" arrow>
                                <span>
                                    <IconButton color="secondary" key="edit" onClick={() => handleEdit(el.row)}><EditOutlined fontSize="small" /> </IconButton>
                                </span>
                            </Tooltip>}
                            {updateAlarmProfile && <Tooltip title={<FormattedMessage id={el.row.enabled ? "DISABLE" : "ENABLE"} />} placement="bottom" arrow>
                                <span>
                                    <IconButton color={el.row.enabled ? "warning" : "success"} key="update" onClick={() => handleEnableDisable(el.id, el.row.enabled)}>{el.row.enabled ? <DoNotDisturbOnOutlined fontSize='small' /> : <DoNotDisturbOffOutlined fontSize='small' />}</IconButton>
                                </span>
                            </Tooltip>}
                            {deleteAlarmProfile && <Tooltip title={<FormattedMessage id="DELETE" />} placement="bottom" arrow>
                                <span>
                                    <IconButton color="error" key="delete" onClick={() => { setSelected(el.row); setOpenDialog(true); }}><DeleteOutlined fontSize="small" /></IconButton>
                                </span>
                            </Tooltip>}
                        </>
                    ]
                    :
                    [smallScreen ? <IconButton key="disabled-actions" disabled><CancelOutlined fontSize="small" /></IconButton> : <Button key="disabled-actions" disabled><FormattedMessage id="DISABLED" /></Button>]
                ,
                minWidth: 200
            },
        ];

        if (data?.error) return <Alert severity='error'><FormattedMessage id="ERROR.NO_DATA" /></Alert>;
        else if (data) return <CustomDataGrid rows={data} columns={headers} handleData={({ row }) => { setSelected(row); setOpenDrawer(true); }} />;
        else return <LoadingData />;

    }, [data, allEntities, smallScreen, intl, createAlarmProfile, updateAlarmProfile, deleteAlarmProfile, handleCopy, handleEdit, handleEnableDisable]);

    return <Card sx={{ width: '100%' }}>
        <AlarmProfileForm open={openForm} onClose={() => { setSelected({}); setOpenForm(false); setCopy(false); }} profile={selected} copyFlag={copy} setReset={setReset} />
        <ConfirmationDialog
            fullWidth
            open={openDialog && Boolean(selected)}
            title={<FormattedMessage id="ALARMS.PROFILES.CONFIRM_DELETE" />}
            content={<ProfileDeleteDialogContent profile={selected} />}
            handleCancel={() => { setOpenDialog(false); setSelected({}); }}
            handleCustomButton={() => handleDelete(selected.id)}
            customButtonTitle={<FormattedMessage id="DELETE" />}
            customButtonColor='error' />
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <AlarmDetailsDialog open={openDrawer} onClose={() => { setOpenDrawer(!openDrawer); setSelected({}) }} alarmProfile={selected} />
        <CardHeader title={<FormattedMessage id="ALARMS_OVERVIEW" />} action={createAlarmProfile && <Chip variant={data?.length ? "outlined" : "filled"} icon={<AddOutlined color="primary" />} label={<FormattedMessage id="ALARMS.PROFILES.ADD" />} onClick={() => setOpenForm(true)} />} />
        <CardActions sx={{ px: 2 }}>
            <ButtonGroup size="small">
                {viewAlarmsPermission && <Button onClick={() => navigate('/alarms')}><FormattedMessage id="TRIGGERED" /></Button>}
                {viewAlarmProfilesPermission && <Button variant="contained" color="primary"><FormattedMessage id="PROFILES" /></Button>}
            </ButtonGroup>
        </CardActions>
        <CardContent>
            {renderTable()}
        </CardContent>
    </Card>;
}