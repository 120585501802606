import { RefreshOutlined } from "@mui/icons-material";
import { Alert, Button } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { updateStatus } from "redux/usersAndGroupsSlice";



export default function UserGroupFetchAlert({ variant }) {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);

    const handleClick = () => {
        dispatch(updateStatus("failed"));
        setDisabled(true);
        setTimeout(() => setDisabled(false), 1000);
    }

    return <Alert severity="error" variant={variant} action={<Button size="small" startIcon={<RefreshOutlined fontSize="small" />} disabled={disabled} color="error" onClick={handleClick}><FormattedMessage id="RELOAD_DATA" /></Button>}><FormattedMessage id="ERROR.FETCHING_USERS_DATA" /></Alert>
}