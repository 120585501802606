import { configureStore } from '@reduxjs/toolkit';
import appReducer from './appSlice';
import configurationReducer from './configurationSlice';
import dateRangeReducer from './dateRangeSlice';
import entityReducer from './entitySlice';
import mapReducer from './mapSlice';
import topicReducer from './topicSlice';
import userReducer from './userSlice';
import usersAndGroupsReduces from './usersAndGroupsSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    configuration: configurationReducer,
    dateRange: dateRangeReducer,
    entities: entityReducer,
    map: mapReducer,
    topic: topicReducer,
    user: userReducer,
    usersAndGroups: usersAndGroupsReduces,
  },
  devTools: Boolean(process.env.REACT_APP_DEVTOOLS),
});