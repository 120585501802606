import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectEntities } from 'redux/entitySlice';
import { selectNotificationMethodTypes, selectNoteTypes } from 'redux/configurationSlice';
import { Autocomplete, Checkbox, FormControl, FormHelperText, FormLabel, MenuItem, Stack, TextField } from '@mui/material';
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { enGB, hr } from "date-fns/locale";
import { AssignOwnerField, DescriptionField } from 'components';

const NoteFormContent = ({ selectedEntity, setSelectedEntity, title, setTitle, content, setContent, owner, setOwner, selectedNotificationTypes, setNotificationTypes, noteType, setNoteType, noteDate, setNoteDate, contacts, setContacts }) => {
    const entities = useSelector(selectEntities);
    const entitiesList = Object.keys(entities).map(ent => entities[ent]);
    const intl = useIntl();
    const notificationMethodTypes = useSelector(selectNotificationMethodTypes);
    const noteTypes = useSelector(selectNoteTypes);

    return <Stack direction="column" spacing={1}>
        <FormControl>
            <FormLabel required><FormattedMessage id="ENTITY" /></FormLabel>
            <Autocomplete
                id="select-entity"
                options={entitiesList}
                getOptionLabel={(entity) => entity ? entity.name : ""}
                value={selectedEntity}
                onChange={(e, entity) => setSelectedEntity(entity)}
                renderInput={(params) => (
                    <TextField {...params} key={entitiesList.map(entity => entity._id)} />
                )}
                size="small"
                fullWidth
            />
        </FormControl>
        <FormControl>
            <FormLabel required><FormattedMessage id="TITLE" /></FormLabel>
            <TextField
                required
                fullWidth
                variant="outlined"
                id="TITLE"
                type="text"
                size="small"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />
        </FormControl>
        <DescriptionField description={content} onChange={setContent} required />
        <FormControl >
            <FormLabel required ><FormattedMessage id="DATE" /></FormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                <DateTimePicker
                    value={new Date(noteDate)}
                    onChange={(date) => setNoteDate(date)}
                    slotProps={{ textField: { size: 'small' } }}
                />
            </LocalizationProvider>
            {new Date(noteDate).toString() === "Invalid Date" && <FormHelperText error><FormattedMessage id="INVALID_DATE" /></FormHelperText>}
        </FormControl>
        <FormControl>
            <FormLabel required><FormattedMessage id="NOTIFICATIONS.METHOD" /></FormLabel>
            <Autocomplete
                id="select-notification"
                multiple
                options={notificationMethodTypes.map(type => type.key)}
                getOptionLabel={option => intl.formatMessage({ id: "NOTIFICATIONS.METHOD." + notificationMethodTypes.find(type => type.key === option).value })}
                value={selectedNotificationTypes}
                onChange={(e, type) => setNotificationTypes(type)}
                renderInput={(params) => (
                    <TextField {...params} key={entitiesList.map(entity => entity._id)} />
                )}
                size="small"
                fullWidth
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckBoxOutlined fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {intl.formatMessage({ id: "NOTIFICATIONS.METHOD." + notificationMethodTypes.find(type => type.key === option).value })}
                    </li>
                )}
            />
        </FormControl>
        <FormControl required>
            <FormLabel><FormattedMessage id="NOTE.TYPE" /></FormLabel>
            <TextField
                select
                size="small"
                value={noteType}
                onChange={(e) => setNoteType(e.target.value)}>
                {noteTypes.map(type => <MenuItem key={type.key} value={type.key}><FormattedMessage id={"NOTE.TYPE." + type.value} /></MenuItem>)}
            </TextField>
        </FormControl>

        <AssignOwnerField owner={owner} onChange={(value) => setOwner(value)} />
        
        <AssignOwnerField multiple participants={contacts} onChange={(data) => setContacts(data)} contacts />

    </Stack>
}

export default NoteFormContent;