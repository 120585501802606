import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { selectMeasurementsTypesConf, selectCollectorTypesConf, } from 'redux/configurationSlice';
import { selectMeasureEntities } from 'redux/entitySlice';
import { UnitSelectorField } from "components"
import { Autocomplete, FormLabel, TextField, MenuItem, Stack, FormControl, Typography, Chip, Collapse, } from '@mui/material';
import { FilterAltOffOutlined, FilterAltOutlined, WarningOutlined } from '@mui/icons-material';

const MeasurementsSection = ({ setEditMode, allEntitiesFlag, existingMetric, measurementType, setMeasurementType, categoryType, setCategoryType, entityId, selectedEntity, sourceType, setSourceType, collectorName, setCollectorName, unit, setUnit, calculationMethod, setCalculationMethod, reportUnit, setReportUnit }) => {
    const measureEntities = useSelector(selectMeasureEntities);
    const measurementTypes = useSelector(selectMeasurementsTypesConf);
    const [useAllTypes, setUseAllTypes] = useState(false);
    const sourceTypes = useSelector(selectCollectorTypesConf);
    const selectedEntityMeasurementTypes = measureEntities[entityId || selectedEntity[0]?._id]?.measurementTypes
    const intl = useIntl();
    const calculationMethods = ['sum', 'avg', 'min', 'max', 'last', 'count'];
    const checkIncludes = (measurementType, measureEntityMeasurementTypes) => {
        if (allEntitiesFlag) return true;
        if (!measureEntityMeasurementTypes) return false;
        const measureEntityCondition = measureEntityMeasurementTypes.service.OWM.includes(measurementType.key) || measureEntityMeasurementTypes.manual.types.includes(measurementType.key)
            || (measureEntityMeasurementTypes?.sensor && Object.keys(measureEntityMeasurementTypes.sensor).includes(measurementType.key));

        return measureEntityCondition;
    }

    useEffect(() => {
        setUseAllTypes(selectedEntity.length > 1);
    }, [selectedEntity.length])

    const notConfiguredType = selectedEntity.length === 1 && !allEntitiesFlag && measurementType && !checkIncludes(measurementType, selectedEntityMeasurementTypes);

    const quantitativeMetric = categoryType === process.env.REACT_APP_ESG_METRIC_DEFAULT_TYPE;

    return <Stack direction="column" spacing={2} sx={{ mb: 2 }}>
        <FormControl>
            <FormLabel ><FormattedMessage id="ESG.METRIC.TYPE" /></FormLabel>
            <TextField
                disabled={Boolean(existingMetric)}
                select
                size="small"
                value={categoryType}
                onChange={(e) => { setCategoryType(e.target.value); setEditMode(true) }}
            >
                {[process.env.REACT_APP_ESG_METRIC_DEFAULT_TYPE, "Qualitative"].map(type => <MenuItem key={type} value={type}><FormattedMessage id={"ESG.METRIC.TYPE." + type.toUpperCase()} /></MenuItem>)}
            </TextField>
        </FormControl>
        <Collapse in={quantitativeMetric}>
            <Stack direction="column" spacing={2}>
                <FormControl>
                    <FormLabel ><FormattedMessage id="SOURCE_TYPE" /></FormLabel>
                    <TextField
                        select
                        size="small"
                        value={sourceType ?? ''}
                        onChange={(e) => { setSourceType(e.target.value); setEditMode(true) }}
                    >
                        {sourceTypes.map(type => <MenuItem key={type.key} value={type.key}><FormattedMessage id={type.value + '_TYPE'} /></MenuItem>)}
                    </TextField>
                </FormControl>
                <FormControl>
                    <FormLabel sx={{ color: notConfiguredType ? 'warning.main' : 'default' }} required ><FormattedMessage id="MEASUREMENT_TYPE" /></FormLabel>
                    <Autocomplete
                        id="select-measurement-type"
                        options={allEntitiesFlag || useAllTypes ? measurementTypes : measurementTypes.filter(type => checkIncludes(type, selectedEntityMeasurementTypes) || (measurementType && type.key === measurementType.key))}
                        getOptionLabel={(measurementType) => measurementType ? (measurementType.name || intl.formatMessage({ id: measurementType.key })) : ""}
                        value={measurementType}
                        onChange={(e, measurementType) => {
                            setReportUnit({
                                prefix: "",
                                unit: "",
                                suffix: ""
                            });
                            setUnit(measurementType ? (measurementType.dataType.measure ? "" : null) : "");
                            setMeasurementType(measurementType);
                            setEditMode(true)
                        }}
                        renderInput={(params) => (
                            <TextField {...params} {...(notConfiguredType && { color: 'warning', focused: true })} />
                        )}
                        renderOption={(props, option, { selected }) => {
                            const includes = selectedEntity.length > 1 || checkIncludes(option, selectedEntityMeasurementTypes);
                            return <li {...props}>
                                {!includes && <WarningOutlined fontSize='small' sx={{ mr: 0.5 }} color="warning" />}
                                {option.name || intl.formatMessage({ id: option.key })}
                            </li>
                        }}
                        isOptionEqualToValue={(option, value) => value === "" || option.key === value.key}
                        size="small"
                        fullWidth
                    />
                    {!(allEntitiesFlag || selectedEntity.length > 1) && <Stack direction="row" spacing={0.5} sx={{ pt: 0.5 }} alignItems='center'>
                        <Chip color={useAllTypes ? "warning" : "default"} size="small" label={<FormattedMessage id={"MEASUREMENT_TYPES." + (useAllTypes ? "VIEW_ALL" : "VIEW_ENTITYS")} />} icon={useAllTypes ? <FilterAltOffOutlined fontSize='small' /> : <FilterAltOutlined fontSize='small' />} onClick={() => setUseAllTypes(!useAllTypes)} />
                        {notConfiguredType && <Typography color="warning.main" variant="caption">
                            <FormattedMessage id="MEASUREMENTS.TYPE_ENTITY_WARNING" />
                        </Typography>}
                    </Stack>}
                </FormControl>

                <UnitSelectorField unit={unit} setUnit={setUnit} measurementType={measurementType} setEditMode={setEditMode} required={!reportUnit.unit?.name && calculationMethod !== "count"} />

                <FormControl >
                    <FormLabel required><FormattedMessage id="CALCULATION_METHOD" /></FormLabel>
                    <TextField
                        select
                        disabled={Boolean(existingMetric)}
                        size="small"
                        value={calculationMethod}
                        onChange={(e) => { setCalculationMethod(e.target.value); setEditMode(true) }}
                    >
                        {calculationMethods.map((method, index) => <MenuItem key={index} value={method}><FormattedMessage id={"CALCULATION_METHOD." + method} /></MenuItem>)}
                    </TextField>
                </FormControl>
                <FormControl>
                    <FormLabel ><FormattedMessage id="COLLECTOR" /></FormLabel>
                    <TextField
                        variant="outlined"
                        placeholder={intl.formatMessage({ id: "COLLECTOR_NAME" })}
                        type="text"
                        size="small"
                        value={collectorName}
                        onChange={(e) => { setCollectorName(e.target.value); setEditMode(true) }}
                    />
                </FormControl>
            </Stack>
        </Collapse>
    </Stack>


}

export default MeasurementsSection;