import { Box, LinearProgress, Typography } from "@mui/material";



export default function LinearProgressWithLabel(props) {
    const { min, max, value, label } = props;
    const percentage = value && min !== max ? ((value - min) / (max - min)) * 100 : 0;
    const progressColor = percentage >= 100 ? "success"
        : percentage < 0 ? "error"
            : percentage === 0 ? "warning" : "primary";

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {Boolean(label) && <Typography variant="caption" color="text.secondary" sx={{ mr: 0.5 }}>{label}:</Typography>}
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} value={percentage ? percentage : 0} color={progressColor} sx={{ bgcolor: percentage >= 100 ? 'success.main' : 'default' }} />
            </Box>
            <Typography variant="body2" color="text.secondary">{`${Math.round(percentage)}%`}</Typography>
        </Box>
    );
}