import instance from "./axiosEnv";
import { setAuthHeader } from "./axiosEnv";

const getAllMeasurementTypes = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/measurementType', config);
}

const createMeasurementType = (data, token) => {
    const config = setAuthHeader(token);
    return instance.post('/measurementType', data, config);
}

const updateMeasurementType = (data, token, measurementTypeId) => {
    const config = setAuthHeader(token);
    return instance.put('/measurementType/' + measurementTypeId, data, config);
}

const deleteMeasurementType = (token, measurementTypeId) => {
    const config = setAuthHeader(token);
    return instance.delete('/measurementType/' + measurementTypeId, config);
}

const measurementTypesAPI = {
    getAllMeasurementTypes,
    createMeasurementType,
    updateMeasurementType,
    deleteMeasurementType,
};

export default measurementTypesAPI;