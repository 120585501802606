import { Box, List, ListItem, ListItemIcon, ListItemText, Paper, Popper, Typography } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';

function isOverflown(element) {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}

export default React.memo(function ExpandedGridCell(props) {
    const { width, value, list } = props;
    const wrapper = useRef(null);
    const cellDiv = useRef(null);
    const cellValue = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showFullCell, setShowFullCell] = useState(false);
    const [showPopper, setShowPopper] = useState(false);

    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    return (
        <Box
            ref={wrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                alignItems: 'center',
                lineHeight: '24px',
                width: 1,
                height: 1,
                position: 'relative',
                display: 'flex',
            }}
        >
            <Box
                ref={cellDiv}
                sx={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            <Box
                ref={cellValue}
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
                {!list ? value : value?.join(", ")}
            </Box>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17, wordBreak: 'break-word' }}
                >
                    <Paper
                        elevation={10}
                        style={{ minHeight: wrapper.current.offsetHeight - 3, maxHeight: 150, overflowY: 'auto' }}
                    >

                        {!list
                            ? <Typography variant={props.textVariant ? props.textVariant : "body2"} style={{ padding: 8 }}>
                                {value}
                            </Typography>
                            : <List>
                                {value?.map((el, i) => <ListItem disablePadding key={el}>
                                    <ListItemIcon sx={{ pl: 2.5 }}>{i + 1}.</ListItemIcon><ListItemText primary={<Typography component="span" variant={props.textVariant ? props.textVariant : "body2"}>{el}</Typography>} />
                                </ListItem>)}
                            </List>}
                    </Paper>
                </Popper>
            )}
        </Box>
    );
});