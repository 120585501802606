import { DefaultLayout } from 'layouts';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { SplashPage } from "pages";
import { EmptyState } from 'layouts';
import { TopicsView } from 'components';


const TopicsPage = ({ file }) => {
    return <EmptyState component={<TopicsView />} />;
}

const TopicsPageComponent = ({ file }) => {
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn) return <DefaultLayout page={<TopicsPage file={file} />} />;
    else return <SplashPage />;
};

export default TopicsPageComponent;