import { DefaultLayout } from 'layouts';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { SplashPage } from "pages";
import { EmptyState } from 'layouts';
import { useParams } from 'react-router';
import { ActivitiesView } from 'components';
import { selectEntities } from 'redux/entitySlice';


const EntityActivitiesPage = ({ entityId }) => {
    return <EmptyState component={<ActivitiesView entityId={entityId} />} />;
}

const EntityActivitiesPageComponent = () => {
    const entityId = useParams().entityId;
    const { isSuccess: loggedIn } = useSelector(userSelector);
    const entities = useSelector(selectEntities);
    const viewActivityPermission = useSelector((state) => permissionSelector(state, 'view-activity'));

    if (loggedIn && entities[entityId] && viewActivityPermission) return <DefaultLayout page={<EntityActivitiesPage entityId={entityId} />} />;
    else return <SplashPage />;
};

export default EntityActivitiesPageComponent;