import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router"
import { selectEntities } from "redux/entitySlice";
import { userSelector, permissionSelector } from "redux/userSlice";
import { selectDateRange } from 'redux/dateRangeSlice';
import { selectSeverityTypes } from 'redux/configurationSlice';
import { AlertSnackbar, CustomDataGrid, LoadingData, AlarmFilters, AlarmButtons, AlarmSeverityIcon, ExpandedGridCell, AlarmDetailsDialog } from "components";
import { renderDateCell } from "components/dashboardView/TableHeaders";
import { Stack, Alert, Button, Card, CardContent, CardHeader, useMediaQuery, Typography, ButtonGroup, CardActions } from "@mui/material";
import API from "api";

const AlarmsView = (props) => {

    const { token } = useSelector(userSelector);
    const severityTypes = useSelector(selectSeverityTypes);
    const entities = useSelector(selectEntities);
    const [alert, setAlert] = useState({ open: false });

    const [alarms, setAlarms] = useState([]);
    const [alarm, setAlarm] = useState({});

    const selectedDateRange = useSelector(selectDateRange);
    const [severity, setSeverity] = useState();
    const [active, setActive] = useState(false);
    const [acknowledged, setAcknowledged] = useState(false);
    const [cleared, setCleared] = useState(false);

    const [disableActions, setDisableActions] = useState(false);

    const [reset, setReset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);

    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const intl = useIntl();
    const navigate = useNavigate();

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const ackAlarm = useSelector((state) => permissionSelector(state, 'acknowledge-alarms'));
    const clrAlarm = useSelector((state) => permissionSelector(state, 'clear-alarms'));
    const viewAlarmsPermission = useSelector((state) => permissionSelector(state, 'view-alarms'));
    const viewAlarmProfilesPermission = useSelector((state) => permissionSelector(state, 'view-alarmProfiles'));

    useEffect(() => {
        if (!viewAlarmsPermission && viewAlarmProfilesPermission) navigate('/alarmProfiles');
    }, [viewAlarmsPermission, viewAlarmProfilesPermission, navigate]);

    useEffect(() => {
        if (!viewAlarmsPermission) return;
        
        setLoading(true);
        API.alarm.getAllAlarms(token, selectedDateRange.dateFrom, selectedDateRange.dateTo, severity, acknowledged, cleared).then(items => {
            if (items && items.data) {
                const data = items.data.map(item => {
                    const severity = severityTypes.find(el => el.key === item.severityType).value;
                    return {
                        ...item,
                        id: item._id,
                        name: item.name,
                        entity: entities[item.entityId]?.name,
                        severityKey: severity,
                        severity: intl.formatMessage({ id: "ALARMS.PROFILE_RULES." + severity }),
                        timestamp: new Date(item.triggeredTime),
                        acknowledgedBy: item.acknowledgedBy,
                        clearedBy: item.clearedBy
                    }
                })
                setAlarms(data);
                setAlarm(alarm => data.find(el => el.id === alarm.id) || {});
            }
            else setAlarms([]);
            setLoading(false);
            setDisableActions(false);
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
            setLoading(false);
            setDisableActions(false);
            setAlarms({ error });
            console.error(error);
        });

        return () => {
            setReloadData(false);
        }
    }, [intl, token, reloadData, selectedDateRange, severity, acknowledged, cleared, entities, severityTypes, viewAlarmsPermission, reset]);

    const handleAcknowledgeAlarm = useCallback((alarmId) => {
        setDisableActions(true);
        API.alarm.acknowledgeAlarm(alarmId, token).then(response => {
            if (response?.data) {
                setAlert({ open: true, messageId: "ALARM.ACKNOWLEDGE_SUCCESS", severity: "success" });
                setReset(reset => reset + 1)
            }
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_ACKNOWLEDGED", severity: "error" });
            console.error(error);
            setDisableActions(false);
        });
    }, [token])

    const handleClearAlarm = useCallback((alarmId) => {
        setDisableActions(true);
        API.alarm.clearAlarm(alarmId, token).then(response => {
            if (response?.data) {
                setAlert({ open: true, messageId: "ALARM.CLEAR_SUCCESS", severity: "success" });
                setReset(reset => reset + 1)
            }
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_CLEARED", severity: "error" });
            console.error(error);
            setDisableActions(false);
        });
    }, [token]);

    const headers = [
        {
            field: 'name',
            headerName: intl.formatMessage({ id: 'NAME' }),
            ...(!smallScreen && { flex: 1 }),
            renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
            minWidth: 200
        },
        {
            field: 'acknowledged',
            headerName: intl.formatMessage({ id: 'ACKNOWLEDGED' }),
            type: 'boolean',
            valueGetter: (el) => Boolean(el.row.acknowledgedBy),
            minWidth: 100,
            flex: 1
        },
        {
            field: 'severity',
            headerName: intl.formatMessage({ id: 'SEVERITY' }),
            ...(!smallScreen && { flex: 1 }),
            renderCell: ({ row, value }) => <Typography> <AlarmSeverityIcon severity={row.severityKey} /> {value} </Typography>,
            minWidth: 200
        },
        {
            field: 'entity',
            headerName: intl.formatMessage({ id: 'ENTITY' }),
            ...(!smallScreen && { flex: 1 }),
            renderCell: ({ value }) => <Typography> {value} </Typography>,
            minWidth: 200
        },
        {
            field: 'timestamp',
            type: 'dateTime',
            headerName: intl.formatMessage({ id: 'TRIGGERED_TIME' }),
            valueGetter: (row) => new Date(row.value),
            ...renderDateCell(intl),
            ...(!smallScreen && { flex: 1 }),
            minWidth: 200
        },
        {
            field: 'actions',
            headerName: intl.formatMessage({ id: 'ACTIONS' }),
            type: 'actions',
            getActions: (el) => [
                <AlarmButtons
                    alarm={el.row}
                    smallScreen={true}
                    handleAcknowledgeAlarm={() => handleAcknowledgeAlarm(el.id)}
                    handleClearAlarm={() => handleClearAlarm(el.id)}
                    disableAcknowledge={!ackAlarm || disableActions || el.row.acknowledgedBy}
                    disableClear={!clrAlarm || disableActions || el.row.clearedBy}
                />
            ],
            minWidth: 200
        }
    ];

    return (
        <>
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
            <AlarmDetailsDialog
                open={openDrawer}
                onClose={() => { setOpenDrawer(!openDrawer); setAlarm({}) }}
                alarm={alarm}
                handleAcknowledgeAlarm={() => { handleAcknowledgeAlarm(alarm.id); setOpenDrawer(false) }}
                handleClearAlarm={() => { handleClearAlarm(alarm.id); setOpenDrawer(false) }}
                disableActions={disableActions}
            />
            <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
                <Card>
                    <CardHeader title={<FormattedMessage id="ALARMS_OVERVIEW" />} />
                    <CardActions sx={{ px: 2 }}>
                        <ButtonGroup size="small">
                            {viewAlarmsPermission && <Button variant="contained" color="primary"><FormattedMessage id="TRIGGERED" /></Button>}
                            {viewAlarmProfilesPermission && <Button onClick={() => navigate('/alarmProfiles')}><FormattedMessage id="PROFILES" /></Button>}
                        </ButtonGroup>
                    </CardActions>
                    <CardContent>
                        <AlarmFilters
                            severity={severity}
                            active={active}
                            acknowledged={acknowledged}
                            cleared={cleared}
                            setSeverity={setSeverity}
                            setActive={setActive}
                            setAcknowledged={setAcknowledged}
                            setCleared={setCleared}
                        />
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        {loading ? <LoadingData />
                            : !alarms?.error ? <CustomDataGrid rows={alarms.filter(alarm => active ? !(alarm.acknowledgedBy || alarm.clearedBy) : true)} columns={headers} handleData={(el) => { setAlarm(el.row); setOpenDrawer(true) }} />
                                : <Alert severity='error'><FormattedMessage id="ERROR.NO_DATA" /></Alert>
                        }
                    </CardContent>
                </Card>
            </Stack>
        </>
    )
}
export default AlarmsView;