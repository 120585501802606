import { InfoOutlined } from "@mui/icons-material";
import { Autocomplete, Button, CardHeader, Dialog, DialogActions, DialogContent, Divider, Typography, IconButton, Stack, TextField, useMediaQuery, InputAdornment } from "@mui/material";
import { ActivityDetails, AlertSnackbar } from "components";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectEntities } from "redux/entitySlice";
import { userSelector } from "redux/userSlice";
import { selectSectorsConfiguration } from "redux/configurationSlice";
import { GroupHeader, GroupItems } from "components/unitSelectorField/UnitSelectorField";
import { useNavigate } from "react-router";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB, hr } from "date-fns/locale";
import API from 'api';


export default function ActivityAssignForm({ open, onClose, activityId, entityId, AlertCall, onReset, selectedDate }) {
    const intl = useIntl();
    const navigate = useNavigate();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const { token } = useSelector(userSelector);
    const entities = useSelector(selectEntities);
    const sectorConf = useSelector(selectSectorsConfiguration);
    const [activities, setActivities] = useState([]);
    const [openInfo, setOpenInfo] = useState(false);
    const [selected, setSelected] = useState(null);
    const [selectedSector, setSelectedSector] = useState(null);
    const [localEntity, setEntity] = useState(null);
    const [localActivity, setActivity] = useState(null);
    const [date, setDate] = useState(new Date())
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const ENTITY = entities[entityId];
    const ACTIVITY = activities.find(el => el._id === activityId);

    useEffect(() => {
        if (selectedDate) setDate(selectedDate);
    }, [selectedDate]);

    useEffect(() => {
        if (open) API.activity.getAllActivities(token).then(res => {
            if (res.data) setActivities(res.data.map(el => {
                const sector = sectorConf.find(sector => sector.key === el.sectorKey);
                return {
                    id: el._id,
                    ...el,
                    nameKey: intl.formatMessage({ id: el.name }),
                    sectorValue: sector ? intl.formatMessage({ id: sector.value }) : null,
                    objectiveArray: el.objectives.map(obj => intl.formatMessage({ id: obj.objectiveKey })).join(", ")
                };
            }));
        }).catch(error => {
            console.error(error);
        });
    }, [open, token, intl, sectorConf, entityId]);

    const renderSubheader = () => {
        if (ENTITY) return <Typography variant={smallScreen ? "caption" : "inherit"} color="text.secondary">
            {ENTITY?.name}
            {localActivity && <Typography variant="caption">
                <br />
                {localActivity.nameKey}
            </Typography>}
        </Typography>;
        else if (ACTIVITY) return <Typography variant={smallScreen ? "caption" : "inherit"} color="text.secondary">
            {ACTIVITY.nameKey}
            {localEntity && <Typography variant="caption">
                <br />
                {localEntity.name}
            </Typography>}
        </Typography>
        else return null;
    }

    const handleClose = () => {
        if (localEntity) setEntity(null);
        if (localActivity) setActivity(null);
        setSelectedSector(null);
        onClose();
    }

    const handleSubmit = () => {
        const eId = localEntity ? localEntity._id : entityId;
        const aId = localActivity ? localActivity._id : activityId;
        if (eId && aId) API.activity.postEntityActivity(token, eId, aId, date.getFullYear()).then(res => {
            setEntity(null);
            setActivity(null);
            AlertCall({ open: true, messageId: "SUCCESS.ASSIGN", severity: "success", ...(activityId && { action: <Button color="success" size="small" onClick={() => navigate(eId)}><FormattedMessage id="VIEW" /></Button> }) });
            if (onReset && typeof onReset === 'function') onReset();
            handleClose();
        }).catch(error => {
            console.error(error);
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_ASSIGNED", severity: "error" });
        });
    }

    return <Dialog open={open} fullScreen={smallScreen} maxWidth="sm" fullWidth>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <CardHeader title={<FormattedMessage id="ACTIVITY.ASSIGN" />}
            subheader={renderSubheader()}
            action={[
                localActivity ? <IconButton key="info" onClick={() => { setSelected(localActivity); setOpenInfo(true); }} color="info"><InfoOutlined /></IconButton> : null,
            ]}
        />
        <Divider />
        <DialogContent>
            <Stack gap={1.5}>
                {entityId !== undefined && <Stack gap={1.5}>
                    <Autocomplete
                        id="set-sector"
                        options={sectorConf}
                        getOptionLabel={(option) => intl.formatMessage({ id: option.value })}
                        value={selectedSector}
                        onChange={(e, value) => {
                            setSelectedSector(value);
                            if (value && (value.key !== localActivity?.sectorKey)) setActivity(null);
                        }}
                        renderInput={(params) => <TextField {...params} label={intl.formatMessage({ id: "SECTOR" })} />}
                        isOptionEqualToValue={(opt, val) => val === null || opt.key === val.key}
                        size="small"
                        fullWidth
                    />

                    <Autocomplete
                        id="set-activity"
                        options={activities.sort((a, b) => a.reference - b.reference).filter(el => selectedSector !== null ? el.sectorKey === selectedSector.key : true)}
                        getOptionLabel={(option) => option.nameKey}
                        value={localActivity}
                        groupBy={(option) => option.sectorValue}
                        renderGroup={(params) => (<li key={params.key}>
                            {!selectedSector && <GroupHeader>{params.group}</GroupHeader>}
                            <GroupItems>{params.children}</GroupItems>
                        </li>
                        )}
                        onChange={(e, value) => {
                            setActivity(value);
                            if (value && !selectedSector) setSelectedSector(sectorConf.find(el => el.key === value.sectorKey))
                        }}
                        renderInput={(params) => <TextField {...params} label={intl.formatMessage({ id: "ACTIVITY" })} required />}
                        isOptionEqualToValue={(opt, val) => val === null || opt.name === val.name}
                        size="small"
                        fullWidth
                    />
                </Stack>}
                {activityId !== undefined && <Autocomplete
                    id="set-entity"
                    options={Object.values(entities)}
                    getOptionLabel={(option) => option.name}
                    value={localEntity}
                    onChange={(e, value) => setEntity(value)}
                    renderInput={(params) => <TextField {...params} label={intl.formatMessage({ id: "ENTITY" })} />}
                    isOptionEqualToValue={(opt, val) => val === null || opt.name === val.name}
                    size="small"
                    fullWidth
                />}
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                    <DatePicker slotProps={{ textField: { size: "small", fullWidth: true, InputProps: { startAdornment: <InputAdornment position="start"><FormattedMessage id="DATE.PREFIX.ACTIVITY_ASSIGN" /></InputAdornment> } } }} value={date} onChange={(val) => setDate(val)} minDate={new Date("2020")} views={['year']} />
                </LocalizationProvider>
            </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
            <Button onClick={handleClose} color="warning"><FormattedMessage id="CANCEL" /></Button>
            <Button onClick={handleSubmit} disabled={!((activityId !== undefined && localEntity !== null) || (entityId !== undefined && localActivity !== null))}><FormattedMessage id="SUBMIT" /></Button>
        </DialogActions>
        {localActivity && <ActivityDetails noAction open={openInfo} onClose={() => { setSelected(null); setOpenInfo(false); }} activity={selected} />}
    </Dialog>;
}