import instance, { setAuthHeader } from "./axiosEnv";

const getTypesConfiguration = () => {
    return instance.get('/configuration');
}

const getEntitiesConfiguration = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/configuration/entities', config);
}

const getUserConfiguration = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/configuration/user', config);
}

const getNotifications = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/notifications/missedNotifications', config);
}

const configurationAPI = {
    getTypesConfiguration,
    getEntitiesConfiguration,
    getUserConfiguration,
    getNotifications,
}

export default configurationAPI;