import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { userSelector } from 'redux/userSlice';
import { updateMetricInTopic } from 'redux/topicSlice';
import { ComplexDescriptiveSection, ComplexMeasurementsSection, ReportSection, DialogFormTitle } from "components";
import { Button, Dialog, DialogActions, DialogContent, Divider, Typography, useMediaQuery, } from '@mui/material';
import { selectEntities } from 'redux/entitySlice';
import { localISOString } from "utils";
import API from 'api';

const ComplexMetricForm = ({ open, onClose, entityId, metric, dialogAction, setAlert }) => {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const entities = useSelector(selectEntities);
    const [selectedSimpleMetrics, setSelectedSimpleMetrics] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState([]);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [owner, setOwner] = useState("");
    const [metricType, setMetricType] = useState("");
    const [calculationMethod, setCalculationMethod] = useState("");
    const [dateFrom, setDateFrom] = useState(Date.now());
    const [reportInterval, setReportInterval] = useState("");
    const [dataFrequencyInterval, setDataFrequencyInterval] = useState("");
    const [allEntitiesFlag, setAllEntitiesFlag] = useState(false);
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [reportUnit, setReportUnit] = useState({
        prefix: "",
        unit: "",
        suffix: ""
    });
    const { token } = useSelector(userSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        if (entityId && open) setSelectedEntity([entities[entityId]]);
        if (metric && open) {
            setName(metric.name);
            setDescription(metric.description || "");
            setOwner(metric.owner ?? "");
            setMetricType(metric.type || "");
            setSelectedSimpleMetrics(metric.metrics || []);
            setReportInterval(metric.reportData.reportInterval || "");
            setDataFrequencyInterval(metric.reportData.dataFrequencyInterval || "");
            setCalculationMethod(metric.reportData.calculationMethod || "");
            setReportUnit(reportUnit => metric.reportData.reportUnit ? { ...reportUnit, ...metric.reportData.reportUnit, unit: { name: metric.reportData.reportUnit.unit } } : reportUnit)
            setDateFrom(new Date(metric.reportData.startDate).getTime() || NaN);
        }
    }, [entityId, open, metric, entities]);

    const handleClose = (resetFlag) => {
        setSelectedEntity([]);
        setSelectedSimpleMetrics([]);
        setName("");
        setDescription("");
        setOwner("");
        setMetricType("");
        setReportInterval("");
        setDataFrequencyInterval("");
        setCalculationMethod("");
        setDateFrom(Date.now());
        setAllEntitiesFlag(false);
        setEditMode(false);
        setReportUnit({
            prefix: "",
            unit: "",
            suffix: ""
        })
        onClose(resetFlag);
    }

    const compareData = (oldData, newData) => {
        return (
            oldData.name.trim() === newData.name.trim() &&
            oldData.metricId.length === newData.metricId.length &&
            JSON.stringify(oldData.metricId.sort()) === JSON.stringify(newData.metricId.sort()) &&
            oldData.entityIdModel === newData.entityIdModel &&
            oldData.entityId === newData.entityId &&
            oldData.type === newData.type &&
            oldData.reportInterval === newData.reportInterval &&
            oldData.reportData.dataFrequencyInterval === newData.dataFrequencyInterval &&
            oldData.reportData.startDate === new Date(newData.startDate).toISOString() &&
            oldData.reportData.reportUnit?.prefix === newData.reportUnit?.prefix &&
            oldData.reportData.reportUnit?.suffix === newData.reportUnit?.suffix &&
            oldData.reportData.reportUnit?.unit === newData.reportUnit?.unit &&
            oldData.owner?.trim() === newData.owner?.trim() &&
            oldData.description?.trim() === newData.description?.trim() &&
            oldData.reportData.calculationMethod === newData.calculationMethod
        )
    }

    const onSubmit = () => {
        setDisabledSubmitButton(true);
        const newMetricObject = {
            name: name,
            metricId: selectedSimpleMetrics.map(metric => metric._id),
            entityIdModel: "entity",
            entityId: allEntitiesFlag ? Object.keys(entities) : selectedEntity.map(el => el._id),
            type: metricType,
            reportInterval: reportInterval,
            dataFrequencyInterval: dataFrequencyInterval,
            startDate: localISOString(dateFrom),
            reportUnit: {
                prefix: reportUnit.prefix,
                unit: reportUnit.unit?.name,
                suffix: reportUnit.suffix,
            },
            owner: owner,
            description: description,
            calculationMethod: calculationMethod
        }

        if (metric && compareData(metric, newMetricObject)) {
            setAlert({ open: true, messageId: "ERROR.NO_CHANGE_DETECTED", severity: "error" });
            setDisabledSubmitButton(false);
        } else if (metric) API.metrics.putComplexMetric(token, metric._id, newMetricObject).then(items => {
            dispatch(updateMetricInTopic(items.data));
            setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
            handleClose(true);
            setDisabledSubmitButton(false);
            setEditMode(false);
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_UPDATED", severity: "error" });
            console.error(error);
            setDisabledSubmitButton(false);
            setEditMode(false);
        })

        else API.metrics.postComplexMetric(token, newMetricObject).then(items => {
            setAlert({ open: true, messageId: "SUCCESS.CREATE", severity: "success" });
            handleClose(true);
            setDisabledSubmitButton(false);
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_CREATED", severity: "error" });
            console.error(error);
            setDisabledSubmitButton(false);
        })
    }

    useEffect(() => {
        if (((!entityId && allEntitiesFlag) || entityId || selectedEntity.length) &&
            selectedSimpleMetrics.length &&
            name !== "" &&
            calculationMethod !== "" &&
            metricType !== "" &&
            reportInterval !== "" &&
            dataFrequencyInterval !== "" &&
            (selectedSimpleMetrics.every(met => !met.category?.scope?.unit && !met.reportData?.reportUnit?.unit) || ((selectedSimpleMetrics.every(met => !!met.category?.scope?.unit || !!met.reportData?.reportUnit?.unit) && !!reportUnit.unit) || calculationMethod === "count")) &&
            (new Date(dateFrom).toString() !== "Invalid Date")) setDisabledSubmitButton(false);
        else setDisabledSubmitButton(true);
    }, [entityId, selectedEntity, allEntitiesFlag, selectedSimpleMetrics, name, description, owner, calculationMethod, metricType, reportInterval, dataFrequencyInterval, dateFrom, reportUnit, editMode]);

    return <Dialog open={open} maxWidth="md" fullWidth fullScreen={smallScreen} >
        <DialogFormTitle
            mainTitle={<FormattedMessage id={metric ? "ESG.METRIC.EDIT_COMPLEX" : "ESG.METRIC.FORM_COMPLEX"} />}
            subTitle={metric ? metric.name : name}
            action={dialogAction}
        />
        <Divider />

        <DialogContent>
            <Divider>
                <Typography variant='h6' color="secondary.main"><FormattedMessage id="DESCRIPTION" /></Typography>
            </Divider>
            <ComplexDescriptiveSection
                key="description"
                entityId={entityId}
                selectedEntity={selectedEntity} setSelectedEntity={setSelectedEntity}
                allEntitiesFlag={allEntitiesFlag} setAllEntitiesFlag={setAllEntitiesFlag}
                name={name} setName={setName}
                description={description} setDescription={setDescription}
                owner={owner} setOwner={setOwner}
                metricType={metricType} setMetricType={setMetricType}
                setEditMode={setEditMode}
            />

            {!metric && <><Divider>
                <Typography variant='h6' color="secondary.main"><FormattedMessage id="MEASUREMENTS" /></Typography>
            </Divider>
                <ComplexMeasurementsSection
                    key="measurement"
                    existingMetric={metric}
                    selectedSimpleMetrics={selectedSimpleMetrics} setSelectedSimpleMetrics={setSelectedSimpleMetrics}
                    calculationMethod={calculationMethod} setCalculationMethod={setCalculationMethod}
                    setEditMode={setEditMode}
                    setReportUnit={setReportUnit}
                />
                <Divider>
                    <Typography variant='h6' color="secondary.main"><FormattedMessage id="REPORT" /></Typography>
                </Divider>
                <ReportSection
                    key="report"
                    dateFrom={dateFrom} setDateFrom={setDateFrom}
                    reportUnit={reportUnit} setReportUnit={setReportUnit}
                    reportInterval={reportInterval} setReportInterval={setReportInterval}
                    dataFrequencyInterval={dataFrequencyInterval} setDataFrequencyInterval={setDataFrequencyInterval}
                    setEditMode={setEditMode}
                    selectedSimpleMetrics={selectedSimpleMetrics}
                    isEditing={!!metric}
                    calculationMethod={calculationMethod}
                    complex={true}
                /></>}
        </DialogContent>
        <Divider />

        <DialogActions>
            <Button onClick={() => handleClose(false)} color="warning"><FormattedMessage id="CANCEL" /></Button>
            <Button disabled={disabledSubmitButton} onClick={onSubmit}><FormattedMessage id={metric ? "EDIT" : "ADD"} /></Button>
        </DialogActions>
    </Dialog>
}

export default ComplexMetricForm;