import { useEffect, useState } from "react";
import { AddOutlined, DeleteOutlineOutlined, PreviewOutlined, UpdateOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Divider, Box, Switch, FormLabel, Dialog, DialogContent, DialogActions, Button, Stack, Typography, useMediaQuery, CardHeader, Avatar, List, ListItem, Chip, ListItemText, IconButton, Tooltip } from "@mui/material";
import { AlertSnackbar, LoadingData, DisplayBox, CompactTargetList, GoalForm, TargetForm, StatusUpdateInput, ConfirmationDialog, UserList } from "components";
import { useParams } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectStatusTypes, selectGoalCategoryTypesConf } from "redux/configurationSlice";
import { userSelector, permissionSelector } from "redux/userSlice";
import { selectTopics } from "redux/topicSlice";
import { selectEntities } from "redux/entitySlice";
import { selectUsersAndGroups, selectUsersAndGroupsStatus, fetchUsersAndGroups } from "redux/usersAndGroupsSlice";
import { formatDateLocale, getStatusColor } from 'utils';
import { getTopicIcon } from "components/topicsView/TopicsView";
import { store } from 'redux/store';
import API from "api";

const Display = (props) => {
    return <Typography color="text.secondary" component="span" justifyContent="space-between" display="flex">
        {props.title}
        <Typography variant={props.variant} color={props.color || "black"} display="inline" component="span" sx={{ ml: 1 }}>{props.message}</Typography>
    </Typography>
};


export default function GoalDetailsView({ goalId, goal, topicId, openDialog, onClose, onChangeView, action, small, setRefreshData }) {
    const { token } = useSelector(userSelector);
    const intl=useIntl();

    const queryId = useParams().entityId;
    const [reset, setReset] = useState(0);
    const [data, setData] = useState(null);
    const [targetData, setTargetData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openSubgoal, setOpenSubgoal] = useState(false);
    const [openTarget, setOpenTarget] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(false);
    const [editTargets, setEditTargets] = useState(false);
    const [editSubgoals, setEditSubgoals] = useState(false);
    const [newStatus, setNewStatus] = useState(goal ? goal.status : null);
    const [confirmation, setConfirmation] = useState({ open: false });
    const [alert, setAlert] = useState({ open: false });
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const statuses = useSelector(selectStatusTypes);
    const categories = useSelector(selectGoalCategoryTypesConf);
    const topics = useSelector(selectTopics);
    const entities = useSelector(selectEntities);
    const usersAndGroups = useSelector(selectUsersAndGroups);
    const status = useSelector(selectUsersAndGroupsStatus);
    const [allUsers, setAllUsers] = useState([]);
    const [allGroups, setAllGroups] = useState([]);

    const createTargetPermission = useSelector((state) => permissionSelector(state, 'create-target'));
    const deleteTargetPermission = useSelector((state) => permissionSelector(state, 'delete-target'));
    const createGoalPermission = useSelector((state) => permissionSelector(state, 'create-goal'));
    const deleteGoalPermission = useSelector((state) => permissionSelector(state, 'delete-goal'));
    const updateGoalPermission = useSelector((state) => permissionSelector(state, 'update-goal'));
    const viewTargetPermission = useSelector((state) => permissionSelector(state, 'view-target'));
    const viewUsersPermission = useSelector((state) => permissionSelector(state, 'view-users'));

    const onAlertClose = () => setAlert({ ...alert, open: false });
    const onDialogClose = () => {
        setUpdateStatus(false);
        setLoading(true);
        setEditTargets(false);
        setEditSubgoals(false);
        onClose();
    }

    useEffect(() => {
        if (updateStatus === false) {
            if (goal) setNewStatus(goal.status);
            else if (data) setNewStatus(data.status);
            else setNewStatus(null);
        }
    }, [updateStatus, goal, data]);

    useEffect(() => {
        const convertData = (goal) => ({
            ...goal,
            id: goal._id,
            statusId: "STATUS." + statuses.find(el => el.key === goal.status)?.value,
            categoryId: "ESG.GOAL." + categories.find(el => el.key === goal.category)?.value,
            typeId: "ESG.TYPE." + goal.type,
            topic: topics.find(el => el._id === goal.topicId),
            parentId: goal.connections?.parentId,
            startDate: formatDateLocale(goal.dateRange?.startDate),
            endDate: formatDateLocale(goal.dateRange?.endDate),
        });

        setLoading(true);
        if (goal) {
            setData(convertData(goal));
            setNewStatus(goal.status);
            setTargetData(viewTargetPermission ? goal.targets : []);
            setLoading(false);
        }

        else if (goalId && openDialog) {
            API.goal.getGoal(goalId, token).then(item => {
                if (item && item.data) {
                    setData(convertData(item.data));
                    setNewStatus(item.data.status)
                    if (viewTargetPermission) {
                        setTargetData(item.data.targets);
                    }
                    else {
                        setTargetData([]);
                    }
                }
                else {
                    setData({});
                }
                setLoading(false);
            }).catch(error => {
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
                console.error(error);
                setData(null);
                setLoading(false);
            });
        };
    }, [goalId, goal, openDialog, token, categories, statuses, topics, reset, viewTargetPermission]);

    useEffect(() => {
        if (!viewUsersPermission) {
            setAllUsers([]);
            setAllGroups([]);
            return;
        }
        else if (status === 'failed') {
            store.dispatch(fetchUsersAndGroups(token));
        } else if (status === 'complete') {
            setAllUsers(usersAndGroups.users);
            setAllGroups(usersAndGroups.groups);
        } else {
            setAllUsers([]);
            setAllGroups([]);
        }
    }, [token, status, usersAndGroups, viewUsersPermission]);

    const onUpdateStatus = (status) => {
        if (data) API.goal.updateGoalStatus(data._id, status, token)
            .then((item) => {
                setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
                setData(goal => ({ ...goal, status: item.data.status, statusId: "STATUS." + statuses.find(el => el.key === item.data.status)?.value }));
            })
            .catch((error) => setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_UPDATED", severity: "error" }));
        setUpdateStatus(false);
    };

    const onUpdateTargetStatus = (status, targetId) => {
        const targetIndex = targetData.findIndex(t => t._id === targetId);
        setTargetData(targets => {
            if (targetIndex > -1) targets[targetIndex] = {
                ...targets[targetIndex],
                status: status
            }
            return targets;
        })
    }

    const onDelete = (data, type, deleteFlag) => {
        const getStatus = () => "STATUS." + statuses.find(el => el.key === data.status)?.value;
        const thenCb = () => {
            setAlert({ open: true, messageId: "SUCCESS.DELETE", severity: "success" });
            setReset(reset => reset + 1);
            setEditSubgoals(false); setEditTargets(false);
            setConfirmation({ open: false });
        };
        const catchCb = (error) => setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_DELETED", severity: "error" });

        if (type === "goal")
            setConfirmation({
                open: true,
                name: <Typography component="span" display="inline" color="error" variant="h6">{data.name}</Typography>,
                content: <Stack direction="column" spacing={1}>
                    <Display title={<FormattedMessage id="OWNER" />} message={data.owner} />
                    <Display title={<FormattedMessage id="ESG.GOAL.STATUS" />} color={getStatusColor(data.status)} message={<FormattedMessage id={getStatus()} />} />
                    <Display title={<FormattedMessage id="NUMBER_OF_CHILDREN.PREFIX" />} message={data.children.length} />
                    <Display title={<FormattedMessage id="START_DATE" />} message={formatDateLocale(data.dateRange.startDate)} />
                    <Display title={<FormattedMessage id="END_DATE" />} message={formatDateLocale(data.dateRange.endDate)} />

                    <Stack direction="column" spacing={1}>
                        <Box>
                            <FormattedMessage id="COUNT_CHILDREN.ESTIMATE" />
                            <FormLabel sx={{ color: 'primary.main', typography: 'body1' }}>{data?.children?.length ? data.children.length + 1 : 1}</FormLabel>
                        </Box>
                        {data?.children?.length > 0 &&
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <FormattedMessage id="DELETE_ALL" />
                                <FormLabel sx={{ ml: 0.5 }}>
                                    <Switch size="small" checked={deleteFlag} onChange={() => onDelete(data, "goal", !deleteFlag)} />
                                    <Typography sx={{ cursor: 'pointer' }} display="inline" color={deleteFlag ? "primary" : "text.disabled"}><FormattedMessage id={deleteFlag ? "YES" : "NO"} /></Typography>
                                </FormLabel>
                            </Box>
                        }
                    </Stack>
                </Stack>,
                handleCb: () => API.goal.deleteGoal(data._id, token).then(thenCb).catch(catchCb),
                disabled: !deleteFlag
            })

        if (type === "target")
            setConfirmation({
                open: true,
                name: <Typography component="span" display="inline" color="error" variant="h6">{data.name}</Typography>,
                content: <Stack direction="column" spacing={1}>
                    <Display title={<FormattedMessage id="OWNER" />} message={data.owner} />
                    <Display title={<FormattedMessage id="STATUS" />} color={getStatusColor(data.status)} message={<FormattedMessage id={getStatus()} />} />
                    <Display title={<FormattedMessage id="START_DATE" />} message={formatDateLocale(data.dateRange.startDate)} />
                    <Display title={<FormattedMessage id="END_DATE" />} message={formatDateLocale(data.dateRange.endDate)} />
                </Stack>,
                handleCb: () => API.target.deleteTarget(data._id, token).then(thenCb).catch(catchCb)
            })
    };

    const onCloseGoalForm = (flag) => {
        setOpenSubgoal(false);
        if (flag) {
            setReset(reset => reset + 1);
            if (!openDialog) setRefreshData();
        }
    }

    const onCloseTargetForm = (flag) => {
        setOpenTarget(false);
        if (flag) {
            setReset(reset => reset + 1);
        }
    }

    const entity = queryId ? entities[queryId]
        : data ? entities[data.topic?.entityId]
            : null;

    return (
        <>
            <ConfirmationDialog
                open={confirmation.open}
                title={<span><FormattedMessage id="CONFIRM_DELETE_FOR" /> {confirmation.name}</span>}
                content={confirmation.content}
                handleCancel={() => setConfirmation({ open: false })}
                handleCustomButton={confirmation.handleCb}
                customButtonTitle={<FormattedMessage id="DELETE" />}
                customButtonColor='error'
                disabled={confirmation.disabled}
            />
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
            <GoalForm open={openSubgoal} onClose={onCloseGoalForm} topicId={topicId ? topicId : (openSubgoal && (goal ? goal.topicId : data.topicId))} parent={goal ? goal : data} entity={entity} setAlert={setAlert} />
            <TargetForm size="sm" open={openTarget} onClose={onCloseTargetForm} defaultGoal={goal ? goal : data} setAlert={setAlert} />
            <Dialog
                open={openDialog}
                maxWidth={small ? 'sm' : 'md'}
                fullWidth
                fullScreen={smallScreen}
                onClose={onDialogClose}
            >
                {loading ? <DialogContent><LoadingData /></DialogContent> : <>
                    <CardHeader
                        sx={{ maxWidth: '100%' }}
                        avatar={<Avatar sx={{ bgcolor: 'primary.main' }}>{data?.type}</Avatar>}
                        title={<Typography variant="h6"><FormattedMessage id={"ESG.GOAL.DETAILS" + (data?.parentId ? "_SUB" : "")} /></Typography>}
                        subheader={<Typography color="primary" className="ellipsis-header" display="block">{data?.name} </Typography>}
                        action={data !== null && action}
                    />
                    <Divider />

                    {data && <DialogContent>

                        <Divider><Typography variant='h6' color="secondary.main"><FormattedMessage id="DESCRIPTION" /></Typography></Divider>
                        <Stack spacing={1}>
                            <DisplayBox label={<FormattedMessage id="NAME" />} value={data.name} />
                            <DisplayBox label={<FormattedMessage id="ENTITY_NAME" />} value={entity?.name} />
                            <DisplayBox label={<FormattedMessage id="DESCRIPTION" />} value={data.description} />
                            <DisplayBox label={<FormattedMessage id="ESG.TOPIC" />} value={data.topic ? <Chip sx={{ border: 'transparent' }} variant="outlined" icon={<Tooltip title={<FormattedMessage id={"ESG.TYPE." + data.topic.type} />} placement="top"><span>{getTopicIcon(data.topic.type)}</span></Tooltip>} label={<Typography component="span" variant="body1">{data.topic.name}</Typography>} /> : null} />
                            <DisplayBox label={<FormattedMessage id="ESG.GOAL.STATUS" />} value={
                                updateStatus
                                    ? <StatusUpdateInput newStatus={newStatus} onStatusChange={setNewStatus} onCancel={() => setUpdateStatus(false)} onUpdate={() => onUpdateStatus(newStatus)} />
                                    : <Stack direction="row" spacing={1}>
                                        <Typography variant="h6" color={getStatusColor(data.status)}><FormattedMessage id={data.statusId} /></Typography>
                                        {updateGoalPermission && (goal ? !goal.connections?.childrenArray?.length : !data?.connections?.childrenArray?.length) && !targetData.length && <Chip size="small" variant="outlined" label={<FormattedMessage id="UPDATE" />} icon={<UpdateOutlined fontSize="small" />} onClick={() => setUpdateStatus(true)} />}
                                    </Stack>
                            } />
                            <DisplayBox label={<FormattedMessage id="OWNER" />} value={data.owner} />
                            {data.participants?.length > 0 && <UserList
                                label={<FormattedMessage id="PARTICIPANTS" />}
                                users={data.participants.filter(participant => participant.type === "user").map((participant) => (allUsers.find(user => user.id === participant.id)?.username))}
                                groups={data.participants.filter(participant => participant.type === "group").map(group => allGroups.find(grp => grp.id === group.id))}
                            />}
                            <DisplayBox label={<FormattedMessage id="ESG.METRIC.CATEGORY" />} value={<FormattedMessage id={data.categoryId} />} />
                            <DisplayBox label={<FormattedMessage id="ESG.GOAL.TYPE" />} value={<FormattedMessage id={data.typeId} />} />
                        </Stack>


                        <Divider><Typography variant='h6' color="secondary.main"><FormattedMessage id="ESG.GOALS.SUB" /></Typography></Divider>
                        <>
                            {data.children?.length > 0
                                ? <List dense>
                                    {data.children.map(child => <ListItem
                                        key={child._id}
                                        secondaryAction={
                                            <Tooltip title={editSubgoals ? <FormattedMessage id="DELETE" /> : <FormattedMessage id="ESG.GOALS.VIEW_SUBGOAL" />} placement="left">
                                                <span>
                                                    <IconButton size="small" color="primary" edge="end" aria-label="view" onClick={() => editSubgoals ? onDelete(child, "goal", !child.children?.length) : onChangeView(child)}>
                                                        {editSubgoals ? <DeleteOutlineOutlined color="error" /> : <PreviewOutlined fontSize="small" />}
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        }>
                                        <ListItemText
                                            primary={child.name}
                                            secondary={<span>{child.children?.length || "0"} <FormattedMessage id="ESG.GOALS.SUB" /></span>}
                                        />
                                    </ListItem>)}
                                </List>
                                : <Typography color="text.disabled"><FormattedMessage id="NONE" /></Typography>
                            }
                            <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ my: 1 }}>
                                {createGoalPermission && <Chip size="small" variant="outlined" onClick={() => setOpenSubgoal(true)} icon={<AddOutlined color="primary" />} label={<FormattedMessage id="ESG.GOALS.CREATE_SUB" />} />}
                                {data.children?.length > 0 && deleteGoalPermission && <Button size="small" onClick={() => setEditSubgoals(!editSubgoals)} startIcon={editSubgoals ? null : <DeleteOutlineOutlined />}><FormattedMessage id={editSubgoals ? "CANCEL" : "ESG.GOALS.DELETE_SUB"} /></Button>}
                            </Stack>
                        </>

                        <Divider><Typography variant='h6' color="secondary.main"><FormattedMessage id="ESG.TARGETS" /></Typography></Divider>
                        <Stack spacing={1}>
                            {!viewTargetPermission
                                ? <Typography sx={{ pt: 1 }} color="text.disabled"><FormattedMessage id="UNAUTHORIZED.NO_PERMISSION" /></Typography>
                                : (targetData.length && viewTargetPermission ?
                                    <CompactTargetList targets={targetData} xsmall={small} onUpdateTargetStatus={onUpdateTargetStatus} onUpdate={() => setReset(reset => reset + 1)} customAction={editTargets && { icon: <DeleteOutlineOutlined color="error" />, title: <FormattedMessage id="DELETE" /> }} customActionHandler={editTargets && ((t) => onDelete(t, "target"))} />
                                    : <Typography color="text.disabled"><FormattedMessage id="NONE" /></Typography>)
                            }
                            <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ my: 1 }}>
                                {createTargetPermission && <Chip size="small" variant="outlined" onClick={() => setOpenTarget(true)} icon={<AddOutlined color="primary" />} label={<FormattedMessage id="ESG.TARGET.ADD" />} />}
                                {targetData.length > 0 && deleteTargetPermission && <Button size="small" onClick={() => setEditTargets(!editTargets)} startIcon={editTargets ? null : <DeleteOutlineOutlined />}><FormattedMessage id={editTargets ? "CANCEL" : "ESG.TARGET.DELETE"} /></Button>}
                            </Stack>
                        </Stack>

                        <Divider><Typography variant='h6' color="secondary.main"><FormattedMessage id="REPORT" /></Typography></Divider>
                        <Stack spacing={1}>
                            <DisplayBox label={<FormattedMessage id="START_DATE" />} value={data.startDate} />
                            <DisplayBox label={<FormattedMessage id="END_DATE" />} value={data.endDate} />
                        </Stack>

                    </DialogContent>}
                </>}
                <Divider />

                <DialogActions>
                    {data?.parentId && <Chip disabled={loading} color="primary" variant="outlined" icon={<VisibilityOutlined />} onClick={() => onChangeView({ _id: data.parentId })} label={intl.formatMessage({id: "VIEW_PARENT"})} />}
                    <Button color="warning" onClick={onDialogClose}><FormattedMessage id="CLOSE" /></Button>
                </DialogActions>
            </Dialog>
        </>
    )
}