import React, { useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import { paletteColors, sampleTypePaletteColors } from '../utils/chartsColorPalette';
import defaultOptions from 'charts/utils/defaultOptions';
import { useSelector } from "react-redux";
import { selectFontSize, selectFontWeight } from 'redux/appSlice';
import { deepMerge } from 'utils';
import { Doughnut } from 'react-chartjs-2';
import { useMediaQuery, useTheme } from '@mui/material';

Chart.register(...registerables);

const DoughnutChart = (props) => {
    const theme = useTheme();
    const fontSize = useSelector(selectFontSize);
    const fontWeight = useSelector(selectFontWeight);
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const chartRef = useRef(null);
    const chartOptions = {
        plugins: {
            ...props.fixed && {
                title: {
                    display: true,
                    text: `${props.targetName} (${props.entityName})`
                }
            },
            zoom: undefined,
            legend: {
                labels: {
                    color: theme.palette.text.primary,
                    font: {
                        size: smallScreen ? 14 : fontSize,
                        weight: fontWeight < 0 ? 'lighter' : fontWeight < 100 ? 'normal' : 'bolder'
                    }
                }
            },
            tooltip: {
                titleFont: {
                    size: smallScreen ? 10 : fontSize,
                    weight: fontWeight < 0 ? 'lighter' : fontWeight < 100 ? 'normal' : 'bolder'
                },
                bodyFont: {
                    size: smallScreen ? 10 : fontSize,
                    weight: fontWeight < 0 ? 'lighter' : fontWeight < 100 ? 'normal' : 'bolder'
                },
            }
        },
        scales: {
            x: { display: false },
            y: { display: false }
        },
        radius: 110,
        animation: {
            animateScale: true
        }
    };

    // inject palette colors to each serie
    if (props.data && props.data.datasets) {
        props.data.datasets.forEach(dataset => {
            if (dataset.data) {
                if (!(dataset.backgroundColor && dataset.backgroundColor.length)) {
                    dataset.backgroundColor = []
                    if (!props.fixed) {
                        dataset.data.forEach((dataItem, index) => {
                            // get new color from palette
                            const dsColor = !dataset.keys ? paletteColors(index) : sampleTypePaletteColors(dataset.keys[index]);
                            dataset.backgroundColor.push(dsColor);
                            dataset.borderColor = 'rgba(0, 0, 0, 0)';
                        })
                    }
                    else {
                        dataset.data.forEach((dataItem, index) => {
                            if (index === 0) dataset.backgroundColor.push("#388e3c")
                            else dataset.backgroundColor.push("#e0e0e0")
                            dataset.borderColor = 'rgba(0, 0, 0, 0)';
                        })
                    }
                }
            }
        })
    }

    return (
        <Doughnut className='chart' data={props.data} options={deepMerge(defaultOptions, chartOptions)} ref={chartRef} onDoubleClick={() => chartRef?.current?.resetZoom()} />
    );
}


export default DoughnutChart;