import instance from "./axiosEnv";
import { setAuthHeader } from "./axiosEnv";

const getAll = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/ESG/stakeholders', config);
}

const getOne = (stakeholderId, token) => {
    const config = setAuthHeader(token);
    return instance.get('/ESG/stakeholders/' + stakeholderId, config);
}

const put = (token, stakeholderId, obj) => {
    const config = setAuthHeader(token);
    return instance.put(`/ESG/stakeholders/${stakeholderId}`, obj, config);
}

const post = (token, obj) => {
    const config = setAuthHeader(token);
    return instance.post('/ESG/stakeholders', obj, config);
}

const deleteOne = (token, stakeholderId) => {
    const config = setAuthHeader(token);
    return instance.delete(`/ESG/stakeholders/${stakeholderId}`, config);
}

const stakeholderAPI = {
    getAll,
    getOne,
    put,
    post,
    deleteOne,
};

export default stakeholderAPI;