import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectTopics } from 'redux/topicSlice';
import { selectMetricTypesConf, selectStatusTypes, selectGoalCategoryTypesConf } from 'redux/configurationSlice';
import { Autocomplete, Checkbox, FormControl, FormHelperText, FormLabel, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { DateRangeSelector, AssignOwnerField, GroupHeader, GroupItems, DescriptionField, GoToButton } from 'components';
import { selectEntities } from 'redux/entitySlice';
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router';

const GoalFormContent = ({ setEditMode, name, setName, description, setDescription, selectedTopic, setSelectedTopic, topicId, entityId, existingGoal, goalType, setGoalType, goalStatus, setGoalStatus, categoryType, setCategoryType, startDate, endDate, setStartDate, setEndDate, owner, setOwner, participants, setParticipants }) => {
    const navigate = useNavigate();
    const metricTypes = useSelector(selectMetricTypesConf);
    const statusTypes = useSelector(selectStatusTypes);
    const categories = useSelector(selectGoalCategoryTypesConf);
    const topics = useSelector(selectTopics);
    const entities = useSelector(selectEntities);

    const topicOptions = entityId ? topics.filter(el => el.entityId === entityId) : topics;

    return <Stack direction="column" spacing={2}>
        <FormControl>
            <FormLabel required><FormattedMessage id="NAME" /></FormLabel>
            <TextField
                required
                fullWidth
                variant="outlined"
                id="NAME"
                type="text"
                size="small"
                value={name}
                inputProps={{ maxLength: process.env.REACT_APP_ESG_INPUT_RESTRICTION_SMALL }}
                onChange={(e) => { setName(e.target.value); setEditMode(true) }}
            />
        </FormControl>
        <DescriptionField description={description} onChange={(value) => { setDescription(value); setEditMode(true) }} />
        <FormControl>
            <FormLabel required><FormattedMessage id="ESG.GOAL.TYPE" /></FormLabel>
            <TextField
                select
                size="small"
                value={goalType}
                onChange={(e) => { setGoalType(e.target.value); setEditMode(true) }}
            >
                {metricTypes.map(type => {
                    return <MenuItem key={type.key} value={type.key}><FormattedMessage id={"ESG.TYPE." + type.key} /></MenuItem>
                })}
            </TextField>
        </FormControl>

        <FormControl>
            <FormLabel required><FormattedMessage id="ESG.TOPIC" /></FormLabel>
            {topicId
                ? <Typography variant="h6" color="primary">{`${topics.find(el => el._id === topicId).name} (${entities[topics.find(el => el._id === topicId).entityId].name})`}</Typography>
                : <Autocomplete
                    id="select-topic"
                    options={[...topicOptions].sort((opt1, opt2) => opt1.entityId.localeCompare(opt2.entityId))}
                    getOptionLabel={(topic) => `${topic?.name} (${entities[topic?.entityId]?.name})`}
                    value={selectedTopic}
                    onChange={(e, topic) => { setSelectedTopic(topic); setEditMode(true) }}
                    groupBy={(option) => option.entityId}
                    renderInput={(params) => (
                        <TextField {...params} key={topics.map(topic => topic._id)} />
                    )}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    renderGroup={(params) => (
                        <li key={params.group}>
                            <GroupHeader>{entities[params.group].name}</GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                        </li>
                    )}
                    renderOption={(props, option, { selected }) => {
                        return <li {...props} key={option._id}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlank fontSize="small" />}
                                checkedIcon={<CheckBoxOutlined fontSize="small" />}
                                checked={selected}
                                style={{ marginRight: 8 }}
                            />
                            {option.name}
                        </li>
                    }}
                    size="small"
                    fullWidth
                />}
            {!topicId && <FormHelperText><GoToButton onClick={() => navigate('/topics' + (entityId ? `/${entityId}` : ""))} label={<FormattedMessage id="ESG.TOPIC.MANAGMENT" />} /></FormHelperText>}
        </FormControl>

        <FormControl>
            <FormLabel><FormattedMessage id="ESG.GOAL.STATUS" /></FormLabel>
            <TextField
                select
                disabled={!!(existingGoal && (existingGoal.children?.length > 0 || existingGoal.targetCount > 0))}
                helperText={<FormattedMessage id="ESG.GOAL.STATUS_DEPENDENCY" />}
                FormHelperTextProps={{ hidden: !(existingGoal && (existingGoal.children?.length > 0 || existingGoal.targetCount > 0)) }}
                size="small"
                value={goalStatus}
                onChange={(e) => { setGoalStatus(e.target.value); setEditMode(true) }}
            >
                {statusTypes.map(type => {
                    return <MenuItem key={type.key} value={type.key}><FormattedMessage id={"STATUS." + type.value} /></MenuItem>
                })}
            </TextField>
        </FormControl>

        <FormControl>
            <FormLabel ><FormattedMessage id="ESG.GOAL.CATEGORY" /></FormLabel>
            <TextField
                select
                size="small"
                value={categoryType >= 0 ? categoryType : ""}
                onChange={(e) => { setCategoryType(e.target.value); setEditMode(true) }}
            >
                {categories.map(({ key, value }) => <MenuItem key={key} value={key}><FormattedMessage id={"ESG.GOAL." + value} /></MenuItem>)}
            </TextField>
        </FormControl>

        <DateRangeSelector startDateLabel={<FormattedMessage id="DATE_FROM" />} noButtons dateFrom={startDate} dateTo={endDate} customOnChangeHandler={{ startDate: setStartDate, endDate: setEndDate }} setEditMode={setEditMode} />

        <AssignOwnerField owner={owner} onChange={(value) => { setOwner(value); setEditMode(true); }} />

        <AssignOwnerField multiple participants={participants} onChange={(value) => { setParticipants(value); setEditMode(true); }} />
    </Stack >
}

export default GoalFormContent;