import { CheckBoxOutlineBlank, CheckBoxOutlined, DownloadOutlined, ScaleOutlined } from "@mui/icons-material";
import { Dialog, Button, Checkbox, DialogActions, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, IconButton, DialogTitle, Typography, Chip, Divider, DialogContent, useMediaQuery, TextField, Autocomplete } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectMeasurementsTypesConf, selectUnits } from "redux/configurationSlice";
import { exportData } from "utils/helpers";



export default function MeasurementsTypeTemplatePicker({ open, types, entityName, onClose }) {
    const intl = useIntl();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const measurementTypes = useSelector(selectMeasurementsTypesConf);
    const measureUnits = useSelector(selectUnits);
    const [anchorEl, setAnchorEl] = useState(Object.fromEntries(types.map(type => [type, null])));
    const [checked, setChecked] = useState(types);
    const [units, setUnits] = useState({});
    const [searchQuery, setSearchQuery] = useState(null);

    const handleDownloadClick = event => {
        const tableHeaders = [intl.formatMessage({ id: "TIMESTAMP" })];
        checked.forEach(el => {
            const res = measurementTypes.find(rt => rt.key === el);
            if (res) tableHeaders.push(res.key + (units[res.key] ? ` [${units[res.key]}]` : ""));
        });
        exportData(entityName, intl.formatMessage({ id: "TEMPLATE" }), [], tableHeaders, undefined, false);
        onClose();
    }

    const handleToggle = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    }
    const toggleUnitSelect = (type, unit) => {
        const newUnits = { ...units, [type]: unit };
        setUnits(newUnits);
        setAnchorEl(anchors => ({ ...anchors, [type]: null }));
    }

    const measureTypes = types.map(el => measurementTypes.find(type => type.key === el)).filter(Boolean);

    return <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth fullScreen={smallScreen}>
        <DialogTitle key="title">
            <Typography variant="h6" component="span" display="block">
                <FormattedMessage id="MEASUREMENT_TYPES" />: <Typography variant="body2" color="text.secondary" component="span"><FormattedMessage id="SELECTED" /> {checked.length}</Typography>
            </Typography>
            <Chip size="small" label={checked.length ? <FormattedMessage id="UNCHECK_ALL" /> : <FormattedMessage id="CHECK_ALL" />} icon={checked.length ? <CheckBoxOutlineBlank fontSize="small" /> : <CheckBoxOutlined fontSize="small" />} onClick={() => checked.length ? setChecked([]) : setChecked(types)} />
        </DialogTitle>
        <Divider key="divider1" />
        <DialogContent sx={{ pb: 0 }}>
            <Autocomplete
                id="search-by-name"
                size="small"
                freeSolo
                value={searchQuery}
                onChange={(e, val) => setSearchQuery(val)}
                options={measureTypes.map(el => el.name || intl.formatMessage({ id: el.key }))}
                renderInput={(params) => <TextField variant="standard" fullWidth placeholder={intl.formatMessage({ id: "SEARCH_BY.NAME" })} {...params} />}
            />
            <List key="list" sx={{ width: '100%' }}>
                {measureTypes?.map((type) => {
                    const labelId = `checkbox-list-label-${type.key}`;
                    const search = !searchQuery ? true
                        : (type.name ? type.name.includes(searchQuery) : intl.formatMessage({ id: type.key }).includes(searchQuery))
                    if (search) return (
                        <ListItem
                            disablePadding
                            key={type.key}
                            secondaryAction={
                                Boolean(type.dataType.measure) && <div>
                                    <Tooltip title={<FormattedMessage id="UNITS" />} placement="left">
                                        <IconButton color={units[type.key] ? "default" : "primary"} onClick={e => setAnchorEl(anchors => ({ ...anchors, [type.key]: e.currentTarget }))} edge="end" aria-label={"unit-select-" + type.key} size="small" >
                                            <ScaleOutlined fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        key={type.key + "-unit-menu"}
                                        id="menu-appbar"
                                        anchorEl={anchorEl[type.key]}
                                        open={Boolean(anchorEl[type.key])}
                                        onClose={() => setAnchorEl(anchors => ({ ...anchors, [type.key]: null }))}
                                    >
                                        {measureUnits[type.dataType.measure].map(unit => <div key={type.key + unit}>
                                            <MenuItem key="unit" onClick={() => toggleUnitSelect(type.key, unit)} sx={{ color: "inherit" }}>
                                                {unit}
                                            </MenuItem>
                                        </div>)}
                                    </Menu>
                                </div>
                            }
                        >
                            <ListItemButton role={undefined} onClick={() => handleToggle(type.key)} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checked.indexOf(type.key) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId}
                                    primary={type.name || <FormattedMessage id={type.key} />}
                                    secondary={Boolean(type.dataType.measure) && (
                                        (units[type.key] && <span><FormattedMessage id="UNIT" />: {units[type.key]}</span>) ||
                                        <FormattedMessage id="SELECT.UNIT" />
                                    )}
                                />
                            </ListItemButton>
                        </ListItem>
                    );
                    else return null
                }).filter(el => el)}
            </List>
        </DialogContent>
        <Divider />
        <DialogActions>
            <Button onClick={onClose} color="warning">
                <FormattedMessage id="CANCEL" />
            </Button>
            <Button disabled={!checked.length} startIcon={<DownloadOutlined />} onClick={handleDownloadClick}>
                <FormattedMessage id="DOWNLOAD_CSV" />
            </Button>
        </DialogActions>
    </Dialog>
}