import React, { useEffect, useRef, useState, useCallback, cloneElement, Children, isValidElement } from 'react';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import Feature from 'ol/Feature';
import { Cluster, OSM, Vector as VectorSource } from 'ol/source.js';
import {
    Circle as CircleStyle,
    Fill,
    Stroke,
    Style,
    Text,
} from 'ol/style.js';
import { Point, LineString } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { fromLonLat } from 'ol/proj';
import { defaults } from 'ol/control/defaults';
import { useTheme } from '@mui/material';
import { MapPopup, MARKERS } from 'components';
import { useSelector } from 'react-redux';
import { selectFontSize, selectFontWeight } from 'redux/appSlice';
import { selectMapProps } from 'redux/mapSlice';
import './mapStyle.css';
import { selectEntities } from 'redux/entitySlice';

// SHAPE FILE LAYERS
const geoJSONLayer = new VectorLayer({
    source: new VectorSource({})
});
const vectorSource = new VectorSource();
const clusterVectorSource = new VectorSource();
const vectorLayer = new VectorLayer();
const clusters = new VectorLayer();

export default function MapComponent(props) {
    const { position,
        zoom,
        className,
        children,
        customFeatures,
        disableInteraction,
        // disableShapes,
        onClick,
        onUpdate } = props;
    const mapProps = useSelector(selectMapProps);
    const [customClass, setCustomClass] = useState('map-main-container');
    const ref = useRef();
    const entities = useSelector(selectEntities);
    const theme = useTheme();
    const fontSize = useSelector(selectFontSize);
    const fontWeight = useSelector(selectFontWeight);
    const [mapZoom, setMapZoom] = useState(zoom ? zoom : mapProps.zoomLevel);

    useEffect(() => {
        if (vectorSource.getFeatures().length === 0) {
            const clusterFontWeight = fontWeight < 100 ? 'normal' : 'bold';
            const feature = Object.values(entities).map((entity) => {
                const feature = new Feature({
                    geometry: new Point(fromLonLat(entity.geoProperties.position)),
                    name: entity.name,
                    type: "ENTITY",
                    object: entity
                });
                feature.setStyle(MARKERS[!entity.connections.parentId ? 'location' : 'measurePoint'](theme.palette.mode));
                return feature;
            });
            clusterVectorSource.addFeatures(feature);
            vectorSource.addFeatures(feature);
            vectorSource.addFeatures(Object.values(entities).filter(el => el.connections.parentId && entities[el.connections.parentId]).map((entity) => {
                const parent = entities[entity.connections.parentId];
                const feature = new Feature({
                    geometry: new LineString([fromLonLat(parent.geoProperties.position), fromLonLat(entity.geoProperties.position)]),
                    name: 'Connections'
                });
                feature.setStyle(MARKERS.connection(theme.palette.mode));
                return feature;
            }));

            if (customFeatures && customFeatures.length) {
                vectorSource.addFeatures(customFeatures);
            }
            vectorLayer.setSource(vectorSource);


            clusters.setSource(new Cluster({
                distance: 25,
                minDistance: 100,
                source: clusterVectorSource,
            }));
            const styleCache = {};
            clusters.setStyle(function (feature) {
                const size = feature.get('features').length;
                let style = styleCache[size];
                if (!style) {
                    style = new Style({
                        image: new CircleStyle({
                            radius: 12,
                            stroke: new Stroke({
                                color: '#fff',
                            }),
                            fill: new Fill({
                                color: '#1976d2',
                            }),
                        }),
                        text: new Text({
                            text: size.toString(),
                            fill: new Fill({
                                color: '#fff',
                            }),
                            justify: 'center',
                            textAlign: 'center',
                            offsetY: 1,
                            font: `${fontSize}px ${clusterFontWeight} sans-serif`
                        }),
                    });
                    styleCache[size] = style;
                }
                return style;
            });
        }
    }, [entities, fontSize, theme, customFeatures, fontWeight]);

    //MAP
    const [theMap] = useState(new Map({
        showFullExtent: true,
        layers: [
            // Base Layer - OpenStreetMap
            new TileLayer({
                source: new OSM(),
            }),
            geoJSONLayer,
            // clusters/vectorLayer with location pins has to be last in array
            new VectorLayer({ source: new VectorSource() })
        ],
        view: new View({
            center: position ? fromLonLat(position) : fromLonLat([mapProps.defaultY, mapProps.defaultX]),
            zoom: mapZoom,
            minZoom: parseInt(mapProps.minZoom),
            maxZoom: parseInt(mapProps.maxZoom),
        }),
        controls: defaults({
            zoom: !disableInteraction
        })
    }));

    theMap.getInteractions().forEach(i => {
        i.setActive(!disableInteraction)
    });

    const listener = useCallback((event) => {
        const newZoomLevel = (theMap.getView().getZoom());
        setMapZoom(newZoomLevel);

        if (newZoomLevel > mapProps.zoomThreshold) {
            // remove Clusters Layer
            theMap.removeLayer(theMap.getAllLayers().pop());
            // add Vector Layer with location pins
            theMap.addLayer(vectorLayer);

        }

        if (newZoomLevel < mapProps.zoomThreshold) {
            // remove Vector Layer
            theMap.removeLayer(theMap.getAllLayers().pop());
            // add Clusters Layers
            theMap.addLayer(clusters);
        }
    }, [theMap, mapProps.zoomThreshold]);

    theMap.on('moveend', listener);

    theMap.on("pointermove", function (evt) {
        let hit = theMap.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            return feature.get('name') !== 'Connections';
        });
        if (hit) theMap.getTargetElement().style.cursor = 'pointer';
        else if (theMap.getTargetElement().style.cursor !== 'grab') theMap.getTargetElement().style.cursor = '';
    });
    theMap.on("pointerup", function (evt) {
        theMap.getTargetElement().style.cursor = 'default';
    });
    theMap.on("pointerdown", function (evt) {
        let hit = theMap.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            return feature && feature.get('name') !== 'Connections';
        });
        if (hit) theMap.getTargetElement().style.cursor = 'pointer';
        else theMap.getTargetElement().style.cursor = 'grab';
    });
    theMap.on("pointerdrag", function (evt) {
        theMap.getTargetElement().style.cursor = 'grabbing';
    });

    useEffect(() => {
        const clickCB = (event) => onClick(event);
        if (typeof onClick === 'function') theMap.on('singleclick', clickCB);
        return () => theMap.un('singleclick', clickCB);
    }, [onClick, theMap])

    useEffect(() => {
        if (ref.current) {
            theMap.setTarget(ref.current)
        }

        return () => theMap.setTarget(undefined);
    }, [ref, theMap, zoom]);

    useEffect(() => {
        const mapClass = "map-main-container";
        if (className !== mapClass && !!className) setCustomClass(mapClass + ' ' + className);
        else setCustomClass(mapClass);
    }, [className, setCustomClass]);

    const childrenWithProps = Children.map(children, child => {
        if (isValidElement(child)) {
            return cloneElement(child, { map: theMap });
        }
        return child;
    });

    const removeFeature = (id) => {
        const feature = customFeatures.find(feature => feature.get('object')._id === id);
        vectorLayer.getSource().removeFeature(feature)
    }

    return (<div ref={ref} className={customClass}>
        <MapPopup disabled={disableInteraction} theMap={theMap} onUpdate={onUpdate} onDelete={removeFeature} />
        {/* <MapGeoJSON disabled={disableShapes} theMap={theMap} geoJSONLayer={geoJSONLayer} /> */}
        {children ? childrenWithProps : null}
    </div >);

}