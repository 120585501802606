import { useCallback, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { Navigate, Route, Routes } from 'react-router';
import { useSelector } from 'react-redux';
import { selectInitDone, selectLanguage } from 'redux/appSlice';
import {
  MapPage,
  SplashPage,
  AdministrationPage,
  EntitiesPage,
  MeasureEntitiesPage,
  MeasurementsEntryPage,
  MetricsPage,
  NotesPage,
  TopicsPage,
  GoalsPage,
  MeasurementTypesPage,
  MetricsEntryPage,
  AlarmsPage,
  DashboardPage,
  ActivitiesPage,
  EntityActivitiesPage,
  StakeholdersPage,
} from 'pages';
import { PageError, PageLoading } from 'components';
import { languages } from 'lang';
import { selectLocale } from 'utils';
import { selectThemeMode } from 'redux/appSlice';


const App = () => {
  const language = useSelector(selectLanguage);
  const initDone = useSelector(selectInitDone);
  const themeMode = useSelector(selectThemeMode)

  useEffect(() => {
    if (themeMode === 'dark') {
      document.body.classList.remove("light");
      document.body.classList.add("dark");
    }
    else if (themeMode === 'light') {
      document.body.classList.add("light");
      document.body.classList.remove("dark");
    }
    else return () => {
      document.body.classList.remove("light");
      document.body.classList.remove("dark");
    }
  }, [themeMode]);

  const renderApp = useCallback(() => {
    if (initDone === 'failed') return <PageError messageId="ERROR.FETCHING_DATA" />;
    else if (initDone) return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="dashboard" />} />
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="map" element={<MapPage />} />
          <Route path="administration/users" element={<AdministrationPage users />} />
          <Route path="administration/roles" element={<AdministrationPage roles />} />
          <Route path="administration/userGroups" element={<AdministrationPage userGroups />} />
          <Route path="entities" element={<EntitiesPage />} />
          <Route path="entities/:entityId" element={<EntitiesPage />} />
          <Route path="notes" element={<NotesPage />} />
          <Route path="measureEntities/:entityId" element={<MeasureEntitiesPage />} />
          <Route path="topics" element={<TopicsPage />} />
          <Route path="topics/:entityId" element={<TopicsPage />} />
          <Route path="metrics" element={<MetricsPage />} />
          <Route path="metrics/:entityId" element={<MetricsPage />} />
          <Route path="goals" element={<GoalsPage />} />
          <Route path="goals/:entityId" element={<GoalsPage />} />
          <Route path="activities" element={<ActivitiesPage />} />
          <Route path="activities/:entityId" element={<EntityActivitiesPage />} />
          <Route path="stakeholders" element={<StakeholdersPage />} />

          <Route path="measurementsEntry" element={<MeasurementsEntryPage />} />
          <Route path="measurementsEntry/uploadFile" element={<MeasurementsEntryPage file />} />
          <Route path="measurementsEntry/metrics" element={<MetricsEntryPage />} />
          <Route path="measurementTypes" element={<MeasurementTypesPage />} />

          <Route path="alarmProfiles" element={<AlarmsPage profiles />} />
          <Route path="alarmProfiles/details/:id" element={<AlarmsPage profiles />} />
          <Route path="alarms" element={<AlarmsPage />} />
          <Route path="alarms/details/:id" element={<AlarmsPage />} />

          <Route path="/*" element={<SplashPage />} />
        </Routes>
      </BrowserRouter>
    )
    else return <PageLoading messageId="SPLASH.WAIT" />;

  }, [initDone]);


  const onIntlError = (error) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.error(error);
    }
  }

  return (
    <IntlProvider locale={selectLocale(language, true)} messages={languages[language]} onError={onIntlError}>
      {renderApp()}
    </IntlProvider>

  );
}
export default App;