import { DefaultLayout, EmptyState } from 'layouts';
import { SplashPage } from 'pages';
import { StakeholdersView } from 'components';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';


const StakeholdersPage = () => {
    return <EmptyState component={<StakeholdersView />} />;
}

const StakeholdersPageComponent = () => {
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn) return <DefaultLayout page={<StakeholdersPage />} />;
    else return <SplashPage />;
};

export default StakeholdersPageComponent;