import { BuildOutlined, CloseOutlined, OpenInNewOutlined, SearchOutlined } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, Chip, Dialog, DialogActions, DialogContent, Divider, Grid, IconButton, InputAdornment, List, ListItem, ListSubheader, Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import { EntityCard, OWAttribution, MeasurementsCharts, MeasureEntityForm, BreadcrumbsAndNavigation, CollectorTypeDistributionPie } from "components";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router";
import { selectMeasurementsTypesConf } from "redux/configurationSlice";
import { selectMeasureEntities, selectEntities } from "redux/entitySlice";
import { permissionSelector } from 'redux/userSlice';
import { EmptyState } from "layouts";
import { selectDateRange } from "redux/dateRangeSlice";
import { formatDateLocale } from "utils";

export default function MeasureEntitiesView(props) {
    const entityId = useParams().entityId;
    const allEntities = useSelector(selectEntities)
    const measureEntity = useSelector(selectMeasureEntities)[entityId];
    const measurementTypes = useSelector(selectMeasurementsTypesConf);
    const [openNewMeasureForm, setOpenNewMeasureForm] = useState(false);
    const selectedDateRange = useSelector(selectDateRange);

    const configureMeasureEntityPermission = useSelector((state) => permissionSelector(state, 'update-entity'));

    if (!measureEntity) return <EmptyState message={<span><FormattedMessage id="SPLASH.INVALID_ID" /> <Typography variant="button" color="error">{entityId}</Typography></span>} />;

    const manualTypes = measurementTypes.filter(el => measureEntity.measurementTypes.manual.types.includes(el.key))
    const OWMTypes = measurementTypes.filter(el => measureEntity.measurementTypes.service.OWM && measureEntity.measurementTypes.service.OWM.includes(el.key))

    const owm = Boolean(OWMTypes.length);
    const manual = Boolean(manualTypes.length);
    const sensor = Boolean(measureEntity.measurementTypes.sensor && Object.keys(measureEntity.measurementTypes.sensor).length);

    return <Grid container direction="column">

        {entityId && <MeasureEntityForm entity={allEntities[entityId]} measureEntity={measureEntity} open={openNewMeasureForm} onClose={() => setOpenNewMeasureForm(false)} />}
        <BreadcrumbsAndNavigation entity={allEntities[entityId]} baseNavigate="/measureEntities" measureMode />
        <Grid container direction="row" spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{ display: 'flex' }}>
                <EntityCard showDescription entityId={entityId} disableActions customAction={configureMeasureEntityPermission && <Chip variant="outlined" key="configure" icon={<BuildOutlined fontSize="small" color="primary" />} onClick={() => setOpenNewMeasureForm(true)} label={<Typography variant="button" component="span"><FormattedMessage id="CONFIGURE.DATA" /></Typography>} />} />
            </Grid>
            {(owm || manual || sensor) && <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ display: 'flex' }}>
                <Stack direction="column" spacing={1} sx={{ width: '100%' }}>
                    {owm && <Card sx={{ height: '100%', overflowY: 'auto' }}>
                        <CardHeader title={<FormattedMessage id="SERVICE_TYPES" />} subheader={<OWAttribution />} />
                        <CardContent>
                            {OWMTypes.map(el => <Chip key={el.key} sx={{ mr: 1, mt: 1 }} label={<FormattedMessage id={el.key} />} />)}
                        </CardContent>
                    </Card>}
                    {manual && <TypesCard types={manualTypes} entity={allEntities[entityId]} measureEntity={measureEntity} title="MANUAL_TYPES" />}
                    {sensor &&
                        <TypesCard sensorTypesObject={measureEntity.measurementTypes.sensor} title="SENSOR_TYPES" />}
                </Stack>
            </Grid>}
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3} sx={{ display: 'flex' }}>
                <Card sx={{ width: '100%' }}>
                    <CardHeader title={<FormattedMessage id="COLLECTOR.TOTAL_DATA" />} subheader={<Stack direction="row" gap={0.5} alignItems="center">
                        <Typography variant="caption" color="inherit">{formatDateLocale(selectedDateRange.dateFrom)}</Typography>
                        -
                        <Typography variant="caption" color="inherit">{formatDateLocale(selectedDateRange.dateTo)}</Typography>
                    </Stack>} />
                    <CardContent >
                        <CollectorTypeDistributionPie entityIdArray={[entityId]} />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack direction="column" spacing={1}>
                    <MeasurementsCharts entityId={entityId} />
                </Stack>
            </Grid>
        </Grid>
    </Grid >;
}

const TypesCard = ({ types, entity, measureEntity, title, sensorTypesObject }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const [openMore, setOpenMore] = useState(false);
    const [search, setSearch] = useState("");
    const [selectedSensor, setSelectedSensor] = useState("");

    const addMeasurementPermission = useSelector((state) => permissionSelector(state, 'create-measurement'));

    const onClose = () => {
        setSearch("");
        setSelectedSensor("");
        setOpenMore(false);
    }
    const manualTypes = title === "MANUAL_TYPES" && Array.from(types).map(el => ({ ...el, name: el.name || intl.formatMessage({ id: el.key }) }));
    const dialogTypes = title === "MANUAL_TYPES" && Array.from(manualTypes).filter(el => search.length ? el.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) : true);

    return <>
        <Dialog maxWidth="xs" fullWidth fullScreen={smallScreen} open={openMore} onClose={onClose}>
            <CardHeader title={<FormattedMessage id={title} />} subheader={title === "MANUAL_TYPES" ? entity?.name : selectedSensor} action={<IconButton onClick={onClose}><CloseOutlined /></IconButton>} />
            <Divider />
            <DialogContent sx={{ height: 250, py: 0 }}>
                <List>
                    <ListSubheader disableGutters sx={{ height: 'fit-content' }}>
                        <TextField variant="standard" sx={{ mt: 0.5, py: 0.5 }} value={search} onChange={(e) => setSearch(e.target.value)} fullWidth size="small" InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlined fontSize="small" />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton size="small" sx={{ visibility: search.length ? 'visible' : 'hidden' }} onClick={() => setSearch("")}><CloseOutlined fontSize="small" /></IconButton>
                                </InputAdornment>
                            ),
                        }} />
                    </ListSubheader>
                    {dialogTypes && dialogTypes.map((el, i) => <ListItem key={el.key}>{i + 1}. {el.name}</ListItem>)}
                    {sensorTypesObject && selectedSensor && sensorTypesObject[selectedSensor].filter(el => search.length ? intl.formatMessage({ id: el }).toLocaleLowerCase().includes(search.toLocaleLowerCase()) : true).map((el, i) => <ListItem key={i}>{i + 1}. {intl.formatMessage({ id: el })}</ListItem>)}
                </List>
            </DialogContent>
            <DialogActions />
        </Dialog>
        <Card sx={{ height: '100%', overflowY: 'auto' }}>
            <CardHeader title={<FormattedMessage id={title} />} action={title === "MANUAL_TYPES" && addMeasurementPermission && <Button key="add-new" sx={{ width: '100%' }} endIcon={<OpenInNewOutlined fontSize="small" />} onClick={() => navigate('/measurementsEntry', { state: measureEntity })}><FormattedMessage id="ADD.DATA" /></Button>} />
            <CardContent>
                {manualTypes && manualTypes.slice(0, 9).map(el => <Chip key={el.key} sx={{ mr: 1, mt: 1 }} label={el.name} />)}
                {manualTypes && manualTypes.length >= 10 && <Chip onClick={() => setOpenMore(true)} sx={{ mr: 1, mt: 1 }} label={<span>{(manualTypes.length - 9)} {intl.formatMessage({ id: "MORE" })?.toLocaleLowerCase()}...</span>} variant="outlined" icon={<SearchOutlined fontSize="small" />} />}
                {sensorTypesObject && Object.keys(sensorTypesObject).map((sensorName, index, sensorsArray) => <Box key={sensorName} >
                    <Typography variant="subtitle2" >{sensorName}</Typography>
                    <Box>
                        {sensorTypesObject[sensorName].slice(0, 9).map((el, index) => <Chip key={index} sx={{ mr: 1, mt: 1 }} label={intl.formatMessage({ id: el })} />)}
                        {sensorTypesObject[sensorName].length >= 10 && <Chip onClick={() => { setSelectedSensor(sensorName); setOpenMore(true) }} sx={{ mr: 1, mt: 1 }} label={<span>{(sensorTypesObject[sensorName].length - 9)} {intl.formatMessage({ id: "MORE" })?.toLocaleLowerCase()}...</span>} variant="outlined" icon={<SearchOutlined fontSize="small" />} />}
                    </Box>
                    {index < sensorsArray.length - 1 && < Divider sx={{ my: 2 }} />}
                </Box>
                )}
            </CardContent>
        </Card>
    </>
}