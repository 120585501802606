import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { paletteColors, paletteColorsWithOpacity } from '../utils/chartsColorPalette';
import defaultOptions from 'charts/utils/defaultOptions';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectFontSize, selectFontWeight, selectLanguage } from 'redux/appSlice';
import { deepMerge, timeObjectAggregation } from 'utils';
import { SideDrawer, MeasurementDetails, LoadingData } from 'components';
import { Alert, useMediaQuery, useTheme, } from '@mui/material';
import { parse } from 'date-fns';
import { enGB, hr } from 'date-fns/locale';
import 'chartjs-adapter-date-fns';
import { selectMeasurementsTypesConf } from 'redux/configurationSlice';
import { userSelector } from 'redux/userSlice';
import API from 'api';
import zoomPlugin from 'chartjs-plugin-zoom';


Chart.register(...registerables);

const MultiAxisLineChart = ({ disableInteraction, ...props }) => {
    const { token } = useSelector(userSelector);
    const measurementTypes = useSelector(selectMeasurementsTypesConf);
    const fontSize = useSelector(selectFontSize);
    const fontWeight = useSelector(selectFontWeight);
    const lang = useSelector(selectLanguage);
    const timeAggregation = timeObjectAggregation(props.aggregation);
    const intl = useIntl();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const currentLocale = lang === "hr" ? hr : enGB;
    const [openDrawer, setOpenDrawer] = useState(false);
    const [drawerDetails, setDrawerDetails] = useState({});

    const toggleDrawer = () => { if (!props?.disableDrawer) setOpenDrawer(!openDrawer) }

    useEffect(() => {
        if (!disableInteraction) Chart.register(zoomPlugin);
        else Chart.unregister(zoomPlugin);

    }, [disableInteraction]);

    if (props.data && props.data.datasets) {
        props.data.datasets.forEach((dataset, index) => {
            const dsColor = paletteColors(index);
            const dsColorWithOpacity = paletteColorsWithOpacity(dsColor, 0.4);
            dataset.cubicInterpolationMode = 'monotone';
            dataset.tension = 0.4;
            dataset.backgroundColor = dsColorWithOpacity;
            dataset.borderColor = dsColor;
            dataset.pointStyle = 'circle';
            dataset.pointRadius = 6;
        })
    }
    const propKey = props.data.datasets[0]?.data ? props.data.datasets[0].data.length : 0;

    const chartOptions = {
        plugins: {
            tooltip: !disableInteraction ? {
                titleFont: {
                    size: smallScreen ? 10 : fontSize
                },
                bodyFont: {
                    size: smallScreen ? 10 : fontSize
                },
                footerFont: {
                    size: smallScreen ? 10 : fontSize
                },
                callbacks: {
                    title: function (context) {
                        return context && context[0] && context[0].label;
                    },
                    label: function (context) {
                        const measurementType = !props.KPI && measurementTypes.find(type => type.key === context.dataset.key)
                        let label = "";
                        if (measurementType) {
                            if (measurementType.name) label = measurementType.name;
                            else label = ` ${intl.formatMessage({ id: measurementType.key })}`;
                        }
                        else if (context.dataset?.key) label = intl.formatMessage({ id: context.dataset.key });
                        else label = context.dataset?.name || "";
                        const value = ` ${context.formattedValue}`;
                        const unit = context.raw.unit;
                        return [label, [value, unit].join(' ')];
                    }
                }
            } : null,
            legend: {
                display: !props.hideLegend,
                labels: {
                    color: theme.palette.text.primary,
                    font: {
                        size: smallScreen ? 8 : fontSize,
                        weight: fontWeight < 0 ? 'lighter' : fontWeight < 100 ? 'normal' : 'bolder'
                    }
                },
                ...(disableInteraction && { onClick: null })
            }
        },
        scales: {
            y: {
                grid: {
                    display: true,
                    color: theme.palette.scale.primary,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                },
                ticks: {
                    color: theme.palette.text.primary,
                    font: {
                        size: smallScreen ? 10 : fontSize,
                        weight: fontWeight < 0 ? 'lighter' : fontWeight < 100 ? 'normal' : 'bolder'
                    }
                }
            },
            x: {
                grid: {
                    display: true,
                    color: theme.palette.scale.primary
                },
                type: 'time',
                time: {
                    parser: (date) => {
                        if (props.aggregation === 'w') {
                            // if date is real date format ('2022-12-12T10:05:53.800Z', 1670839544689) - parsing is not needed, 
                            // we parse because DB prepared week aggregation that is not recognised format in date-fns library
                            if (date.includes('T') || typeof date === 'number') return date;
                            else return parse(date.split('-')[1], 'ww', new Date().setFullYear(date.split('-')[0]), { weekStartsOn: 1, locale: currentLocale });
                        }
                        else if (props.aggregation === "y") {
                            return parse(date.toString(), 'yyyy', new Date());
                        }
                        else return date
                    },
                    ...timeAggregation
                },
                adapters: {
                    date: {
                        locale: currentLocale,
                    }
                },
                ticks: {
                    source: 'auto',
                    // Disabled rotation for performance
                    maxRotation: 0,
                    autoSkip: true,
                    maxTicksLimit: props.itemWidth < 5 ? 4 : (window.innerWidth < 420 ? 4 : (fontSize > 16 || props.itemWidth < 8 ? 8 : 16)),
                    color: theme.palette.text.primary,
                    font: {
                        size: smallScreen ? 10 : fontSize,
                        weight: fontWeight < 0 ? 'lighter' : fontWeight < 100 ? 'normal' : 'bolder'
                    }
                }
            }
        },
        onClick: !disableInteraction ? (event, elements) => {
            const point = elements[0];
            if (point === undefined) return;
            const { datasetIndex, index } = point;
            const dataset = props.data.datasets[datasetIndex];
            const pointElement = dataset.data[index];

            if (pointElement.id) {
                toggleDrawer();
                setDrawerDetails({
                    title: <FormattedMessage id='LOADING_DATA' />,
                    subtitle: <FormattedMessage id='SPLASH.WAIT' />,
                    display: <LoadingData noText />
                });
                API.measurements.getSingleMeasurement(token, pointElement.id).then(item => {
                    if (item.data) {
                        setDrawerDetails({
                            title: <FormattedMessage id="MEASUREMENT_DETAILS" />,
                            subtitle: <></>,
                            display: <MeasurementDetails measurement={item.data} sideDrawer />
                        });
                    }
                })
                    .catch(error => {
                        setDrawerDetails({
                            title: <FormattedMessage id='ERROR' />,
                            subtitle: <></>,
                            display: <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>
                        });
                        console.error(error);
                    })
            }
        } : null
    };
    const options = deepMerge(deepMerge({}, props.options), defaultOptions);

    const onDoubleClick = () => {
        if (!disableInteraction && typeof props.chartRef?.current?.resetZoom === 'function') props.chartRef.current.resetZoom();
        if (!disableInteraction && typeof props.onReset === 'function') props.onReset();
    }

    return (<>
        <Line onDoubleClick={onDoubleClick} className='chart' key={propKey} data={props.data} options={deepMerge(options, chartOptions)} ref={props.chartRef} />
        <SideDrawer open={openDrawer} state={drawerDetails} toggleDrawer={toggleDrawer} />
    </>
    );
}

export default MultiAxisLineChart;