import { AddOutlined, CancelOutlined, DeleteOutline, ContentCopyOutlined, MailOutlineOutlined, PhoneIphoneOutlined, NotificationsOutlined, ArchiveOutlined, UnarchiveOutlined } from "@mui/icons-material";
import { Alert, Box, Button, Card, CardContent, CardHeader, Chip, Grid, IconButton, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { CustomDataGrid, LoadingData, AlertSnackbar, NoteDetails, ConfirmationDialog, NoteForm, ExpandedGridCell } from "components";
import { renderDateCell } from "components/dashboardView/TableHeaders";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectEntities } from "redux/entitySlice";
import { userSelector, permissionSelector } from "redux/userSlice";
import { selectNotificationMethodTypes } from "redux/configurationSlice";
import API from 'api';

export const getNoteStatusColor = (status) => {
    if (typeof status !== "string") return "inherit";
    else if (status.includes("SUCCESS")) return "success";
    else if (status.includes("FAIL")) return "error";
    else if (status.includes("CONTACTS_EMPTY")) return "warning";
    else return "default";
}

export const NotificationMethodIcon = ({ type, noTooltip, status, ...props }) => {
    const notificationMethodTypes = useSelector(selectNotificationMethodTypes);
    const iconProps = { color: getNoteStatusColor(status), ...props };
    let icon = null;
    switch (type) {
        case 1:
            icon = <NotificationsOutlined {...iconProps} />;
            break;
        case 2:
            icon = <MailOutlineOutlined {...iconProps} />;
            break;
        case 3:
            icon = <PhoneIphoneOutlined {...iconProps} />;
            break;
        default:
            break;
    }

    if (noTooltip) return icon;

    else return <Tooltip title={<span>
        <FormattedMessage id={"NOTIFICATIONS.METHOD." + notificationMethodTypes.find(m => m.key === type)?.value} />
        {status && <span> (<FormattedMessage id={"NOTE.STATUS." + status} />)</span>}
    </span>} arrow>
        <Box sx={{ ml: 1 }}>
            {icon}
        </Box>
    </Tooltip>;
}



export default function NotesView(props) {
    const intl = useIntl();
    const { token } = useSelector(userSelector);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const entities = useSelector(selectEntities);
    const notificationMethodTypes = useSelector(selectNotificationMethodTypes);
    const [data, setData] = useState([]);
    const [showArchived, setShowArchived] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const [selectedNote, setSelectedNote] = useState(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openNoteDetails, setOpenNoteDetails] = useState(false);
    const [reset, setReset] = useState(0);
    const [openAddForm, setOpenAddForm] = useState(false);
    const [selectedForCopy, setSelectedForCopy] = useState(null);

    const createNotePermission = useSelector((state) => permissionSelector(state, 'create-notes'));
    const updateNotePermission = useSelector((state) => permissionSelector(state, 'archive-notes'));
    const deleteNotePermission = useSelector((state) => permissionSelector(state, 'delete-notes'));

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        setLoading(true);
        const convertData = (data) => data.map(el => ({
            id: el._id,
            ...el
        }))
        API.notes.getAllNotes(token).then((items) => {
            if (items.data) setData(convertData(items.data));
            else setData([]);
            setLoading(false);
        }).catch((error) => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
            setData({ error: error });
            setLoading(false);
        })
    }, [token, reset]);

    const renderTable = useCallback(() => {

        const archiveNote = (noteId) => {
            API.notes.archiveNote({ noteIdArray: [noteId] }, token)
                .then(() => {
                    setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
                    setReset(reset => reset + 1);
                })
                .catch((error) => {
                    setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_ARCHIVED", severity: "error" });
                    console.error(error);
                })
        };

        const unarchiveNote = (noteId) => {
            API.notes.unarchiveNote({ noteIdArray: [noteId] }, token)
                .then(() => {
                    setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
                    setReset(reset => reset + 1);
                })
                .catch((error) => {
                    setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_UNARCHIVED", severity: "error" });
                    console.error(error);
                })
        };

        const headers = [
            {
                field: 'title',
                headerName: intl.formatMessage({ id: 'TITLE' }),
                ...(!smallScreen && { flex: 2 }),
                editable: true,
                renderCell: (row) => <ExpandedGridCell value={<span>{row.row.title} {showArchived && row.row.archived && (<Chip size="small" label={<FormattedMessage id="NOTE.ARCHIVED" />} />)}</span>} width={row.colDef.computedWidth} />,
                minWidth: 200
            },
            {
                field: 'timestamp',
                type: 'dateTime',
                headerName: intl.formatMessage({ id: 'TIMESTAMP' }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: (row) => new Date(row.value),
                ...renderDateCell(intl),
                minWidth: 150
            },
            {
                field: 'entityId',
                headerName: intl.formatMessage({ id: 'ENTITY' }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: (row) => entities[row.row.entityId]?.name,
                renderCell: ({ value }) => <Typography>{value}</Typography>,
                minWidth: 150
            },
            {
                field: 'notificationMethod',
                headerName: intl.formatMessage({ id: 'NOTIFICATIONS.METHOD' }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: (row) => row.row.notificationMethod.map(method => intl.formatMessage({ id: "NOTIFICATIONS.METHOD." + notificationMethodTypes.find(el => el.key === method).value })).join(","),
                renderCell: (row) => row.row.notificationMethod.map(el => <NotificationMethodIcon key={el} type={el} status={row.row.sendStatus.find(s => s.statusType === el)?.message} />),
                minWidth: 150
            },
            {
                field: 'actions',
                headerName: intl.formatMessage({ id: 'ACTIONS' }),
                type: 'actions',
                getActions: (el) => createNotePermission || updateNotePermission || deleteNotePermission ?
                    [
                        <Tooltip title={<FormattedMessage id="NOTE.COPY" />} placement="bottom" arrow>
                            <IconButton component="span" disabled={!createNotePermission} color="secondary" key="copy" onClick={() => { setSelectedForCopy(el.row); setOpenAddForm(true); }}> <ContentCopyOutlined fontSize="small" /> </IconButton>
                        </Tooltip>,
                        !el.row.archived
                            ? <Tooltip title={<FormattedMessage id="NOTE.ARCHIVE" />} placement="bottom" arrow>
                                <IconButton component="span" disabled={!updateNotePermission} color="secondary" key="unarchive" onClick={() => archiveNote(el.row._id)}><ArchiveOutlined fontSize="small" /></IconButton>
                            </Tooltip>
                            : <>
                                <Tooltip title={<FormattedMessage id="NOTE.UNARCHIVE" />} placement="bottom" arrow>
                                    <IconButton component="span" disabled={!updateNotePermission} color="secondary" key="archive" onClick={() => unarchiveNote(el.row._id)}><UnarchiveOutlined fontSize="small" /></IconButton>
                                </Tooltip>
                                <Tooltip title={<FormattedMessage id="NOTE.DELETE" />} placement="bottom" arrow>
                                    <IconButton component="span" disabled={!deleteNotePermission} color="error" key="delete" onClick={() => { setSelectedNote(el.row); setOpenConfirmDialog(true) }}><DeleteOutline fontSize="small" /></IconButton>
                                </Tooltip>
                            </>
                    ]
                    :
                    [smallScreen ? <IconButton key="disabled-actions" disabled><CancelOutlined fontSize="small" /></IconButton> : <Button key="disabled-actions" disabled><FormattedMessage id="DISABLED" /></Button>],
                minWidth: 200
            },
        ];

        if (loading) return <LoadingData />;
        else if (data?.error) return <Alert severity='error'><FormattedMessage id="ERROR.NO_DATA" /></Alert>;
        else {
            const rows = data.filter(el => showArchived ? el.archived : !el.archived)
            return <CustomDataGrid enableExport rows={rows} columns={headers} handleData={(el) => { setSelectedNote(el.row); setOpenNoteDetails(true) }} />;
        }

    }, [token, data, entities, showArchived, createNotePermission, updateNotePermission, deleteNotePermission, notificationMethodTypes, loading, intl, smallScreen])


    const onConfirmDialogClose = () => {
        setOpenConfirmDialog(false);
        setSelectedNote(null);
    }

    const handleDeleteMetric = () => {
        API.notes.deleteNote(selectedNote._id, token).then(() => {
            setAlert({ open: true, messageId: "SUCCESS.DELETE", severity: "success" });
            onConfirmDialogClose();
            setReset(reset => reset + 1);
        }).catch((error) => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_DELETED", severity: "error" });
            console.error(error);
        })
    }

    return <Grid container direction="column">
        <ConfirmationDialog
            open={openConfirmDialog}
            content={selectedNote && <Typography variant="h6" color="error">{selectedNote.title}</Typography>}
            title={<FormattedMessage id="NOTE.CONFIRM_DELETE" />}
            customButtonTitle={<FormattedMessage id="DELETE" />}
            customButtonColor="error"
            handleCancel={onConfirmDialogClose}
            handleCustomButton={handleDeleteMetric}
        />
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <NoteDetails open={openNoteDetails} onClose={() => setOpenNoteDetails(false)} noteId={selectedNote?._id} setAlert={setAlert} setReset={setReset} />
        <NoteForm open={openAddForm} onClose={() => setOpenAddForm(false)} setAlert={setAlert} reset={reset} setReset={setReset} existingNote={selectedForCopy} />
        <Card sx={{ width: '100%' }}>
            <CardHeader
                title={<Stack direction="column" gap={2} alignItems='flex-start'>
                    <FormattedMessage id="NOTES.TABLE" />
                    {!data?.error && <Button size="small" startIcon={showArchived ? <ArchiveOutlined /> : <UnarchiveOutlined />} onClick={() => setShowArchived(!showArchived)}><FormattedMessage id={showArchived ? "NOTE.HIDE_ARCHIVED" : "NOTE.SHOW_ARCHIVED"} /></Button>}
                </Stack>}
                action={createNotePermission && <Chip variant={data?.length && data.filter(el => showArchived ? el.archived : !el.archived).length ? "outlined" : "filled"} onClick={() => { setSelectedForCopy(null); setOpenAddForm(true) }} icon={<AddOutlined fontSize="small" color="primary" />} label={<FormattedMessage id="NOTE.ADD" />} />}
            />
            <CardContent>
                {renderTable()}
            </CardContent>
        </Card>
    </Grid>
}