import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";



export default function DisplayBox({ label, value, sx, labelColor, labelSize }) {
    return <Box sx={sx ? sx : undefined}>
        <Typography color={labelColor ? labelColor : "primary"} display="block" variant={labelSize === 'large' ? "body1" : "body2"} component="span">{label}</Typography>
        <Typography sx={{ overflowWrap: "break-word" }} display="block" variant="body1" color={!value && "text.disabled"} component="span">{value || <FormattedMessage id="NONE" />}</Typography>
    </Box>
}