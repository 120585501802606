import { DefaultLayout, PublicLayout, EmptyState } from 'layouts';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';



const SplashPage = () => {
    return <EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />;
}

const SplashPageComponent = () => {
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn) return <DefaultLayout page={<SplashPage />} />;
    else return <PublicLayout page={<SplashPage />} />;
};

export default SplashPageComponent;