import { localISOString } from "utils";
import instance from "./axiosEnv";
import { setAuthHeader } from "./axiosEnv";

const getAllNotes = (token, archived, notificationMethod, types, entities, dateFrom, dateTo) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    if (archived !== undefined) queryParams.append('archived', archived);
    if (notificationMethod !== undefined) queryParams.append('notificationMethod', notificationMethod);
    if (types) {
        for (let type of types) {
            queryParams.append('noteType', type);
        }
    }
    if (entities) {
        for (let entity of entities) {
            queryParams.append('entityId', entity);
        }
    }
    if (dateFrom) queryParams.append("dateFrom", localISOString(dateFrom));
    if (dateTo) queryParams.append("dateTo", localISOString(dateTo));
    return instance.get('/notes?' + queryParams.toString(), config);
}

const getNote = (noteId, token) => {
    const config = setAuthHeader(token);
    return instance.get('/notes/' + noteId, config);
}

const createNote = (data, token) => {
    const config = setAuthHeader(token);
    return instance.post('/notes', data, config);
}

const updateNote = (noteId, data, token) => {
    const config = setAuthHeader(token);
    return instance.put('/notes/' + noteId, data, config);
}

const archiveNote = (data, token) => {
    const config = setAuthHeader(token);
    return instance.put('/notes/archive', data, config);
}

const unarchiveNote = (data, token) => {
    const config = setAuthHeader(token);
    return instance.put('/notes/unarchive', data, config);
}

const deleteNote = (noteId, token) => {
    const config = setAuthHeader(token);
    return instance.delete('/notes/' + noteId, config);
}

const resendNote = (noteId, token) => {
    const config = setAuthHeader(token);
    return instance.put('/notes/' + noteId + '/resend', null, config);
}

const noteAPI = {
    getAllNotes,
    getNote,
    createNote,
    updateNote,
    archiveNote,
    unarchiveNote,
    deleteNote,
    resendNote,
};

export default noteAPI;