import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectReportIntervalTypesConf, selectDataFrequencyInterval } from 'redux/configurationSlice';
import { Stack, TextField, FormLabel, MenuItem, FormControl, FormHelperText, Typography, useMediaQuery } from '@mui/material';
import { UnitSelectorField } from 'components';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB, hr } from "date-fns/locale";

const ReportSection = ({ setEditMode, reportInterval, setReportInterval, dataFrequencyInterval, setDataFrequencyInterval, measurementType, reportUnit, setReportUnit, dateFrom, setDateFrom, unit, isEditing, selectedSimpleMetrics, calculationMethod, complex, categoryType }) => {
    const intl = useIntl();
    const reportIntervalTypes = useSelector(selectReportIntervalTypesConf);
    const dataFrequencyIntervalTypes = useSelector(selectDataFrequencyInterval);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

    const findIndex = () => {
        const foundReportInterval = reportIntervalTypes.find(type => type.key === reportInterval)
        if (foundReportInterval) return reportIntervalTypes.indexOf(foundReportInterval);
        else return -1;
    }

    const setReportUnitData = (key, data) => {
        setReportUnit(reportUnit => ({
            ...reportUnit,
            [key]: data,
        }));
    }

    return <Stack direction="column" spacing={2} sx={{ mb: 2 }}>
        <FormControl >
            <FormLabel required ><FormattedMessage id="DATE_FROM" /></FormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                <DateTimePicker
                    disabled={isEditing}
                    value={new Date(dateFrom)}
                    onChange={(date) => { setDateFrom(date); setEditMode(true) }}
                    slotProps={{ textField: { size: 'small' } }}
                />
            </LocalizationProvider>
            {new Date(dateFrom).toString() === "Invalid Date" && <FormHelperText error><FormattedMessage id="INVALID_DATE" /></FormHelperText>}
        </FormControl>
        <FormControl >
            <FormLabel required ><FormattedMessage id="REPORT_INTERVAL" /></FormLabel>
            <TextField
                select
                size="small"
                value={reportInterval}
                onChange={(e) => { setDataFrequencyInterval(""); setReportInterval(e.target.value); setEditMode(true); }}
            >
                {reportIntervalTypes.map(type => <MenuItem key={type.key} value={type.key}><FormattedMessage id={'GROUPING.' + type.value} /></MenuItem>)}
            </TextField>
        </FormControl>
        <FormControl >
            <FormLabel required ><FormattedMessage id="DATA_FREQUENCY_INTERVAL" /></FormLabel>
            <TextField
                select
                size="small"
                value={dataFrequencyInterval}
                onChange={(e) => { setDataFrequencyInterval(e.target.value); setEditMode(true) }}
                disabled={reportInterval === ""}
            >
                {dataFrequencyIntervalTypes.filter((type, index) => index <= findIndex()).map(type => <MenuItem key={type.key} value={type.key}><FormattedMessage id={'GROUPING.' + type.value} /></MenuItem>)}
            </TextField>
        </FormControl>
        <FormControl>
            <FormLabel ><FormattedMessage id="REPORT_UNITS" /></FormLabel>
            <Stack direction={smallScreen ? "column" : "row"} spacing={0.5}>
                <TextField
                    size="small"
                    value={reportUnit.prefix}
                    inputProps={{ maxLength: 24 }}
                    placeholder={intl.formatMessage({ id: "PREFIX" })}
                    onChange={(e) => { setReportUnitData("prefix", e.target.value); setEditMode(true) }}
                />
                <UnitSelectorField unit={reportUnit.unit} setUnit={(unit) => setReportUnitData("unit", unit)} measurementType={measurementType} noLabel setEditMode={setEditMode}
                    required={(!unit && calculationMethod !== "count" && !complex && categoryType === "Quantitative") || (selectedSimpleMetrics?.every(met => !!met.category?.scope?.unit || !!met.reportData?.reportUnit?.unit) && calculationMethod !== "count")} disabled={isEditing} selectedSimpleMetrics={selectedSimpleMetrics} />
                <TextField
                    size="small"
                    value={reportUnit.suffix}
                    inputProps={{ maxLength: 24 }}
                    placeholder={intl.formatMessage({ id: "SUFFIX" })}
                    onChange={(e) => { setReportUnitData("suffix", e.target.value); setEditMode(true) }}
                />
            </Stack>
            {(reportUnit.suffix.length || reportUnit.prefix.length || reportUnit.unit?.name) && <FormHelperText sx={{ pt: -2 }}>
                <FormattedMessage id="EXAMPLE_VALUE" />: <Typography variant="subtitle1" display="inline" component="span">
                    {reportUnit.prefix} <Typography sx={{ mr: 0.5, fontStyle: 'italic', textTransform: 'lowercase' }} variant="body1" color="primary" display="inline" component="span"><FormattedMessage id="VALUE" /></Typography>
                    {reportUnit.unit?.name} {reportUnit.suffix}
                </Typography>
            </FormHelperText>}
        </FormControl>
    </Stack>


}

export default ReportSection;