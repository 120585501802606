import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { selectMetricTypesConf } from 'redux/configurationSlice';
import { selectEntities } from 'redux/entitySlice';
import { Autocomplete, Collapse, FormControl, FormLabel, MenuItem, Stack, TextField, Typography, } from '@mui/material';
import { AssignOwnerField, DescriptionField } from 'components';
import { AllEntitiesCheckbox } from '../simpleMetricForm/DescriptiveSection'

const ComplexDescriptiveSection = ({ setEditMode, entityId, selectedEntity, setSelectedEntity, allEntitiesFlag, setAllEntitiesFlag, name, setName, description, setDescription, owner, setOwner, metricType, setMetricType, }) => {
    const metricTypes = useSelector(selectMetricTypesConf);
    const entities = useSelector(selectEntities);
    const entitiesList = Object.keys(entities).map(ent => entities[ent]);


    return <Stack direction="column" spacing={2} sx={{ mb: 2 }}>
        <FormControl>
            <FormLabel required><FormattedMessage id="NAME" /></FormLabel>
            <TextField
                required
                fullWidth
                variant="outlined"
                id="NAME"
                type="text"
                size="small"
                value={name}
                inputProps={{ maxLength: process.env.REACT_APP_ESG_INPUT_RESTRICTION_SMALL }}
                onChange={(e) => { setName(e.target.value); setEditMode(true) }}
            />
        </FormControl>
        <DescriptionField noCharacterLimit description={description} onChange={(value) => { setDescription(value); setEditMode(true) }} />
        <FormControl>
            <FormLabel required><FormattedMessage id="ENTITY" /></FormLabel>
            {entityId
                ? <Typography variant="h6" color="primary">{selectedEntity[0]?.name}</Typography>
                : <Collapse in={!allEntitiesFlag}>
                    <Autocomplete
                        multiple disableCloseOnSelect
                        disabled={allEntitiesFlag}
                        id="select-entity"
                        options={entitiesList}
                        getOptionLabel={(entity) => entity ? entity.name : ""}
                        value={selectedEntity}
                        onChange={(e, value) => { setSelectedEntity([...value]); setEditMode(true) }}
                        renderInput={(params) => (
                            <TextField {...params} key={entitiesList.map(entity => entity._id)} />
                        )}
                        size="small"
                        fullWidth
                    /></Collapse>}
            {!entityId && <AllEntitiesCheckbox entities={entities} allEntitiesFlag={allEntitiesFlag} setAllEntitiesFlag={setAllEntitiesFlag} />}
        </FormControl>

        <AssignOwnerField owner={owner} onChange={(value) => { setOwner(value); setEditMode(true); }} />

        <FormControl>
            <FormLabel required><FormattedMessage id="ESG.METRIC.CATEGORY" /></FormLabel>
            <TextField
                select
                size="small"
                value={metricType}
                onChange={(e) => { setMetricType(e.target.value); setEditMode(true) }}
            >
                {metricTypes.map(type => {
                    return <MenuItem key={type.key} value={type.key}><FormattedMessage id={"ESG.TYPE." + type.key} /></MenuItem>
                })}
            </TextField>
        </FormControl>
    </Stack>

}

export default ComplexDescriptiveSection;