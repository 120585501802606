import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { organizationAdminSelector } from 'redux/userSlice';
import { AddEditRoleModal, MiniDataDisplay, DialogFormTitle } from 'components';
import { Button, Dialog, DialogActions, DialogContent, Divider, IconButton, Typography, useMediaQuery } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';

const RoleDetailsView = ({ role, open, onClose, setReloadData, setRoleId }) => {
    const intl = useIntl();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const isOrganizationAdmin = useSelector(organizationAdminSelector);
    const [openEditRoleForm, setOpenEditRoleForm] = useState(false);

    return <>
        <AddEditRoleModal
            openDialog={openEditRoleForm}
            onClose={() => setOpenEditRoleForm(false)}
            roleId={role?.id}
            reloadData={() => { setReloadData(true); setOpenEditRoleForm(false); }}
        />
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth fullScreen={smallScreen}>
            <DialogFormTitle disableHelpText mainTitle={<span>
                <FormattedMessage id="ROLE.DETAILS" /><Typography color="primary">{role?.name}</Typography>
            </span>}
                action={isOrganizationAdmin && <IconButton disabled={role?.name === process.env.REACT_APP_ADMIN_ROLE} onClick={() => setOpenEditRoleForm(true)}><EditOutlined /></IconButton>}
            />
            <Divider />
            <DialogContent>
                <MiniDataDisplay titleId="EFFECTIVE_PERMISSIONS" array={role?.permissions.map(el => intl.formatMessage({ id: el }))} />
                <MiniDataDisplay titleId="AFFECTED_ENTITIES" array={role?.entities} />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button color="warning" onClick={onClose}><FormattedMessage id="CLOSE" /></Button>
            </DialogActions>
        </Dialog>
    </>
}

export default RoleDetailsView;