import { EditOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Button, Card, Collapse, FormLabel, Grid, IconButton, Slider, Stack, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

function SizeButton({ selected, icon, label, onClick, manual }) {
    const isSelected = selected && !manual;
    return <Button disableRipple={selected} variant={isSelected ? "contained" : "outlined"} startIcon={icon} onClick={onClick}>
        {label}
    </Button>
}

function ManualSizeSlider({ disabled, label, value, onChange, min, max }) {
    const getMarks = () => {
        let marks = [];
        for (let i = min; i <= max; i++) {
            marks.push({ value: i, label: i === value ? i - 1 : "" })
        }
        return marks;
    }

    return <Stack direction="column" sx={{ width: '100%', px: 0.5 }} gap={0}>
        <FormLabel>{label}</FormLabel>
        <Slider
            size="small"
            disabled={disabled}
            value={value}
            onChange={(e, val) => onChange(val)}
            aria-labelledby="input-slider"
            step={1} min={min} max={max}
            marks={getMarks()}
        />
    </Stack>
}


export default function WidgetSizeSelector({ value, onChange }) {
    const [width, setWidth] = useState(value.width);
    const [height, setHeight] = useState(value.height);
    const [manual, setManual] = useState(false);
    const [preview, setPreview] = useState(false);

    const handleChange = (val, resetManual) => {
        setWidth(val.width);
        setHeight(val.height);
        if (resetManual) setManual(false);
        if (typeof onChange === 'function') onChange({ width: val.width, height: val.height });
    }

    const matrix = Array.from({ length: 3 }, () => Array.from({ length: 9 }, () => null));
    for (let i = 0; i < height - 1; i++) {
        for (let j = 0; j < width - 1; j++) {
            matrix[i][j] = 1;
        }
    }

    return <Stack direction="column" sx={{ width: '100%' }} spacing={1}>
        <Stack direction="row" gap={1}>
            <SizeButton manual={manual} selected={width === 2 && height === 2} onClick={() => handleChange({ width: 2, height: 2 }, manual)} label={"Small"} />
            <SizeButton manual={manual} selected={width === 5 && height === 3} onClick={() => handleChange({ width: 5, height: 3 }, manual)} label={"Medium"} />
            <SizeButton manual={manual} selected={width === 10 && height === 4} onClick={() => handleChange({ width: 10, height: 4 }, manual)} label={"Large"} />
            <SizeButton selected={manual} disabled={manual} onClick={() => { setManual(true); setPreview(true); }} key="manual" label={<FormattedMessage id="MANUAL" />} icon={<EditOutlined fontSize="small" />} />
            <Tooltip placement="right" title={<FormattedMessage id="PREVIEW" />}>
                <IconButton size="small" onClick={() => setPreview(!preview)} color={preview ? "primary" : "default"}><VisibilityOutlined /></IconButton>
            </Tooltip>
        </Stack>
        <Stack direction="row" gap={2} alignItems="center">
            <ManualSizeSlider disabled={!manual} label={<FormattedMessage id="WIDTH" />} value={width} onChange={(value) => handleChange({ width: value, height: height })} min={2} max={10} />
            <Typography variant="h6" color={"text." + (manual ? "secondary" : "disabled")}>x</Typography>
            <ManualSizeSlider disabled={!manual} label={<FormattedMessage id="HEIGHT" />} value={height} onChange={(value) => handleChange({ width: width, height: value })} min={2} max={4} />
        </Stack>
        <Collapse in={preview}>
            <Grid container direction="row" spacing={0.2} columns={9} sx={{ width: '50%', marginX: 'auto !important' }}>
                {Array.from({ length: 9 * 3 /* size of fullscreen widget on the dashboard*/ })
                    .map((el, i) => <Grid key={i} xl={1} lg={1} md={1} item>
                        <Card sx={{ minWidth: 10, minHeight: 40, bgcolor: matrix.flat()[i] ? 'primary.main' : 'background.paper' }} />
                    </Grid>)}
            </Grid>
        </Collapse>
    </Stack>
}