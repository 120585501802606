import instance, { setAuthHeader } from "./axiosEnv";

const getAllMeasureEntities = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/measureEntity', config);
}

const createMeasureEntity = (token, entityObject) => {
    const config = setAuthHeader(token);
    return instance.post('/measureEntity', entityObject, config);
}

const updateMeasureEntity = (token, id, entityObject) => {
    const config = setAuthHeader(token);
    return instance.put('/measureEntity/' + id, entityObject, config);
}

const deleteMeasureEntity = (token, id) => {
    const config = setAuthHeader(token);
    return instance.delete('/measureEntity/' + id, config);
}

const entitiesAPI = {
    getAllMeasureEntities,
    createMeasureEntity,
    updateMeasureEntity,
    deleteMeasureEntity,
}

export default entitiesAPI;