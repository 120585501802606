import { Autocomplete, Checkbox, Collapse, FormControl, FormLabel, Stack, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectEntities, selectMeasureEntities } from 'redux/entitySlice';
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';
import { GroupHeader, GroupItems } from 'components';
import { selectCollectorTypesConf, selectMeasurementsTypesConf } from 'redux/configurationSlice';

const LineChartConstructor = ({ checkExistance, selectedEntities, setSelectedEntities, selectedAttributes, setSelectedAttributes, collectorType, setCollectorType, allMetrics, existingWidget, dataType, fromTable }) => {
    const intl = useIntl();
    const entities = useSelector(selectEntities);
    const measureEntities = useSelector(selectMeasureEntities);
    const collectorTypes = useSelector(selectCollectorTypesConf);
    const measurementTypes = useSelector(selectMeasurementsTypesConf);
    const [loadedMetrics, setLoadedMetrics] = useState(false);

    const filterMeasurements = useCallback((entityArray) => {
        const measurementList = [];
        entityArray.forEach(entity => {
            measureEntities[entity._id]?.measurementTypes?.service?.OWM?.forEach(key => measurementList.push(key));
            measureEntities[entity._id]?.measurementTypes?.sensor?.types?.forEach(key => measurementList.push(key));
            measureEntities[entity._id]?.measurementTypes?.manual?.types?.forEach(key => measurementList.push(key));
        })

        return [...new Set(measurementList)];
    }, [measureEntities]);

    const filterMetrics = useCallback((entityArray) => {
        const entityIds = entityArray.map(ent => ent._id);
        const filteredMetrics = allMetrics.filter(met => entityIds.includes(met.entityId));
        return filteredMetrics;
    }, [allMetrics]);

    useEffect(() => {
        if (allMetrics?.length > 0) {
            setLoadedMetrics(true);
        }
    }, [allMetrics])

    useEffect(() => {
        const loadValues = async () => {
            if (existingWidget) {
                const existingEntities = Object.values(entities).filter(ent =>
                    existingWidget.config.source[existingWidget.config.source.dataType].entity?.includes(ent._id)
                );
                setSelectedEntities(existingEntities);

                if (existingWidget.config.source[existingWidget.config.source.dataType].collector) {
                    setCollectorType(
                        collectorTypes.filter(col =>
                            existingWidget.config.source[existingWidget.config.source.dataType].collector?.includes(col.key)
                        )
                    );
                }

                let attributes = [];
                if (fromTable) {
                    if (existingWidget.config.source.dataType === "metric" || existingWidget.config.source.dataType === "measurement") {
                        attributes = filterMeasurements(existingEntities).filter(meas =>
                            existingWidget.config.source[existingWidget.config.source.dataType].measurementType?.includes(meas)
                        );
                    }
                    if (existingWidget.config.source.dataType === "metricReport" || existingWidget.config.source.dataType === "target") {
                        attributes = filterMetrics(existingEntities).filter(met =>
                            existingWidget.config.source[existingWidget.config.source.dataType].metric?.includes(met._id)
                        );
                    }
                }
                else {


                    if (existingWidget.config.source.dataType === "measurement" || existingWidget.config.source.dataType === "measurementType") {
                        attributes = filterMeasurements(existingEntities).filter(meas =>
                            existingWidget.config.source[existingWidget.config.source.dataType].measurementType?.includes(meas)
                        );
                    } else if (loadedMetrics) {
                        attributes = filterMetrics(existingEntities).filter(met =>
                            existingWidget.config.source[existingWidget.config.source.dataType].metric?.includes(met._id)
                        );
                    }
                }
                setSelectedAttributes(attributes);
            }
        };

        loadValues();

    }, [fromTable, loadedMetrics, collectorTypes, entities, existingWidget, filterMeasurements, filterMetrics, setCollectorType, setSelectedAttributes, setSelectedEntities]);
    return <><Stack direction="column" width={"100%"} spacing={1} sx={{ mb: fromTable ? 1 : 0 }}>
        {checkExistance("entity") && <FormControl>
            <FormLabel required={!fromTable}><FormattedMessage id="ENTITIES" /></FormLabel>
            <Autocomplete
                id="select-entities"
                multiple
                options={Object.keys(entities).filter(ent => !!measureEntities[ent]).map(ent => entities[ent])}
                getOptionLabel={(option) => option.name}
                value={selectedEntities}
                onChange={(e, newValue) => {
                    if ((dataType === "measurement" || dataType === "metric") && selectedAttributes.length && !selectedAttributes.every(el => filterMeasurements(newValue).includes(el))) setSelectedAttributes(selectedAttributes.filter(el => filterMeasurements(newValue).includes(el)));
                    else if ((dataType === "metricReport" || dataType === "target") && selectedAttributes.length && !selectedAttributes.every(el => filterMetrics(newValue).includes(el))) setSelectedAttributes(selectedAttributes.filter(el => filterMetrics(newValue).includes(el)));
                    setSelectedEntities(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                size="small"
                fullWidth
            />
        </FormControl>}
    </Stack >
        <Collapse in={selectedEntities.length > 0}>
            <Stack direction="column" width={"100%"} spacing={1} sx={{ mb: fromTable ? 1 : 0 }}>
                {checkExistance("measurementType") && < FormControl >
                    <FormLabel><FormattedMessage id="MEASUREMENT_TYPES" /></FormLabel>
                    <Autocomplete
                        id="select-measurements-type"
                        multiple
                        options={filterMeasurements(selectedEntities)}
                        getOptionLabel={(option) => {
                            const type = measurementTypes.find(el => el.key === option);
                            return type.name || intl.formatMessage({ id: option });
                        }}
                        value={selectedAttributes}
                        onChange={(e, newValue) => setSelectedAttributes(newValue)}
                        renderInput={(params) => <TextField {...params} />}
                        size="small"
                        fullWidth
                    />
                </FormControl>}
                {checkExistance("metric") && selectedEntities.length > 0 && <FormControl>
                    <FormLabel required={!fromTable}><FormattedMessage id="ESG.METRICS" /></FormLabel>
                    <Autocomplete
                        id="select-metric-type"
                        multiple
                        options={filterMetrics(selectedEntities)}
                        getOptionLabel={(metric) => `${metric.name} (${entities[metric.entityId]?.name})`}
                        value={selectedAttributes}
                        onChange={(e, newValue) => setSelectedAttributes(newValue)}
                        renderInput={(params) => <TextField {...params} />}
                        size="small"
                        fullWidth
                        isOptionEqualToValue={(option, value) => option?._id === value?._id}
                        groupBy={(option) => option.entityId}
                        renderGroup={(params) => (
                            <li key={params.group}>
                                <GroupHeader>{entities[params.group].name}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                            </li>
                        )}
                        renderOption={(props, option, { selected }) => {
                            return <li {...props} key={option._id}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                                    checkedIcon={<CheckBoxOutlined fontSize="small" />}
                                    checked={selected}
                                    style={{ marginRight: 8 }}
                                />
                                {option.name}
                            </li>
                        }}
                    />
                </FormControl>
                }
                {checkExistance("collector") && selectedEntities.length > 0 && <FormControl>
                    <FormLabel ><FormattedMessage id="SOURCE_TYPE" /></FormLabel>
                    <Autocomplete
                        id="select-collector-type"
                        multiple
                        options={collectorTypes}
                        getOptionLabel={(option) => intl.formatMessage({ id: option.value + '_TYPE' })}
                        value={collectorType}
                        onChange={(e, newValue) => setCollectorType(newValue)}
                        renderInput={(params) => <TextField {...params} />}
                        size="small"
                        fullWidth
                    />
                </FormControl>}
            </Stack>
        </Collapse></>
}

export default LineChartConstructor;