import instance from "./axiosEnv";
import { setAuthHeader } from "./axiosEnv";
import { localISOString } from "utils";

const getAllTargets = (token, goalIds, entityIds, metricIds, statuses, types, dateFrom, dateTo) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    if (entityIds) {
        for (let el of entityIds) {
            queryParams.append('entityId', el);
        }
    }
    if (metricIds) {
        for (let el of metricIds) {
            queryParams.append('metricId', el);
        }
    }
    if (goalIds) {
        for (let el of goalIds) {
            queryParams.append('goalId', el);
        }
    }
    if (statuses) {
        for (let el of statuses) {
            queryParams.append('status', el);
        }
    }
    if (types) {
        for (let el of types) {
            queryParams.append('types', el);
        }
    }
    if (dateFrom) queryParams.append('dateFrom', localISOString(dateFrom));
    if (dateTo) queryParams.append('dateTo', localISOString(dateTo));
    return instance.get('/ESG/target?' + queryParams.toString(), config);
}

const getTarget = (targetId, token) => {
    const config = setAuthHeader(token);
    return instance.get('/ESG/target/' + targetId, config);
}

const getTargetsCount = (token, entityIds, statuses, types, goalIds, metricIds, dateFrom, dateTo, display) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    if (entityIds) {
        for (let el of entityIds) {
            queryParams.append('entityId', el);
        }
    }
    if (statuses) {
        for (let el of statuses) {
            queryParams.append('status', el);
        }
    }
    if (types) {
        for (let el of types) {
            queryParams.append('type', el);
        }
    }
    if (goalIds) {
        for (let el of goalIds) {
            queryParams.append('goalId', el);
        }
    }
    if (metricIds) {
        for (let el of metricIds) {
            queryParams.append('metricId', el);
        }
    }
    if (dateFrom) queryParams.append('dateFrom', localISOString(dateFrom));
    if (dateTo) queryParams.append('dateTo', localISOString(dateTo));
    if (display) queryParams.append('display', display);

    return instance.get('/ESG/target/count?' + queryParams.toString(), config);
}

const updateTarget = (targetId, data, token) => {
    const config = setAuthHeader(token);
    return instance.put('/ESG/target/' + targetId, data, config);
}
const updateTargetStatus = (targetId, status, token) => {
    const config = setAuthHeader(token);
    return instance.put(`/ESG/target/${targetId}/status/${status}`, null, config);
}

const createTarget = (data, token) => {
    const config = setAuthHeader(token);
    return instance.post('/ESG/target', data, config);
}

const deleteTarget = (targetId, token) => {
    const config = setAuthHeader(token);
    return instance.delete('/ESG/target/' + targetId, config);
}

const targetAPI = {
    getAllTargets,
    getTarget,
    getTargetsCount,
    updateTarget,
    updateTargetStatus,
    createTarget,
    deleteTarget,
};

export default targetAPI;