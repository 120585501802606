import { AddOutlined, LabelImportantOutlined } from "@mui/icons-material";
import { Autocomplete, Button, FormControl, FormLabel, MenuItem, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { selectSeverityTypes } from "redux/configurationSlice";
import { selectEntities } from "redux/entitySlice";
import AlarmProfileRules from "./AlarmProfileRules";


export default function AlarmProfileFormContent({ name, entity, alarmRules, usedTypes, updateAlarm }) {
    const entitiesList = useSelector(selectEntities);
    const severityTypes = useSelector(selectSeverityTypes);
    const [newRule, setNewRule] = useState(false);

    return <Stack direction="column" spacing={1}>
        <FormControl>
            <FormLabel required><FormattedMessage id="NAME" /></FormLabel>
            <TextField
                required
                fullWidth
                variant="outlined"
                id="NAME"
                type="text"
                size="small"
                value={name || ""}
                onChange={(e) => updateAlarm(e.target.value, "name")}
            />
        </FormControl>
        <FormControl>
            <FormLabel required><FormattedMessage id="ENTITY" /></FormLabel>
            <Autocomplete
                id="select-entity"
                options={Object.values(entitiesList)}
                getOptionLabel={(entity) => entity ? entity.name : ""}
                isOptionEqualToValue={(opt, val) => opt?.name === val?.name}
                value={entity}
                onChange={(e, entity) => updateAlarm(entity, "entity")}
                renderInput={(params) => (
                    <TextField {...params} />
                )}
                size="small"
                fullWidth
            />
        </FormControl>
        <FormLabel><FormattedMessage id="ALARMS.PROFILE_RULES" /></FormLabel>

        {usedTypes.map((rule) => alarmRules[rule] !== undefined && <AlarmProfileRules key={rule} alarmRule={alarmRules[rule]} rule={rule} updateAlarm={updateAlarm} entityId={entity?._id} severityTypes={severityTypes} usedTypes={usedTypes} />)}

        {usedTypes.length !== severityTypes.length && (!newRule ? <Button onClick={() => setNewRule(true)} startIcon={<AddOutlined />}>
            <FormattedMessage id="ADD.RULE" />
        </Button> : <FormControl>
            <TextField
                sx={{ textAlign: 'center', minWidth: '20%' }}
                select
                size="small"
                value={""}
                fullWidth
                label={<FormattedMessage id="ADD.RULE" />}
                onChange={(e) => {
                    const type = e.target.value?.value
                    setNewRule(false);
                    // setUsedTypes([...usedTypes, type.value]);
                    updateAlarm(type, 'rules-add');
                }}
            >
                {severityTypes.filter(el => !usedTypes.includes(el.value)).map((sevType, index) => (
                    <MenuItem key={sevType.value + index} value={sevType}>
                        <LabelImportantOutlined
                            name={'severity-' + sevType.value}
                            className={'severity-' + sevType.value}
                            sx={(theme) => ({ width: theme.typography.fontSize * 1, height: theme.typography.fontSize * 1, mr: 0.7 })}
                        />
                        <FormattedMessage id={"ALARMS.PROFILE_RULES." + sevType.value} />
                    </MenuItem>
                ))}
            </TextField>
        </FormControl>)}
    </Stack>
}