import { VerifiedUserOutlined } from "@mui/icons-material";
import { Button, ButtonGroup, Stack } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { permissionSelector } from "redux/userSlice";


export default function RolesUsersLinks({ users, userGroups, roles }) {
    const navigate = useNavigate();
    const viewRolesPermission = useSelector((state) => permissionSelector(state, 'view-role'));
    const viewUsersPermission = useSelector((state) => permissionSelector(state, 'view-users'));

    return <Stack direction="row" gap={0.5}>
        {viewUsersPermission && <ButtonGroup size="small">
            <Button variant={users ? "contained" : "outlined"} onClick={users ? undefined : () => navigate('/administration/users')}>
                <FormattedMessage id="USERS" />
            </Button>
            <Button variant={userGroups ? "contained" : "outlined"} onClick={userGroups ? undefined : () => navigate('/administration/userGroups')}>
                <FormattedMessage id="USER_GROUPS" />
            </Button>
        </ButtonGroup>}
        {viewRolesPermission && viewUsersPermission && <Button startIcon={<VerifiedUserOutlined fontSize="small"/>} size="small" variant={roles ? "contained" : "outlined"} onClick={roles ? undefined : () => navigate('/administration/roles')}>
            <FormattedMessage id="ROLES" />
        </Button>}
    </Stack>
}