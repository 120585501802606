import axios from 'axios';
import { keycloak } from './authKeycloak';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH;

const instance = axios.create({
    baseURL: BASE_URL,
    params: {
        realm: process.env.REACT_APP_KEYCLOAK_REALM
    },
});
instance.interceptors.response.use(res => res, (error) => {
    if (keycloak.authenticated) {
        if (error.response?.status === 401) {
            error.data = { id: "UNAUTHORIZED" };
            setTimeout(() => {
                window.history.pushState(undefined, undefined, "/");
                keycloak.logout();
            }, 5000);
        }
        if (error.response?.status === 403) {
            error.data = { id: "UNAUTHORIZED.NO_PERMISSION" };
        }
        throw error;
    }
    else throw error.response;
});

export default instance;

export const setAuthHeader = (token, config) => {
    if (config) {
        config.headers['Authorization'] = 'Bearer ' + token;
        return config;
    }
    else return {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
}

export const setContentTypeHeader = (contentType) => {
    const contentTypes = {
        "JSON": 'application/json',
        "FORM": 'multipart/form-data',
    }

    return { headers: { 'Content-Type': contentTypes[contentType] } }
}