import { Alert, Box } from '@mui/material';
import API from 'api';
import { StackedBarChart } from 'charts';
import { LoadingData } from 'components';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectPriorityTypes, selectStatusTypes, selectMetricTypesConf, selectGoalCategoryTypesConf, selectSeverityTypes } from 'redux/configurationSlice';
import { selectEntities } from 'redux/entitySlice';
import { selectTopics } from 'redux/topicSlice';
import { userSelector } from 'redux/userSlice';

const DashboardItemBarChart = ({ metricIds, entityId, dataType, category, priority, type, status, goalForTarget, topicId, display, severities, profileIds, ...props }) => {
    const [data, setData] = useState({ loading: true });
    const { token } = useSelector(userSelector);
    const entities = useSelector(selectEntities);
    const intl = useIntl();
    const statusTypes = useSelector(selectStatusTypes);
    const priorityTypes = useSelector(selectPriorityTypes);
    const severityTypes = useSelector(selectSeverityTypes);
    const categories = useSelector(selectGoalCategoryTypesConf);
    const metricTypes = useSelector(selectMetricTypesConf);
    const topics = useSelector(selectTopics);

    useEffect(() => {
        const convertData = (apiData) => {
            const chartData = {
                labels: [],
                datasets: [{
                    data: [],
                    key: 0,
                }]
            }
            if (metricIds && dataType !== "target") {
                apiData.forEach((element, index) => {
                    chartData.labels.push(`${element.name} (${entities[element.entityId]?.name})`);
                    chartData.datasets[0].data.push(element.value);
                });
            }
            else if (dataType === "goal" || dataType === "topic" || dataType === "target" || dataType === "alarm" || dataType === "alarmProfile") {
                apiData.forEach((element, index) => {
                    chartData.datasets[0].data.push(element.count);
                    if (display === "status") chartData.labels.push(intl.formatMessage({ id: "STATUS." + statusTypes.find(type => type.key.toString() === element[display])?.value }));
                    else if (display === "type") chartData.labels.push(intl.formatMessage({ id: "ESG.TYPE." + metricTypes.find(type => type.key.toString() === element[display])?.key }));
                    else if (display === "category") chartData.labels.push(intl.formatMessage({ id: "ESG.GOAL." + categories.find(type => type.key.toString() === element[display])?.value }));
                    else if (display === "priority") chartData.labels.push(intl.formatMessage({ id: "PRIORITY." + priorityTypes.find(type => type.key.toString() === element[display])?.value }));
                    else if (display === "topicId") {
                        const topic = topics.find(top => top._id === element[display])
                        chartData.labels.push(`${topic?.name} (${entities[topic.entityId]?.name})`);
                    }
                    else if (display === "entityId") chartData.labels.push(entities[element[display]]?.name);
                    else if (display === "goalId" || display === "profileId") chartData.labels.push(element.name);
                    else if (display === "metricId") chartData.labels.push(`${element.name} (${element.entity})`);
                    else if (display === "severityType") chartData.labels.push(intl.formatMessage({ id: "ALARMS.PROFILE_RULES." + severityTypes.find(type => type.key.toString() === element[display])?.value }));
                    else if (display === "enabled") chartData.labels.push(intl.formatMessage({ id: element[display] === "true" ? "ENABLED" : "DISABLED" }));
                    else if (display === "collector") chartData.labels.push(element[display]);

                    //else chartData.labels.push(`${display.toUpperCase()} = ${element[display]}`);
                });
            }
            setData(chartData);
        }

        const dateFrom = new Date(props.dateFrom);
        const dateTo = new Date(props.dateTo);

        if (metricIds && dataType !== "target") {
            API.metricReports.getMetricReportBarChartData(token, metricIds, dateFrom, dateTo).then(response => {
                if (response.data) convertData(response.data)
            }).catch(error => {
                setData({ error: error })
                console.error(error);
            })
        }
        else if (dataType === "goal") {
            API.goal.getGoalsCount(token, entityId, status, category, type, topicId, dateFrom, dateTo, display === "entity" ? "entityId" : display).then(response => {
                if (response.data) convertData(response.data)
            }).catch(error => {
                setData({ error: error })
                console.error(error);
            })
        }
        else if (dataType === "topic") {
            API.topic.getTopicsCount(token, entityId, priority, type, dateFrom, dateTo, display === "entity" ? "entityId" : display).then(response => {
                if (response.data) convertData(response.data)
            }).catch(error => {
                setData({ error: error })
                console.error(error);
            })
        }
        else if (dataType === "target") {
            API.target.getTargetsCount(token, entityId, status, type, goalForTarget, metricIds, dateFrom, dateTo, goalForTarget ? "goalId" : display === "entity" ? "entityId" : display).then(response => {
                if (response.data) convertData(response.data)
            }).catch(error => {
                setData({ error: error })
                console.error(error);
            })
        }
        else if (dataType === "alarm") {
            API.alarm.getAlarmsCount(token, dateFrom, dateTo, entityId, severities, profileIds, display === "entity" ? "entityId" : display).then(response => {
                if (response.data) convertData(response.data)
            }).catch(error => {
                setData({ error: error })
                console.error(error);
            })
        }
        else if (dataType === "alarmProfile") {
            API.alarmProfile.getAlarmProfilesCount(token, entityId, display === "entity" ? "entityId" : display).then(response => {
                if (response.data) convertData(response.data)
            }).catch(error => {
                setData({ error: error })
                console.error(error);
            })
        }

    }, [token, metricIds, props.dateFrom, props.dateTo, entities, category, dataType, status, type, entityId, display, priority, goalForTarget, topicId, intl, statusTypes, categories, metricTypes, priorityTypes, topics, severityTypes, severities, profileIds])

    const render = useCallback(() => {
        if (!data.loading && data.datasets && data.datasets.length > 0) return <Box sx={{ minHeight: 300 }}><StackedBarChart name={"barchart"} data={data} dataType title={intl.formatMessage({ id: "DASHBOARD.OPTIONS.COUNT." + dataType })} /></Box>;
        else if (data.loading) return <LoadingData />;
        else if (data.error) return <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>;
        else return <Alert severity="warning"><FormattedMessage id='NO_DATA_TIME_PERIOD' /></Alert>

    }, [data, dataType, intl])

    return <>
        {render()}
    </>
}

export default DashboardItemBarChart;