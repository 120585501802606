import React, { useState } from 'react';
import TargetDetailsView from 'components/targetDetailsView/TargetDetailsView';
import { PreviewOutlined } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { IconButton, List, ListItem, ListItemText, Tooltip, Typography } from "@mui/material";

const CompactTargetList = ({ targets, xsmall, onUpdateTargetStatus, onUpdate, customAction, customActionHandler }) => {
    const [target, setTarget] = useState(null);

    const onUpdateStatus = (status, id) => {
        setTarget(target => ({ ...target, status: status }))
        onUpdateTargetStatus(status, id);
    }

    return (
        <>
            <TargetDetailsView target={target} openDialog={Boolean(target)} small={!xsmall} xsmall={xsmall} onUpdateTargetStatus={onUpdateStatus} onClose={() => setTarget(null)} onUpdate={onUpdate} />
            <List>
                {targets.map((target, index) => (
                    <ListItem
                        key={target._id}
                        secondaryAction={
                            <Tooltip title={customAction?.title ? customAction?.title : <FormattedMessage id="ESG.TARGET.VIEW" />} placement="left">
                                <span>
                                    <IconButton size="small" color="primary" edge="end" aria-label="view" onClick={() => customActionHandler ? customActionHandler(target) : setTarget(target)}>
                                        {customAction?.icon ? customAction?.icon : <PreviewOutlined fontSize='small' />}
                                    </IconButton>
                                </span>
                            </Tooltip>
                        }
                    >
                        <ListItemText
                            primaryTypographyProps={{ variant: 'button', component: 'span' }}
                            primary={<span><Typography variant="span" color="text.disabled" display="inline">{index + 1}.</Typography> {target.name}</span>}
                            secondary={<span><FormattedMessage id="ESG.TARGET.VALUE.CURRENT" />: <Typography color={target.measurable?.currentValue ? "primary" : "text.disabled"} component="span" display="inline">{parseFloat(target.measurable?.currentValue).toFixed(4)}</Typography></span>}
                        />
                    </ListItem>
                ))}
            </List>
        </>
    )

}
export default CompactTargetList;