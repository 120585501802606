import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, Button, DialogActions, DialogContent, Divider } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const ConfirmationDialog = (props) => {
    const { open, title, content, handleCancel, handleCustomButton, customButtonTitle, customButtonColor, customCancelTitle, disabled, maxWidth, fullWidth } = props;
    const [clicked, setClicked] = useState(false);

    const handleClicked = () => {
        setClicked(true);
        handleCustomButton();
    }

    useEffect(() => {
        if (!open) setClicked(false);
    }, [open]);

    if (!open) return null;

    return (
        <Dialog onClose={handleCancel} open={open} maxWidth={maxWidth || 'sm'} fullWidth={Boolean(fullWidth)}>
            <DialogTitle> {title} </DialogTitle>
            {Boolean(content) && <Divider />}
            <DialogContent> {content} </DialogContent>
            <Divider />
            <DialogActions>
                <Button color="warning" onClick={handleCancel}>{customCancelTitle || <FormattedMessage id="CANCEL" />}</Button>
                <Button disabled={clicked || disabled} onClick={handleClicked} color={customButtonColor}> {customButtonTitle} </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ConfirmationDialog