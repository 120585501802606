import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectEntities } from 'redux/entitySlice';
import { userSelector } from 'redux/userSlice';
import { selectMeasurementsTypesConf, selectUnits } from 'redux/configurationSlice';
import { updateMetricInTopic } from 'redux/topicSlice';
import { DescriptiveSection, MeasurementsSection, ReportSection, DialogFormTitle } from "components";
import { Button, Collapse, Dialog, DialogActions, DialogContent, Divider, Typography, useMediaQuery } from '@mui/material';
import { localISOString } from "utils";
import API from 'api';

const QUANTITATIVE = process.env.REACT_APP_ESG_METRIC_DEFAULT_TYPE;

const SimpleMetricForm = ({ open, onClose, entityId, metric, dialogAction, setAlert }) => {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"))
    const entities = useSelector(selectEntities);
    const measurementTypes = useSelector(selectMeasurementsTypesConf);
    const units = useSelector(selectUnits);
    const [selectedEntity, setselectedEntity] = useState([]);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [owner, setOwner] = useState("");
    const [metricType, setMetricType] = useState("");
    const [categoryType, setCategoryType] = useState(QUANTITATIVE);
    const [measurementType, setMeasurementType] = useState("");
    const [sourceType, setSourceType] = useState('');
    const [collectorName, setCollectorName] = useState("");
    const [unit, setUnit] = useState("");
    const [reportInterval, setReportInterval] = useState("");
    const [dataFrequencyInterval, setDataFrequencyInterval] = useState("");
    const [calculationMethod, setCalculationMethod] = useState("");
    const [dateFrom, setDateFrom] = useState(Date.now());
    const [allEntitiesFlag, setAllEntitiesFlag] = useState(false);
    const { token } = useSelector(userSelector);
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [reportUnit, setReportUnit] = useState({
        prefix: "",
        unit: "",
        suffix: ""
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (entityId && open) setselectedEntity([entities[entityId]])
        if (metric && open) {
            setName(metric.name);
            setDescription(metric.description || "");
            setOwner(metric.owner ?? "");
            setMetricType(metric.type ?? "");
            setCategoryType(metric.category.type || QUANTITATIVE);
            setReportUnit(reportUnit => metric.reportData.reportUnit ? { ...reportUnit, ...metric.reportData.reportUnit, unit: { name: metric.reportData.reportUnit.unit } } : reportUnit)
            if (metric.category.scope) {
                setMeasurementType(measurementTypes.find(el => el.key === metric.category.scope.measurementTypeKey) ?? "");
                setUnit({ name: Object.values(units).flat().find(el => el === metric.category.scope.unit) ?? "" });
                setSourceType(metric.category.scope.sourceType ?? '');
                setCollectorName(metric.category.scope.collector ?? "");
            }
            setReportInterval(metric.reportData.reportInterval ?? "");
            setDataFrequencyInterval(metric.reportData.dataFrequencyInterval ?? "");
            setCalculationMethod(metric.reportData.calculationMethod ?? "");
            setDateFrom(new Date(metric.reportData.startDate).getTime() ?? NaN);
        }
    }, [metric, open, entityId, entities, units, measurementTypes]);

    const handleClose = (resetFlag) => {
        setselectedEntity([]);
        setName("");
        setDescription("");
        setOwner("");
        setMetricType("");
        setMeasurementType("");
        setSourceType('');
        setCategoryType(QUANTITATIVE)
        setCollectorName("");
        setUnit("");
        setReportInterval("");
        setDataFrequencyInterval("");
        setCalculationMethod("");
        setDateFrom(Date.now());
        setAllEntitiesFlag(false);
        setEditMode(false);
        setReportUnit({
            prefix: "",
            unit: "",
            suffix: ""
        })
        onClose(resetFlag);
    }

    const compareData = (oldData, newData) => {
        return (
            oldData.name?.trim() === newData.name?.trim() &&
            oldData.entityIdModel === newData.entityIdModel &&
            oldData.entityId === newData.entityId &&
            oldData.categoryType === newData.categoryType &&
            oldData.type === newData.type &&
            oldData.reportData.reportInterval === newData.reportInterval &&
            oldData.reportData.dataFrequencyInterval === newData.dataFrequencyInterval &&
            oldData.reportData.startDate === new Date(newData.startDate).toISOString() &&
            (oldData.reportData.reportUnit?.prefix === undefined && newData.reportUnit?.prefix === '' ? true
                : oldData.reportData.reportUnit?.prefix?.trim() === newData.reportUnit?.prefix?.trim() ? true : false
            ) &&
            (oldData.reportData.reportUnit?.suffix === undefined && newData.reportUnit?.suffix === '' ? true
                : oldData.reportData.reportUnit?.suffix?.trim() === newData.reportUnit?.suffix?.trim() ? true : false
            ) &&

            oldData.reportData.reportUnit?.unit?.trim() === newData.reportUnit?.unit?.trim() &&

            (oldData.description === undefined && newData.description === '' ? true
                : oldData.description?.trim() === newData.description?.trim() ? true : false
            ) &&
            (oldData.owner === undefined && newData.owner === '' ? true
                : oldData.owner?.trim() === newData.owner?.trim() ? true : false
            ) &&

            (oldData.categoryType === "Quantitative" ?
                (oldData.category?.scope?.collector === undefined && newData.collector === '' ? true
                    : oldData.category?.scope?.collector?.trim() === newData.collector?.trim() ? true : false
                ) &&
                (oldData.category?.scope?.measurementTypeKey === newData.measurementTypeKey ? true : false) &&
                ((oldData.category?.scope?.sourceType === undefined || oldData.category?.scope?.sourceType === null) && newData.sourceType === '' ? true
                    : oldData.category?.scope?.sourceType === undefined && newData.sourceType !== '' ? false
                        : oldData.category?.scope?.sourceType === newData.sourceType ? true : false
                ) &&
                (oldData.reportData?.calculationMethod === newData.calculationMethod ? true : false) &&
                (oldData.category?.scope?.unit === newData.unit ? true : false)
                : true)
        )
    }

    const onSubmit = () => {
        setDisabledSubmitButton(true);
        const quantitativeMetric = categoryType === QUANTITATIVE;

        const newMetricObject = {
            name: name,
            description: description,
            entityIdModel: "entity",
            entityId: allEntitiesFlag ? Object.keys(entities) : selectedEntity.map(el => el._id),
            categoryType: categoryType,
            type: metricType,
            ...(quantitativeMetric && {
                measurementTypeKey: measurementType.key,
                sourceType: sourceType,
                unit: unit?.name
            }),
            reportInterval: reportInterval,
            dataFrequencyInterval: dataFrequencyInterval,
            startDate: localISOString(dateFrom),
            reportUnit: {
                prefix: reportUnit.prefix,
                unit: reportUnit.unit?.name,
                suffix: reportUnit.suffix,
            },
            owner: owner,
            collector: collectorName,
            calculationMethod: calculationMethod,
        }

        if (metric && compareData(metric, newMetricObject)) {
            setAlert({ open: true, messageId: "ERROR.NO_CHANGE_DETECTED", severity: "error" });
            setDisabledSubmitButton(false);
        } else if (metric) API.metrics.putMetric(token, metric._id, newMetricObject).then(items => {
            dispatch(updateMetricInTopic(items.data));
            setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
            handleClose(true);
            setDisabledSubmitButton(false);
            setEditMode(false);
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_UPDATED", severity: "error" });
            console.error(error);
            setDisabledSubmitButton(false);
            setEditMode(false);
        })
        else API.metrics.postMetric(token, newMetricObject).then(items => {
            setAlert({ open: true, messageId: "SUCCESS.CREATE", severity: "success" });
            handleClose(true);
            setDisabledSubmitButton(false);
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_CREATED", severity: "error" });
            console.error(error);
            setDisabledSubmitButton(false);
        })
    }

    useEffect(() => {

        if (editMode) {
            if (((!entityId && allEntitiesFlag) || entityId || selectedEntity.length) &&
                name !== "" &&
                metricType !== "" &&
                (categoryType !== QUANTITATIVE || (calculationMethod !== "" && measurementType)) && // disable check on qualitative type
                reportInterval !== "" &&
                dataFrequencyInterval !== "" &&
                (!measurementType?.dataType?.measure || unit !== "" || !!reportUnit.unit?.name || calculationMethod === "count") &&
                (new Date(dateFrom).toString() !== "Invalid Date")) setDisabledSubmitButton(false);
            else setDisabledSubmitButton(true);
        } else setDisabledSubmitButton(true);
    }, [entityId, selectedEntity, allEntitiesFlag, name, calculationMethod, metricType, categoryType, measurementType, reportInterval, dataFrequencyInterval, dateFrom, description, owner, sourceType, unit, reportUnit, collectorName, editMode]);

    return <>
        <Dialog open={open} maxWidth="md" fullWidth fullScreen={smallScreen} >
            <DialogFormTitle
                mainTitle={<FormattedMessage id={metric ? "ESG.METRIC.EDIT" : "ESG.METRIC.FORM"} />}
                subTitle={metric ? metric.name : name}
                action={dialogAction}
            />
            <Divider />

            <DialogContent>
                <Divider>
                    <Typography variant='h6' color="secondary.main"><FormattedMessage id="DESCRIPTION" /></Typography>
                </Divider>
                <DescriptiveSection
                    entityId={entityId}
                    selectedEntity={selectedEntity} setselectedEntity={(e) => { setselectedEntity(e); if (entityId) setMeasurementType("") }}
                    allEntitiesFlag={allEntitiesFlag} setAllEntitiesFlag={setAllEntitiesFlag}
                    name={name} setName={setName}
                    description={description} setDescription={setDescription}
                    owner={owner} setOwner={setOwner}
                    metricType={metricType} setMetricType={setMetricType}
                    setEditMode={setEditMode}
                />

                <Collapse in={Boolean(selectedEntity.length) || allEntitiesFlag}>

                    {!metric && <><Divider>
                        <Typography variant='h6' color="secondary.main"><FormattedMessage id="MEASUREMENTS" /></Typography>
                    </Divider>
                        <MeasurementsSection
                            existingMetric={metric}
                            allEntitiesFlag={allEntitiesFlag}
                            categoryType={categoryType} setCategoryType={setCategoryType}
                            entityId={entityId} selectedEntity={selectedEntity}
                            measurementType={measurementType} setMeasurementType={setMeasurementType}
                            sourceType={sourceType} setSourceType={setSourceType}
                            collectorName={collectorName} setCollectorName={setCollectorName}
                            unit={unit} setUnit={setUnit}
                            calculationMethod={calculationMethod} setCalculationMethod={setCalculationMethod}
                            setEditMode={setEditMode}
                            reportUnit={reportUnit}
                            setReportUnit={setReportUnit}
                        />

                        <Divider>
                            <Typography variant='h6' color="secondary.main"><FormattedMessage id="REPORT" /></Typography>
                        </Divider>
                        <ReportSection
                            dateFrom={dateFrom} setDateFrom={setDateFrom}
                            measurementType={measurementType}
                            reportUnit={reportUnit} setReportUnit={setReportUnit}
                            reportInterval={reportInterval} setReportInterval={setReportInterval}
                            dataFrequencyInterval={dataFrequencyInterval} setDataFrequencyInterval={setDataFrequencyInterval}
                            setEditMode={setEditMode}
                            unit={unit}
                            isEditing={!!metric}
                            calculationMethod={calculationMethod}
                            categoryType={categoryType}
                        /></>}

                </Collapse>
            </DialogContent>
            <Divider />

            <DialogActions>
                <Button onClick={() => handleClose(false)} color="warning"><FormattedMessage id="CANCEL" /></Button>
                <Button disabled={disabledSubmitButton} onClick={onSubmit}><FormattedMessage id={metric ? "EDIT" : "ADD"} /></Button>
            </DialogActions>
        </Dialog>
    </>
}

export default SimpleMetricForm;