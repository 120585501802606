import { Button, MenuItem, Stack, TextField, useMediaQuery } from "@mui/material"
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { selectStatusTypes } from "redux/configurationSlice";


export default function StatusUpdateInput({ newStatus, disabled, disabledStatusArray, onStatusChange, onCancel, onUpdate }) {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const statusTypes = useSelector(selectStatusTypes);

    const buttons = [
        <Button key="cancel" size="small" color="warning" onClick={onCancel}><FormattedMessage id="CANCEL" /></Button>,
        <Button key="update" size="small" color="primary" onClick={onUpdate}><FormattedMessage id="UPDATE" /></Button>
    ];

    return <Stack direction={smallScreen ? "column" : "row"} spacing={smallScreen ? 0.5 : 1}>
        <TextField
            select
            disabled={Boolean(disabled)}
            fullWidth
            size="small"
            value={newStatus}
            onChange={(e) => onStatusChange(e.target.value)}
        >
            {statusTypes.map(type => {
                return <MenuItem disabled={Boolean(disabledStatusArray && disabledStatusArray.length && disabledStatusArray.includes(type.key))} key={type.key} value={type.key}><FormattedMessage id={"STATUS." + type.value} /></MenuItem>
            })}
        </TextField>
        {smallScreen ? <div>
            {buttons}
        </div> : buttons}
    </Stack>
}