import { Box, Paper, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { selectMeasurementsTypesConf } from "redux/configurationSlice";
import { formatDateLocale } from 'utils/helpers';


export default function MeasurementDisplayBlock({ data, error }) {
    const measurementTypes = useSelector(selectMeasurementsTypesConf);

    const type = measurementTypes.find(el => el.key === data.measurementTypeKey);

    if (type) return <Box>
        <Paper sx={theme => ({ p: 2, borderColor: theme.palette.error.main })} variant={error ? "outlined" : "elevation"}>
            <Typography variant="body2" display="block" color={data.error ? "text.disabled" : "primary"}>
                {type.name || <FormattedMessage id={type.key} />}
            </Typography>
            <Typography variant="h3" display="inline" sx={{ wordWrap: 'break-word' }}>
                {data.value || <Typography color="text.disabled" py={1}><FormattedMessage id="NO_VALUE" /></Typography>}
            </Typography>
            <Typography variant="body2" display="inline" color="text.secondary">
                {data.unit}
            </Typography>
            {data.error && <Typography variant="button" display="block" color="error">
                <FormattedMessage id={data.error} />
            </Typography>}
            {data.timestamp && <Typography variant="button" display="block" color="text.disabled">
                {formatDateLocale(data.timestamp)}
            </Typography>}
        </Paper>
    </Box>
    else return <Box><Typography variant="caption" color="error"><FormattedMessage id="ERROR" /> ({data && data.measurementTypeKey})</Typography></Box>
} 