import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { updateMeasureTypeConf, selectMeasurementsTypesConf } from 'redux/configurationSlice';
import { DialogFormTitle } from 'components';
import { Button, Dialog, DialogActions, DialogContent, Divider, useMediaQuery } from '@mui/material';
import MeasurementTypeFormContent from './MeasurementTypeFormContent';
import API from 'api';

const MeasurementTypeForm = ({ open, onClose, setAlert, existingMeasurementType, reset, setReset }) => {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [dataType, setDataType] = useState("");
    const [measure, setMeasure] = useState("");
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const measureTypeNames = useSelector(selectMeasurementsTypesConf).map(el => el.key);
    const { token } = useSelector(userSelector);
    const dispatch = useDispatch();

    const handleClose = () => {
        setName(existingMeasurementType?.name || "");
        setDescription(existingMeasurementType?.description ?? "");
        setDataType(existingMeasurementType?.dataType.type || "");
        setMeasure(existingMeasurementType?.dataType.measure ?? "");
        setEditMode(false);
        onClose();
    }

    useEffect(() => {
        if (existingMeasurementType && open) {
            setName(existingMeasurementType.name);
            setDescription(existingMeasurementType.description || "");
            setDataType(existingMeasurementType.dataType.type);
            setMeasure(existingMeasurementType.dataType.measure || "");
        }
        else if (open) {
            setName("");
            setDescription("");
            setDataType("");
            setMeasure("");
        }

    }, [open, existingMeasurementType])

    const compareData = (oldData, newData) => {
        return (
            oldData.name === newData.name &&
            oldData.description?.trim() === newData.description?.trim() &&
            oldData.dataType.type === newData.dataType.type &&
            oldData.dataType.measure === newData.dataType.measure
        )
    }

    const onSubmit = () => {
        setDisabledSubmitButton(true);
        const newMeasurementTypeObject = {
            name: name,
            description: description,
            dataType: {
                type: dataType,
                measure: measure
            }
        }

        if (existingMeasurementType && compareData(existingMeasurementType, newMeasurementTypeObject)) {
            setAlert({ open: true, messageId: "ERROR.NO_CHANGE_DETECTED", severity: "error" });
            setEditMode(false);
        } else if (existingMeasurementType) {
            API.measurementTypes.updateMeasurementType(newMeasurementTypeObject, token, existingMeasurementType._id).then(response => {
                setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
                dispatch(updateMeasureTypeConf(response.data));
                handleClose();
                setDisabledSubmitButton(false);
            }).catch(error => {
                console.error(error);
                setAlert({ open: true, messageId: error?.message?.id || "ERROR.NOT_UPDATED", severity: "error" });
                setDisabledSubmitButton(false);
            })
            setReset(reset + 1);
            setEditMode(false);
        }
        else {
            API.measurementTypes.createMeasurementType(newMeasurementTypeObject, token).then(response => {
                setAlert({ open: true, messageId: "SUCCESS.CREATE", severity: "success" });
                dispatch(updateMeasureTypeConf(response.data));
                handleClose();
                setDisabledSubmitButton(false);
            }).catch(error => {
                console.error(error);
                setAlert({ open: true, messageId: error?.message?.id || "ERROR.NOT_CREATED", severity: "error" });
                setDisabledSubmitButton(false);
            })
            setReset(reset + 1);
        }
    }

    useEffect(() => {
        if (editMode) setDisabledSubmitButton(false);
        else setDisabledSubmitButton(true);

        if (name.trim() === "" || measureTypeNames.filter(el => existingMeasurementType ? el !== existingMeasurementType.key : true).includes(name) || dataType === "") setDisabledSubmitButton(true);
    }, [name, dataType, description, measure, editMode, measureTypeNames, existingMeasurementType])

    return <>
        <Dialog open={open} maxWidth="md" fullWidth fullScreen={smallScreen} >
            <DialogFormTitle mainTitle={<FormattedMessage id={existingMeasurementType ? "MEASUREMENT_TYPES.EDIT" : "MEASUREMENT_TYPES.ADD"} />} />
            <Divider />
            <DialogContent>
                <MeasurementTypeFormContent existingMeasurementType={existingMeasurementType} name={name} setName={setName}
                    description={description} setDescription={setDescription}
                    dataType={dataType} setDataType={setDataType}
                    measure={measure} setMeasure={setMeasure}
                    setEditMode={setEditMode} />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={handleClose} color="warning"><FormattedMessage id="CANCEL" /></Button>
                <Button disabled={disabledSubmitButton} onClick={onSubmit}><FormattedMessage id={existingMeasurementType ? "EDIT" : "ADD"} /></Button>
            </DialogActions>

        </Dialog>
    </>
}

export default MeasurementTypeForm;