import { DefaultLayout } from 'layouts';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { SplashPage } from "pages";
import { DashboardView } from 'components';
import { EmptyState } from 'layouts';
import { Navigate } from 'react-router';


const DashboardPage = () => {
    return <EmptyState component={<DashboardView />} />;
}

const DashboardPageComponent = () => {
    const { isSuccess: loggedIn } = useSelector(userSelector);
    const viewDashboardPermission = useSelector((state) => permissionSelector(state, 'view-dashboard'));

    if (loggedIn && viewDashboardPermission) return <DefaultLayout page={<DashboardPage />} />;
    else if (loggedIn) return <Navigate to="/map" />;
    else return <SplashPage />;
};

export default DashboardPageComponent;