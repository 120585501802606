import { DefaultLayout } from 'layouts';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { SplashPage } from "pages";
import { MeasureEntitiesView } from 'components';
import { EmptyState } from 'layouts';


const MeasureEntitiesPage = () => {
    return <EmptyState component={<MeasureEntitiesView />} />;
}

const MeasureEntitiesPageComponent = () => {
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn) return <DefaultLayout page={<MeasureEntitiesPage />} />;
    else return <SplashPage />;
};

export default MeasureEntitiesPageComponent;