import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { selectEntities } from 'redux/entitySlice';
import { selectNoteTypes, selectNotificationMethodTypes } from 'redux/configurationSlice';
import { selectUsersAndGroups, selectUsersAndGroupsStatus, fetchUsersAndGroups } from 'redux/usersAndGroupsSlice';
import { DisplayBox, LoadingData, NoteForm, DialogFormTitle, UserList } from 'components';
import { NotificationMethodIcon, getNoteStatusColor } from 'components/notesView/NotesView';
import { Alert, Box, Button, Chip, Dialog, DialogActions, DialogContent, Divider, List, ListItem, ListItemText, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import { ArchiveOutlined, ReplayOutlined } from '@mui/icons-material';
import { formatDateLocale } from 'utils';
import { store } from 'redux/store';
import API from 'api';

const NoteDetails = ({ open, onClose, noteId, setAlert, setReset }) => {
    const fullScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const entities = useSelector(selectEntities);
    const noteTypes = useSelector(selectNoteTypes);
    const notificationMethodTypes = useSelector(selectNotificationMethodTypes);
    const usersAndGroups = useSelector(selectUsersAndGroups);
    const status = useSelector(selectUsersAndGroupsStatus);
    const intl = useIntl();
    const [note, setNote] = useState(null);
    const [loading, setLoading] = useState(false);
    const [resending, setResending] = useState(false);
    const [reset, setResetLocal] = useState(0);
    const { token } = useSelector(userSelector);
    const [openAddForm, setOpenAddForm] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [allGroups, setAllGroups] = useState([]);

    const resendPermission = useSelector((state) => permissionSelector(state, 'create-notes'));
    const viewUsersPermission = useSelector((state) => permissionSelector(state, 'view-users'));

    useEffect(() => {
        if (!viewUsersPermission) {
            setAllUsers([]);
            setAllGroups([]);
            return;
        }
        else if (status === 'failed') {
            store.dispatch(fetchUsersAndGroups(token));
        } else if (status === 'complete') {
            setAllUsers(usersAndGroups.users);
            setAllGroups(usersAndGroups.groups);
        } else {
            setAllUsers([]);
            setAllGroups([]);
        }
    }, [token, status, usersAndGroups, viewUsersPermission])

    useEffect(() => {
        setLoading(true);
        if (open) {
            API.notes.getNote(noteId, token).then(response => {
                if (response.data) setNote(response.data);
                setLoading(false);
            })
                .catch(error => {
                    console.error(error);
                    setNote(null);
                    setLoading(false);
                })
        }
    }, [token, open, noteId, reset]);

    const resendHandler = () => {
        setResending(true);
        API.notes.resendNote(noteId, token).then(response => {
            setAlert({ open: true, messageId: "SUCCESS", severity: "success" });
            setResetLocal(reset => reset + 1);
            setReset(reset => reset + 1);
            setResending(false);
        })
            .catch(error => {
                console.error(error);
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_SENT", severity: "error" })
                setResending(false);
            })
    }

    return <>
        <NoteForm open={openAddForm} onClose={() => setOpenAddForm(false)} setAlert={setAlert} setReset={setReset} onDetailsClose={onClose} existingNote={note} />
        <Dialog open={open} onClose={onClose} fullScreen={fullScreen} fullWidth>
            {loading ? <DialogContent><LoadingData /></DialogContent> : <>
                <DialogFormTitle disableHelpText mainTitle={<FormattedMessage id="NOTE.DETAILS" />}
                    subTitle={note && <Typography className='.ellipsis-header' color="primary" component="span">{note.title}</Typography>}
                    action={note?.archived && <Chip color="default" label={<FormattedMessage id="NOTE.ARCHIVED" />} icon={<ArchiveOutlined />} />}
                />
                <Divider />
                {note ? <DialogContent>
                    <Stack direction="column" spacing={1}>
                        <DisplayBox label={<FormattedMessage id="TITLE" />} value={note.title} />
                        <DisplayBox label={<FormattedMessage id="TIMESTAMP" />} value={note && formatDateLocale(note.timestamp)} />
                        {note.type !== undefined && <DisplayBox label={<FormattedMessage id="NOTE.TYPE" />} value={<FormattedMessage id={"NOTE.TYPE." + noteTypes.find(type => type.key === note.type)?.value} />} />}
                        <DisplayBox label={<FormattedMessage id="NOTE.CONTENT" />} value={<TextField value={note.content} multiline rows={4} fullWidth focused={false} />} />
                        <DisplayBox label={<FormattedMessage id="ENTITY" />} value={entities[note.entityId]?.name} />
                        {note.owner?.length > 0 && <DisplayBox label={<FormattedMessage id="OWNER" />} value={note.owner} />}

                        {note.notificationMethod?.length > 0 && <DisplayBox label={
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <FormattedMessage id="NOTIFICATIONS.METHOD" />
                                {note?.resendStatus && !note?.archived && resendPermission ? <Chip disabled={resending && !resendPermission} onClick={() => resendHandler()} size="small" sx={{ color: 'text.secondary' }} icon={<ReplayOutlined color="info" />} variant="outlined" label={<FormattedMessage id={!resending ? "NOTE.RESEND" : "NOTE.SENDING"} />} /> : null}
                            </Box>
                        } value={
                            <List>
                                {note.notificationMethod.map((item, index) => {
                                    const foundType = intl.formatMessage({ id: "NOTIFICATIONS.METHOD." + notificationMethodTypes.find(type => type.key === item)?.value });
                                    const noteStatus = note.sendStatus.find(el => el.statusType === item)?.message;
                                    return <ListItem key={item}>
                                        <ListItemText
                                            primaryTypographyProps={{ component: 'span' }}
                                            primary={<Stack direction="row" alignItems="flex-end" gap={1}>
                                                <Typography variant="caption" color="text.secondary">{index + 1}.</Typography>
                                                <Typography>{foundType}</Typography>
                                            </Stack>}
                                            secondary={<Chip component="span" icon={<NotificationMethodIcon sx={{ ml: 1 }} size="small" type={item} status={noteStatus} noTooltip />} size="small" color={getNoteStatusColor(noteStatus)} variant="outlined" label={<FormattedMessage id={"NOTE.STATUS." + noteStatus} />} />}
                                        />
                                    </ListItem>
                                })}
                            </List>
                        } />}

                        {note.contacts?.length > 0 && <UserList
                            label={<FormattedMessage id="NOTE.CONTACTS" />}
                            users={note.contacts.filter(contact => contact.type === "user").map(contact => allUsers.find(usr => usr.id === contact.id)?.username)}
                            groups={note.contacts.filter(participant => participant.type === "group").map(group => allGroups.find(grp => grp.id === group.id))}
                        />}

                    </Stack>
                </DialogContent> :
                    <Alert severity="error"><FormattedMessage id="NO_DATA" /></Alert>}
            </>}
            <Divider />
            <DialogActions>
                <Button color="warning" onClick={onClose}><FormattedMessage id="CLOSE" /></Button>
                {!loading && note && resendPermission && <Button onClick={() => setOpenAddForm(true)}><FormattedMessage id="COPY" /></Button>}
            </DialogActions>
        </Dialog>
    </>
}

export default NoteDetails;