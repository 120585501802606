import { DefaultLayout } from 'layouts';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { SplashPage } from "pages";
import { EmptyState } from 'layouts';
import { MetricsEntry } from 'components';

const MetricsEntryPage = () => {
    return <EmptyState component={<MetricsEntry />} />;
}

const MetricsEntryPageComponent = () => {
    const { isSuccess: loggedIn } = useSelector(userSelector);
    const updateMetricPermission = useSelector((state) => permissionSelector(state, 'update-metric'));

    if (loggedIn && updateMetricPermission) return <DefaultLayout page={<MetricsEntryPage />} />;
    else return <SplashPage />;
};

export default MetricsEntryPageComponent;