import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Divider, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { DialogFormTitle } from 'components';
import { FormattedMessage, useIntl } from 'react-intl';
import { CloseOutlined, DeleteOutlined } from '@mui/icons-material';
import DashboardFormContent from './DashboardFormContent';

const DashboardForm = ({ open, onClose, setDashboardItems, setLayout, existingWidget, handleDelete, setDisabledSaveButton }) => {
    const getLastYearDate = () => {
        const currentDate = new Date();
        currentDate.setFullYear(currentDate.getFullYear() - 1);
        return currentDate.getTime();
    };
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const intl = useIntl();
    const [title, setTitle] = useState("");
    const [showTitle, setShowTitle] = useState(true);
    const [size, setSize] = useState({ width: 5, height: 3 });
    const [itemType, setItemType] = useState(existingWidget?.config?.type || null);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [collectorType, setCollectorType] = useState([]);;
    const [dateFrom, setDateFrom] = useState(getLastYearDate());
    const [dateTo, setDateTo] = useState(Date.now());
    const [dataType, setDataType] = useState(existingWidget?.config?.source?.dataType || null);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [disabledNextButton, setDisabledNextButton] = useState(true);
    const [disabledFinishButton, setDisabledFinishButton] = useState(true);
    const [disabledEditButton, setDisabledEditButton] = useState(true);
    const [selectedTarget, setSelectedTarget] = useState(null);
    const [tableParams, setTableParams] = useState(null);
    const [steps, setSteps] = useState([1, 2, 3].map(el => intl.formatMessage({ id: "DASHBOARD.STEPS." + el })));
    const [activeStep, setActiveStep] = useState(0);
    const showDateFields = dataType && itemType !== "simple-card" && !(itemType === 'chart-pie' && dataType === 'target') && !(itemType === 'table' && (dataType === 'entity' || dataType === 'topic')) && dataType !== "alarmProfile" && dataType !== "stakeholder";

    useEffect(() => {
        if (existingWidget) {
            setTitle(existingWidget.title);
            setShowTitle(existingWidget.showTitle);
            setItemType(existingWidget.config.type);
            setDataType(existingWidget.config.source.dataType);
            setDateFrom(existingWidget.config.time.dateFrom);
            setDateTo(existingWidget.config.time.dateTo);
        }
        else {
            setTitle("");
            setShowTitle(true);
            setSize({ width: 5, height: 3 });
            setItemType(null);
            setDataType(null);
            setSelectedEntities([]);
            setCollectorType([]);
            setDateFrom(getLastYearDate());
            setDateTo(Date.now());
            setSelectedAttributes([]);
            setSelectedTarget(null);
            setTableParams(null);
            setActiveStep(0);
        }
    }, [open, existingWidget])

    const resetForm = () => {
        setTitle("");
        setShowTitle(true);
        setSize({ width: 5, height: 3 });
        setItemType(null);
        setDataType(null);
        setSelectedEntities([]);
        setCollectorType([]);
        setDateFrom(getLastYearDate());
        setDateTo(Date.now());
        setSelectedAttributes([]);
        setSelectedTarget(null);
        setTableParams(null);
        setActiveStep(0);
    }
    const handleClose = () => {
        resetForm();
        onClose();
    }

    const handleSubmit = () => {
        const newId = Math.floor(Math.random() * 10000000) + 1;
        const newItemObject = {
            _id: existingWidget?._id || newId.toString(),
            title: title,
            showTitle: showTitle,
            position: {
                x: 0,
                y: 0,
                ...size
            },
            config: {
                time: {
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                },
                type: itemType,
                source: (itemType !== 'table' && dataType === 'measurement') ? {
                    dataType: 'measurement',
                    measurement: {
                        entity: selectedEntities.map(entity => entity._id),
                        measurementType: selectedAttributes,
                        collector: collectorType.map(type => type.key),
                    }
                } : (itemType !== 'simple-card' && itemType !== 'table' && dataType === 'metricReport' ? {
                    dataType: 'metricReport',
                    metricReport: {
                        entity: selectedEntities.map(entity => entity._id),
                        metric: selectedAttributes.map(metric => metric._id),
                    }
                } : (itemType === 'chart-pie' && dataType === 'target' ? {
                    dataType: 'target',
                    target: {
                        entity: selectedEntities[0]?.name,
                        id: selectedTarget._id,
                    }
                } : (itemType === 'chart-bar' && dataType !== 'metricReport' ? {
                    dataType: dataType,
                    [dataType]: {
                        ...selectedAttributes,
                    },
                } : (itemType === 'simple-card' ? {
                    dataType: dataType,
                    [dataType]: {
                        ...selectedAttributes,
                    },
                } : (itemType === 'table' ? {
                    ...tableParams,
                    dataType: dataType,
                } : {})))))
            }

        };
        if (existingWidget) {
            setDashboardItems(dashboardItems => dashboardItems.map(el => {
                if (el._id === existingWidget._id) return newItemObject;
                else return el;
            }))
            setDisabledSaveButton(false);
        }
        else {
            setDashboardItems(dashboardItems => [...dashboardItems, newItemObject]);
            setLayout(layout => [...layout, { i: newId.toString(), x: 0, y: Infinity, w: size.width, h: size.height, minW: 2, minH: 2 }]);
        }

        handleClose();
    }

    useEffect(() => {
        const newSteps = [1, 2, 3].map(el => intl.formatMessage({ id: "DASHBOARD.STEPS." + el }))
        if (showDateFields) newSteps.push(intl.formatMessage({ id: "DASHBOARD.STEPS.4" }));
        setSteps(newSteps);
    }, [showDateFields, intl]);

    useEffect(() => {
        setSelectedAttributes([]);
        setSelectedEntities([]);
    }, [itemType, dataType]);

    useEffect(() => {
        if (!existingWidget) {
            if ((activeStep === 0 && title.trim() === '') ||
                (activeStep === 1 && (!itemType || !dataType)) ||
                (activeStep === 2 && ((itemType === 'chart-line' && (!selectedEntities.length || (dataType === "metricReport" && !selectedAttributes.length))) ||
                    (itemType === 'chart-pie' && dataType === 'target' && !selectedTarget) || (itemType === 'chart-pie' && dataType === 'measurement' && !selectedEntities.length) ||
                    (itemType === 'chart-bar' && dataType === 'metricReport' && !selectedAttributes.length) ||
                    (itemType === 'chart-bar' && dataType !== 'metricReport' && !selectedAttributes?.display) ||
                    (itemType === 'simple-card' && (!selectedAttributes.properties?.length || typeof (selectedAttributes.id) !== "string")) ||
                    (itemType === 'table' && tableParams && !tableParams[dataType]?.properties?.length))
                )) {
                setDisabledNextButton(true);
                setDisabledFinishButton(true)
            }
            else {
                setDisabledNextButton(false);
                setDisabledFinishButton(false)
            }
        }

    }, [existingWidget, activeStep, title, itemType, dataType, selectedEntities, selectedAttributes, tableParams, selectedTarget]);

    useEffect(() => {
        if (existingWidget) {
            if (title.trim() === '' || (!itemType || !dataType) ||
                (itemType === 'chart-line' && (!selectedEntities.length || !selectedAttributes.length)) ||
                (itemType === 'chart-pie' && dataType === 'target' && !selectedTarget) || (itemType === 'chart-pie' && dataType === 'measurement' && !selectedEntities.length) ||
                (itemType === 'chart-bar' && dataType === 'metricReport' && !selectedAttributes.length) ||
                (itemType === 'chart-bar' && dataType !== 'metricReport' && !selectedAttributes?.display) ||
                (itemType === 'simple-card' && (!selectedAttributes.properties?.length || typeof (selectedAttributes.id) !== "string")) ||
                (itemType === 'table' && tableParams && !tableParams[dataType]?.properties?.length)) {
                setDisabledEditButton(true)
            }
            else setDisabledEditButton(false)
        }
    }, [existingWidget, dataType, itemType, selectedAttributes, selectedEntities.length, selectedTarget, tableParams, title])

    useEffect(() => {
        if (new Date(dateFrom).toString() === 'Invalid Date' || new Date(dateTo).toString() === 'Invalid Date') {
            setDisabledEditButton(true);
            setDisabledFinishButton(true);
        }
        else {
            setDisabledEditButton(false);
            setDisabledFinishButton(false);
        }

    }, [dateFrom, dateTo])
    return <Dialog open={open} maxWidth="md" fullWidth fullScreen={smallScreen} onClose={handleClose}>
        <DialogFormTitle mainTitle={<FormattedMessage id={existingWidget ? "EDIT" : "ADD.NEW"} />} action={existingWidget ?
            <Tooltip title={<FormattedMessage id="DELETE" />} placement="bottom" arrow>
                <IconButton color="error" onClick={() => handleDelete()}><DeleteOutlined /></IconButton>
            </Tooltip> :
            <Tooltip title={<FormattedMessage id="CLOSE" />} placement="bottom" arrow>
                <IconButton size="small" onClick={() => handleClose()}><CloseOutlined /></IconButton>
            </Tooltip>} />
        <Divider />
        <DialogContent>
            <DashboardFormContent
                open={open} title={title} setTitle={setTitle} showTitle={showTitle} setShowTitle={setShowTitle} size={size} setSize={setSize} itemType={itemType} setItemType={setItemType}
                selectedEntities={selectedEntities} setSelectedEntities={setSelectedEntities} dateFrom={dateFrom} setDateFrom={setDateFrom}
                dateTo={dateTo} setDateTo={setDateTo} collectorType={collectorType} setCollectorType={setCollectorType}
                dataType={dataType} setDataType={setDataType} selectedAttributes={selectedAttributes} setSelectedAttributes={setSelectedAttributes}
                selectedTarget={selectedTarget} setSelectedTarget={setSelectedTarget} setTableParams={setTableParams}
                activeStep={activeStep} setActiveStep={setActiveStep} showDateFields={showDateFields} steps={steps} existingWidget={existingWidget} />
        </DialogContent>
        <Divider />
        <DialogActions>
            {existingWidget && <><Button color="warning" onClick={handleClose}><FormattedMessage id="CANCEL" /></Button>
                <Button disabled={disabledEditButton} onClick={handleSubmit}><FormattedMessage id="EDIT" /></Button></>}
            {activeStep > 0 && <Button color="warning" onClick={() => setActiveStep(activeStep - 1)}><FormattedMessage id="BACK" /></Button>}
            {(!existingWidget && (activeStep < 2 || (activeStep === 2 && showDateFields))) && <Button disabled={disabledNextButton} onClick={() => setActiveStep(activeStep + 1)}><FormattedMessage id="NEXT" /></Button>}
            {(activeStep === 3 || (activeStep === 2 && !showDateFields)) && < Button disabled={disabledFinishButton} onClick={handleSubmit}><FormattedMessage id="FINISH" /></Button>}
        </DialogActions>
    </Dialog >
}

export default DashboardForm;