import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from 'api';

const initialState = {
    token: null,
    refreshToken: null,
    userId: undefined,
    username: '',
    fullName: '',
    email: '',
    roles: [],
    cookieConsent: false,
    isFetching: false,
    isSuccess: false,
    isError: false,
    isDone: false,
    errorMessage: ''
};


export const authUser = createAsyncThunk('user/auth', async (arg, thunkAPI) => {
    const cookieConsent = document.cookie.indexOf('COOKIE_CONSENT') !== -1
    try {
        await API.authKeycloak('login-required'); // check-sso
        if (API.keycloak.authenticated) {
            const userInfo = await API.keycloak.loadUserInfo();
            return {
                token: API.keycloak.token,
                refreshToken: API.keycloak.refreshToken,
                userid: userInfo.sub,
                username: userInfo.preferred_username,
                fullName: [userInfo.given_name, userInfo.family_name].join(" "),
                email: userInfo.email,
                roles: API.keycloak.realmAccess.roles,
                cookieConsent: cookieConsent
            };
        }
        else throw new Error()
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ cookieConsent: cookieConsent });

    }

});

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        toggleCookieConsent: (state, action) => {
            state.cookieConsent = action.payload.cookieConsent
        }
    },
    extraReducers:
        (builder) => {
            builder
                .addCase(authUser.fulfilled, (state, action) => {
                    state.token = action.payload.token;
                    state.refreshToken = action.payload.refreshToken;
                    state.userId = action.payload.userid;
                    state.fullName = action.payload.fullName;
                    state.username = action.payload.username;
                    state.email = action.payload.email;
                    state.roles = action.payload.roles;
                    state.cookieConsent = action.payload.cookieConsent;
                    state.isSuccess = true;
                    state.isDone = true;
                    state.isError = false;
                })
                .addCase(authUser.pending, (state) => {
                    state.isFetching = true;
                })
                .addCase(authUser.rejected, (state, action) => {
                    state.cookieConsent = action.payload !== undefined ? action.payload.cookieConsent : false;
                    state.isSuccess = false;
                    state.isDone = true;
                })

        }
});

export const userSelector = (state) => state.user;
export const cookieConsentSelector = (state) => state.user.cookieConsent;
export const fullNameSelector = (state) => {
    return state.user.fullName === " " ? state.user.username : state.user.fullName;
};
export const permissionSelector = (state, permission) => {
    return state.configuration.data.userPermission && state.configuration.data.userPermission[permission];
};

export const unauthorizedFlagSelector = (state) => state.configuration.error === "UNAUTHORIZED" || state.entities.error === "UNAUTHORIZED";
export const crashedAPIFlagSelector = (state) => state.configuration.error === "CRASHED" || state.entities.error === "CRASHED";

export const organizationAdminSelector = (state) => {
    return state.user.roles && Boolean(state.user.roles.filter(role => role.includes('organization-admin')).length);
};
export const accountControlSelector = (state) => {
    return state.user.roles && Boolean(state.user.roles.filter(role => role.includes('uma_authorization')).length);
};

export const { toggleCookieConsent } = userSlice.actions;

export default userSlice.reducer;
