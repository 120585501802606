import { ChevronLeftOutlined, FilterAltOffOutlined, FilterAltOutlined, SearchOutlined, TuneOutlined, SportsScoreOutlined, TroubleshootOutlined, AddOutlined, PlagiarismOutlined } from "@mui/icons-material";
import { Autocomplete, Box, ButtonGroup, Card, Chip, Collapse, IconButton, InputAdornment, Stack, TextField, Tooltip, Badge, useMediaQuery } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router";
import { CustomButton } from "components/entityLinksButtons/EntityLinksButtons";
import { useState } from "react";
import { useSelector } from "react-redux";
import { permissionSelector } from "redux/userSlice";
import { selectStatusTypes } from "redux/configurationSlice";

export default function GoalFilters({ loading, goals, specialNavigate, searchFilter, setSearchFilter, statusFilter, setStatusFilter, onChangeView, setOpenGoalForm }) {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const intl = useIntl();
    const queryId = useParams().entityId;
    const statusTypes = useSelector(selectStatusTypes);
    const [moreFilters, setMoreFilters] = useState(false);

    const createGoalPermission = useSelector((state) => permissionSelector(state, 'create-goal'));

    const goalsArray = goals && goals.length ? goals : [];

    return <Card sx={{ width: '100%', p: 0.5, mb: 1 }}>
        <Stack direction={smallScreen ? "column" : "row"} spacing={1} alignItems={smallScreen ? "flex-start" : "center"}>
            <Stack direction="row" spacing={1} alignItems="center">
                <Chip onClick={() => navigate(specialNavigate ? specialNavigate : ('/topics/' + queryId))} icon={<ChevronLeftOutlined />} label={<FormattedMessage id="BACK" />} />
                <ButtonGroup size="small" variant="outlined">
                    <CustomButton curvedLeft color="secondary" variant="outlined" icon={<PlagiarismOutlined />} onClick={() => onChangeView('subtopics')} label={<FormattedMessage id="ESG.SUB-TOPICS" />} />
                    <CustomButton color="secondary" variant="outlined" icon={<TroubleshootOutlined />} onClick={() => onChangeView('metrics')} label={<FormattedMessage id="ESG.METRICS" />} />
                    <CustomButton curvedRight color="secondary" variant="contained" icon={<SportsScoreOutlined />} label={<FormattedMessage id="ESG.GOALS" />} />
                </ButtonGroup>
            </Stack>
            <Autocomplete
                id="search-goal"
                freeSolo
                options={goalsArray.map(goal => goal.name)}
                value={searchFilter}
                onChange={(event, value) => setSearchFilter(value)}
                renderInput={(params) => (
                    <TextField {...params} placeholder={intl.formatMessage({ id: "ESG.GOALS.SEARCH_PLACEHOLDER" })} InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlined color="secondary" />
                            </InputAdornment>
                        )
                    }} />
                )}
                size="small"
                fullWidth
            />
            <Stack direction="row" gap={1}>
                <Tooltip title={<FormattedMessage id={"MORE_FILTERS" + (statusFilter ? ".ACTIVE" : "")} />} placement="bottom" arrow>
                    <span>
                        <IconButton size="small" onClick={() => setMoreFilters(!moreFilters)}>
                            <Badge color="secondary" variant="dot" invisible={!statusFilter}>
                                <TuneOutlined color={moreFilters ? "secondary" : "default"} />
                            </Badge>
                        </IconButton>
                    </span>
                </Tooltip>
                {createGoalPermission && <Chip disabled={loading} variant="outlined" sx={{ px: 1 }} icon={<AddOutlined fontSize="small" color="secondary" />} onClick={() => setOpenGoalForm(true)} label={<FormattedMessage id="ESG.GOALS.CREATE" />} />}
            </Stack>
        </Stack>
        <Collapse in={moreFilters}>
            <Box sx={{ mt: 1 }}>
                <Autocomplete
                    id="filter-goal"
                    options={statusTypes}
                    value={statusFilter}
                    onChange={(event, value) => setStatusFilter(value)}
                    getOptionLabel={(opt) => intl.formatMessage({ id: 'STATUS.' + opt.value })}
                    renderInput={(params) => (
                        <TextField {...params} placeholder={intl.formatMessage({ id: "ESG.GOALS.SEARCH_PLACEHOLDER.STATUS" })} InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    {statusFilter ? <FilterAltOutlined color="secondary" /> : <FilterAltOffOutlined color="text.disabled" />}
                                </InputAdornment>
                            )
                        }} />
                    )}
                    size="small"
                    fullWidth
                />
            </Box>
        </Collapse>
    </Card>
}