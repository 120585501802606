import { Alert, Box, Card, CardContent, CardHeader, Chip, Divider, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { DisplayBox } from 'components';
import { MapComponent, MapElementNew, LinearProgressWithLabel, UserList, AlarmSeverityIcon, AssessmentChip, KpiDisplay, CompactList, LoadingData } from 'components';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Vector } from 'ol/source';
import { selectMapProps } from "redux/mapSlice";
import { useSelector } from 'react-redux';
import { formatDateLocale } from 'utils';
import { selectStatusTypes, selectNoteTypes, selectPriorityTypes, selectNotificationMethodTypes, selectSeverityTypes, selectMeasurementsTypesConf, selectCollectorTypesConf } from 'redux/configurationSlice';
import { getStatusColor } from 'utils';
import { ArchiveOutlined, EmojiEventsOutlined, ReplayOutlined, TrackChangesOutlined, UnarchiveOutlined, UpdateOutlined } from '@mui/icons-material';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { selectUsersAndGroupsStatus, fetchUsersAndGroups, selectUsersAndGroups } from 'redux/usersAndGroupsSlice';
import { store } from 'redux/store';
import { getNoteStatusColor, NotificationMethodIcon } from 'components/notesView/NotesView';
import { ValueCell } from 'components/metricReportDetailCard/MetricReportDetailCard';
import API from 'api';
import { selectEntities, selectMeasureEntities } from 'redux/entitySlice';
import { selectTopics } from 'redux/topicSlice';
import { getLabelId } from 'utils';

const RenderProperty = ({ property, itemObject, dataType, width, entityId }) => {
    const mapProps = useSelector(selectMapProps);
    const statusTypes = useSelector(selectStatusTypes);
    const [position, setPosition] = useState([parseFloat(mapProps.defaultY), parseFloat(mapProps.defaultX)])
    const markerSource = useRef(new Vector());
    const noteTypes = useSelector(selectNoteTypes);
    const priorityTypes = useSelector(selectPriorityTypes);
    const notificationMethodTypes = useSelector(selectNotificationMethodTypes);
    const severityTypes = useSelector(selectSeverityTypes);
    const sourceTypes = useSelector(selectCollectorTypesConf);
    const intl = useIntl();
    const entities = useSelector(selectEntities);
    const measurementTypes = useSelector(selectMeasurementsTypesConf);

    const [allUsers, setAllUsers] = useState([]);
    const [allGroups, setAllGroups] = useState([]);
    const [resending, setResending] = useState(false);
    const { token } = useSelector(userSelector);
    const status = useSelector(selectUsersAndGroupsStatus);
    const usersAndGroups = useSelector(selectUsersAndGroups);
    const resendPermission = useSelector((state) => permissionSelector(state, 'create-notes'));
    const viewUsersPermission = useSelector((state) => permissionSelector(state, 'view-users'));

    const getConditionLabel = (condition) => {
        switch (condition) {
            case "<":
                return "LESS_THAN";
            case ">":
                return "GREATER_THAN";
            case "<=":
                return "LESS_THAN_OR_EQUAL";
            case ">=":
                return "GREATER_THAN_OR_EQUAL";
            case "=":
                return "EQUAL";
            case "<>":
                return "NOT_EQUAL";
            default:
                return "undefined";
        }
    }

    useEffect(() => {
        if (!viewUsersPermission) {
            setAllUsers([]);
            setAllGroups([]);
            return;
        }
        else if (status === 'failed') {
            store.dispatch(fetchUsersAndGroups(token));
        } else if (status === 'complete') {
            setAllUsers(usersAndGroups.users);
            setAllGroups(usersAndGroups.groups);
        } else {
            setAllUsers([]);
            setAllGroups([]);
        }
    }, [token, status, usersAndGroups, property, viewUsersPermission]);

    const resendHandler = () => {
        setResending(true);
        API.notes.resendNote(itemObject._id, token).then(response => {
            setResending(false);
        })
            .catch(error => {
                console.error(error);
                setResending(false);
            })
    }
    if (itemObject.error) return <Alert><FormattedMessage id="NO_DATA" /></Alert>
    switch (property) {
        case "status": return <Box key={property}>
            <Typography color="primary" display="block" component="span">{intl.formatMessage({ id: getLabelId(property) })}</Typography>
            <Typography display="block" variant="body1" color={getStatusColor(itemObject.status)} component="span"><FormattedMessage id={"STATUS." + statusTypes.find(type => type.key === itemObject.status)?.value} /></Typography>
        </Box>
        case "category": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.category && <FormattedMessage id={"ESG.GOAL.CAT_" + itemObject.category} />} />
        case "dateRange.startDate": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.dateRange.startDate && formatDateLocale(itemObject.dateRange.startDate)} />
        case "dateRange.endDate": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.dateRange.endDate && formatDateLocale(itemObject.dateRange.endDate)} />
        case "datetime.dateFrom": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.datetime.dateFrom && formatDateLocale(itemObject.datetime.dateFrom)} />
        case "datetime.dateTo": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.datetime.dateTo && formatDateLocale(itemObject.datetime.dateTo)} />
        case "datetime.lastUpdated": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.datetime.lastUpdated && formatDateLocale(itemObject.datetime.lastUpdated)} />
        case "reportData.createdAt": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.reportData.createdAt && formatDateLocale(itemObject.reportData.createdAt)} />
        case "reportData.startDate": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.reportData.startDate && formatDateLocale(itemObject.reportData.startDate)} />
        case "timestamp": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.timestamp && formatDateLocale(itemObject.timestamp)} />
        case "reportData.reportInterval": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.reportData.reportInterval} />
        case "reportData.dataFrequencyInterval": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.reportData.dataFrequencyInterval} />
        case "reportData.calculationMethod": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.reportData.calculationMethod} />
        case "category.type": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.category.type} />
        case "type": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={<FormattedMessage id={itemObject.content ? ("NOTE.TYPE." + noteTypes.find(type => type.key === itemObject.type)?.value) : ("ESG.TYPE." + itemObject.type)} />} />
        case "geoProperties.position": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={<Box key={property}>
            <div style={{ height: '200px' }}>
                <MapComponent className="pin-input" zoom={12} position={itemObject.geoProperties.position} disableShapes>
                    <MapElementNew
                        key="map-elements"
                        markerSource={markerSource.current}
                        positionInput={position}
                        positionChange={setPosition}
                    />
                </MapComponent>
            </div>
        </Box>} />
        case "measurable": return <Stack spacing={1} key={property}>
            <Typography variant='h6' color="secondary.main"><FormattedMessage id="REPORT" /></Typography>
            {itemObject?.measurable ? <>
                <Stack direction="row" spacing={1} justifyContent="space-around" sx={{ my: 1 }}>
                    <DisplayBox label={<FormattedMessage id="ESG.TARGET.VALUE.BASE" />} value={<Chip sx={{ borderColor: 'transparent' }} variant="outlined" icon={<TrackChangesOutlined color="primary" />} label={itemObject.measurable.baseValue} />} />
                    <DisplayBox label={<FormattedMessage id="ESG.TARGET.VALUE.CURRENT" />} value={<Chip sx={{ borderColor: 'transparent' }} variant="outlined" icon={<UpdateOutlined color={!itemObject.measurable.currentValue ? "disabled" : (itemObject.measurable.currentValue < itemObject.measurable.endValue ? "warning" : "success")} />} label={parseFloat(itemObject.measurable.currentValue).toFixed(4)} />} />
                    <DisplayBox label={<FormattedMessage id="ESG.TARGET.VALUE.GOAL" />} value={<Chip sx={{ borderColor: 'transparent' }} variant="outlined" icon={<EmojiEventsOutlined color="success" />} label={itemObject.measurable.endValue} />} />
                </Stack>
                <Box sx={{ my: 2 }}>
                    <LinearProgressWithLabel value={itemObject.measurable.currentValue} min={itemObject.measurable.baseValue} max={itemObject.measurable.endValue} label={<FormattedMessage id="PROGRESS" />} />
                </Box>
            </> : null}
        </Stack>
        case "geoProperties.address": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.geoProperties.address} />;
        case "active": return <DisplayBox key={property} value={<Chip color={itemObject.active ? "success" : "error"} label={<FormattedMessage id={itemObject.active ? "ACTIVE" : "DISABLED"} />} />} />;
        case "enabled": return <DisplayBox key={property} value={<Chip color={itemObject.enabled ? "success" : "error"} label={<FormattedMessage id={itemObject.enabled ? "ENABLED" : "DISABLED"} />} />} />;
        case "reportData.reportUnit.unit": return <DisplayBox key={property} label={<FormattedMessage id="REPORT_UNITS" />} value={itemObject.reportData.reportUnit
            ? (itemObject.reportData.reportUnit?.prefix || itemObject.reportUnit?.suffix) ? <Stack direction="column" spacing={0.5} sx={{ width: '20%' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1 }}><Typography color="text.secondary" variant="caption"><FormattedMessage id="PREFIX" /></Typography> {itemObject.reportData.reportUnit?.prefix}</Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1 }}><Typography color="text.secondary" variant="caption"><FormattedMessage id="UNIT" /></Typography> {itemObject.reportData.reportUnit?.unit || <Typography color="text.secondary">{itemObject.category?.scope?.unit} (<FormattedMessage id="DEFAULT" />)</Typography>}</Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1 }}><Typography color="text.secondary" variant="caption"><FormattedMessage id="SUFFIX" /></Typography> {itemObject.reportData.reportUnit?.suffix}</Box>
            </Stack> : itemObject.reportData.reportUnit?.unit
            : null
        } />;
        case "measurementTypes.service.OWM":
            return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.measurementTypes?.service?.OWM?.length && itemObject.measurementTypes.service.OWM.map(el => <Chip key={el} sx={{ mr: 1, mt: 1 }} label={<FormattedMessage id={el} />} />)} />;
        case "measurementTypes.sensor":
            let allSensorTypes = itemObject.measurementTypes?.sensor;
            let sensorDevice = allSensorTypes && Object.keys(allSensorTypes);
            return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={sensorDevice?.length && <Stack direction="column" gap={1}>
                {sensorDevice.map(sensor => <DisplayBox key={sensor} label={sensor} labelColor="secondary.dark" value={allSensorTypes[sensor]?.length && allSensorTypes[sensor].map(type => {
                    const measureType = measurementTypes.find(el => el.key === type);
                    return <Chip key={type} sx={{ mr: 1, mt: 1 }} label={measureType.name || <FormattedMessage id={measureType.key} />} />
                })} />)}
            </Stack>} />;
        case "measurementTypes.manual":
            return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.measurementTypes?.manual?.types?.length && itemObject.measurementTypes.manual.types.map(el => {
                const measurementType = measurementTypes.find(type => type.key === el);
                return <Chip key={el} sx={{ mr: 1, mt: 1 }} label={measurementType.name || <FormattedMessage id={el} />} />
            })} />;
        case "value":
            let reportUnit = itemObject.metricId.reportData?.reportUnit;
            let prefix = itemObject.value && itemObject.metricId ? reportUnit?.prefix || "" : "";
            let suffix = itemObject.value && itemObject.metricId ? `${reportUnit?.suffix ?? ""} ${reportUnit.unit ?? ""}` : "";
            return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={<Box sx={{ pr: 1 }}>
                <ValueCell
                    customStringWidth={width * 6}
                    prefix={!!prefix.length && prefix}
                    value={itemObject.value ? itemObject.value : "N/A"}
                    suffix={!!suffix.length && suffix}
                />
            </Box>
            } />;
        case "metricId": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.metrics ? itemObject.metrics?.map(metric => (
            <Chip sx={{ mt: 1, ml: 1 }} key={metric._id} label={`${metric.name} (${entities[metric.entityId || entityId]?.name})`} />))
            : itemObject.metricName ? itemObject.metricName : (itemObject.metricId?.name ?? itemObject.metricId)} />;
        case "participants": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.participants?.length > 0 && <UserList
            users={itemObject.participants.filter(participant => participant.type === "user").map((participant) => (allUsers.find(user => user.id === participant.id)?.username))}
            groups={itemObject.participants.filter(participant => participant.type === "group").map(group => allGroups.find(grp => grp.id === group.id))}
        />} />;
        case "metricIds":
            return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={(itemObject.metrics?.length || itemObject.complexmetrics?.length) && <>{itemObject.metrics?.map(metric => (
                <Chip sx={{ mt: 1, ml: 1 }} key={metric._id} label={metric.name} />)
            )}
                {itemObject.complexmetrics?.map(metric => (
                    <Chip sx={{ mt: 1, ml: 1 }} key={metric._id} label={metric.name} />))
                }</>} />;
        case "priority": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={<FormattedMessage id={"PRIORITY." + priorityTypes.find(type => type.key === itemObject.priority)?.value} />} />;
        case "contacts": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.contacts?.length > 0 && <UserList
            users={itemObject.contacts.filter(contact => contact.type === "user").map(contact => allUsers.find(usr => usr.id === contact.id)?.username)}
            groups={itemObject.contacts.filter(participant => participant.type === "group").map(group => allGroups.find(grp => grp.id === group.id))}
        />} />;
        case "notificationMethod": return <DisplayBox key={property} label={
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormattedMessage id="NOTIFICATIONS.METHOD" />
                {itemObject?.resendStatus && !itemObject?.archived && resendPermission ? <Chip disabled={resending && !resendPermission} onClick={() => resendHandler()} size="small" sx={{ color: 'text.secondary' }} icon={<ReplayOutlined color="info" />} variant="outlined" label={<FormattedMessage id={!resending ? "NOTE.RESEND" : "NOTE.SENDING"} />} /> : null}
            </Box>
        } value={
            <List>
                {itemObject.notificationMethod.map((item, index) => {
                    const foundType = intl.formatMessage({ id: "NOTIFICATIONS.METHOD." + notificationMethodTypes.find(type => type.key === item)?.value });
                    const noteStatus = itemObject.sendStatus.find(el => el.statusType === item)?.message;
                    return <ListItem key={item}>
                        <ListItemText
                            primaryTypographyProps={{ component: 'span' }}
                            primary={<Stack direction="row" alignItems="flex-end" gap={1}>
                                <Typography variant="caption" color="text.secondary">{index + 1}.</Typography>
                                <Typography>{foundType}</Typography>
                            </Stack>}
                            secondary={<Chip component="span" icon={<NotificationMethodIcon sx={{ ml: 1 }} size="small" type={item} status={noteStatus} noTooltip />} size="small" color={getNoteStatusColor(noteStatus)} variant="outlined" label={<FormattedMessage id={"NOTE.STATUS." + noteStatus} />} />}
                        />
                    </ListItem>
                })}
            </List>
        } />
        case "archived": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={<Chip icon={itemObject.archived ? <ArchiveOutlined /> : <UnarchiveOutlined />}
            label={<FormattedMessage id={itemObject.archived ? "NOTE.ARCHIVED" : "NOTE.NOT_ARCHIVED"} />} />} />;;
        case "triggeredTime": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject?.triggeredTime && formatDateLocale(itemObject?.triggeredTime)} />;
        case "severityType":
            const severity = severityTypes.find(type => type.key === itemObject.severityType)?.value;
            return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={severity && <span><AlarmSeverityIcon severity={severity} /> {intl.formatMessage({ id: "ALARMS.PROFILE_RULES." + severity })}</span>} />;
        case "entityId": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={entities[itemObject.entityId || entityId]?.name} />;
        case "acknowledgedTime": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.acknowledgedTime && formatDateLocale(itemObject.acknowledgedTime)} />;
        case "clearedTime": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject.clearedTime && formatDateLocale(itemObject.clearedTime)} />;
        case "conditionsPassed": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject?.conditionsPassed && itemObject.conditionsPassed.map((el, index) => {
            const measureType = measurementTypes.find(type => type.key === el.measurementTypeKey)
            return <Card key={el.measurementTypeKey + index} sx={{ mb: 0.5 }}>
                <CardHeader title={measureType.name || <FormattedMessage id={el.measurementTypeKey} />} subheader={el.timestamp && formatDateLocale(el.timestamp)} />
                <CardContent sx={{ pt: 0 }}>
                    <Typography variant="h4" color="primary" component="span" display="inline">{el.value}</Typography>
                    <Typography component="span" display="inline">{el.unit}</Typography>
                </CardContent>
            </Card>
        })} />;
        case "sendStatus": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject?.sendStatus && itemObject?.sendStatus.map((el, index) => <Chip key={el.statusType + index} variant='outlined' sx={{ mb: 0.5, borderColor: 'transparent' }} icon={<NotificationMethodIcon noTooltip type={el.statusType} />} label={<FormattedMessage id={"NOTE.STATUS." + el.message} />} />)} />;
        case "rules.CRITICAL":
        case "rules.MAJOR":
        case "rules.MINOR":
        case "rules.WARNING":
        case "rules.INDETERMINATE":
            const rule = property.substr(6);
            const profileRule = itemObject?.rules && itemObject.rules[rule];
            return profileRule && <DisplayBox value={
                <Card key={rule} sx={{ mb: 0.5 }}>
                    <CardHeader
                        avatar={<AlarmSeverityIcon severity={rule} customStyle={(theme) => ({ width: theme.typography.fontSize * 2, height: theme.typography.fontSize * 2 })} />}
                        title={<Typography variant="h6"><FormattedMessage id={"ALARMS.PROFILE_RULES." + rule} /></Typography>}
                        subheader={profileRule?.notificationMethod && profileRule?.notificationMethod.map((el, index) => {
                            const method = notificationMethodTypes.find(type => type.key === el)?.value
                            return <Chip size="small" key={el + index} component="div" sx={{ mr: 0.5 }} variant='outlined' icon={<NotificationMethodIcon noTooltip type={el} fontSize="small" />} label={<FormattedMessage id={"NOTIFICATIONS.METHOD." + method} />} />
                        })}
                    />
                    <CardContent sx={{ pt: 0 }}>
                        <DisplayBox label={<FormattedMessage id="ALARM.MESSAGE" />} value={profileRule.details.length > 0 ? <span style={{ fontStyle: 'italic' }}>{profileRule.details}</span> : null} />
                        <br />
                        <DisplayBox label={<FormattedMessage id="FILTERS" />} value={<List dense>
                            {profileRule.filters.flatMap(filter => filter.conditions.map((condition, index) => {
                                const measureType = measurementTypes.find(type => type.key === condition.measurementTypeKey);
                                return <React.Fragment key={condition.measurementTypeKey + index}>
                                    <ListItem disableGutters sx={{ display: 'flex', gap: 1, pt: 0 }}>
                                        <Typography display="inline" component="span" variant="h6">{measureType.name || <FormattedMessage id={condition.measurementTypeKey} />}</Typography>
                                        <Typography display="inline" component="span"><FormattedMessage id={getConditionLabel(condition.operator)} /></Typography>
                                        <Typography display="inline" component="span" variant="h6">{condition.value}</Typography>
                                        <Typography display="inline" component="span" color="text.secondary">{condition.unit}</Typography>
                                    </ListItem>
                                    {index < (filter.conditions?.length - 1) && <ListItem sx={{ py: 0 }}><Typography sx={{ textTransform: 'lowercase' }} display="block" variant="button" component="span" color="secondary.dark"><FormattedMessage id="AND" /></Typography></ListItem>}
                                </React.Fragment>
                            }))}
                        </List>} />
                        <DisplayBox label={<FormattedMessage id="NOTE.CONTACTS" />} value={<UserList
                            users={profileRule?.contacts.filter(participant => participant.type === "user").map(participant => allUsers.find(usr => usr.id === participant.id)?.username)}
                            groups={profileRule?.contacts.filter(participant => participant.type === "group").map(group => allGroups.find(grp => grp.id === group.id))}
                        />} />
                    </CardContent>
                </Card>
            } />
        case "KPI.turnover": return dataType === "entityActivity" ? <span key={property}><KpiDisplay title={<FormattedMessage id="turnover" />} value={itemObject?.KPI?.turnover} secondaryValue={itemObject?.KPIAlignedPercentage?.turnover} />
            <Divider /></span> : <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject?.KPI?.turnover} />;
        case "KPI.capEx": return dataType === "entityActivity" ? <span key={property}><KpiDisplay title={<FormattedMessage id="capEx" />} value={itemObject?.KPI?.capEx} secondaryValue={itemObject?.KPIAlignedPercentage?.turnover} />
            <Divider /></span> : <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject?.KPI?.capEx} />;
        case "KPI.opEx": return dataType === "entityActivity" ? <span key={property}><KpiDisplay title={<FormattedMessage id="opEx" />} value={itemObject?.KPI?.opEx} secondaryValue={itemObject?.KPIAlignedPercentage?.turnover} />
            <Divider /></span> : <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject?.KPI?.opEx} />;
        case "objectives.MITIGATION":
        case "objectives.ADAPTATION":
        case "objectives.WATER":
        case "objectives.CIRCULAR":
        case "objectives.POLLUTION":
        case "objectives.BIODIVERSITY": const obj = property.substr(11);
            return <Stack gap={1} key={obj}>
                <Box display="flex" justifyContent="space-between">
                    <Typography color="secondary" component="span" variant="h6"><FormattedMessage id={obj} /></Typography>
                    <AssessmentChip assessment={itemObject.objectives[obj]?.assessment} />
                </Box>
                {itemObject.objectives[obj]?.dnsh && Object.keys(itemObject.objectives[obj].dnsh).map(dnsh => <Stack pl={1} key={dnsh} gap={1}>
                    <DisplayBox labelColor="text.secondary" labelSize="large" label={<Stack direction="column"><FormattedMessage id={dnsh} /><Typography component="span" variant="caption" color="text.disabled"><FormattedMessage id="DNSH_CRITERIA" /></Typography></Stack>} value={<AssessmentChip sx={{ m: 1 }} outlined assessment={itemObject.objectives[obj]?.dnsh[dnsh]} />} />
                </Stack>)}
            </Stack>;
        case "activityId": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject[property]?._id} />;
        case "engagementExamples": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject[property]?.length && <CompactList data={itemObject[property]} />} />;
        case "raisedConcerns": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject[property]?.length && <CompactList data={itemObject[property]} />} />;
        case "category.scope.collector": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject?.category?.scope?.collector} />;
        case "category.scope.sourceType": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={(() => {
            const type = sourceTypes.find(el => el.key === itemObject?.category?.scope?.sourceType);
            if (type) return intl.formatMessage({ id: type });
            else return null;
        })()} />;
        case "category.scope.measurementTypeKey": return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={(() => {
            const measurementType = measurementTypes.find(el => el.key === itemObject?.category?.scope?.measurementTypeKey);
            if (measurementType?.name) return measurementType.name;
            else if (measurementType?.key) return intl.formatMessage({ id: measurementType.key });
            else return null;
        })()} />;
        default: return <DisplayBox key={property} label={intl.formatMessage({ id: getLabelId(property) })} value={itemObject[property]} />;
    }
}

const SimpleCardView = ({ properties, itemId, dataType, entityId, width }) => {
    const [itemObject, setItemObject] = useState(null);
    const entities = useSelector(selectEntities);
    const measureEntities = useSelector(selectMeasureEntities);
    const topics = useSelector(selectTopics);
    const { token } = useSelector(userSelector);

    useEffect(() => {
        setItemObject({ loading: true })
        const selectApiCall = () => {
            switch (dataType) {
                case "metric": return API.metrics.getMetric;
                case "goal": return API.goal.getGoal;
                case "target": return API.target.getTarget;
                case "note": return API.notes.getNote;
                case "complexMetric": return API.metrics.getComplexMetric;
                case "alarm": return API.alarm.getSingleAlarm;
                case "alarmProfile": return API.alarmProfile.getAlarmProfile;
                case "entityActivity": return API.activity.getEntityActivity;
                case "stakeholder": return API.stakeholder.getOne;
                default: return () => Promise.reject("ERROR");
            }
        }
        if (dataType === "entity") setItemObject(entities[itemId] || null);
        else if (dataType === "measureEntity") setItemObject(measureEntities[itemId] || null);
        else if (dataType === "topic") setItemObject(topics.find(top => top._id === itemId) || null);
        else if (dataType === "metricReport") {
            API.metricReports.getLatestMetricReports(token, [itemId]).then(response => {
                if (response.data && response.data[0]) setItemObject({ ...response.data[0], _id: response.data[0]._id })
                else setItemObject(null);
            }).catch((error) => {
                console.error(error);
                setItemObject(null)
            });
        }
        else {
            const apiCall = selectApiCall();
            apiCall(itemId, token, entityId).then(response => {
                if (response.data) setItemObject(response.data)
            }).catch((error) => {
                console.error(error);
                setItemObject(null)
            });
        }

    }, [dataType, entities, itemId, measureEntities, token, topics, entityId]);

    if (itemObject) {
        if (itemObject.loading) return <LoadingData />;
        else return <Stack direction="column" spacing={2}>
            {properties.map(property => <RenderProperty width={width} entityId={entityId} key={property} property={property} itemObject={itemObject} dataType={dataType} />)}
        </Stack>
    }
    else return <Typography color="text.disabled" sx={{ m: 'auto' }} variant='h6'><FormattedMessage id="ERROR.NO_REFERENCE" /></Typography>;
}

export default SimpleCardView;