import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectNotificationMethodTypes, selectMeasurementsTypesConf, selectUnits } from 'redux/configurationSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Autocomplete, Card, CardHeader, Box, Stack, Tooltip, Typography, CardContent, IconButton, FormLabel, TextField, MenuItem, Button, useMediaQuery, FormControl } from '@mui/material';
import { LabelImportantOutlined, AddOutlined, DeleteOutlined } from '@mui/icons-material';
import { AssignOwnerField } from 'components';
import { selectMeasureEntities } from 'redux/entitySlice';

export default function AlarmProfileRules(props) {
    const { alarmRule, rule, updateAlarm, entityId, severityTypes, usedTypes } = props;
    const [filters, setFilters] = useState([]);
    const [ruleMessage, setRuleMessage] = useState("");
    const [contacts, setContacts] = useState([]);
    const notificationOptions = useSelector(selectNotificationMethodTypes);
    const [selectedNotificationTypes, setSelectedNotificationTypes] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const intl = useIntl();
    const [ruleSeverity, setRuleSeverity] = useState(rule);

    useEffect(() => {
        setFilters(alarmRule.filters);
        setRuleMessage(alarmRule.details);
        setContacts(alarmRule.contacts || []);
        setSelectedNotificationTypes(notificationOptions.filter(el => alarmRule.notificationMethod.includes(el.key)));
    }, [alarmRule, notificationOptions])

    const handleNewFilter = (event) => {
        const newFilter = { type: "SINGLE", conditions: [] };
        setFilters([...filters, newFilter]);
        updateAlarm(newFilter, 'filters', rule, filters.length);
    }

    return <Card sx={{ width: '100%', mb: 2 }}>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle>
                <FormattedMessage id="ALARMS.PROFILE.RULE_DELETE" /> <Typography display="inline" component="span" color="error" variant="h6" sx={{ ml: 0.5 }}><FormattedMessage id={"ALARMS.PROFILE_RULES." + rule} /></Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage id="ALARMS.PROFILE.RULE_DELETE_INFO" /><Typography display="inline" component="span" color="primary" variant="h6" sx={{ mx: 0.5 }}>{filters.length}</Typography><FormattedMessage id="FILTERS.MULTI" />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenDialog(false)} autoFocus><FormattedMessage id="CANCEL" /></Button>
                <Button color="error" onClick={() => updateAlarm(rule, 'rules-delete')}><FormattedMessage id="DELETE" /></Button>
            </DialogActions>
        </Dialog>
        <CardHeader title={
            <span>
                <TextField
                    id="select-severity"
                    key="select-severity"
                    fullWidth
                    sx={{ mb: 2 }}
                    size="small"
                    select
                    value={ruleSeverity}
                    onChange={(e) => {
                        setRuleSeverity(e.target.value)
                        updateAlarm(e.target.value, 'rule-severity-edit', rule)
                    }}
                >
                    {severityTypes.map((sevType, index) => (<MenuItem disabled={usedTypes.includes(sevType.value) && sevType.value !== rule} key={index} value={sevType.value}>
                        <Box >
                            <LabelImportantOutlined
                                name={'severity-' + sevType.value}
                                className={'severity-' + sevType.value}
                                sx={(theme) => ({ width: theme.typography.fontSize * 1.5, height: theme.typography.fontSize * 1 })}
                            />
                            <FormattedMessage id={"ALARMS.PROFILE_RULES." + sevType.value} />
                        </Box>
                    </MenuItem>))}

                </TextField>
            </span>
        }
            // subheader={<SecondaryText direction="row" messageId="TYPE" valueId={"TYPE." + alarmRule.type} />}
            action={<Tooltip title={<FormattedMessage id={"DELETE.RULE"} />} arrow>
                <IconButton color="error" onClick={() => setOpenDialog(true)}><DeleteOutlined /></IconButton>
            </Tooltip>}
        />
        <CardContent sx={{ pt: 0 }}>
            <Stack direction="column" spacing={1} sx={{ mb: 2 }}>
                <FormControl>
                    <FormLabel><FormattedMessage id="NOTIFICATIONS.METHOD" /></FormLabel>
                    <Autocomplete
                        multiple
                        size="small"
                        limitTags={3}
                        isOptionEqualToValue={(option, value) => option.key === value.key}
                        disableCloseOnSelect
                        filterSelectedOptions
                        onChange={(event, newValue) => {
                            setSelectedNotificationTypes(newValue);
                            updateAlarm(newValue.map(el => el.key), 'rules-edit', rule);
                        }}
                        id="multiple-limit-tags"
                        value={selectedNotificationTypes}
                        options={notificationOptions}
                        getOptionLabel={(option) => intl.formatMessage({ id: "NOTIFICATIONS.METHOD." + option.value }) || ""}
                        renderInput={(params) => (
                            <TextField {...params} />
                        )}
                        fullWidth
                    />
                </FormControl>
                <FormControl>
                    <FormLabel><FormattedMessage id="ALARM.MESSAGE" /></FormLabel>
                    <TextField
                        fullWidth
                        rows="5"
                        overflow-wrap="break-word"
                        variant="outlined"
                        id={"MESSAGE"}
                        type="text"
                        value={ruleMessage}
                        onChange={(e) => {
                            setRuleMessage(e.target.value);
                        }}
                        onBlur={() => updateAlarm(ruleMessage, 'message', rule)}
                        size="small"
                    />
                </FormControl>
                <AssignOwnerField multiple participants={contacts} onChange={(data) => { setContacts(data); updateAlarm(data, 'contacts', rule) }} contacts />
            </Stack>

            {!!filters.length && <FormLabel sx={{ color: 'primary.main' }}><FormattedMessage id="FILTERS" /></FormLabel>}
            {!!filters && filters.map((filter, filtIndex) => <AlarmProfileFilter key={rule + filtIndex} rule={rule} updateAlarm={updateAlarm} filter={filter} filtIndex={filtIndex} entityId={entityId} />)}
            <Button onClick={handleNewFilter} startIcon={<AddOutlined />}>
                <FormattedMessage id="FILTER_ADD" />
            </Button>
        </CardContent>
    </Card >
}


function AlarmProfileFilter(props) {
    const { rule, filter, filtIndex, updateAlarm, entityId } = props;
    const [newConditionFlag, setNewConditionFlag] = useState(false);
    const [type, setType] = useState(filter.type);
    const [validFlag, setValidFlag] = useState(false);
    const filterTypes = ["AND", "OR", "SINGLE"];

    const handleDeleteFilter = (filterIndex) => {
        updateAlarm(undefined, 'filters', rule, filterIndex);
    }

    const handleNewCondition = () => {
        setNewConditionFlag(true);
    }

    const updateAlarmHandler = (...args) => {

        if (args[0] && args[0].measurementTypeKey === null) setValidFlag(true);
        else setValidFlag(false);

        setNewConditionFlag(false);
        updateAlarm(...args);
    };

    useEffect(() => {
        if (type === "SINGLE" && filter.conditions?.length > 1) {
            updateAlarm({ ...filter, conditions: [filter.conditions[0]] }, 'filters', rule, filtIndex)
            setNewConditionFlag(false);
        }
    }, [filtIndex, filter, rule, type, updateAlarm])

    return <div key={filtIndex}>
        <Box sx={{ border: '1px solid lightgray', p: 2, mb: 1 }}>
            <CardHeader sx={{ p: 1 }} title={<SecondaryText direction="row" messageId="TYPE" >
                <TextField
                    sx={{ mr: 2, textAlign: 'center' }}
                    select
                    size="small"
                    value={type}
                    onChange={(e) => {
                        setType(e.target.value);
                        updateAlarm({ ...filter, type: e.target.value }, 'filters', rule, filtIndex)
                    }}
                >
                    {filterTypes.map((type, index) => (
                        <MenuItem key={index} value={type}>
                            <FormattedMessage id={"FILTERS." + type} />
                        </MenuItem>
                    ))}
                </TextField></SecondaryText>} action={<Tooltip title={<FormattedMessage id={"DELETE.FILTER"} />} arrow>
                    <IconButton size="small" color="error" onClick={() => handleDeleteFilter(filtIndex)}><DeleteOutlined /></IconButton>
                </Tooltip>} />
            {filter.conditions.map((condition, condIndex) => {
                let disabled = false;
                if (type === "SINGLE" && condIndex > 0) disabled = true;
                return <AlarmProfileCondition disabled={disabled} key={JSON.stringify(condition) + condIndex} condition={condition} index={condIndex} filtIndex={filtIndex} rule={rule} updateAlarm={updateAlarmHandler} entityId={entityId} />
            })}

            {newConditionFlag && <AlarmProfileCondition index={filter.conditions.length} filtIndex={filtIndex} rule={rule} updateAlarm={updateAlarmHandler} entityId={entityId} />}
            {!(type === 'SINGLE' && filter.conditions.length) && <Button disabled={Boolean(newConditionFlag || validFlag)} startIcon={<AddOutlined />} onClick={handleNewCondition} >
                <FormattedMessage id="ADD.CONDITION" />
            </Button>}
        </Box>
    </div>;
}

function AlarmProfileCondition(props) {
    const { condition, index, filtIndex, rule, updateAlarm, disabled, entityId } = props;
    const intl = useIntl();
    const [newCondition, setNewCondition] = useState(condition || {
        measurementTypeKey: null,
        operator: "=",
        value: 0,
        unit: "",
    });
    const [value, setValue] = useState(newCondition.value.description || newCondition.value);
    const resourceTypesConfig = useSelector(selectMeasurementsTypesConf);
    const measureEntityTypes = useSelector(selectMeasureEntities)[entityId]?.measurementTypes;
    const units = useSelector(selectUnits);
    const xsScreen = useMediaQuery(theme => theme.breakpoints.down("sm")); // xs -> sm -> md -> lg -> xl


    const renderCondition = useCallback(() => {
        const onUpdate = (data, key) => {
            if (data !== undefined && key === "measurementTypeKey") {
                const newCond = {
                    ...newCondition,
                    [key]: data,
                    unit: '',
                }
                setNewCondition(newCond);
                updateAlarm(newCond, 'condition', rule, filtIndex, index);
            }
            else if (data !== undefined) {
                const newCond = {
                    ...newCondition,
                    [key]: data,
                }
                setNewCondition(newCond);
                updateAlarm(newCond, 'condition', rule, filtIndex, index);
            }
            else updateAlarm(undefined, 'condition', rule, filtIndex, index);
        }
        const operators = [">=", ">", "=", "<>", "<", "<="];

        let availableResources = resourceTypesConfig;
        if (measureEntityTypes) {
            const OWM = measureEntityTypes?.service?.OWM || [];
            const manual = measureEntityTypes?.manual?.types || [];
            availableResources = Array.from(new Set([...manual, ...OWM])).map(type => resourceTypesConfig.find(el => el.key === type)).filter(Boolean);
        }

        return <Stack direction={xsScreen ? "column" : "row"} spacing={1} sx={{ pb: 1 }} >
            <Autocomplete
                disabled={disabled}
                sx={{ minWidth: '35%' }}
                fullWidth={xsScreen}
                size="small"
                id={condition !== undefined ? ("resource-" + condition.measurementTypeKey) : 'new-resource'}
                options={availableResources}
                getOptionLabel={(resource) => resource.name || intl.formatMessage({ id: resource.key })}
                value={resourceTypesConfig.find(el => el.key === newCondition.measurementTypeKey)}
                onChange={(e, resource) => {
                    if (resource !== null) onUpdate(resource.key, 'measurementTypeKey');
                    else onUpdate(undefined, 'measurementTypeKey');
                }}
                renderInput={(params) => (
                    <TextField {...params} key={resourceTypesConfig.map(resource => resource.key)} inputProps={{ ...params.inputProps, ...(xsScreen && { style: { textAlign: 'center' } }) }} />
                )}
                renderOption={(props, option) => (<Box {...props}><DisplayResourceName resource={option} /></Box>)}
            />
            <TextField id={"operator" + newCondition.operator + index} key={"operator" + newCondition.operator + index}
                disabled={disabled}
                sx={{ textAlign: 'center', minWidth: '10%' }}
                fullWidth={xsScreen}
                select
                size="small"
                defaultValue={newCondition.operator}
                onChange={(e) => {
                    onUpdate(e.target.value, 'operator');
                }}
            >
                {operators.map((operator, index) => (
                    <MenuItem key={index} value={operator}>
                        {operator}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                disabled={disabled}
                sx={{ minWidth: '25%' }}
                variant="outlined"
                id={"value" + newCondition.value + index}
                key={"value" + newCondition.value + index}
                placeholder={intl.formatMessage({ id: "VALUE" })}
                type="number"
                defaultValue={value}
                onChange={(e) => {
                    setValue(e.target.value);
                }}
                inputProps={{ style: { textAlign: 'center' } }}
                onBlur={() => onUpdate(value, 'value')}
                size="small"
            />
            <Autocomplete
                id="select-units"
                disabled={!newCondition.measurementTypeKey}
                options={units[resourceTypesConfig.find(el => el.key === newCondition.measurementTypeKey)?.dataType?.measure] || []}
                getOptionLabel={(option) => option}
                value={newCondition.unit}
                onChange={(e, unit, reason) => {
                    if (reason === "clear") onUpdate("", 'unit');
                    else onUpdate(unit, 'unit')
                }}
                renderInput={(params) => <TextField {...params} placeholder={intl.formatMessage({ id: 'UNIT' })} inputProps={{ ...params.inputProps, ...(xsScreen && { style: { textAlign: 'center' } }) }} />}
                size="small"
                fullWidth
                isOptionEqualToValue={(option, value) => option === value || value === ""}
            />
            <Tooltip title={<FormattedMessage id={"DELETE.CONDITION"} />} arrow>
                <IconButton size="small" color="error" onClick={() => onUpdate()}>
                    <DeleteOutlined></DeleteOutlined>
                </IconButton>
            </Tooltip>
        </Stack>;
    }, [condition, resourceTypesConfig, measureEntityTypes, units, newCondition, value, index, filtIndex, rule, updateAlarm, disabled, intl, xsScreen])

    return renderCondition();
}

const DisplayResourceName = (props) => {
    const { resource } = props;
    const intl = useIntl();
    const translatedName = resource ? (resource.name || intl.formatMessage({ id: resource.key })) : '';
    if (resource && !resource?.metas?.latin) return <span>{translatedName}</span>;
    else {
        let resourceName = "";
        const parts = translatedName.split(' (');
        parts.length > 1 ?
            resourceName = <span><i>{parts[0]}</i>{" (" + parts[1]}</span> :
            resourceName = <i>{translatedName}</i>;

        return resourceName;
    }
}

const SecondaryText = (props) => {
    return <Stack sx={{ alignItems: 'center' }} spacing={0.5} direction={props.direction}>
        <Typography variant="body2" color='primary.main'><FormattedMessage id={props.messageId} /></Typography>
        {props.children ? props.children : <Typography variant='body1'><FormattedMessage id={props.valueId} /></Typography>}
    </Stack>
};