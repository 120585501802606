import instance from "./axiosEnv";
import { setAuthHeader } from "./axiosEnv";
import { localISOString } from "utils";

const getAllGoals = (token, topicIds, entityIds, status, type, dateFrom, dateTo) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    if (entityIds) {
        for (let el of entityIds) {
            if (el !== undefined) queryParams.append('entityId', el);
        }
    }
    if (topicIds) {
        for (let el of topicIds) {
            if (el !== undefined) queryParams.append('topicId', el);
        }
    }
    if (status) queryParams.append('status', status);
    if (type) queryParams.append('type', type);
    if (dateFrom) queryParams.append('dateFrom', localISOString(dateFrom));
    if (dateTo) queryParams.append('dateTo', localISOString(dateTo));

    return instance.get('/ESG/goal?' + queryParams.toString(), config);
}

const getGoalsCount = (token, entityIds, statuses, categories, types, topicIds, dateFrom, dateTo, display) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    if (entityIds) {
        for (let el of entityIds) {
            queryParams.append('entityId', el);
        }
    }
    if (statuses) {
        for (let el of statuses) {
            queryParams.append('status', el);
        }
    }
    if (categories) {
        for (let el of categories) {
            queryParams.append('category', el);
        }
    }
    if (types) {
        for (let el of types) {
            queryParams.append('type', el);
        }
    }
    if (topicIds) {
        for (let el of topicIds) {
            queryParams.append('topicId', el);
        }
    }
    if (dateFrom) queryParams.append('dateFrom', localISOString(dateFrom));
    if (dateTo) queryParams.append('dateTo', localISOString(dateTo));
    if (display) queryParams.append('display', display);

    return instance.get('/ESG/goal/count?' + queryParams.toString(), config);
}

const getGoal = (goalId, token) => {
    const config = setAuthHeader(token);
    return instance.get('/ESG/goal/' + goalId, config);
}

const updateGoal = (goalId, data, token) => {
    const config = setAuthHeader(token);
    return instance.put('/ESG/goal/' + goalId, data, config);
}
const updateGoalStatus = (goalId, status, token) => {
    const config = setAuthHeader(token);
    return instance.put(`/ESG/goal/${goalId}/status/${status}`, null, config);
}

const createGoal = (data, token) => {
    const config = setAuthHeader(token);
    return instance.post('/ESG/goal', data, config);
}

const deleteGoal = (goalId, token) => {
    const config = setAuthHeader(token);
    return instance.delete('/ESG/goal/' + goalId + '/' + true, config);
}

const roleAPI = {
    getAllGoals,
    getGoalsCount,
    getGoal,
    createGoal,
    updateGoal,
    updateGoalStatus,
    deleteGoal,
};

export default roleAPI;