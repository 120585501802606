import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from 'api';

const initialState = {
  data: { users: [], groups: [] },
  status: 'idle',
  error: null,
  failCount: 0
};

export const fetchUsersAndGroups = createAsyncThunk('topics/fetchUsersAndGroups', async (token) => {
  const { getAllUsersAndGroups } = API.userGroup;
  if (token) {
    const { data } = await getAllUsersAndGroups(token);
    return { users: data.users, groups: data.groups.map(el => ({ ...el, id: el?._id })) };
  }
  else return initialState.data;
});

export const usersAndGroupsSlice = createSlice({
  name: 'usersAndGroups',
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      state.status = action.payload;
      return state;
    },
    updateUsers: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.data.users = action.payload;
      }
      else {
        const index = state.data.users.findIndex(el => el.id === action.payload.id);
        if (index > -1) state.data.users.splice(index, 1, action.payload);
        else state.data.users.push(action.payload);
      }
    },
    deleteUser: (state, action) => {
      const index = state.data.users.findIndex(el => el.id === action.payload.id);
      if (index > -1) state.data.users.splice(index, 1);
    },
    updateGroups: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.data.groups = action.payload;
      }
      else {
        const newGroup = { id: action.payload._id, ...action.payload };
        const index = state.data.groups.findIndex(el => el.id === newGroup.id);
        if (index > -1) state.data.groups.splice(index, 1, newGroup);
        else state.data.groups.push(newGroup);
      }
    },
    deleteGroup: (state, action) => {
      const index = state.data.groups.findIndex(el => el._id === action.payload._id);
      if (index > -1) state.data.groups.splice(index, 1);
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersAndGroups.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsersAndGroups.rejected, (state) => {
        state.failCount += 1;
        if (state.failCount > 3) state.status = 'disabled';
        else state.status = 'failed';
      })
      .addCase(fetchUsersAndGroups.fulfilled, (state, action) => {
        state.status = 'complete';
        state.data = action.payload;
      });
  },
});

export const { updateStatus, updateUsers, deleteUser, updateGroups, deleteGroup } = usersAndGroupsSlice.actions;

export const selectUsersAndGroups = (state) => {
  return state.usersAndGroups.data;
};

export const selectUsersAndGroupsStatus = (state) => {
  return state.usersAndGroups.status;
};

export default usersAndGroupsSlice.reducer;