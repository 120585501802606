import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { Button, Dialog, DialogActions, DialogContent, Divider, useMediaQuery } from '@mui/material';
import TargetFormContent from './TargetFormContent';
import { DialogFormTitle, AlertSnackbar } from 'components';
import { localISOString } from 'utils';
import API from 'api';


const TargetForm = ({ open, onClose, setAlert, existingTarget, defaultMetric, defaultGoal, size }) => {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const [name, setName] = useState("");
    const [allMetrics, setAllMetrics] = useState([]);
    const [metric, setMetric] = useState(null);
    const [allGoals, setAllGoals] = useState([]);
    const [goal, setGoal] = useState(null);
    const [targetType, setTargetType] = useState("");
    const [targetStatus, setTargetStatus] = useState(3);
    const [owner, setOwner] = useState("");
    const [participants, setParticipants] = useState([]);
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [baseValue, setBaseValue] = useState("");
    const [endValue, setEndValue] = useState("");
    const [currentValue, setCurrentValue] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [targetAlert, setTargetAlert] = useState({ open: false });
    const { token } = useSelector(userSelector);

    useEffect(() => {
        if (defaultMetric) setMetric(defaultMetric);
        if (defaultGoal) setGoal(defaultGoal);

        if (existingTarget && open) {
            const targetMetric = allMetrics.find(metric => metric._id === existingTarget.metricId);
            const targetGoal = allGoals.find(goal => goal._id === existingTarget.goalId);
            setName(existingTarget.name);
            setMetric(targetMetric ? targetMetric : null);
            setGoal(targetGoal ? targetGoal : null);
            setTargetType(existingTarget.type);
            setTargetStatus(existingTarget.status);
            setOwner(existingTarget.owner || "");
            setParticipants(existingTarget.participants ? [...existingTarget.participants] : []);
            if (existingTarget?.dateRange?.startDate) setStartDate(new Date(existingTarget?.dateRange?.startDate))
            if (existingTarget?.dateRange?.endDate) setEndDate(new Date(existingTarget?.dateRange?.endDate))

            if (existingTarget.measurable?.baseValue >= 0) setBaseValue(existingTarget.measurable.baseValue);
            if (existingTarget.measurable?.endValue >= 0) setEndValue(existingTarget.measurable.endValue);
            if (existingTarget.measurable?.currentValue >= 0) setCurrentValue(existingTarget.measurable.currentValue);
        }
        
    }, [allGoals, allMetrics, existingTarget, open, defaultGoal, defaultMetric])

    const handleClose = (flag) => {
        setName(existingTarget?.name || "");
        if (existingTarget) {
            const targetMetric = allMetrics.find(metric => metric._id === existingTarget.metricId);
            const targetGoal = allGoals.find(goal => goal._id === existingTarget.goalId);
            setMetric(targetMetric ? targetMetric : null);
            setGoal(targetGoal ? targetGoal : null);
        }
        else {
            setMetric(null);
            setGoal(null);
        }
        setTargetType(existingTarget?.type || "");
        setTargetStatus(existingTarget?.status || 0);
        setOwner(existingTarget?.owner || "");
        setParticipants(existingTarget?.participants || []);
        setBaseValue(existingTarget?.measurable?.baseValue || "");
        setEndValue(existingTarget?.measurable?.endValue || "");
        setCurrentValue(existingTarget?.measurable?.currentValue || "");
        setEditMode(false);
        onClose(flag);
    }

    const compareData = (oldData, newData) => {
        return (
            oldData.name?.trim() === newData.name?.trim() &&
            oldData.description?.trim() === newData.description?.trim() &&
            oldData.goalId === newData.goalId &&
            oldData.metricId === newData.metricId &&
            oldData.type === newData.type &&
            oldData.status === newData.status &&
            oldData.owner?.trim() === newData.owner?.trim() &&
            oldData.dateRange?.startDate === new Date(newData.startDate).toISOString() &&
            oldData.dateRange?.endDate === new Date(newData.endDate).toISOString() &&
            (oldData.participants && newData.participants ? JSON.stringify(oldData.participants?.sort()) === JSON.stringify(newData.participants?.sort()) : true) &&
            (oldData.measurable && newData.measurable ? oldData.measurable?.baseValue === newData.measurable?.baseValue && oldData.measurable?.endValue === newData.measurable?.endValue : true)
        )
    }

    const onSubmit = () => {
        setDisabledSubmitButton(true);
        const newTargetObject = {
            name: name,
            metricId: metric._id,
            goalId: goal._id,
            type: targetType,
            status: targetStatus,
            owner: owner,
            startDate: localISOString(startDate),
            endDate: localISOString(endDate),
            measurable: {},
            participants: participants,
        }
        if (baseValue !== "") newTargetObject.measurable["baseValue"] = Number(baseValue);
        if (endValue !== "") newTargetObject.measurable["endValue"] = Number(endValue);
        // if (baseValue !== "" && endValue !== "") newTargetObject.measurable["currentValue"] = Number(currentValue);


        if (existingTarget && compareData(existingTarget, newTargetObject)) {
            setTargetAlert({ open: true, messageId: "ERROR.NO_CHANGE_DETECTED", severity: "error" });
            setDisabledSubmitButton(false);
        }
        else if (existingTarget) {
            API.target.updateTarget(existingTarget._id, newTargetObject, token).then(response => {
                setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
                handleClose(true);
                setDisabledSubmitButton(false);
                setEditMode(false);
            }).catch(error => {
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_UPDATED", severity: "error" });
                console.error(error);
                setDisabledSubmitButton(false);
                setEditMode(false);
            })
        }
        else {
            API.target.createTarget(newTargetObject, token).then(response => {
                setAlert({ open: true, messageId: "SUCCESS.CREATE", severity: "success" });
                handleClose(true);
                setDisabledSubmitButton(false);
            }).catch(error => {
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_CREATED", severity: "error" });
                console.error(error);
                setDisabledSubmitButton(false);
            })
        }
    }


    useEffect(() => {
        if (editMode) {
            if (name.trim() !== "" &&
                targetType !== "" &&
                metric !== null &&
                goal !== null &&
                (new Date(startDate).toString() !== "Invalid Date" || new Date(endDate).toString() !== "Invalid Date")) setDisabledSubmitButton(false);
        }
        else setDisabledSubmitButton(true);
    }, [name, targetType, targetStatus, goal, metric, editMode, owner, startDate, endDate, participants, baseValue, endValue]);

    return <>
        <Dialog open={open} maxWidth={size || "md"} fullWidth fullScreen={smallScreen} >
            <AlertSnackbar open={targetAlert.open} onClose={() => setTargetAlert({ ...targetAlert, open: false })} severity={targetAlert.severity} messageId={targetAlert.messageId} />
            <DialogFormTitle
                mainTitle={<FormattedMessage id={existingTarget ? "ESG.TARGET.EDIT" : "ESG.TARGET.ADD"} />}
                subTitle={existingTarget ? existingTarget.name : name}
            />
            <Divider />
            <DialogContent>
                <TargetFormContent setEditMode={setEditMode}
                    name={name} setName={setName}
                    metric={metric} setMetric={setMetric}
                    metrics={allMetrics} setMetrics={setAllMetrics}
                    goal={goal} setGoal={setGoal}
                    goals={allGoals} setGoals={setAllGoals}
                    targetType={targetType} setTargetType={setTargetType}
                    targetStatus={targetStatus} setTargetStatus={setTargetStatus}
                    owner={owner} setOwner={setOwner}
                    participants={participants} setParticipants={setParticipants}
                    baseValue={baseValue} setBaseValue={setBaseValue}
                    endValue={endValue} setEndValue={setEndValue}
                    currentValue={currentValue} setCurrentValue={setCurrentValue}
                    startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}
                    existingTarget={existingTarget} defaultGoal={defaultGoal} defaultMetric={defaultMetric} />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={() => handleClose()} color="warning"><FormattedMessage id="CANCEL" /></Button>
                <Button disabled={disabledSubmitButton} onClick={onSubmit}><FormattedMessage id={existingTarget ? "EDIT" : "ADD"} /></Button>
            </DialogActions>

        </Dialog>
    </>
}

export default TargetForm;