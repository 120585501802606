import { Autocomplete, Checkbox, Collapse, FormControl, FormLabel, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectEntities } from 'redux/entitySlice';
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';
import { GroupHeader, GroupItems } from 'components';
import { selectMetricTypesConf, selectGoalCategoryTypesConf, selectStatusTypes, selectDashboardConfiguration, selectPriorityTypes, selectSeverityTypes } from 'redux/configurationSlice';
import { selectTopics } from 'redux/topicSlice';

const BarChartConstructor = ({ selectedAttributes, setEntities, setSelectedAttributes, allMetrics, checkExistance, dataType, allGoals, allAlarmProfiles, existingWidget }) => {
    const entities = useSelector(selectEntities);
    const allTopics = useSelector(selectTopics);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const dashboardConfiguration = useSelector(selectDashboardConfiguration);
    const priorityTypes = useSelector(selectPriorityTypes);
    const goalConfigurationArray = dashboardConfiguration['chart-bar'].source.find(item => dataType in item) && dashboardConfiguration['chart-bar'].source.find(item => dataType in item)[dataType]
    const [selectedDisplay, setSelectedDisplay] = useState(null);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [selectedSeverities, setSelectedSeverities] = useState([]);
    const [selectedProfiles, setSelectedProfiles] = useState([]);
    const intl = useIntl();
    const filterMetrics = (entityArray) => {
        const entityIds = entityArray.map(ent => ent._id);
        const filteredMetrics = allMetrics.filter(met => entityIds.includes(met.entityId));
        return filteredMetrics;
    }
    const metricTypes = useSelector(selectMetricTypesConf);
    const statusTypes = useSelector(selectStatusTypes);
    const categories = useSelector(selectGoalCategoryTypesConf);
    const severityTypes = useSelector(selectSeverityTypes);

    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [categoryTypes, setCategoryTypes] = useState([]);
    const [selectedPriorities, setSelectedPriorities] = useState([]);
    const [selectedGoalsForTarget, setSelectedGoalsForTarget] = useState([]);
    const [selectedMetrics, setSelectedMetrics] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (((dataType === "metricReport" || dataType === "target") && allMetrics.length) ||
            (dataType === "target" && (allGoals.length && allMetrics.length)) ||
            (dataType === "alarm" && allAlarmProfiles.length) || dataType === "alarmProfile" ||
            dataType === "goal" || dataType === "topic") setLoaded(true);
    }, [allMetrics, allGoals, allAlarmProfiles, dataType]);

    useEffect(() => {
        if (existingWidget && loaded && dataType === "metricReport") {
            setSelectedEntities(Object.values(entities).filter(el => existingWidget.config.source.metricReport.entity?.includes(el._id)));
            setSelectedMetrics(allMetrics.filter(el => existingWidget.config.source.metricReport.metric?.includes(el._id)));
        }
    }, [existingWidget, entities, allMetrics, dataType, loaded])

    useEffect(() => {
        if (dataType === 'metricReport') {
            setEntities(selectedEntities);
            setSelectedAttributes(selectedMetrics);
        }
    }, [dataType, selectedEntities, selectedMetrics, setEntities, setSelectedAttributes])

    useEffect(() => {
        if (existingWidget && loaded && dataType !== 'metricReport') {
            const { display, ...attributes } = existingWidget.config.source[existingWidget.config.source.dataType];
            setSelectedDisplay(display);
            switch (display) {
                case "entityId": setSelectedEntities(Object.values(entities).filter(el => attributes.entity.includes(el._id))); return;
                case "type": setSelectedTypes(metricTypes.filter(el => attributes[display].includes(el.key))); return;
                case "status": setSelectedStatuses(statusTypes.filter(el => attributes[display].includes(el.key))); return;
                case "category": setCategoryTypes(categories.filter(el => attributes[display].includes(el.key))); return;
                case "priority": setSelectedPriorities(priorityTypes.filter(el => attributes[display].includes(el.key))); return;
                case "goalId": setSelectedGoalsForTarget(allGoals.filter(el => attributes.goal.includes(el._id))); return;
                case "metricId": setSelectedMetrics(allMetrics.filter(el => attributes.metric.includes(el._id))); return;
                case "topicId": setSelectedTopics(allTopics.filter(el => attributes.topic.includes(el._id))); return;
                case "severityType": setSelectedSeverities(severityTypes.filter(el => attributes.severity.includes(el.key))); return;
                case "profileId": setSelectedProfiles(allAlarmProfiles.filter(el => attributes.profile.includes(el._id))); return;
                default: return;
            }
        }

    }, [allAlarmProfiles, allGoals, allMetrics, allTopics, categories, entities, loaded, metricTypes, priorityTypes, severityTypes, statusTypes, dataType, existingWidget, setSelectedAttributes]);

    useEffect(() => {
        if (dataType !== 'metricReport') {
            setSelectedAttributes({
                display: selectedDisplay,
                ...(selectedDisplay === "entityId" && { entity: selectedEntities.map(ent => ent._id) }),
                ...(selectedDisplay === "type" && { type: selectedTypes.map(type => type.key) }),
                ...(selectedDisplay === "status" && { status: selectedStatuses.map(type => type.key) }),
                ...(selectedDisplay === "category" && { category: categoryTypes.map(type => type.key) }),
                ...(selectedDisplay === "priority" && { priority: selectedPriorities.map(type => type.key) }),
                ...(selectedDisplay === "goalId" && { goal: selectedGoalsForTarget.map(goal => goal._id) }),
                ...(selectedDisplay === "metricId" && { metric: selectedMetrics.map(metric => metric._id) }),
                ...(selectedDisplay === "topicId" && { topic: selectedTopics.map(topic => topic._id) }),
                ...(selectedDisplay === "severityType" && { severity: selectedSeverities.map(severity => severity.key) }),
                ...(selectedDisplay === "profileId" && { profile: selectedProfiles.map(profile => profile._id) }),
            })
        }
    }, [categoryTypes, dataType, selectedDisplay, selectedEntities, selectedGoalsForTarget, selectedMetrics, selectedPriorities, selectedProfiles, selectedSeverities, selectedStatuses, selectedTopics, selectedTypes, setSelectedAttributes])

    return <Stack direction="column" spacing={2}>
        {dataType !== "metricReport" && <FormControl>
            <FormLabel required><FormattedMessage id="DASHBOARD.SELECT_DISPLAY" /></FormLabel>
            <Autocomplete
                id="select-display"
                options={goalConfigurationArray || []}
                getOptionLabel={(option) => intl.formatMessage({ id: "DASHBOARD.OPTIONS." + option })}
                value={selectedDisplay}
                onChange={(e, newValue) => setSelectedDisplay(newValue)}
                renderInput={(params) => <TextField {...params} />}
                size="small"
                fullWidth
            />
        </FormControl>}
        {(selectedDisplay === "entityId" || (dataType === "metricReport" && checkExistance("entity"))) && <FormControl>
            <FormLabel required={dataType === "metricReport"}><FormattedMessage id="ENTITIES" /></FormLabel>
            <Autocomplete
                id="select-entities"
                multiple
                options={Object.keys(entities).map(ent => entities[ent])}
                getOptionLabel={(option) => option.name}
                value={selectedEntities}
                onChange={(e, newValue) => {
                    if (dataType === "metricReport" && !selectedMetrics.every(el => filterMetrics(newValue).includes(el))) {
                        setSelectedMetrics(selectedMetrics.filter(el => filterMetrics(newValue).includes(el)));
                    }
                    setSelectedEntities(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                size="small"
                fullWidth
            />
        </FormControl>}
        <Collapse in={selectedDisplay !== null || selectedEntities.length > 0}>
            {(checkExistance("metric") || selectedDisplay === "metricId") && <FormControl fullWidth>
                <FormLabel required={dataType === "metricReport"}><FormattedMessage id="ESG.METRICS" /></FormLabel>
                <Autocomplete
                    id="select-metric-type"
                    multiple
                    options={selectedDisplay === "metricId" ? allMetrics : filterMetrics(selectedEntities)}
                    getOptionLabel={(metric) => `${metric.name} (${entities[metric.entityId]?.name})`}
                    value={selectedMetrics}
                    onChange={(e, newValue) => setSelectedMetrics(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    size="small"
                    fullWidth
                    groupBy={(option) => option.entityId}
                    renderGroup={(params) => (
                        <li key={params.group}>
                            <GroupHeader>{entities[params.group].name}</GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                        </li>
                    )}
                    renderOption={(props, option, { selected }) => {
                        return <li {...props} key={option._id}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlank fontSize="small" />}
                                checkedIcon={<CheckBoxOutlined fontSize="small" />}
                                checked={selected}
                                style={{ marginRight: 8 }}
                            />
                            {option.name}
                        </li>
                    }}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                />
            </FormControl>}

            {selectedDisplay === "status" && <FormControl fullWidth>
                <FormLabel><FormattedMessage id="STATUS" /></FormLabel>
                <Autocomplete
                    id="select-status"
                    multiple
                    options={statusTypes}
                    getOptionLabel={(option) => intl.formatMessage({ id: "STATUS." + option.value })}
                    value={selectedStatuses}
                    onChange={(e, newValue) => setSelectedStatuses(newValue)}
                    renderInput={(params) => (
                        <TextField {...params} />
                    )}
                    size="small"
                    fullWidth
                />
            </FormControl>}

            {selectedDisplay === "category" && <FormControl fullWidth>
                <FormLabel ><FormattedMessage id="ESG.GOAL.CATEGORY" /></FormLabel>
                <Autocomplete
                    id="select-category"
                    multiple
                    options={categories}
                    getOptionLabel={(option) => intl.formatMessage({ id: "ESG.GOAL." + option.value })}
                    value={categoryTypes}
                    onChange={(e, newValue) => setCategoryTypes(newValue)}
                    renderInput={(params) => (
                        <TextField {...params} />
                    )}
                    size="small"
                    fullWidth
                />
            </FormControl>}

            {selectedDisplay === "type" && <FormControl fullWidth>
                <FormLabel><FormattedMessage id="ESG.TYPE" /></FormLabel>
                <Autocomplete
                    id="select-type"
                    multiple
                    options={metricTypes}
                    getOptionLabel={(option) => intl.formatMessage({ id: "ESG.TYPE." + option.key })}
                    value={selectedTypes}
                    onChange={(e, newValue) => setSelectedTypes(newValue)}
                    renderInput={(params) => (
                        <TextField {...params} />
                    )}
                    size="small"
                    fullWidth
                />
            </FormControl>}

            {selectedDisplay === "priority" && <FormControl fullWidth>
                <FormLabel><FormattedMessage id="PRIORITY" /></FormLabel>
                <Autocomplete
                    id="select-priority"
                    multiple
                    options={priorityTypes}
                    getOptionLabel={(option) => intl.formatMessage({ id: "PRIORITY." + option.value })}
                    value={selectedPriorities}
                    onChange={(e, newValue) => setSelectedPriorities(newValue)}
                    renderInput={(params) => (
                        <TextField {...params} />
                    )}
                    size="small"
                    fullWidth
                />
            </FormControl>}
            {selectedDisplay === "topicId" && <FormControl fullWidth>
                <FormLabel><FormattedMessage id="ESG.TOPIC" /></FormLabel>
                <Autocomplete
                    id="select-topic"
                    multiple
                    options={allTopics}
                    getOptionLabel={(topic) => `${topic.name} (${entities[topic.entityId].name})`}
                    value={selectedTopics}
                    onChange={(e, topic) => setSelectedTopics(topic)}
                    renderInput={(params) => (
                        <TextField {...params} />
                    )}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    size="small"
                    fullWidth
                />
            </FormControl>}

            {selectedDisplay === "goalId" && <FormControl fullWidth>
                <FormLabel><FormattedMessage id="ESG.GOAL" /></FormLabel>
                <Autocomplete
                    id="select-goal"
                    multiple
                    options={allGoals}
                    getOptionLabel={(goal) => goal.name}
                    value={selectedGoalsForTarget}
                    onChange={(e, goal) => setSelectedGoalsForTarget(goal)}
                    renderInput={(params) => (
                        <TextField {...params} />
                    )}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    size="small"
                    fullWidth
                />
            </FormControl>}
            {selectedDisplay === "severityType" && <FormControl fullWidth>
                <FormLabel><FormattedMessage id="SEVERITY" /></FormLabel>
                <Autocomplete
                    id="select-severity"
                    multiple
                    options={severityTypes}
                    getOptionLabel={(severity) => intl.formatMessage({ id: "ALARMS.PROFILE_RULES." + severity.value })}
                    value={selectedSeverities}
                    onChange={(e, severity) => setSelectedSeverities(severity)}
                    renderInput={(params) => (
                        <TextField {...params} />
                    )}
                    size="small"
                    fullWidth
                />
            </FormControl>}
            {selectedDisplay === "profileId" && <FormControl fullWidth>
                <FormLabel><FormattedMessage id="PROFILES" /></FormLabel>
                <Autocomplete
                    id="select-alarm-profile"
                    multiple
                    options={allAlarmProfiles}
                    getOptionLabel={(profile) => profile.name}
                    value={selectedProfiles}
                    onChange={(e, profiles) => setSelectedProfiles(profiles)}
                    renderInput={(params) => (
                        <TextField {...params} />
                    )}
                    size="small"
                    fullWidth
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                />
            </FormControl>}
        </Collapse>
    </Stack>
}

export default BarChartConstructor;