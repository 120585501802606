import { ChevronRight } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectEntities } from "redux/entitySlice";
import { recursiveParentSearch } from "utils/helpers";

export default function EntityBreadcrumbs({ entity, navigateTo, suffix, rootUrl }) {
    const navigate = useNavigate();
    const allEntities = useSelector(selectEntities);
    const array = recursiveParentSearch(entity, allEntities);

    const url = navigateTo ? navigateTo : '/entities';
    const root_url = rootUrl ? rootUrl : '/entities';

    return <Breadcrumbs maxItems={3} separator={<ChevronRight fontSize="small" sx={{ pb: 0.5 }} />}>
        {entity ? [
            <Button size="small" key="entities" onClick={() => navigate(root_url)}>{process.env.REACT_APP_ORG_TITLE}</Button>,
            ...array.map(el => <Button key={"parent-" + el.name} size="small" onClick={() => navigate(url + '/' + el._id)}><Box component="span" sx={{ maxWidth: 100, overflow: 'hidden', whiteSpace: 'nowrap', display: 'block', textOverflow: 'ellipsis' }}>{el.name}</Box></Button>),
            <Typography key={entity.name} variant="h6" sx={{ pb: 0.5 }}>{entity.name} <Typography component="span" variant="body2">{suffix}</Typography></Typography>,
        ]
            : <Typography variant="h6">{process.env.REACT_APP_ORG_TITLE}</Typography>
        }
    </Breadcrumbs>
}