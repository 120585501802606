import React, { useEffect, useState } from 'react';
import { Divider, Dialog, DialogContent, DialogActions, Button, Stack, Typography, useMediaQuery, CardHeader, Avatar, IconButton, Chip, Box } from "@mui/material";
import { EditOutlined, EmojiEventsOutlined, TrackChangesOutlined, UpdateOutlined } from '@mui/icons-material';
import { FormattedMessage } from "react-intl";
import { useSelector } from 'react-redux';
import { selectStatusTypes } from 'redux/configurationSlice';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { selectUsersAndGroups, selectUsersAndGroupsStatus, fetchUsersAndGroups } from 'redux/usersAndGroupsSlice';
import { TargetForm, AlertSnackbar, DisplayBox, StatusUpdateInput, LinearProgressWithLabel, UserList } from 'components';
import { formatDateLocale, getStatusColor } from 'utils';
import { store } from 'redux/store';
import API from 'api';

const TargetDetailsView = ({ target, openDialog, onClose, small, xsmall, onUpdateTargetStatus, onUpdate }) => {
    const { token } = useSelector(userSelector);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const statusTypes = useSelector(selectStatusTypes);
    const usersAndGroups = useSelector(selectUsersAndGroups);
    const status = useSelector(selectUsersAndGroupsStatus);
    const [openForm, setOpenForm] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(false);
    const [newStatus, setNewStatus] = useState(null);
    const [alert, setAlert] = useState({ open: false });
    const [allUsers, setAllUsers] = useState([]);
    const [allGroups, setAllGroups] = useState([]);
    const updateTargetPermission = useSelector((state) => permissionSelector(state, 'update-target'));
    const viewUsersPermission = useSelector((state) => permissionSelector(state, 'view-users'));

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const onDialogClose = () => {
        setUpdateStatus(false);
        onClose();
    }

    const targetStatus = statusTypes.find(st => st.key === target?.status);

    const onUpdateStatus = (status) => {
        if (target) API.target.updateTargetStatus(target._id, status, token)
            .then((item) => {
                setAlert({ open: true, messageId: "SUCCESS", severity: "success" });
                onUpdateTargetStatus(item.data.status, target._id);
            })
            .catch((error) => setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_UPDATED", severity: "error" }));
        setUpdateStatus(false);
    };

    useEffect(() => {
        if (!viewUsersPermission) {
            setAllUsers([]);
            setAllGroups([]);
            return;
        }
        else if (status === 'failed') {
            store.dispatch(fetchUsersAndGroups(token));
        } else if (status === 'complete') {
            setAllUsers(usersAndGroups.users);
            setAllGroups(usersAndGroups.groups);
        } else {
            setAllUsers([]);
            setAllGroups([]);
        }
    }, [token, status, usersAndGroups, viewUsersPermission])

    useEffect(() => {
        if (updateStatus === false) {
            if (target) setNewStatus(target.status);
            else setNewStatus(null);
        }
    }, [updateStatus, target]);

    useEffect(() => {
        if (target) setNewStatus(target.status);
    }, [target]);

    const getMetric = () => {
        if (target) {
            if (target.metricName) return target.metricName;
            else if (typeof target.metricId === 'object' && target.metricId.name) return target.metricId.name;
            else return target.metricId;
        }
        else return null;
    }
    const getGoal = () => {
        if (target) {
            if (target.goalName) return target.goalName;
            else if (typeof target.goalId === 'object' && target.goalId.name) return target.goalId.name;
            else return target.goalId;
        }
        else return null;
    }

    return (
        <>
            <TargetForm open={openForm} onClose={(flag) => { setOpenForm(false); if (onUpdate && flag) onUpdate(); }} existingTarget={target} setAlert={setAlert} />
            <Dialog
                open={openDialog}
                maxWidth={xsmall ? "xs" : small ? 'sm' : 'md'}
                fullWidth
                fullScreen={smallScreen}
                onClose={onDialogClose}
            >
                <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
                <CardHeader
                    avatar={<Avatar sx={{ bgcolor: 'primary.main' }}>{target?.type}</Avatar>}
                    title={<Typography variant="h6"><FormattedMessage id="ESG.TARGET.DETAILS" /></Typography>}
                    subheader={<Typography color="primary" className="ellipsis-header">{target?.name} </Typography>}
                    action={updateTargetPermission && <IconButton onClick={() => setOpenForm(true)}><EditOutlined /></IconButton>}
                />

                <Divider />
                <DialogContent>
                    <Divider><Typography variant='h6' color="secondary.main"><FormattedMessage id="DESCRIPTION" /></Typography></Divider>
                    <Stack spacing={1}>
                        <DisplayBox label={<FormattedMessage id="OWNER" />} value={target?.owner} />
                        <DisplayBox label={<FormattedMessage id="ESG.METRIC" />} value={getMetric()} />
                        <DisplayBox label={<FormattedMessage id="ESG.GOAL" />} value={getGoal()} />
                        <DisplayBox label={<FormattedMessage id="STATUS" />} value={
                            updateStatus
                                ? <StatusUpdateInput newStatus={newStatus} onStatusChange={setNewStatus} onCancel={() => setUpdateStatus(false)} onUpdate={() => onUpdateStatus(newStatus)} />
                                : <Stack direction="row" spacing={1}>
                                    <Typography variant="h6" color={targetStatus ? getStatusColor(targetStatus.key) : "text-disabled"}><FormattedMessage id={targetStatus ? ("STATUS." + targetStatus.value) : "NONE"} /></Typography>
                                    {updateTargetPermission && !target?.measurable && <Chip variant="outlined" size="small" label={<FormattedMessage id="UPDATE" />} icon={<UpdateOutlined fontSize="small" />} onClick={() => setUpdateStatus(true)} />}
                                </Stack>
                        } />
                        <DisplayBox label={<FormattedMessage id="ESG.METRIC.CATEGORY" />} value={<FormattedMessage id={"ESG.TYPE." + target?.type} />} />
                    </Stack>



                    <Divider><Typography variant='h6' color="secondary.main"><FormattedMessage id="REPORT" /></Typography></Divider>
                    <Stack spacing={1}>
                        {target?.measurable ? <>
                            <Stack direction="row" spacing={1} justifyContent="space-around" sx={{ my: 1 }}>
                                <DisplayBox label={<FormattedMessage id="ESG.TARGET.VALUE.BASE" />} value={<Chip sx={{ borderColor: 'transparent' }} variant="outlined" icon={<TrackChangesOutlined color="primary" />} label={target.measurable.baseValue} />} />
                                <DisplayBox label={<FormattedMessage id="ESG.TARGET.VALUE.CURRENT" />} value={<Chip sx={{ borderColor: 'transparent' }} variant="outlined" icon={<UpdateOutlined color={!target.measurable.currentValue ? "disabled" : (target.measurable.currentValue < target.measurable.endValue ? "warning" : "success")} />} label={parseFloat(target.measurable.currentValue).toFixed(4)} />} />
                                <DisplayBox label={<FormattedMessage id="ESG.TARGET.VALUE.GOAL" />} value={<Chip sx={{ borderColor: 'transparent' }} variant="outlined" icon={<EmojiEventsOutlined color="success" />} label={target.measurable.endValue} />} />
                            </Stack>
                            <Box sx={{ my: 2 }}>
                                <LinearProgressWithLabel value={target.measurable.currentValue} min={target.measurable.baseValue} max={target.measurable.endValue} label={<FormattedMessage id="PROGRESS" />} />
                            </Box>
                        </> : null}
                        <DisplayBox label={<FormattedMessage id="START_DATE" />} value={formatDateLocale(target?.dateRange?.startDate)} />
                        <DisplayBox label={<FormattedMessage id="END_DATE" />} value={formatDateLocale(target?.dateRange?.endDate)} />
                    </Stack>

                    <Divider><Typography variant='h6' color="secondary.main"><FormattedMessage id="PARTICIPANTS" /></Typography></Divider>
                    {target?.participants.length > 0 && <UserList
                        users={target?.participants.filter(participant => participant.type === "user").map(participant => allUsers.find(usr => usr.id === participant.id)?.username)}
                        groups={target?.participants.filter(participant => participant.type === "group").map(group => allGroups.find(grp => grp.id === group.id))}
                    />}

                </DialogContent>

                <Divider />

                <DialogActions>
                    <Button color="warning" onClick={onDialogClose}><FormattedMessage id="CLOSE" /></Button>
                </DialogActions>

            </Dialog >
        </>
    )
}
export default TargetDetailsView;