import instance, { setAuthHeader } from "./axiosEnv";
import { localISOString } from "utils";

const getAllMetricReports = (token, metricIds, dateFrom, dateTo, date) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    if (metricIds) {
        for (let el in metricIds) {
            queryParams.append('metricId', metricIds[el]);
        }
    }
    if (dateFrom) queryParams.append('dateFrom', dateFrom);
    if (dateTo) queryParams.append('dateTo', dateTo);
    if (date) queryParams.append('date', date);

    return instance.get('/ESG/metricReports?' + queryParams.toString(), config);
}

const getLatestMetricReports = (token, metricIds) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    for (let el in metricIds) {
        queryParams.append('metricId', metricIds[el]);
    }

    return instance.get('/ESG/metricReports/latest?' + queryParams.toString(), config);
}

const getSingleMetricReportById = (reportId, token) => {
    const config = setAuthHeader(token);
    return instance.get('/ESG/metricReports/' + reportId, config);
}

const getMetricReportTimeSeries = (token, metricIds, dateFrom, dateTo) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    for (let el in metricIds) {
        queryParams.append('metricId', metricIds[el]);
    }
    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));
    return instance.get('/ESG/metricReports/timeseries?' + queryParams.toString(), config);
}

const getMetricReportBarChartData = (token, metricIds, dateFrom, dateTo) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    for (let el in metricIds) {
        queryParams.append('metricId', metricIds[el]);
    }
    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));
    return instance.get('/ESG/metricReports/barChart?' + queryParams.toString(), config);
}

const putMetricReport = (token, reportId, newObject) => {
    const config = setAuthHeader(token);
    return instance.put('/ESG/metricReports/' + reportId, newObject, config);
}

const putQualitativeMetricReport = (token, data) => {
    const config = setAuthHeader(token);

    return instance.put('/ESG/metricReports/qualitative/', data, config);
}

const getMetricReportTimeseries = (token, metricId, dateFrom, dateTo) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    queryParams.append('metricId', metricId);
    queryParams.append('dateFrom', (dateFrom));
    queryParams.append('dateTo', (dateTo));

    return instance.get('/ESG/metricReports/timeseries?' + queryParams.toString(), config);
}


const metricReportAPI = {
    getAllMetricReports,
    getLatestMetricReports,
    getSingleMetricReportById,
    getMetricReportTimeSeries,
    getMetricReportBarChartData,
    putMetricReport,
    putQualitativeMetricReport,
    getMetricReportTimeseries
}

export default metricReportAPI;