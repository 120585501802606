import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { userSelector, permissionSelector } from "redux/userSlice";
import { Button, Tooltip, Alert, Card, CardContent, CardHeader, useMediaQuery, Typography, IconButton, Grid, Chip, Stack } from "@mui/material";
import { EditOutlined, DeleteOutline, AddOutlined, CancelOutlined } from "@mui/icons-material";
import { selectEntities } from 'redux/entitySlice';
import { removeMetricFromTopic } from "redux/topicSlice";
import { useParams } from "react-router";
import { ConfirmationDialog, MetricDetailsView, BreadcrumbsAndNavigation, MetricForm, AlertSnackbar, CustomDataGrid, LoadingData, ExpandedGridCell } from "components";
import { renderDateCell } from "components/dashboardView/TableHeaders";
import API from "api";


const MetricsView = (props) => {
    const { token } = useSelector(userSelector);
    const intl = useIntl();
    const entityId = useParams().entityId;
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const dispatch = useDispatch();

    const [metrics, setMetrics] = useState([]);
    const [selectedMetric, setSelectedMetric] = useState(null);

    const entities = useSelector(selectEntities);

    const [loading, setLoading] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [reset, setReset] = useState(0);

    const createMetricPermission = useSelector((state) => permissionSelector(state, 'create-metric'));
    const updateMetricPermission = useSelector((state) => permissionSelector(state, 'update-metric'));
    const deleteMetricPermission = useSelector((state) => permissionSelector(state, 'delete-metric'));

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        setSelectedMetric('')
    }, [entityId]);

    useEffect(() => {
        setLoading(true);
        API.metrics.getMetricAndComplexMetric(entityId, token).then(items => {
            if (items?.data) {
                const data = items.data.metrics.map(item => {
                    return {
                        ...item,
                        id: item._id,
                        reportInterval: item.reportData.reportInterval,
                        categoryType: item.category.type,
                    }
                })
                const complexData = items.data.complexMetrics.map(item => {
                    return {
                        ...item,
                        id: item._id,
                        reportInterval: item.reportData.reportInterval,
                        categoryType: "Complex",
                    }
                })
                setMetrics([...data, ...complexData]);
            }
            else setMetrics([])
            setLoading(false);

        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
            setLoading(false);
            setMetrics({ error });
            console.error(error);
        });
    }, [reloadData, token, entityId, reset]);

    const handleDeleteMetric = () => {
        API.metrics[selectedMetric?.categoryType === "Complex" ? "deleteComplexMetric" : "deleteMetric"](token, selectedMetric._id).then(response => {
            if (response?.data) {
                dispatch(removeMetricFromTopic(response.data));
                setAlert({ open: true, messageId: "ESG.METRIC.DELETE_SUCCESS", severity: "success" });
                setReloadData(true);
                setOpenConfirmDialog(false);
            }
            setReset(reset + 1);
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_DELETED", severity: "error" });
            setOpenConfirmDialog(false);
            console.error(error);
        });
        setSelectedMetric('');
    }

    const renderTable = useCallback(() => {
        const headers = [
            {
                field: 'name',
                headerName: intl.formatMessage({ id: 'NAME' }),
                ...(!smallScreen && { flex: 2 }),
                renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
                editable: true,
                minWidth: 300
            },
            !entityId && {
                field: 'entityId',
                headerName: intl.formatMessage({ id: 'ENTITY' }),
                valueGetter: (row) => entities[row.row.entityId]?.name,
                renderCell: ({ value }) => <Typography>{value}</Typography>,
                minWidth: 150
            },
            {
                field: 'type',
                headerName: intl.formatMessage({ id: 'ESG.METRIC.CATEGORY' }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: ({ value }) => <Typography><FormattedMessage id={"ESG.TYPE." + value} /></Typography>,
                minWidth: 150
            },
            {
                field: 'categoryType',
                headerName: intl.formatMessage({ id: 'ESG.METRIC.TYPE' }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography><FormattedMessage id={"ESG.METRIC.TYPE." + row.row.categoryType.toUpperCase()} /></Typography>,
                minWidth: 150
            },
            {
                field: 'createdAt',
                type: 'dateTime',
                headerName: intl.formatMessage({ id: 'CREATED_AT' }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: (row) => new Date(row.row.reportData.createdAt),
                ...renderDateCell(intl),
                minWidth: 180
            },
            {
                field: 'startDate',
                type: 'dateTime',
                headerName: intl.formatMessage({ id: 'START_DATE' }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: (row) => new Date(row.row.reportData.startDate),
                ...renderDateCell(intl),
                minWidth: 180
            },
            {
                field: 'reportInterval',
                headerName: intl.formatMessage({ id: 'REPORT_INTERVAL' }),
                renderCell: ({ value }) => <FormattedMessage id={"GROUPING." + value.toUpperCase()} />,
                minWidth: 150
            },
            entityId && {
                field: 'actions',
                headerName: intl.formatMessage({ id: 'ACTIONS' }),
                type: 'actions',
                getActions: (el) => (updateMetricPermission || deleteMetricPermission) ?
                    [
                        <Tooltip title={<FormattedMessage id="ESG.METRIC.EDIT" />} placement="bottom" arrow>
                            <IconButton component="span" disabled={!updateMetricPermission} color="secondary" key="edit" onClick={() => { setSelectedMetric(el.row); setOpenForm(true) }}> <EditOutlined fontSize="small" /> </IconButton>
                        </Tooltip>,
                        <Tooltip title={<FormattedMessage id="ESG.METRIC.DELETE" />} placement="bottom" arrow>
                            <IconButton component="span" disabled={!deleteMetricPermission} color="error" key="delete" onClick={() => { setSelectedMetric(el.row); setOpenConfirmDialog(true) }}><DeleteOutline fontSize="small" /></IconButton>
                        </Tooltip>
                    ]
                    :
                    [smallScreen ? <IconButton key="disabled-actions" disabled><CancelOutlined fontSize="small" /></IconButton> : <Button key="disabled-actions" disabled><FormattedMessage id="DISABLED" /></Button>]
                ,
                minWidth: 200
            },
        ].filter(el => el);

        if (loading) return <LoadingData />;
        else if (metrics?.error) return <Alert severity='error'><FormattedMessage id="ERROR.NO_DATA" /></Alert>;
        else return <CustomDataGrid handleData={(el) => { setOpenViewDialog(true); setSelectedMetric(el.row) }} rows={metrics} columns={headers} />

    }, [metrics, intl, loading, smallScreen, updateMetricPermission, deleteMetricPermission, entityId, entities]);

    const onFormDialogClose = (resetFlag) => {
        setOpenForm(false);
        setSelectedMetric('');
        if (resetFlag) setReset(reset + 1);
    }
    const onDialogClose = () => {
        setOpenConfirmDialog(false);
        setSelectedMetric('');
    }
    const onViewClose = () => {
        setOpenViewDialog(false);
        setSelectedMetric('');
    };

    return (
        <Grid container direction="column">
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId}/>
            <MetricForm open={openForm} onClose={onFormDialogClose} entityId={entityId} metric={selectedMetric} disableSwitch={Boolean(selectedMetric)} />

            <ConfirmationDialog
                open={openConfirmDialog}
                content={selectedMetric && <Stack spacing={1}>
                    <Typography variant="h6" color="error">{selectedMetric.name}</Typography>
                    {selectedMetric?.targets?.length > 0 && <Typography component="span">
                        <FormattedMessage id="ESG.TARGET.NUMBER_LABEL.METRIC_PREFIX" />
                        <Typography color="error" component="span">
                            {selectedMetric.targets.length}<FormattedMessage id="ESG.TARGET.NUMBER_LABEL.SUFFIX" />
                        </Typography>
                    </Typography>}
                </Stack>}
                title={<FormattedMessage id="ESG.METRIC.CONFIRM_DELETE" />}
                customButtonTitle={<FormattedMessage id="DELETE" />}
                customButtonColor="error"
                handleCancel={onDialogClose}
                handleCustomButton={handleDeleteMetric}
            />

            <MetricDetailsView metricId={selectedMetric?._id} complex={selectedMetric?.categoryType === "Complex"} openDialog={openViewDialog} onClose={onViewClose} setOpenViewDialog={setOpenViewDialog} setOpenForm={setOpenForm} disableEdit={!entityId} />
            <BreadcrumbsAndNavigation entity={entities[entityId]} baseNavigate="/metrics" rootUrl="/metrics" metricsMode />
            <Card sx={{ width: '100%' }}>
                <CardHeader title={<FormattedMessage id="ESG.METRIC.TABLE" />}
                    action={createMetricPermission && <Chip disabled={loading} variant="outlined" onClick={() => setOpenForm(true)} icon={<AddOutlined fontSize="small" color="primary" />} label={<FormattedMessage id="ESG.METRIC.ADD" />} />}
                />
                <CardContent>
                    {renderTable()}
                </CardContent>
            </Card>
        </Grid>
    )
};
export default MetricsView;