import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { userSelector, permissionSelector } from "redux/userSlice";
import { selectUsersAndGroups, selectUsersAndGroupsStatus, updateGroups, fetchUsersAndGroups } from 'redux/usersAndGroupsSlice';
import { AlertSnackbar, DialogFormTitle } from 'components';
import { Autocomplete, TextField, Checkbox, Stack, Button, Dialog, DialogActions, DialogContent, Divider, useMediaQuery } from '@mui/material';
import { CheckBoxOutlineBlank, CheckBoxOutlined, } from "@mui/icons-material";
import { store } from 'redux/store';
import API from "api";

const UserGroupForm = ({ open, onClose, userGroup, reloadData }) => {
    const [name, setName] = useState('');
    const [users, setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [disableEditButton, setDisabledEditButton] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [alert, setAlert] = useState({ open: false });

    const { token } = useSelector(userSelector);
    const usersAndGroups = useSelector(selectUsersAndGroups);
    const status = useSelector(selectUsersAndGroupsStatus);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const intl = useIntl();
    const dispatch = useDispatch();
    const viewUsersPermission = useSelector((state) => permissionSelector(state, 'view-users'));

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (!viewUsersPermission) {
            setAllUsers([]);
            return;
        }
        else if (status === 'failed') {
            store.dispatch(fetchUsersAndGroups(token));
        } else if (status === 'complete') {
            setAllUsers(usersAndGroups.users.map(user => user.username))
        } else {
            setAllUsers([]);
        }
    }, [token, status, usersAndGroups, viewUsersPermission]);

    useEffect(() => {
        if (userGroup) {
            setName(userGroup.name);
            setUsers(userGroup.users);
        }

        return () => {
            setDisabledEditButton(true);
            setEditMode(false);
            setName('');
            setUsers([]);
        }
    }, [userGroup]);

    useEffect(() => {
        editMode && name !== '' && users.length > 0 ?
            setDisabledEditButton(false)
            : setDisabledEditButton(true);
    }, [name, users, editMode]);

    const compareData = (oldData, newData) => {
        return (
            oldData.name === newData.name &&
            JSON.stringify([...oldData.users].sort()) === JSON.stringify([...newData.users].sort())
        )
    }

    const onSubmit = () => {
        setDisabledEditButton(true);
        const data = {
            name: name,
            users: users
        }

        if (userGroup && compareData(userGroup, data)) {
            setAlert({ open: true, messageId: "ERROR.NO_CHANGE_DETECTED", severity: "error" });
            setDisabledEditButton(false);
        } else if (userGroup) {
            API.userGroup.updateUserGroup(userGroup.id, data, token).then((response) => {
                setAlert({ open: true, messageId: "USER_GROUP.UPDATE_SUCCESS", severity: "success" });
                onClose();
                reloadData();
                setDisabledEditButton(false);
                if (response.data) dispatch(updateGroups(response.data));
            }).catch(e => {
                console.error(e);
                setAlert({ open: true, messageId: "ERROR.NOT_UPDATED", severity: "error" });
                setDisabledEditButton(false);
            })
        } else {
            API.userGroup.createUserGroup(data, token).then((response) => {
                setAlert({ open: true, messageId: "USER_GROUP.CREATE_SUCCESS", severity: "success" });
                onClose();
                reloadData();
                setDisabledEditButton(false);
                if (response.data) dispatch(updateGroups(response.data));
            }).catch(e => {
                console.error(e);
                setAlert({ open: true, messageId: "ERROR.NOT_CREATED", severity: "error" });
                setDisabledEditButton(false);
            })
        }
    }

    return (
        <>
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />

            <Dialog open={open} maxWidth="md" fullWidth fullScreen={smallScreen} >
                <DialogFormTitle
                    mainTitle={<FormattedMessage id={userGroup ? "USER_GROUP.EDIT" : "USER_GROUP.ADD"} />}
                    subTitle={userGroup ? userGroup.name : name}
                />
                <Divider />
                <DialogContent>
                    <Stack direction="column" spacing={2}>
                        <TextField
                            label={<span><FormattedMessage id="NAME" /></span>}
                            required
                            fullWidth
                            variant="outlined"
                            id="NAME"
                            type="text"
                            size="small"
                            value={name}
                            onChange={(e) => { setName(e.target.value); setEditMode(true) }}
                        />
                        <Autocomplete
                            multiple
                            id="combo-box-users"
                            disableCloseOnSelect
                            limitTags={4}
                            options={allUsers}
                            noOptionsText={status === 'disabled' ? intl.formatMessage({ id: 'ERROR.FETCHING_USERS_DATA' }) : undefined}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) => option && value && option === value}
                            value={users}
                            renderInput={(params) => <TextField {...params} required={status === 'complete'} error={status === 'disabled'} label={intl.formatMessage({ id: "USERS" })} placeholder={status === 'complete' && !users.length ? intl.formatMessage({ id: "NONE_SELECTED" }) : ""} />}
                            onChange={(event, newValue) => { setUsers([...newValue]); setEditMode(true) }}
                            size="small"
                            fullWidth
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                                        checkedIcon={<CheckBoxOutlined fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option}
                                </li>
                            )}
                        />
                    </Stack>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={onClose} color="warning"><FormattedMessage id="CANCEL" /></Button>
                    <Button disabled={disableEditButton} onClick={onSubmit}><FormattedMessage id={userGroup ? "EDIT" : "ADD"} /></Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default UserGroupForm;