import { AddOutlined, CloseOutlined, ExpandLess, ExpandMore } from "@mui/icons-material"
import { Button, Card, CardContent, CardHeader, Collapse, Dialog, DialogActions, DialogContent, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, Typography, useMediaQuery } from "@mui/material"
import { DisplayBox, ActivityAssignForm } from "components"
import { useState } from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { permissionSelector } from 'redux/userSlice'


const HTMLParagraph = ({ label, html, labelColor, labelVariant, inset, noPadding, noBorder, alwaysOpen, last }) => {
    const [open, setOpen] = useState(Boolean(alwaysOpen));

    return <>
        {Boolean(label) && <>
            <ListItemButton onClick={() => setOpen(!open)} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant={labelVariant ? labelVariant : "body2"} color={labelColor ? labelColor : "secondary"} pl={inset ? 2 : undefined}><span style={{ display: 'list-item' }}>{label}</span></Typography>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
        </>}

        <Collapse in={open}>
            {noPadding ? <Typography variant="caption" dangerouslySetInnerHTML={{ __html: html }} /> : <Card sx={theme => ({ borderColor: theme.palette.primary.light })} variant="outlined" style={noBorder ? { border: 0 } : undefined}>
                <CardContent sx={{ py: 0 }}>
                    <Typography variant="caption" dangerouslySetInnerHTML={{ __html: html }} />
                    {noBorder && !last && <Divider sx={{ pt: 1 }} />}
                </CardContent>
            </Card>}
        </Collapse >
    </>
}

const ActivityObjective = ({ name, objective, index }) => {
    const [open, setOpen] = useState(false);
    const [openDnshCriteria, setOpenDnshCriteria] = useState(false);
    return [
        <ListItem key="listItem" disableGutters action={<IconButton>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>} secondaryAction={<IconButton onClick={() => setOpen(!open)}>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>}>
            <Typography mr={1} color="text.secondary" component="span" variant="body2">{index + 1}.</Typography>
            <ListItemText primaryTypographyProps={{ variant: 'body1', fontWeight: 'bold' }} primary={name} secondary={objective?.contributionType ? <FormattedMessage id={"CONTRIBUTION_TYPE." + objective?.contributionType} /> : undefined} />
        </ListItem>,
        <Collapse key="more-info" in={open} timeout="auto" unmountOnExit>
            {!!objective?.contributionDescription?.length && <Stack gap={1} sx={{ pb: 1 }}>
                <DisplayBox labelColor="secondary" label={<FormattedMessage id="DESCRIPTION" />} value={<Typography component="span" variant="body2">{objective.contributionDescription}</Typography>} />
            </Stack>}
            <Stack>
                <HTMLParagraph main labelVariant="button" label={<FormattedMessage id="CONTRIBUTION_CRITERIA" />} html={objective?.contributionCriteria?.replaceAll('<a ', '<a target="_blank" ')} />
                <ListItemButton onClick={() => setOpenDnshCriteria(!openDnshCriteria)} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="button" color="error"><FormattedMessage id="DNSH_CRITERIA" /></Typography>
                    {openDnshCriteria ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openDnshCriteria}>
                    <Card variant="outlined" sx={theme => ({ borderColor: theme.palette.error.dark })}>
                        <List sx={{ listStyle: 'decimal', py: 0 }}>
                            {objective?.dnshCriteria?.map((el, index) => <HTMLParagraph noBorder last={objective?.dnshCriteria?.length === index + 1} key={el.objectiveKey} labelColor="error" labelVariant="body1" inset label={<FormattedMessage id={el.objectiveKey} />} html={el.criteria?.replaceAll('<a ', '<a target="_blank" ')} />)}
                        </List>
                    </Card>
                </Collapse>
            </Stack >
        </Collapse >,
        <Divider key="divider" />,
    ]
}

export default function ActivityDetails({ open, onClose, activity, noAction, AlertCall }) {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const [openAssign, setOpenAssign] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState(null);


    const assignActivityPermission = useSelector((state) => permissionSelector(state, 'create-activity'));

    if (!activity) return;

    return <>
        <ActivityAssignForm AlertCall={AlertCall} open={openAssign} onClose={() => setOpenAssign(false)} entity={selectedEntity} setEntity={setSelectedEntity} activityId={activity._id} />
        <Dialog open={open} onClose={onClose} fullScreen={smallScreen} maxWidth="md" fullWidth>
            <CardHeader
                title={activity.nameKey}
                subheader={activity.sectorValue}
                action={<IconButton sx={{ ml: 1 }} key="close" onClick={onClose}><CloseOutlined /></IconButton>}
            />
            <Divider />

            <DialogContent>
                {activity.objectives?.length > 0 ? <Stack direction="column" gap={1}>
                    <DisplayBox label={<FormattedMessage id="CODE_NACE" />} value={activity.codeNACE} />
                    <DisplayBox label={<FormattedMessage id="REFERENCE" />} value={activity.reference} />
                    <DisplayBox label={<FormattedMessage id="DESCRIPTION" />} value={<HTMLParagraph noPadding html={activity.objectives.at(0)?.activityDescription} noBorder last alwaysOpen />} />
                    <DisplayBox label={<FormattedMessage id="OBJECTIVES" />} value={<List>
                        {activity.objectiveArray.split(", ").map((el, index) => <ActivityObjective index={index} key={el} objective={activity.objectives?.at(index)} name={el} />)}
                    </List>} />
                </Stack> : <Typography sx={{ pt: 2 }} color="text.disabled"><FormattedMessage id="NO_DATA" /></Typography>}
            </DialogContent>
            <Divider />
            {!noAction && assignActivityPermission && <DialogActions>
                <Button size="small" onClick={() => setOpenAssign(true)} startIcon={<AddOutlined fontSize="small" color="primary" />}><FormattedMessage id="ASSIGN.TO_ENTITY" /></Button>
            </DialogActions>}
        </Dialog>
    </>
}