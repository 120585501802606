import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DisplayBox, DialogFormTitle, UserList } from 'components';
import { Button, Dialog, DialogActions, DialogContent, Divider, Stack, Typography, useMediaQuery } from '@mui/material';

const UserGroupDetailsView = ({ userGroup, open, onClose }) => {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth fullScreen={smallScreen}>
            <DialogFormTitle disableHelpText mainTitle={<FormattedMessage id="USER_GROUP.DETAILS" />} subTitle={userGroup && <Typography color="primary" display="block">{userGroup?.name}</Typography>} />
            <Divider />

            {userGroup &&
                <DialogContent>
                    <Stack spacing={1}>
                        <DisplayBox label={<FormattedMessage id="NAME" />} value={userGroup?.name} />
                        <UserList
                            label={<FormattedMessage id="USERS" />}
                            users={userGroup?.users}
                        />
                    </Stack>
                </DialogContent>
            }
            <Divider />
            <DialogActions>
                <Button color="warning" onClick={onClose}><FormattedMessage id="CLOSE" /></Button>
            </DialogActions>
        </Dialog>
    )
}

export default UserGroupDetailsView;