import { DefaultLayout } from 'layouts';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { SplashPage } from "pages";
import { GoalsView } from 'components';
import { EmptyState } from 'layouts';
import { useParams } from 'react-router';


const GoalsPage = () => {
    const entityId = useParams().entityId
    return <EmptyState component={<GoalsView entityId={entityId} />} />;
}

const GoalsPageComponent = () => {
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn) return <DefaultLayout page={<GoalsPage />} />;
    else return <SplashPage />;
};

export default GoalsPageComponent;