import { CheckBoxOutlined, CheckBoxOutlineBlank, VisibilityOutlined, EditOutlined } from "@mui/icons-material";
import { Autocomplete, Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, Divider, FormControl, FormLabel, IconButton, TextField, Typography, useMediaQuery } from "@mui/material";
import { LoadingData, AlertSnackbar, DialogFormTitle, GoToButton } from "components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { crudeCompareStringArrays } from "utils/helpers";

import API from 'api';


export const MiniDataDisplay = ({ array, titleId }) => {
    return <>
        <Divider variant="middle" >
            <Typography color="primary" display="block" component="span"><FormattedMessage id={titleId} />: <Typography variant="h6" color="primary" display="inline">{array?.length}</Typography></Typography>
        </Divider>
        <Box sx={{ mt: 1, maxHeight: 200, overflowY: 'auto' }}>
            {array.length ? array.map(el => <Typography variant="caption" display="block" key={el}>{el}</Typography>) : <Typography color="text.disabled"><FormattedMessage id="NONE" /></Typography>}
        </Box>
    </>
}


export default function AssignRoleForm({ open, user, onClose, viewMode, onChangeView }) {
    const intl = useIntl();
    const navigate = useNavigate();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const { token } = useSelector(userSelector);
    const [allRoles, setAllRoles] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    const [noRoleMap, setNoRoleMap] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [selectedView, setSelectedView] = useState(null);
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        setSelectedView(null);
        if (open) {
            setLoading(true);
            setSubmitting(false);
            setNoRoleMap(false);
            API.role.getAllRoles(token).then(({ data }) => {
                setAllRoles(data);
                API.role.getUserRoles(token, user.id).then(({ data }) => {
                    setUserRoles(data.roleIds);
                    setLoading(false);
                }).catch((error) => {
                    if (error?.response?.status === 404) {
                        setUserRoles([]);
                        setNoRoleMap(true);
                        setLoading(false);
                    }
                    else {
                        console.error(error);
                        setAlert({ open: true, messageId: "ERROR.FETCHING_USER_ROLES", severity: "error" });
                        onClose();
                    }
                });
            }).catch((error) => {
                console.error(error);
                setAlert({ open: true, messageId: "ERROR.FETCHING_ALL_ROLES", severity: "error" });
                onClose();
            });
        }
        else {
            setUserRoles([]);
            setRoleOptions([]);
            setLoading(false);
        }
    }, [open, user, onClose, token]);

    useEffect(() => {
        if (userRoles.length) {
            setRoleOptions(userRoles.map(role => allRoles.find(el => el._id === role)).filter(el => el !== null));
        }
    }, [userRoles, allRoles]);


    const onSubmit = () => {
        setSubmitting(true);
        const roles = {
            roles: roleOptions.map(el => el._id)
        };

        if (crudeCompareStringArrays(roleOptions.map(el => el._id), userRoles)) {
            setAlert({ open: true, messageId: "ERROR.NO_CHANGE_DETECTED", severity: "error" });
            setSubmitting(false);
        }
        else if (noRoleMap) {
            API.role.createUserRoles(token, { keycloakId: user.id, username: user.username, ...roles }).then(() => {
                setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
                setSubmitting(false);
                onClose();
            }).catch((error) => {
                console.error(error);
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_UPDATED", severity: "error" });
                setSubmitting(false);
            });
        }
        else {
            API.role.putUserRoles(token, user.id, roles).then(() => {
                setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
                setSubmitting(false);
                onClose();
            }).catch((error) => {
                console.error(error);
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_UPDATED", severity: "error" });
                setSubmitting(false);
            });
        }
    }

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <Dialog open={open} maxWidth="sm" fullWidth fullScreen={smallScreen} onClose={onClose}>
            <DialogFormTitle disableHelpText mainTitle={<span>
                <FormattedMessage id={viewMode ? "USERS_ROLES" : "ASSIGN.ROLE"} /> {user && <Typography color="primary">{user.userName}</Typography>}
            </span>}
                action={viewMode && <IconButton disabled={loading || user?.userName === process.env.REACT_APP_ADMIN_ROLE} onClick={onChangeView}><EditOutlined /></IconButton>}
            />

            <Divider />

            <DialogContent>
                {loading ? <LoadingData />
                    : viewMode
                        ? <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '14px' }}>
                            <FormControl>
                                <FormLabel><FormattedMessage id="ROLES" /></FormLabel>
                                <div>
                                    {roleOptions.length
                                        ? roleOptions.map(el => {
                                            const selectedElement = selectedView && selectedView._id === el._id;
                                            return <Chip icon={selectedElement ? <VisibilityOutlined fontSize="small" /> : null} color={selectedElement ? "primary" : "default"} onClick={roleOptions.length > 1 ? () => setSelectedView(selectedElement ? null : el) : undefined} sx={{ mt: 1, mr: 1 }} key={el._id} label={el.name} />
                                        })
                                        : <Typography color="text.disabled"><FormattedMessage id="NO_DATA" /></Typography>
                                    }
                                </div>
                                {roleOptions.length > 1 && <Typography variant="caption" color="text.disabled"><FormattedMessage id="ROLE.VIEWER_HELPER_TEXT" /></Typography>}
                            </FormControl>
                        </div>
                        : <>
                            <Autocomplete
                                multiple
                                id={"checkboxes-roles-" + user.userName}
                                options={allRoles}
                                disableCloseOnSelect
                                fullWidth
                                value={roleOptions}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                onChange={(event, newValue) => {
                                    setRoleOptions(newValue);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.name}
                                            {...getTagProps({ index })}
                                            disabled={option.name === process.env.REACT_APP_ADMIN_ROLE && user.userName === process.env.REACT_APP_ADMIN_ROLE}
                                        />
                                    ))
                                }
                                renderOption={(props, option, { selected }) => {
                                    const disabled = option.name === process.env.REACT_APP_ADMIN_ROLE && user.userName === process.env.REACT_APP_ADMIN_ROLE
                                    return <li {...props} {...(disabled ? { onClick: null } : {})}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                                            checkedIcon={<CheckBoxOutlined fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            disabled={disabled}
                                            checked={selected}
                                        />
                                        <Typography color={disabled ? "text.disabled" : "inherit"}>
                                            {option.name}
                                        </Typography>
                                    </li>
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder={!roleOptions.length ? intl.formatMessage({ id: 'NONE_SELECTED' }) : ""} />
                                )}
                            />
                            <GoToButton sx={{ mt: 1 }} disableConfirmation={!roleOptions.length} onClick={() => navigate('/administration/roles')} label={<FormattedMessage id="ROLE.MANAGMENT" />} />
                        </>}
                {
                    roleOptions.length > 0 && <>

                        <MiniDataDisplay titleId="EFFECTIVE_PERMISSIONS" array={Array.from(new Set((selectedView ? [selectedView] : roleOptions).flatMap(({ permissions }) => permissions.map(permission => intl.formatMessage({ id: permission })))))} />

                        <MiniDataDisplay titleId="AFFECTED_ENTITIES" array={Array.from(new Set((selectedView ? [selectedView] : roleOptions).flatMap(({ entityIds }) => entityIds.map(entity => entity.name))))} />
                    </>
                }
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button color={viewMode ? "primary" : "warning"} onClick={onClose}><FormattedMessage id={viewMode ? "CLOSE" : "CANCEL"} /></Button>
                {!viewMode && <Button disabled={loading || submitting} onClick={onSubmit}><FormattedMessage id="SUBMIT" /></Button>}
            </DialogActions>
        </Dialog >
    </>

}