import React, { useState, useEffect, useCallback } from 'react';
import { FormattedMessage, } from 'react-intl';
import { FormLabel, TextField, Checkbox, MenuItem, Stack, FormControl, Autocomplete } from '@mui/material';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { selectEntities } from 'redux/entitySlice';
import { CheckBoxOutlineBlank, CheckBoxOutlined, } from '@mui/icons-material';
import { AlertSnackbar, GroupHeader, GroupItems, LoadingData } from 'components';
import { selectUnits } from 'redux/configurationSlice';
import { findUnitFamily } from 'utils';
import API from 'api';

const ComplexMeasurementsSection = ({ setEditMode, selectedSimpleMetrics, setSelectedSimpleMetrics, calculationMethod, setCalculationMethod, existingMetric, setReportUnit }) => {
    const calculationMethods = ['sum', 'avg', 'min', 'max', 'last', 'count'];
    const { token } = useSelector(userSelector);
    const entities = useSelector(selectEntities);
    const [simpleMetrics, setSimpleMetrics] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const units = useSelector(selectUnits);
    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        setLoading(true);
        API.metrics.getAllMetrics(token, process.env.REACT_APP_ESG_METRIC_DEFAULT_TYPE).then(items => {
            if (items.data) {
                setSimpleMetrics(items.data.sort((opt1, opt2) => opt1.entityId.localeCompare(opt2.entityId)))
            }
            setLoading(false);
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
            console.error(error);
            setLoading(false);
        })
    }, [token]);

    const getOptions = useCallback(() => {
        if (selectedSimpleMetrics?.length >= 1 && calculationMethod !== 'count') {
            return simpleMetrics.filter(el => ((el.category.scope.unit && selectedSimpleMetrics[0].category.scope.unit) && findUnitFamily(el.category.scope.unit, units) === findUnitFamily(selectedSimpleMetrics[0].category.scope.unit, units)) ||
                ((el.category.scope.unit && selectedSimpleMetrics[0].reportData?.reportUnit?.unit) && findUnitFamily(el.category.scope.unit, units) === findUnitFamily(selectedSimpleMetrics[0].reportData?.reportUnit?.unit, units)) ||
                ((el.reportData?.reportUnit?.unit && selectedSimpleMetrics[0].category.scope.unit) && findUnitFamily(el.reportData?.reportUnit?.unit, units) === findUnitFamily(selectedSimpleMetrics[0].category.scope.unit, units)) ||
                ((el.reportData?.reportUnit?.unit && selectedSimpleMetrics[0].reportData?.reportUnit?.unit) && findUnitFamily(el.reportData?.reportUnit?.unit, units) === findUnitFamily(selectedSimpleMetrics[0].reportData?.reportUnit?.unit, units)) ||
                !(el.category.scope.unit || el.reportData?.reportUnit?.unit || selectedSimpleMetrics[0].category.scope.unit || selectedSimpleMetrics[0].reportData?.reportUnit?.unit));
        } else return simpleMetrics;
    }, [selectedSimpleMetrics, simpleMetrics, calculationMethod, units]);

    return <Stack direction="column" spacing={2} sx={{ mb: 2 }}>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <FormControl >
            <FormLabel required ><FormattedMessage id="CALCULATION_METHOD" /></FormLabel>
            <TextField
                select
                disabled={Boolean(existingMetric)}
                size="small"
                value={calculationMethod}
                onChange={(e) => {
                    if (calculationMethod === "count") {
                        setSelectedSimpleMetrics([]);
                    }
                    setCalculationMethod(e.target.value);
                    setEditMode(true)
                }}
            >
                {calculationMethods.map((method, index) => <MenuItem key={index} value={method}><FormattedMessage id={"CALCULATION_METHOD." + method} /></MenuItem>)}
            </TextField>
        </FormControl>
        <FormControl>
            <FormLabel required><FormattedMessage id="ESG.METRIC" /></FormLabel>
            <Autocomplete
                id="select-metric"
                disableCloseOnSelect
                multiple
                loading={loading}
                loadingText={<LoadingData />}
                options={getOptions()}
                getOptionLabel={(metric) => `${metric.name} (${entities[metric.entityId]?.name})`}
                groupBy={(option) => option.entityId}
                value={selectedSimpleMetrics}
                onChange={(e, metric) => {
                    setSelectedSimpleMetrics(metric);
                    setReportUnit({
                        prefix: "",
                        unit: "",
                        suffix: ""
                    });
                    setEditMode(true)
                }}
                renderInput={(params) => (
                    <TextField {...params} />
                )}
                renderGroup={(params) => (
                    <li key={params.group}>
                        <GroupHeader>{entities[params.group].name}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                    </li>
                )}
                renderOption={(props, option, { selected }) => {
                    return <li {...props} key={option._id}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckBoxOutlined fontSize="small" />}
                            checked={selected}
                            style={{ marginRight: 8 }}
                        />
                        {option.name}
                    </li>
                }}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                size="small"
                fullWidth
            />
        </FormControl>
    </Stack>


}

export default ComplexMeasurementsSection;