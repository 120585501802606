import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material';
import { DoughnutChart } from 'charts';
import { FormattedMessage, useIntl } from 'react-intl';
import API from 'api';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { LoadingData } from 'components';

const TargetPieChart = ({ source }) => {
    const intl = useIntl();
    const [target, setTarget] = useState(null);
    const { token } = useSelector(userSelector);

    useEffect(() => {
        setTarget({ loading: true });
        API.target.getTarget(source.id, token).then(response => {
            if (response.data) setTarget(response.data)
        }).catch((error) => {
            setTarget(null);
            console.error(error);
        });
    }, [token, source])

    return !target ? <Typography color="text.disabled" sx={{ m: 'auto' }} variant='h6'><FormattedMessage id="ERROR.NO_REFERENCE" /></Typography> :
        target.loading ? <LoadingData /> : <Box sx={{ minHeight: 300 }}>
            <DoughnutChart fixed entityName={source?.entity} targetName={target?.name} name={"typeDoughnutChart_" + source?.entity} data={{
                labels: [intl.formatMessage({ id: "ESG.TARGET.VALUE.CURRENT" }), intl.formatMessage({ id: "DASHBOARD.PIE_CHART.REMAINING" })],
                datasets: [
                    {
                        data: [target?.measurable?.currentValue, Math.abs(target?.measurable?.endValue - target?.measurable?.currentValue)],
                        keys: [1, 2]
                    }
                ]
            }} /></Box >
}

export default TargetPieChart;