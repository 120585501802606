import { forwardRef, useEffect, useState } from "react";
import { Box, Card, Container, useMediaQuery } from "@mui/material";
import { EntityCard, BreadcrumbsAndNavigation, EntityForm, AlertSnackbar, InvalidIdSplash, SearchBar } from "components";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { selectEntities } from "redux/entitySlice";
import { VirtuosoGrid } from "react-virtuoso";

const getComponents = ((width) => ({
    List: forwardRef(({ style, children, ...props }, ref) => (
        <div
            ref={ref}
            {...props}
            style={{
                display: "flex",
                flexWrap: "wrap",
                ...style,
            }}
        >
            {children}
        </div>
    )),
    Item: ({ children, ...props }) => (
        <div
            {...props}
            style={{
                paddingTop: "0.4rem",
                paddingRight: "0.4rem",
                width: width,
                display: "flex",
                flex: "none",
                alignContent: "stretch",
                boxSizing: "border-box",
            }}
        >
            {children}
        </div>
    ),
}));

export default function EntitiesView(props) {
    const queryId = useParams().entityId;
    const navigateState = useLocation().state;
    const allEntities = useSelector(selectEntities);
    const entityId = allEntities[queryId] ? queryId : null;
    const [openForm, setOpenForm] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const [search, setSearch] = useState("");

    const mq_xs = useMediaQuery(theme => theme.breakpoints.only('xs'));
    const mq_sm = useMediaQuery(theme => theme.breakpoints.only('sm'));
    const mq_md = useMediaQuery(theme => theme.breakpoints.only('md'));
    const mq_lg = useMediaQuery(theme => theme.breakpoints.only('lg'));
    const mq_xl = useMediaQuery(theme => theme.breakpoints.only('xl'));

    const getSize = () => {
        if (mq_xs) return "100%";
        else if (mq_sm) return "100%";
        else if (mq_md) return "50%";
        else if (mq_lg) return "33%";
        else if (mq_xl) return "20%";
        else return "100%";
    }


    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (navigateState) setAlert(navigateState);
    }, [navigateState]);

    let array = [];
    if (queryId && !entityId) return <InvalidIdSplash queryId={queryId} />;
    else if (entityId && allEntities[entityId]) {
        const parentEntityChildren = allEntities[entityId].connections.childrenArray;
        for (const child of parentEntityChildren) {
            array.push(allEntities[child]);
        }
    }
    else array = Object.values(allEntities).filter(entity => entity.connections.parentId === null || !allEntities[entity.connections.parentId]);


    if (search !== "") array = array.filter(el => el.name.includes(search));

    return <Container maxWidth={false} disableGutters sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <div style={{ display: 'block' }}>
            <BreadcrumbsAndNavigation entity={allEntities[entityId]} entityMode />
            <Card sx={{ mb: 1 }}>
                <SearchBar search={search} setSearch={setSearch} entityId={entityId} addEntity={{ setOpenForm: setOpenForm }} />
            </Card>
        </div>
        <EntityForm openForm={openForm} setOpenForm={setOpenForm} parentId={entityId} />
        <Box height={'100%'} minHeight={400}>
            <VirtuosoGrid
                style={{ flexGrow: 1 }}
                totalCount={array.length}
                data={array}
                components={getComponents(getSize())}
                itemContent={(_, entity) => <EntityCard entityId={entity._id} />}
            />
        </Box>
    </Container>;
}