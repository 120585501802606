import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectMetricTypesConf, selectStatusTypes } from 'redux/configurationSlice';
import { Autocomplete, FormControl, Checkbox, FormLabel, MenuItem, Stack, TextField, Collapse, FormHelperText, Typography } from '@mui/material';
import { AlertSnackbar, DateRangeSelector, GroupHeader, GroupItems, LoadingData, MeasurableObjectSection, AssignOwnerField, GoToButton } from 'components';
import { selectEntities } from 'redux/entitySlice';
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';
import { userSelector } from 'redux/userSlice';
import { selectTopics } from 'redux/topicSlice';
import { useNavigate } from 'react-router';
import API from 'api';

const TargetFormContent = ({ setEditMode, metric, setMetric, metrics, setMetrics, goal, setGoal, goals, setGoals, name, setName, targetType, setTargetType, targetStatus, setTargetStatus, owner, setOwner, participants, setParticipants, baseValue, setBaseValue, endValue, setEndValue, currentValue, setCurrentValue, existingTarget, defaultMetric, defaultGoal, startDate, setStartDate, endDate, setEndDate }) => {
    const navigate = useNavigate();
    const intl = useIntl();
    const metricTypes = useSelector(selectMetricTypesConf);
    const statusTypes = useSelector(selectStatusTypes);
    const entities = useSelector(selectEntities);
    const topics = useSelector(selectTopics);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const { token } = useSelector(userSelector);

    const allGoalsList = (goals) => {
        const flattened = [];

        function getAllChildren(goal) {
            flattened.push(goal);
            if (goal.children && goal.children.length > 0) {
                goal.children.forEach(child => {
                    getAllChildren(child);
                });
            }
        }

        goals.forEach(goal => {
            getAllChildren(goal);
        });

        return flattened;
    }
    useEffect(() => {
        setLoading(true);
        API.metrics.getAllMetrics(token).then(items => {
            if (items.data) {
                setMetrics(items.data.sort((opt1, opt2) => opt1.entityId.localeCompare(opt2.entityId)))
            }
            setLoading(false);
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_METRICS", severity: "error" });
            console.error(error);
            setLoading(false);
        })
    }, [token, setMetrics]);

    useEffect(() => {
        API.goal.getAllGoals(token).then(items => {
            if (items.data) {
                setGoals(allGoalsList(items.data).sort((opt1, opt2) => opt1.topicId.localeCompare(opt2.topicId)));
            }
            setLoading(false);
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_GOALS", severity: "error" });
            console.error(error);
            setLoading(false);
        })
    }, [token, setGoals])

    const onAlertClose = () => setAlert({ ...alert, open: false });

    return <Stack direction="column" spacing={2}>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <FormControl>
            <FormLabel required><FormattedMessage id="NAME" /></FormLabel>
            <TextField
                required
                fullWidth
                variant="outlined"
                id="NAME"
                type="text"
                size="small"
                value={name}
                onChange={(e) => { setName(e.target.value); setEditMode(true) }}
                inputProps={{ maxLength: process.env.REACT_APP_ESG_INPUT_RESTRICTION_SMALL }}
            />
        </FormControl>
        <FormControl>
            <FormLabel required={!defaultGoal}><FormattedMessage id="ESG.GOAL" /></FormLabel>
            {!defaultGoal ? <Autocomplete
                id="select-goal"
                loading={loading}
                loadingText={<LoadingData />}
                options={goals}
                getOptionLabel={(goal) => {
                    if (defaultGoal) return defaultGoal.name || intl.formatMessage({ id: "UNTITLED" });
                    const topic = topics.find(topic => topic._id === goal.topicId)
                    const entity = entities[topic?.entityId]
                    return `${goal.name} (${topic?.name} - ${entity?.name})`
                }}
                groupBy={(option) => option.topicId}
                disabled={Boolean(defaultGoal)}
                value={defaultGoal || goal}
                onChange={(e, goal) => { setGoal(goal); setEditMode(true) }}
                renderInput={(params) => (
                    <TextField {...params} />
                )}
                renderGroup={(params) => {
                    const topic = topics.find(topic => topic._id === params.group)
                    const entity = entities[topic?.entityId]
                    return <li key={params.group}>
                        <GroupHeader>{`${topic?.name} (${entity?.name})`}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                    </li>
                }}
                renderOption={(props, option, { selected }) => {
                    return <li {...props} key={option ? option._id : props?.key}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckBoxOutlined fontSize="small" />}
                            checked={selected}
                            style={{ marginRight: 8 }}
                        />
                        {option.name}
                    </li>
                }}
                isOptionEqualToValue={(option, value) => option?._id === value?._id}
                size="small"
                fullWidth
            /> : <Typography color="primary" variant="h6">{defaultGoal.name}</Typography>}
            {!defaultGoal && <FormHelperText><GoToButton onClick={() => navigate('/goals')} label={<FormattedMessage id="ESG.GOAL.MANAGMENT" />} /></FormHelperText>}
        </FormControl>
        <FormControl>
            <FormLabel required={!defaultMetric}><FormattedMessage id="ESG.METRIC" /></FormLabel>
            {!defaultMetric ? <Autocomplete
                id="select-metric"
                loading={loading}
                loadingText={<LoadingData />}
                options={metrics}
                getOptionLabel={(metric) => {
                    if (defaultMetric) return defaultMetric.name || intl.formatMessage({ id: "UNTITLED" });
                    return `${metric.name} (${entities[metric.entityId]?.name})`
                }}
                groupBy={(option) => option.entityId}
                disabled={Boolean(defaultMetric)}
                value={defaultMetric || metric}
                onChange={(e, metric) => { setBaseValue(""); setEndValue(""); setCurrentValue(0); setMetric(metric); setEditMode(true) }}
                renderInput={(params) => (
                    <TextField {...params} />
                )}
                renderGroup={(params) => (
                    <li key={params.group}>
                        <GroupHeader>{entities[params.group].name}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                    </li>
                )}
                renderOption={(props, option, { selected }) => {
                    return <li {...props} key={option ? option._id : props.key}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckBoxOutlined fontSize="small" />}
                            checked={selected}
                            style={{ marginRight: 8 }}
                        />
                        {option.name}
                    </li>
                }}
                isOptionEqualToValue={(option, value) => option?._id === value?._id}
                size="small"
                fullWidth
            /> : <Typography color="primary" variant="h6">{defaultMetric.name}</Typography>}
            {!defaultMetric && <FormHelperText><GoToButton onClick={() => navigate('/metrics')} label={<FormattedMessage id="ESG.METRIC.MANAGMENT" />} /></FormHelperText>}
            <Collapse in={metric?.category?.type === "Quantitative"} sx={{ mt: 1 }}>
                <MeasurableObjectSection setEditMode={setEditMode} baseValue={baseValue} setBaseValue={setBaseValue}
                    endValue={endValue} setEndValue={setEndValue} currentValue={currentValue} setCurrentValue={setCurrentValue} />
            </Collapse>
        </FormControl>
        <FormControl>
            <FormLabel required><FormattedMessage id="TYPE" /></FormLabel>
            <TextField
                select
                size="small"
                value={targetType}
                onChange={(e) => { setTargetType(e.target.value); setEditMode(true) }}
            >
                {metricTypes.map(type => {
                    return <MenuItem key={type.key} value={type.key}><FormattedMessage id={"ESG.TYPE." + type.key} /></MenuItem>
                })}
            </TextField>
        </FormControl>

        <FormControl>
            <FormLabel><FormattedMessage id="STATUS" /></FormLabel>
            <TextField
                disabled={Boolean(existingTarget && existingTarget.measurable)}
                select
                size="small"
                value={targetStatus}
                onChange={(e) => { setTargetStatus(e.target.value); setEditMode(true) }}
            >
                {statusTypes.map(type => {
                    return <MenuItem key={type.key} value={type.key}><FormattedMessage id={"STATUS." + type.value} /></MenuItem>
                })}
            </TextField>
        </FormControl>

        <DateRangeSelector setEditMode={setEditMode} startDateLabel={<FormattedMessage id="DATE_FROM" />} noButtons dateFrom={startDate} dateTo={endDate} customOnChangeHandler={{ startDate: setStartDate, endDate: setEndDate }} />

        <AssignOwnerField owner={owner} onChange={(value) => { setOwner(value); setEditMode(true); }} />

        <AssignOwnerField multiple participants={participants} onChange={(value) => { setParticipants(value); setEditMode(true); }} />
    </Stack >
}

export default TargetFormContent;