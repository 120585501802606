import { DefaultLayout } from 'layouts';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { SplashPage } from "pages";
import { AlarmsView, AlarmDetailsView, AlarmProfiles, AlarmProfileDetails } from 'components';
import { FormattedMessage } from "react-intl";
import { EmptyState } from 'layouts';


const AlarmsPage = ({ profiles }) => {
    const paramId = useParams().id;
    const viewAlarmProfilesPermission = useSelector((state) => permissionSelector(state, 'view-alarmProfiles'));

    if (profiles && paramId && viewAlarmProfilesPermission) return <EmptyState component={<AlarmProfileDetails profileId={paramId} />} />;
    else if (profiles && viewAlarmProfilesPermission) return <EmptyState component={<AlarmProfiles />} />;

    if (!paramId) return <EmptyState component={<AlarmsView />} />;
    else if (paramId) return <EmptyState component={<AlarmDetailsView alarmId={paramId} />} />;
    else return <EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />;

}

const AlarmsPageComponent = ({ profiles }) => {
    const { isSuccess: loggedIn } = useSelector(userSelector);
    const viewAlarmsPermission = useSelector((state) => permissionSelector(state, 'view-alarms'));
    const viewAlarmProfilesPermission = useSelector((state) => permissionSelector(state, 'view-alarmProfiles'));

    if (loggedIn && (viewAlarmsPermission || viewAlarmProfilesPermission)) return <DefaultLayout page={<AlarmsPage profiles={profiles} />} />;
    else return <SplashPage />;
};

export default AlarmsPageComponent;