import React from 'react';
import { FormControl, FormLabel, InputAdornment, Stack, TextField, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { EmojiEventsOutlined, TrackChangesOutlined } from '@mui/icons-material';

const MeasurableObjectSection = ({ baseValue, setBaseValue, endValue, setEndValue, setEditMode }) => {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    return <>
        <Stack direction={smallScreen ? "column" : "row"} spacing={2}>
            <FormControl fullWidth>
                <FormLabel><FormattedMessage id="ESG.TARGET.VALUE.BASE" /></FormLabel>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="BASE"
                    color={baseValue === endValue || baseValue === "" ? "warning" : "primary"}
                    type="number"
                    size="small"
                    value={baseValue}
                    onChange={(e) => { setBaseValue(e.target.value); setEditMode(true) }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><TrackChangesOutlined fontSize='small' color={baseValue === endValue || baseValue === "" ? "warning" : "primary"} /></InputAdornment>,
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <FormLabel><FormattedMessage id="ESG.TARGET.VALUE.GOAL" /></FormLabel>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="END"
                    color={baseValue === endValue || endValue === "" ? "warning" : "primary"}
                    type="number"
                    size="small"
                    value={endValue}
                    onChange={(e) => { setEndValue(e.target.value); setEditMode(true) }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><EmojiEventsOutlined fontSize='small' color={baseValue === endValue || endValue === "" ? "warning" : "primary"} /></InputAdornment>,
                    }}
                />
            </FormControl>
        </Stack>
    </>
}

export default MeasurableObjectSection;