import React, { useEffect, useState } from "react";
import { Autocomplete, Button, Dialog, DialogActions, Divider, DialogContent, Stack, TextField, useMediaQuery, useTheme, Checkbox } from "@mui/material";
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router"
import { userSelector, permissionSelector } from "redux/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { AlertSnackbar, CompactMetricList, GroupHeader, GroupItems, DialogFormTitle, GoToButton } from 'components';
import { crudeCompareStringArrays } from "utils/helpers";
import { fetchTopics, selectTopics } from "redux/topicSlice";
import API from "api";


const AssignMetric = ({ open, onCloseDialog, topicId }) => {
    const navigate = useNavigate();
    const { token } = useSelector(userSelector);
    const topics = useSelector(selectTopics);
    const dispatch = useDispatch();
    const intl = useIntl();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [alert, setAlert] = useState({ open: false });

    const entityId = useParams().entityId;
    const [selectedMetrics, setSelectedMetrics] = useState([]);
    const [allMetrics, setAllMetrics] = useState([]);
    const [disableSubmitButton, setDisableSubmitButton] = useState(true);

    const updateTopicPermission = useSelector((state) => permissionSelector(state, 'update-topic'));

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (open) {
            API.metrics.getMetricAndComplexMetric(entityId, token).then(items => {
                if (items.data) {
                    const groupedMetrics = [];

                    Object.keys(items.data).forEach(metricType => {
                        items.data[metricType]?.forEach(metric => {
                            groupedMetrics.push({
                                ...metric,
                                metricType: metricType
                            })
                        })
                    })
                    setAllMetrics(groupedMetrics);
                    if (topicId) {
                        const topic = topics.find(topic => topic._id === topicId);
                        if (topic) {
                            const complex = topic.complexmetrics?.map(el => {
                                const metric = groupedMetrics.find(m => el._id === m._id);
                                return { ...metric, complex: true }
                            }).filter(el => el._id) || [];
                            const topicMetrics = topic.metrics?.map(el => {
                                const metric = groupedMetrics.find(m => el._id === m._id);
                                return metric;
                            }).filter(el => el._id) || [];

                            setSelectedMetrics([...topicMetrics, ...complex]);
                        }
                    }
                }
            }).catch(error => {
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
                console.error(error);
            })
        }

        return () => {
            setDisableSubmitButton(true);
        }

    }, [entityId, token, open, topics, topicId])

    const handleAssignMetric = () => {
        setDisableSubmitButton(true);
        const topic = topics.find(topic => topic._id === topicId);
        const selectedMetricIds = selectedMetrics.map((metric) => metric._id);
        const receivedMetricIds = [].concat(topic?.metrics).concat(topic?.complexmetrics).filter(el => el);

        const data = { metricIds: selectedMetricIds }

        if (crudeCompareStringArrays(selectedMetricIds, receivedMetricIds)) {
            setAlert({ open: true, messageId: "ERROR.NO_CHANGE_DETECTED", severity: "error" });
            setDisableSubmitButton(false);
        } else if (updateTopicPermission) {
            API.topic.updateTopicAssignMetrics(topicId, data, token).then(response => {
                setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
                dispatch(fetchTopics(token));
                setDisableSubmitButton(false);
                onCloseDialog();
            }).catch(error => {
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_ASSIGNED", severity: "error" });
                console.error(error);
                setDisableSubmitButton(false);
            })
        }
    }

    return (
        <>
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />

            <Dialog open={open} onClose={onCloseDialog} fullScreen={fullScreen} maxWidth='sm' fullWidth>
                <DialogFormTitle mainTitle={<FormattedMessage id="ESG.METRIC.ASSIGN" />} disableHelpText />
                <Divider />
                <DialogContent>
                    <Stack direction="column" justifyContent='space-between' spacing={2}>
                        <>
                            <Autocomplete
                                multiple
                                id="combo-box-demo-entities"
                                disableCloseOnSelect
                                limitTags={1}
                                options={allMetrics}
                                getOptionLabel={(option) => option.name}
                                groupBy={(option) => option.metricType}
                                fullWidth
                                value={selectedMetrics}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderInput={(params) => <TextField {...params} required label={intl.formatMessage({ id: "ESG.METRICS" })} placeholder={!selectedMetrics.length ? intl.formatMessage({ id: "NONE_SELECTED" }) : ""} />}
                                onChange={(event, newValue) => {
                                    setSelectedMetrics([...newValue]);
                                    setDisableSubmitButton(false);
                                }}
                                size="small"
                                renderOption={(props, option, { selected }) => {
                                    return <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankOutlined fontSize="small" />}
                                            checkedIcon={<CheckBoxOutlined fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </li>
                                }}
                                renderGroup={(params) => (
                                    <li key={params.key}>
                                        <GroupHeader><FormattedMessage id={"ESG." + params.group.toUpperCase()} /></GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                )}
                            />
                            <GoToButton sx={{ mt: 1, alignSelf: 'flex-start' }} label={<FormattedMessage id="ESG.METRIC.MANAGMENT" />} onClick={() => navigate('/metrics/' + entityId)} />
                        </>
                        <CompactMetricList metrics={selectedMetrics} />
                    </Stack>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button color="warning" onClick={onCloseDialog}><FormattedMessage id="CANCEL" /></Button>
                    <Button disabled={disableSubmitButton} onClick={handleAssignMetric} autoFocus><FormattedMessage id={"SUBMIT"} /></Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default AssignMetric;