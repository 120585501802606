import { FormControl, FormLabel, TextField } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';


const DescriptionField = ({ description, onChange, required, noCharacterLimit }) => {
    return <FormControl>
        <FormLabel required={!!required}><FormattedMessage id="DESCRIPTION" /></FormLabel>
        <TextField
            required={!!required}
            multiline
            fullWidth
            variant="outlined"
            id="DESCRIPTION"
            type="text"
            size="small"
            value={description}
            onChange={(e) => onChange(e.target.value)}
            rows={4}
            inputProps={!noCharacterLimit ? { maxLength: process.env.REACT_APP_ESG_INPUT_RESTRICTION_MEDIUM } : undefined}
        />
    </FormControl>
}

export default DescriptionField;