import React from 'react';
import { Drawer, Card, CardHeader, CardContent, Box, IconButton, useMediaQuery, Divider, Toolbar } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

const SideDrawer = (props) => {
    const { state, open, fixedWidth, noOverflow } = props;
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    return (
        <Drawer
            elevation={24}
            anchor={smallScreen ? 'top' : 'right'}
            open={open}
            onClose={() => props.toggleDrawer()}
            PaperProps={{
                sx: {
                    backgroundImage: 'none',
                }
            }}
        >
            <Box minWidth={320} width={smallScreen ? window.screen.width : (fixedWidth ?? undefined)} sx={theme => ({ overflow: 'hidden', height: '100vh' })}>
                <Card sx={{ backgroundImage: 'none', display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Toolbar />
                    <CardHeader
                        title={state.title}
                        subheader={state.subtitle}
                        action={
                            <IconButton onClick={() => props.toggleDrawer()}>
                                <CloseOutlined name="cross" />
                            </IconButton>
                        } />
                    <Divider />
                    <CardContent sx={{ overflow: noOverflow ? undefined : 'auto', height: '100%', flexGrow: 1 }}>
                        {state.display}
                    </CardContent>
                </Card>
            </Box >
        </Drawer >
    );
}

export default SideDrawer;