import { FormattedMessage } from "react-intl";
import { Typography, useTheme, Link } from "@mui/material";


export default function OWAttribution({ linkOnly }) {
    const theme = useTheme();
    const fontSize = theme.typography.fontSize;
    const imagePath = theme.palette.mode === "light" ? "/assets/OpenWeather.png" : "/assets/OpenWeather-Negative.png";
    const link = <Link rel="noopener noreferrer" target="_blank" underline="hover" href="https://openweathermap.org/">OpenWeatherMap<img src={window.location.origin + imagePath} alt="OWlogo" width={fontSize * 4} height={fontSize * 2} /></Link>;

    return <span>
        <Typography variant="caption">
            {!linkOnly && <FormattedMessage id="OWM.ATTRIBUTION" />}
            {link}
        </Typography>
    </span>
}