import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { setLanguage } from "redux/appSlice";
import { Avatar, Tooltip, Menu, Stack, MenuItem, Typography } from '@mui/material';
import { languages } from "lang";

const LanguageAvatar = (props) => {
    const { lang, onClick } = props;
    const languageList = {};
    Object.keys(languages).forEach(lang => {
        if (lang === 'en') languageList['gb'] = languages[lang][`LANGUAGE.${lang}`];
        else languageList[lang] = languages[lang][`LANGUAGE.${lang}`];
    })

    return <Tooltip title={languageList[lang]} placement="left" arrow>
        <Avatar onClick={onClick} sx={theme => ({ width: 40, height: 40, cursor: 'pointer', border: `3px solid ${props.active ? theme.palette.primary.main : 'transparent'}` })} alt={lang} src={`https://raw.githubusercontent.com/hampusborgos/country-flags/main/png250px/${lang}.png`} />
    </Tooltip>
}

const LanguageSelect = (props) => {
    const { openLang, setOpenLang, anchorLang } = props;
    const app = useSelector((state) => state.app);
    const dispatch = useDispatch();

    const handleLangChange = (lang) => {
        setOpenLang(false);
        dispatch(setLanguage(lang))
    }

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorLang}
            open={openLang}
            onClose={() => setOpenLang(false)}
        >
            {Object.keys(languages).map((lang, index) => (
                <MenuItem key={index} onClick={() => handleLangChange(lang)}>
                    <Stack spacing={2} direction='row' justifyContent="center" alignItems="center">
                        <LanguageAvatar active={app.lang === lang} lang={lang === 'en' ? 'gb' : lang} onClick={() => handleLangChange(lang)} />
                        <Typography sx={{ color: app.lang === lang ? "primary.main" : "inherit" }}><FormattedMessage id={"LANGUAGE." + lang} /></Typography>
                    </Stack>
                </MenuItem>
            ))}

        </Menu>

    );
};

export default LanguageSelect;