import { BuildOutlined, PlaceOutlined, InsertChartOutlined, HistoryEduOutlined, OutlinedFlag, QueryStatsOutlined, BusinessCenterOutlined } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Chip, IconButton, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectEntities, selectMeasureEntities } from "redux/entitySlice";
import { MeasureEntityForm } from 'components';
import { permissionSelector } from "redux/userSlice";

export const CustomButton = ({ variant, icon, onClick, label, color, disabled, curvedLeft, curvedRight }) => {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    return smallScreen
        ? <Box sx={{ mx: 0.5, display: 'flex', alignItems: 'center', color: color ? color + '.main' : 'primary.main', cursor: 'pointer' }}>
            <Tooltip title={label} arrow>
                <span>
                    <IconButton disabled={disabled} color={(variant === "contained" ? (color || "primary") : "default")} onClick={onClick}>{icon}</IconButton>
                </span>
            </Tooltip>
            <Typography component="span" variant="caption">
                {variant === "contained" && label}
            </Typography>
        </Box>
        : <Button sx={{ ...(curvedLeft && { borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }), ...(curvedRight && { borderTopRightRadius: 15, borderBottomRightRadius: 15 }) }} color={color} disabled={disabled} variant={variant} startIcon={!disabled ? icon : null} onClick={onClick}>{label}</Button>
}


export default function EntityLinksButtons({ entityId, entityMode, topicMode, measureMode, goalsMode, metricsMode, activityMode }) {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const allEntities = useSelector(selectEntities);
    const measureEntities = useSelector(selectMeasureEntities);
    const [openNewMeasureForm, setOpenNewMeasureForm] = useState(false);

    const viewGoalPermission = useSelector((state) => permissionSelector(state, 'view-goal'));
    const viewMetricPermission = useSelector((state) => permissionSelector(state, 'view-metric'));
    const viewMeasurementPermission = useSelector((state) => permissionSelector(state, 'view-measurement'));
    const viewTopicPermission = useSelector((state) => permissionSelector(state, 'view-topic'));
    const configureMeasureEntityPermission = useSelector((state) => permissionSelector(state, 'update-entity'));
    const viewActivitiesPermission = useSelector((state) => permissionSelector(state, 'view-activity'));

    const handleNavigate = (route, id) => {
        return navigate(id ? `/${route}/${id}` : `/${route}`);
    }

    return <>
        {entityId && <MeasureEntityForm entity={allEntities[entityId]} open={openNewMeasureForm} onClose={() => setOpenNewMeasureForm(false)} />}
        <Stack direction="row" gap={0.5} sx={{ overflowX: 'auto' }}>
            <ButtonGroup component="div" sx={{ mt: 1, ...(smallScreen && { width: '100%' }) }} size="small" variant="outlined">
                <CustomButton variant={entityMode ? "contained" : "outlined"} icon={<PlaceOutlined fontSize="small" />} onClick={() => handleNavigate('entities', entityId)} label={<FormattedMessage id="ENTITIES" />} />
                {viewTopicPermission && <CustomButton variant={topicMode ? "contained" : "outlined"} icon={<HistoryEduOutlined fontSize="small" />} onClick={() => handleNavigate('topics', entityId)} label={<FormattedMessage id="ESG.TOPICS" />} />}
                {viewGoalPermission && <CustomButton variant={goalsMode ? "contained" : "outlined"} icon={<OutlinedFlag fontSize="small" />} onClick={() => handleNavigate('goals', entityId)} label={<FormattedMessage id="ESG.GOALS" />} />}
                {viewMetricPermission && <CustomButton variant={metricsMode ? "contained" : "outlined"} icon={<QueryStatsOutlined fontSize="small" />} onClick={() => handleNavigate('metrics', entityId)} label={<FormattedMessage id="ESG.METRICS" />} />}
            </ButtonGroup>
            {entityId && viewActivitiesPermission && <ButtonGroup size="small" sx={{ mt: 1 }}><CustomButton variant={activityMode ? "contained" : "outlined"} icon={<BusinessCenterOutlined fontSize="small" />} onClick={() => handleNavigate('activities', entityId)} label={<FormattedMessage id="ACTIVITIES" />} /></ButtonGroup>}
            {entityId && (measureEntities[entityId]
                ? (viewMeasurementPermission && <ButtonGroup size="small" sx={{ mt: 1 }}><CustomButton key="measurements" variant={measureMode ? "contained" : "outlined"} icon={<InsertChartOutlined fontSize="small" />} onClick={() => navigate('/measureEntities/' + entityId)} label={<FormattedMessage id="MEASUREMENTS" />} /></ButtonGroup>)
                : configureMeasureEntityPermission
                    ? <Chip color="primary" variant="outlined" size="small" sx={{ ml: 0.5, mt: 1, alignSelf: 'center', textTransform: 'lowercase' }} icon={<BuildOutlined sx={{ ml: 0.5, pl: 0.5 }} />} onClick={() => setOpenNewMeasureForm(true)} label={<FormattedMessage id="CONFIGURE.DATA" />} />
                    : null
            )}
        </Stack>
    </>

}