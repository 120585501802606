import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectDataTypes } from 'redux/configurationSlice';
import { FormControl, FormLabel, MenuItem, Stack, TextField } from '@mui/material';
import { selectUnits, selectMeasurementsTypesConf } from 'redux/configurationSlice';
import { DescriptionField } from 'components';

const MeasurementTypeFormContent = ({ setEditMode, name, setName, dataType, setDataType, description, setDescription, measure, setMeasure, existingMeasurementType }) => {
    const intl = useIntl();
    const dataTypes = useSelector(selectDataTypes);
    const units = useSelector(selectUnits);
    const measureTypeNames = useSelector(selectMeasurementsTypesConf).map(el => el.key);
    const [invalidName, setInvalidName] = useState(false);

    useEffect(() => {
        if (existingMeasurementType && existingMeasurementType.key === name) setInvalidName(false);
        else if (name !== "" && measureTypeNames.includes(name)) setInvalidName(true);
        else setInvalidName(false);
    }, [existingMeasurementType, name, measureTypeNames]);

    return <Stack direction="column" spacing={2}>
        <FormControl>
            <FormLabel error={invalidName} required><FormattedMessage id="NAME" /></FormLabel>
            <TextField
                required
                fullWidth
                variant="outlined"
                id="NAME"
                error={invalidName}
                helperText={invalidName && <FormattedMessage id="MEASUREMENT_TYPE.NAME_TAKEN" />}
                type="text"
                size="small"
                value={name}
                onChange={(e) => { setName(e.target.value); setEditMode(true) }}
                inputProps={{ maxLength: process.env.REACT_APP_ESG_INPUT_RESTRICTION_SMALL }}
            />
        </FormControl>
        <DescriptionField description={description} onChange={(value) => { setDescription(value); setEditMode(true) }} />
        <FormControl>
            <FormLabel required><FormattedMessage id="VALUE_TYPE" /></FormLabel>
            <TextField
                select
                size="small"
                value={dataType}
                onChange={(e) => { setDataType(e.target.value); setEditMode(true) }}
            >
                {dataTypes.map(type => {
                    return <MenuItem key={type.key} value={type.key}><FormattedMessage id={"DATA_TYPE." + type.value} /></MenuItem>
                })}
            </TextField>
        </FormControl>

        <FormControl>
            <FormLabel><FormattedMessage id="UNIT_FAMILY" /></FormLabel>
            <TextField
                select
                fullWidth
                variant="outlined"
                id="UNIT_FAMILY"
                placeholder={intl.formatMessage({ id: "UNIT_FAMILY" })}
                type="text"
                size="small"
                value={measure}
                onChange={(e) => { setMeasure(e.target.value); setEditMode(true) }}
            >
                {Object.keys(units).map(unit => {
                    return <MenuItem key={unit} value={unit}><FormattedMessage id={unit} /></MenuItem>
                })}
            </TextField>
        </FormControl>
    </Stack >
}

export default MeasurementTypeFormContent;