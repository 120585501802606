import React, { useState, useEffect } from 'react';
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { userSelector, permissionSelector } from "redux/userSlice";
import { AlertSnackbar } from 'components';
import { Autocomplete, Button, ButtonGroup, Card, CardContent, CardHeader, Collapse, CardActions, TextField, FormLabel, Typography } from "@mui/material";
import { ConfirmationDialog, GoToButton } from 'components';
import API from 'api';

const MetricsEntry = () => {

    const navigate = useNavigate();
    const navigateState = useLocation().state;
    const { token } = useSelector(userSelector)

    const [metrics, setMetrics] = useState([]);
    const [selected, setSelected] = useState(navigateState?.metric || null);
    const [value, setValue] = useState('');
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [alert, setAlert] = useState({ open: false });

    const updateMetricPermission = useSelector((state) => permissionSelector(state, 'update-metric'));
    const createMeasurementPermission = useSelector((state) => permissionSelector(state, 'create-measurement'));

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        API.metrics.getAllMetrics(token, 'Qualitative')
            .then((response) => {
                setMetrics(response.data)
            }).catch(error => {
                setAlert({ open: true, messageId: error?.message?.id || "ERROR.FETCHING_DATA", severity: "error" });
                console.error(error);
            })
    }, [token])

    const handleCancel = () => {
        if (navigateState?.metric && navigateState?.entityId) navigate('/metrics/' + navigateState.entityId);
        else setOpenConfirmDialog(true);
    }

    const handleSubmit = () => {
        if (!updateMetricPermission) return null;

        const data = {
            'entityId': selected.entityId,
            'metricId': selected._id,
            'value': value,
            'timestamp': Date.now()
        }

        API.metricReports.putQualitativeMetricReport(token, data)
            .then((response) => {
                if (response.status >= 200 && response.status <= 300) {
                    setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
                    setSelected(null);
                    setValue('');
                    if (navigateState?.metric && navigateState?.entityId) navigate('/metrics/' + navigateState.entityId);
                }
            }).catch(error => {
                setAlert({ open: true, messageId: error?.message?.id || "ERROR.NOT_UPDATED", severity: "error" });
                console.error(error);
            })
    }

    const renderLinkButtons = () => {
        return <>
            {createMeasurementPermission && <ButtonGroup size="small" sx={{ mt: 2, mr: 0.5 }}>
                <Button variant="outlined" onClick={() => navigate('/measurementsEntry')}>
                    <FormattedMessage id="MANUAL" />
                </Button>
                <Button variant="outlined" onClick={() => navigate('/measurementsEntry/uploadFile')}>
                    <FormattedMessage id="FILE" />
                </Button>
            </ButtonGroup>}
            <Button size="small" variant="contained">
                <FormattedMessage id="ESG.METRICS" />
            </Button>
            <GoToButton disableConfirmation={!selected} sx={{ ml: 1 }} onClick={() => navigate('/metrics')} label={<FormattedMessage id="ESG.METRIC.MANAGMENT" />} />
        </>
    }

    return (
        <>
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
            <ConfirmationDialog
                open={openConfirmDialog}
                title={<FormattedMessage id="ESG.METRIC_ENTRY.CANCEL" />}
                customCancelTitle={<FormattedMessage id="NO" />}
                customButtonTitle={<FormattedMessage id="YES" />}
                handleCancel={() => setOpenConfirmDialog(false)}
                handleCustomButton={() => { setOpenConfirmDialog(false); setSelected(null); setValue('') }}
            />
            <Card sx={{ mx: 'auto', maxWidth: 800, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>

                    <CardHeader
                        title={<FormattedMessage id={"ESG.METRICS_ENTRY"} />}
                        subheader={navigateState?.metric ? <></> : renderLinkButtons()}
                    />
                    <CardContent>
                        {navigateState?.metric ?
                            <><FormLabel><FormattedMessage id="ESG.METRIC.QUALITATIVE" /></FormLabel>:<Typography variant="h6" color="primary" display="inline" sx={{ ml: 1 }}>{`${navigateState.metric.name} (${navigateState.metric.entityName})`}</Typography></> :
                            <Autocomplete
                                id="selectMetrics"
                                options={metrics}
                                value={selected}
                                onChange={(e, option) => setSelected(option)}
                                getOptionLabel={(option) => `${option.name} (${option.entityName})`}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderOption={(props, option) => (
                                    <li {...props} key={option._id}>
                                        {option.name} ({option.entityName})
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label={selected ? <FormattedMessage id="ESG.METRIC.QUALITATIVE" /> : <FormattedMessage id="SELECT.METRIC.QUALITATIVE" />} />
                                )}
                                size="small"
                                fullWidth
                            />}

                        <Collapse in={Boolean(selected)}>
                            <TextField
                                sx={{ mt: 2 }}
                                id="outlined-multiline-flexible"
                                label={<FormattedMessage id={"VALUE"} />}
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </Collapse>
                    </CardContent>
                </div>
                {selected &&
                    <CardActions>
                        <Button disabled={!updateMetricPermission || value === ''} onClick={handleSubmit}> <FormattedMessage id="SUBMIT" /> </Button>
                        <Button color="warning" onClick={handleCancel}> <FormattedMessage id="CANCEL" /> </Button>
                    </CardActions>}
            </Card>
        </>
    )
}

export default MetricsEntry;
