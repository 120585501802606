import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { selectDateRange, setDatesRange, setNamedRange } from 'redux/dateRangeSlice';
import { Avatar, Badge, Box, Button, IconButton, ButtonGroup, Tooltip, useMediaQuery } from '@mui/material';
import { TodayOutlined } from '@mui/icons-material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB, hr } from "date-fns/locale";


const DateRangeSelector = (props) => {
    const { direction, startDateLabel, endDateLabel, noButtons, dateFrom, dateTo, customOnChangeHandler } = props;
    const selectedDateRange = useSelector(selectDateRange);
    const { rangeName } = selectedDateRange;
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("lg"));
    const intl = useIntl();
    const dispatch = useDispatch();

    const changeDateFrom = useCallback((value) => {
        const startDate = new Date(value);
        if (customOnChangeHandler && customOnChangeHandler.startDate) customOnChangeHandler.startDate(startDate);

        else if (value && (startDate.getTime() < selectedDateRange.dateTo)) {
            dispatch(setDatesRange({
                dateFrom: startDate.getTime(),
                dateTo: selectedDateRange.dateTo
            }))
        }
        else if (value && (startDate.getTime() >= selectedDateRange.dateTo)) {
            dispatch(setDatesRange({
                dateFrom: startDate.getTime(),
                dateTo: startDate.getTime() + 1
            }))
        }

        Object.keys(props).length !== 0 && props.hasOwnProperty("setEditMode") && props.setEditMode(true);
    }, [selectedDateRange, customOnChangeHandler, dispatch, props]);

    const changeDateTo = useCallback((value) => {
        const endDate = new Date(value);
        if (customOnChangeHandler && customOnChangeHandler.endDate) customOnChangeHandler.endDate(endDate);

        else if (value && (endDate.getTime() > selectedDateRange.dateFrom)) {
            dispatch(setDatesRange({
                dateFrom: selectedDateRange.dateFrom,
                dateTo: endDate.getTime()
            }))
        }
        else if (value && (endDate.getTime() <= selectedDateRange.dateFrom)) {
            dispatch(setDatesRange({
                dateFrom: endDate.getTime() - 1,
                dateTo: endDate.getTime()
            }))
        }

        Object.keys(props).length !== 0 && props.hasOwnProperty("setEditMode") && props.setEditMode(true);
    }, [selectedDateRange, customOnChangeHandler, dispatch, props]);

    const renderButtons = useCallback(() => {
        const column = direction === "column" || (!direction && smallScreen);
        if (noButtons) return null;

        return <>
            {column ? <Badge badgeContent={
                <Tooltip arrow title={<FormattedMessage id="TODAY" />}>
                    <IconButton color="primary" onClick={() => dispatch(setNamedRange('today'))}>
                        <Avatar sx={{ width: 25, height: 25, bgcolor: rangeName === 'today' ? 'primary.main' : 'default' }}><TodayOutlined fontSize="small" /></Avatar>
                    </IconButton>
                </Tooltip>
            } />
                : <Button
                    name='btnToday' key="today"
                    sx={{ textTransform: 'none', flex: 1 }}
                    onClick={() => dispatch(setNamedRange('today'))}
                    variant={rangeName === 'today' ? 'contained' : 'outlined'}
                >
                    <FormattedMessage id="TODAY" />
                </Button>}
            <Button
                name='btnWeek' key="week"
                sx={{ textTransform: 'none', flex: 1 }}
                onClick={() => dispatch(setNamedRange('week'))}
                variant={rangeName === 'week' ? 'contained' : 'outlined'}
            >
                <FormattedMessage id="GROUPING.WEEK" />
            </Button>
            <Button
                name='btnMonth' key="month"
                sx={{ textTransform: 'none', flex: 1 }}
                onClick={() => dispatch(setNamedRange('month'))}
                variant={rangeName === 'month' ? 'contained' : 'outlined'}
            >
                <FormattedMessage id="GROUPING.MONTH" />
            </Button>
            <Button
                name='btnYear' key="year"
                sx={{ textTransform: 'none', flex: 1 }}
                onClick={() => dispatch(setNamedRange('year'))}
                variant={rangeName === 'year' ? 'contained' : 'outlined'}
            >
                <FormattedMessage id="YEAR" />
            </Button>
        </>
    }, [noButtons, rangeName, dispatch, direction, smallScreen]);

    const flexDirection = direction ? direction : (smallScreen ? "column" : "row");

    return (
        <Box sx={{ display: 'flex', flexDirection: flexDirection, gap: '8px', pt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                <DateTimePicker
                    label={startDateLabel ? startDateLabel : intl.formatMessage({ id: "DATE_FROM" })}
                    value={dateFrom ? dateFrom : new Date(selectedDateRange.dateFrom)}
                    onChange={changeDateFrom}
                    slotProps={{ textField: { size: 'small', fullWidth: true } }}
                />
                <DateTimePicker
                    label={endDateLabel ? endDateLabel : intl.formatMessage({ id: "DATE_TO" })}
                    value={dateTo ? dateTo : new Date(selectedDateRange.dateTo)}
                    onChange={changeDateTo}
                    slotProps={{
                        textField: {
                            size: 'small',
                            fullWidth: true,
                            sx: { mt: flexDirection === "column" ? 1 : 0 }
                        }
                    }}
                />
            </LocalizationProvider>

            {!noButtons && flexDirection === "column" ?
                <ButtonGroup fullWidth sx={{ ...(flexDirection === "column" && { mt: 1 }) }}>
                    {renderButtons()}
                </ButtonGroup>
                : renderButtons()
            }
        </Box>
    );
}

export default DateRangeSelector;