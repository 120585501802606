import { AccountTreeOutlined, SegmentOutlined } from "@mui/icons-material";
import { Box, Card, IconButton, Stack, Tooltip, CardContent, Collapse } from "@mui/material";
import { SideDrawer, EntityBreadcrumbs, EntityLinksButtons, SearchBar, LoadingData } from 'components';
import { Suspense, lazy, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

const EntityTree = lazy(() => import('components/entityTree/EntityTree.js'));

export default function BreadcrumbsAndNavigation({ noMargin, entity, baseNavigate, rootUrl, titleSuffix, entityMode, topicMode, measureMode, metricsMode, goalsMode, activityMode }) {
    const intl = useIntl();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openTreeView, setOpenTreeView] = useState(false);
    const [search, setSearch] = useState("");
    const [foundEntities, setFoundEntities] = useState([]);

    return <Card sx={{ mb: !noMargin ? 1 : 0, width: '100%' }}>
        <SideDrawer noOverflow fixedWidth={360} open={openDrawer} state={
            {
                title: <FormattedMessage id='ORGANIZATION_VIEW' />,
                subtitle: <SearchBar variant="standard" sx={{ p: 0, pt: 1 }} tooltip search={search} setSearch={setSearch} helperText={search.length > 0 ? `${intl.formatMessage({ id: 'FOUND' })} ${foundEntities.length} ${intl.formatMessage({ id: 'ENTITIES_AKUZATIV' }).toLowerCase()}` : undefined} />,
                display: <Suspense fallback={<LoadingData />}>
                    <EntityTree search={search} foundEntities={foundEntities} setFoundEntities={setFoundEntities} entityId={entity?._id} navigateTo={baseNavigate} rootUrl={rootUrl} />
                </Suspense>
            }
        }
            toggleDrawer={() => setOpenDrawer(!openDrawer)} />
        <Box sx={{ p: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                <EntityBreadcrumbs entity={entity} navigateTo={baseNavigate} suffix={titleSuffix} rootUrl={rootUrl} />
                <Box display="flex">
                    <Tooltip title={<FormattedMessage id="TREE_VIEW.OPEN" />} placement="bottom" arrow><span>
                        <IconButton color={openTreeView ? "secondary" : "default"} onClick={() => setOpenTreeView(!openTreeView)}><AccountTreeOutlined /></IconButton>
                    </span></Tooltip>
                    <Tooltip title={<FormattedMessage id="ORGANIZATION_VIEW" />} placement="bottom" arrow><span>
                        <IconButton onClick={() => setOpenDrawer(!openDrawer)}><SegmentOutlined className="icon-flipped" /></IconButton>
                    </span></Tooltip>
                </Box>
            </Stack>
            <EntityLinksButtons entityId={entity?._id} entityMode={entityMode} topicMode={topicMode} measureMode={measureMode} metricsMode={metricsMode} goalsMode={goalsMode} activityMode={activityMode} />
        </Box>
        <Collapse in={openTreeView}>
            <CardContent className="graph-tree" sx={{ height: '50vh' }}>
                <Suspense fallback={<LoadingData />}>
                    <EntityTree isOpen={openTreeView} onClose={() => setOpenTreeView(false)} entityId={entity?._id} graphFlag={true} navigateTo={baseNavigate} rootUrl={rootUrl} />
                </Suspense>
            </CardContent>
        </Collapse>
    </Card>
}