import instance from "./axiosEnv";
import { setAuthHeader } from "./axiosEnv";

const getAllAlarmProfiles = (token, entityId) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    if (entityId) for (let el in entityId) {
        queryParams.append('entityId', entityId[el]);
    }

    return instance.get('/alarmProfiles?' + queryParams.toString(), config);
}

const getAlarmProfilesCount = (token, entityId, display) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    if (entityId) for (let el in entityId) {
        queryParams.append('entityId', entityId[el]);
    }
    if (display) queryParams.append('display', display);

    return instance.get('/alarmProfiles/count?' + queryParams.toString(), config);
}

const getAlarmProfile = (profileId, token) => {
    const config = setAuthHeader(token);
    return instance.get('/alarmProfiles/' + profileId, config);
}

const createAlarmProfile = (data, token) => {
    const config = setAuthHeader(token);
    return instance.post('/alarmProfiles', data, config);
}

const updateAlarmProfile = (profileId, data, token) => {
    const config = setAuthHeader(token);
    return instance.put('/alarmProfiles/' + profileId, data, config);
}

const enableAlarmProfile = (profileId, token) => {
    const config = setAuthHeader(token);
    return instance.put('/alarmProfiles/' + profileId + '/enable', null, config);
}

const disableAlarmProfile = (profileId, token) => {
    const config = setAuthHeader(token);
    return instance.put('/alarmProfiles/' + profileId + '/disable', null, config);
}

const deleteAlarmProfile = (profileId, token) => {
    const config = setAuthHeader(token);
    return instance.delete('/alarmProfiles/' + profileId, config);
}

const alarmProfileAPI = {
    getAllAlarmProfiles,
    getAlarmProfilesCount,
    getAlarmProfile,
    createAlarmProfile,
    updateAlarmProfile,
    enableAlarmProfile,
    disableAlarmProfile,
    deleteAlarmProfile,
};

export default alarmProfileAPI;