import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from 'api';

const initialState = {
  data: [],
  status: 'idle',
  error: null
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchTopics = createAsyncThunk('topics/fetchTopics', async (token) => {
  const { getAllTopics } = API.topic;
  if (token) {
    const response = await getAllTopics(token);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
  return initialState.data;
}
);

export const topicSlice = createSlice({
  name: 'topic',
  initialState,
  reducers: {
    addTopic: (state, action) => {
      if (action.payload.length > 1) state.data.push(...action.payload);
      else {
        const topic = action.payload;
        if (topic.connections.parentId) {
          const parentIndex = state.data.findIndex(el => el._id === topic.connections.parentId);
          if (parentIndex > -1) state.data[parentIndex].connections.childrenArray.push(topic._id);
        }
        state.data.push(topic);
      }
    },
    updateTopicState: (state, action) => {
      const topicIndex = state.data.findIndex(el => el._id === action.payload._id);
      if (topicIndex > -1) state.data[topicIndex] = {
        ...state.data[topicIndex],
        ...action.payload
      };
      else state.data.push(action.payload);
    },
    removeTopic: (state, action) => {
      const topic = action.payload;
      const topicIndex = state.data.findIndex(el => el._id === topic._id);
      if (topicIndex > -1) state.data.splice(topicIndex, 1);
      if (topic.connections.parentId) {
        const parentIndex = state.data.findIndex(el => el._id === topic.connections.parentId);
        if (parentIndex > -1) state.data[parentIndex].connections.childrenArray = state.data[parentIndex].connections.childrenArray.filter(el => el !== topic._id);
      }
    },
    removeMetricFromTopic: (state, action) => {
      state.data.forEach(topic => {
        if (topic.metricIds.includes(action.payload._id)) {
          topic.metricIds.splice(topic.metricIds.indexOf(action.payload._id), 1);
          if (topic.metrics && topic.metrics.find(metric => metric._id === action.payload._id)) {
            topic.metrics?.splice(topic.metrics.indexOf(topic.metrics.find(metric => metric._id === action.payload._id)), 1);
          }
          else {
            topic.complexmetrics?.splice(topic.complexmetrics.indexOf(topic.complexmetrics.find(metric => metric._id === action.payload._id)), 1);
          }
        }
      })
    },
    updateMetricInTopic: (state, action) => {
      state.data.forEach(topic => {
        if (topic.metricIds.includes(action.payload._id)) {
          if (topic.metrics && topic.metrics.find(metric => metric._id === action.payload._id)) {
            topic.metrics[topic.metrics.indexOf(topic.metrics.find(metric => metric._id === action.payload._id))] = { name: action.payload.name, _id: action.payload._id };
          }
          else {
            topic.complexmetrics[topic.complexmetrics.indexOf(topic.complexmetrics.find(metric => metric._id === action.payload._id))] = { name: action.payload.name, _id: action.payload._id };
          }
        }
      })
    },

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTopics.rejected, (state) => {
        console.error("Failed to load topics...");
        state.status = 'failed';
      })
      .addCase(fetchTopics.fulfilled, (state, action) => {
        state.status = 'complete';
        state.data = action.payload;
      });
  },
});

export const { addTopic, updateTopicState, removeTopic, removeMetricFromTopic, updateMetricInTopic } = topicSlice.actions;

export const selectTopics = (state) => {
  return state.topic.data;
};

export default topicSlice.reducer;