
import { useState, useEffect, useCallback } from 'react';
import { Dialog, CardHeader, DialogContent, DialogActions, Divider, Switch, Button, Typography, Avatar, useMediaQuery, Alert, Stack } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { CustomDataGrid, DateRangeSelector, MetricHistoryChartView, LoadingData } from 'components';
import { Timeline, ViewList } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import { selectDateRange } from "redux/dateRangeSlice";
import { localISOString } from 'utils';
import { renderDateCell } from 'components/dashboardView/TableHeaders';
import API from 'api';

const SwitchIcon = ({ graphView }) => {
    return <Avatar sx={{ width: 24, height: 24, bgcolor: 'primary.main' }}>
        {graphView ? <Timeline fontSize="small" /> : <ViewList fontSize="small" />}
    </Avatar>;
}

const MetricHistoryReportView = ({ metricId, openDialog, onCloseHandler, entityName, reportName, type, label }) => {

    const [allReports, setAllReports] = useState([]);
    const [graphView, setGraphView] = useState(false);

    const [loading, setLoading] = useState(false);
    const { token } = useSelector(userSelector);
    const selectedDateRange = useSelector(selectDateRange);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const intl = useIntl();

    useEffect(() => {
        setLoading(true);
        if (metricId && openDialog === true) API.metricReports.getAllMetricReports(token, [metricId], localISOString(selectedDateRange.dateFrom), localISOString(selectedDateRange.dateTo)).then(items => {
            if (items.data) {
                const reports = items.data.map((item) => {
                    return {
                        id: item._id,
                        name: item.metricId?.name,
                        value: item.value,
                        unit: item.metricId?.reportData?.reportUnit?.unit || item.metricId?.category?.scope?.unit,
                        calculationMethod: item.metricId?.reportData?.calculationMethod,
                        startDate: item.datetime?.dateFrom,
                        endDate: item.datetime?.dateTo,
                        lastUpdated: item.datetime?.lastUpdated,
                        reportInterval: item.metricId?.reportData?.reportInterval,
                        measurementTypeKey: item.metricId?.category?.scope?.measurementTypeKey
                    }
                })
                setAllReports(reports);
            }
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
            setAllReports({ error });
        })
        return (() => {
            setAllReports([]);
            setLoading(false);
        })

    }, [metricId, openDialog, token, selectedDateRange]);

    const renderTable = useCallback(() => {
        const headers = [
            {
                field: 'reportInterval',
                headerName: intl.formatMessage({ id: "REPORT_INTERVAL" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {intl.formatMessage({ id: "GROUPING." + row.row.reportInterval?.toUpperCase() })} </Typography>,
                minWidth: 150
            },
            {
                field: 'startDate',
                type: 'dateTime',
                headerName: intl.formatMessage({ id: "DATE_FROM" }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: ({ row }) => new Date(row.startDate ? row.startDate : undefined),
                ...renderDateCell(intl),
                minWidth: 150
            },
            {
                field: 'endDate',
                type: 'dateTime',
                headerName: intl.formatMessage({ id: "DATE_TO" }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: ({ row }) => new Date(row.endDate ? row.endDate : undefined),
                ...renderDateCell(intl),
                minWidth: 150
            },
            {
                field: 'lastUpdated',
                type: 'dateTime',
                headerName: intl.formatMessage({ id: "LAST_UPDATED" }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: ({ row }) => new Date(row.lastUpdated ? row.lastUpdated : undefined),
                ...renderDateCell(intl),
                minWidth: 150
            },
            {
                field: 'lastValue',
                headerName: intl.formatMessage({ id: "MEASUREMENTS.LAST_VALUE" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.row.value !== null ? `${row.row.value} ${row.row.unit !== undefined && row.row.calculationMethod !== "count" ? row.row.unit : ''}` : ''} </Typography>,
                minWidth: 200
            },
        ]
        if (loading) return <LoadingData />;
        else if (allReports?.error) return <Alert severity='error'><FormattedMessage id="ERROR.NO_DATA" /></Alert>;
        else return <CustomDataGrid rows={allReports} columns={headers} />

    }, [intl, loading, allReports, smallScreen])

    return (
        <Dialog
            open={openDialog}
            maxWidth={'lg'}
            fullWidth
            fullScreen={smallScreen}
            onClose={onCloseHandler}
        >
            <CardHeader
                avatar={type && <Avatar sx={{ bgcolor: 'primary.main' }}>{type}</Avatar>}
                title={<Typography variant="h6"><FormattedMessage id="ESG.METRIC.PREVIOUS_REPORTS" /></Typography>}
                action={<Switch icon={<SwitchIcon graphView={graphView} />} checkedIcon={<SwitchIcon graphView={graphView} />}
                    checked={graphView} onChange={() => setGraphView(!graphView)} sx={{ minWidth: 50, mb: 3 }} />}
                subheader={<Stack direction="column" gap={0.5}>
                    <Typography color="primary" width='100%' >{reportName} </Typography>
                    <Typography> {entityName} </Typography>
                </Stack>
                }
            />
            <DialogContent>
                <DateRangeSelector column sx={{ mb: 5 }} />
            </DialogContent>

            <DialogContent>
                {graphView ? <MetricHistoryChartView metricId={metricId} label={label} reportName={reportName} /> : renderTable()}

            </DialogContent>
            <Divider />
            <DialogActions>
                <Button color="warning" onClick={() => { onCloseHandler(); setGraphView(false) }}><FormattedMessage id="CLOSE" /></Button>
            </DialogActions>
        </Dialog>
    )
}
export default MetricHistoryReportView;
