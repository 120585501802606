import instance, { setAuthHeader } from "./axiosEnv";

const getAllUserGroups = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/userGroups', config);
}

const getAllUsersAndGroups = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/userGroups/users', config);
}

const deleteUserGroup = (userGroupId, token) => {
    const config = setAuthHeader(token);
    return instance.delete('/userGroups/' + userGroupId, config);
}

const updateUserGroup = (userGroupId, data, token) => {
    const config = setAuthHeader(token);
    return instance.put('/userGroups/' + userGroupId, data, config);
}

const createUserGroup = (data, token) => {
    const config = setAuthHeader(token);
    return instance.post('/userGroups', data, config);
}

const userGroupAPI = {
    getAllUserGroups,
    getAllUsersAndGroups,
    deleteUserGroup,
    updateUserGroup,
    createUserGroup
};

export default userGroupAPI;