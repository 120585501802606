import { DefaultLayout, EmptyState } from 'layouts';
import { SplashPage } from 'pages';
import { NotesView } from 'components';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';



const NotesPage = () => {
    return <EmptyState component={<NotesView />} />;
}

const NotesPageComponent = () => {
    const { isSuccess: loggedIn } = useSelector(userSelector);
    const viewNotesPermission = useSelector((state) => permissionSelector(state, 'view-notes'));

    if (loggedIn && viewNotesPermission) return <DefaultLayout page={<NotesPage />} />;
    else return <SplashPage />;
};

export default NotesPageComponent;