import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormatSelect, LanguageSelect, UserMenu } from 'components';
import { useSelector, useDispatch } from "react-redux";
import { setDarkTheme } from "redux/appSlice";
import { AppBar as MuiAppBar, Box, Toolbar, IconButton, Link, Typography, Tooltip } from '@mui/material';
import { DarkModeOutlined, FormatSize, LightModeOutlined, LoginOutlined, MenuOutlined, MenuOpenOutlined, TranslateOutlined } from '@mui/icons-material';
import API from 'api';

const AppBar = (props) => {
    const { toggleDrawer, open, title, login } = props;
    const app = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const [openLang, setOpenLang] = useState(false);
    const [format, setFormat] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorLang, setAnchorLang] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setFormat(true);
    };

    const handleLanguage = (event) => {
        setAnchorLang(event.currentTarget);
        setOpenLang(true);
    };

    return (
        <MuiAppBar position="absolute" sx={theme => ({ zIndex: theme.zIndex.drawer + 1 })}>
            <Toolbar sx={{ flexWrap: 'wrap', justifyContent: 'flex-end' }}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                >
                    {open ? <MenuOpenOutlined /> : <MenuOutlined />}
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    noWrap
                    sx={{ flexGrow: 1, ml: 2 }}
                >
                    <Link underline='none' href="/" color="inherit">
                        {title}
                    </Link>
                </Typography>

                <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title={<FormattedMessage id="CHANGE_FORMAT" />} placement="bottom" arrow>
                        <IconButton onClick={handleClick} color="inherit"
                        >
                            <FormatSize fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <FormatSelect anchorEl={anchorEl} format={format} setFormat={setFormat} />

                    <Tooltip title={<FormattedMessage id="CHANGE_LANGUAGE" />} placement="bottom" arrow>
                        <IconButton onClick={handleLanguage} color="inherit">
                            <TranslateOutlined fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <LanguageSelect openLang={openLang} setOpenLang={setOpenLang} anchorLang={anchorLang} />

                    {!login ? <Tooltip title={<FormattedMessage id="TOGGLE_MODE" />} placement="bottom" arrow>
                        {
                            app.darkTheme ?
                                <IconButton onClick={() => dispatch(setDarkTheme(false))} color="inherit"><DarkModeOutlined fontSize='small' /></IconButton>
                                : <IconButton onClick={() => dispatch(setDarkTheme(true))} color="inherit"><LightModeOutlined fontSize='small' /></IconButton>

                        }
                    </Tooltip> : null}
                    {login ?
                        <Tooltip title={<FormattedMessage id="LOG_IN" />} placement="bottom" arrow>
                            <IconButton onClick={() => API.authKeycloak('login-required')} color="inherit"><LoginOutlined /></IconButton>
                        </Tooltip>
                        : <UserMenu />
                    }
                </Box>
            </Toolbar>
        </MuiAppBar>
    );
};

export default AppBar;