import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMeasurementsTypesConf } from 'redux/configurationSlice';
import { selectDateRange } from 'redux/dateRangeSlice';
import { userSelector } from 'redux/userSlice';
import { useIntl, FormattedMessage } from 'react-intl';
import { CustomDataGrid, LoadingData, SideDrawer, MeasurementDetails } from "components";
import { renderDateCell } from 'components/dashboardView/TableHeaders';
import { Alert, useMediaQuery } from '@mui/material';
import API from 'api';



const MeasurementsTableContainer = ({ entityId, measureTypes, collectorTypes }) => {
    const { token } = useSelector(userSelector);
    const selectedDateRange = useSelector(selectDateRange);
    const measurementTypes = useSelector(selectMeasurementsTypesConf);
    const intl = useIntl();
    const [tableData, setTableData] = useState({ loading: true });
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [openDrawer, setOpenDrawer] = useState(false);
    const [measurementDetails, setMeasurementDetails] = useState({});


    useEffect(() => {
        setTableData({ loading: true });
        API.measurements.getMeasurementValuesHistory(token, selectedDateRange.dateFrom, selectedDateRange.dateTo, [entityId], measureTypes, collectorTypes).then(items => {
            if (items.data) {
                const data = [];
                items.data.forEach(measurement => {
                    const measurementType = measurementTypes.find(mt => mt.key === measurement.key);
                    measurement.values.forEach(collection => {
                        data.push({
                            id: collection.id,
                            date: new Date(collection.y),
                            value: collection.x,
                            unit: collection.unit,
                            measurementType: measurementType.name || intl.formatMessage({ id: measurementType.key }),
                        })
                    })
                })
                setTableData(data);
            }
            else setTableData([]);
        }).catch(error => {
            console.error(error);
            setTableData({ error })
        })
    }, [entityId, intl, measurementTypes, selectedDateRange.dateFrom, selectedDateRange.dateTo, measureTypes, collectorTypes, token])



    const renderTable = useCallback(() => {
        const handleRowClick = (row) => {
            setOpenDrawer(true);
            setMeasurementDetails({
                title: <FormattedMessage id='LOADING_DATA' />,
                subtitle: <FormattedMessage id='SPLASH.WAIT' />,
                display: <LoadingData noText />
            });
            API.measurements.getSingleMeasurement(token, row.row.id).then(item => {
                if (item.data) {
                    setMeasurementDetails({
                        title: <FormattedMessage id="MEASUREMENT_DETAILS" />,
                        subtitle: <></>,
                        display: <MeasurementDetails measurement={item.data} sideDrawer />
                    });
                }
            })
                .catch(error => {
                    setMeasurementDetails({
                        title: <FormattedMessage id='ERROR' />,
                        subtitle: <></>,
                        display: <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>
                    });
                    console.error(error);
                })
        }

        const tableHeaders = [
            {
                field: 'date',
                type: 'dateTime',
                headerName: intl.formatMessage({ id: "DATE" }),
                ...renderDateCell(intl),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 200 }),
            },
            {
                field: 'measurementType',
                headerName: intl.formatMessage({ id: "MEASUREMENT_TYPE" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 200 }),
            },
            {
                field: 'value',
                type: 'number',
                headerName: intl.formatMessage({ id: "VALUE" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 150 }),
            },
            {
                field: 'unit',
                headerName: intl.formatMessage({ id: "UNIT" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 150 }),
            },
        ];

        if (tableData.loading) return <LoadingData />;
        else if (tableData.error) return <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>;
        else return <CustomDataGrid rows={tableData} columns={tableHeaders} handleData={handleRowClick} enableExport />;

    }, [intl, smallScreen, tableData, token])

    return <>
        {renderTable()}
        <SideDrawer open={openDrawer} toggleDrawer={() => setOpenDrawer(false)} state={measurementDetails} />
    </>
}

export default MeasurementsTableContainer;