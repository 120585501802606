import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Autocomplete, Chip, FormControl, FormHelperText, FormLabel, TextField, Typography } from '@mui/material';


const FreeMultipleForm = ({ participants, onChange, label }) => {
    const [inputValue, setInputValue] = useState('');
    const intl = useIntl();
    const [focused, setFocused] = useState(false);

    return <FormControl>
        {label && <FormLabel>{label}</FormLabel>}
        <Autocomplete
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            id={"free-multiple-form-" + label}
            open={false}
            freeSolo
            multiple
            options={participants}
            value={participants}
            onInputChange={(e, participant, reason) => {
                if (reason === "clear") onChange([])
                else setInputValue(p => participant.trim().length > 0 ? participant : participant.trim());
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && inputValue.trim() !== '') {
                            e.preventDefault();
                            if (!participants.includes(inputValue.trim())) onChange([...participants, inputValue.trim()])
                            setInputValue('');
                        }
                        else if (e.key === 'Backspace' && inputValue === '') {
                            const lastChipIndex = participants.length - 1;
                            if (lastChipIndex >= 0) {
                                const updatedParticipants = [...participants];
                                updatedParticipants.splice(lastChipIndex, 1);
                                onChange(updatedParticipants);
                            }
                        }
                    }}
                />
            )}
            renderTags={(options, getTagProps) => {
                return options.map((option, index) => (
                    <Chip
                        size='small'
                        label={option}
                        {...getTagProps({ index })}
                        onDelete={() => {
                            const updatedParticipants = [...participants];
                            updatedParticipants.splice(index, 1);
                            onChange(updatedParticipants);
                            options.splice(index, 1)
                        }}
                    />
                ))
            }}
            size="small"
            fullWidth
        />
        {focused && <FormHelperText>
            <Typography variant='caption'>{intl.formatMessage({ id: "INPUT.ENTER" })}</Typography>
        </FormHelperText>}
    </FormControl>


}

export default FreeMultipleForm;