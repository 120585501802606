import instance, { setAuthHeader } from "./axiosEnv";

const getDashboard = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/dashboard', config);
}

const updateDashboard = (token, dashboardId, newDashboardObject) => {
    const config = setAuthHeader(token);
    return instance.put('/dashboard/' + dashboardId, newDashboardObject, config);
}

const dashboardAPI = {
    getDashboard,
    updateDashboard,
}

export default dashboardAPI;