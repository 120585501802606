import instance from "./axiosEnv";
import { setAuthHeader, setContentTypeHeader } from "./axiosEnv";

const getAllRoles = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/roles', config);
}

const getRole = (roleId, token) => {
    const config = setAuthHeader(token);
    return instance.get('/roles/'+ roleId, config);
}

const createRole = (data, token) => {
    const config = setAuthHeader(token);
    return instance.post('/roles', data, config);
}

const updateRole = (roleId, data, token) => {
    const config = setAuthHeader(token);
    return instance.put('/roles/'+ roleId, data, config);
}

const deleteRole = (roleId, token) => {
    const config = setAuthHeader(token);
    return instance.delete('/roles/'+ roleId, config);
}

const getUserRoles = (token, userId) => {
    const config = setAuthHeader(token);
    return instance.get('/userRoleMap/' + userId, config);
}

const createUserRoles = (token,body) => {
    const config = setAuthHeader(token);
    const contentType = setContentTypeHeader('JSON');

    const headers = {headers: {...config.headers, ...contentType?.headers}}

    return instance.post('/userRoleMap', JSON.stringify(body), headers);
}

const putUserRoles = (token, userId, body) => {
    const config = setAuthHeader(token); 
    const contentType = setContentTypeHeader('JSON');

    const headers = {headers: {...config.headers, ...contentType?.headers}}

    return instance.put('/userRoleMap/' + userId, JSON.stringify(body), headers);
}

const roleAPI = {
    getAllRoles,
    getRole,
    createRole,
    updateRole,
    deleteRole,
    getUserRoles,
    createUserRoles,
    putUserRoles,
};

export default roleAPI;